import { switchMap, filter, map } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { getRolesDictionaryData } from '@/features/accounts/services/dictionaries';
import { dictionaryEffectHelper } from '@/common/utils';
import { rolesActions } from './actions';

const getRolesList: Epic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(rolesActions.request)),
    switchMap(() => {
      const state = state$.value.roles;
      return dictionaryEffectHelper(() => getRolesDictionaryData(), state, rolesActions);
    })
  );

export const rolesEpics = [getRolesList];
