import { ActionType, Action } from 'typesafe-actions';

import { ApplicationsDictionaryVM } from '@/models/applications';
import { createDictionaryActions } from '@/common/utils/store';

export const applicationsActions = createDictionaryActions(
  `FETCH_APPLICATIONS_DICTIONARY_REQUEST`,
  `FETCH_APPLICATIONS_DICTIONARY_SUCCESS`,
  `FETCH_APPLICATIONS_DICTIONARY_FAILURE`,
  `FETCH_APPLICATIONS_DICTIONARY_CANCEL`,
  `FETCH_APPLICATIONS_DICTIONARY_SILENT_REQUEST`
)<undefined, ApplicationsDictionaryVM>();

export type applicationsActions = Action | ActionType<typeof applicationsActions>;
