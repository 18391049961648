import { switchMap, filter, map } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { getVoucherBatchListData } from '@/features/management/services/voucher-batches/voucher-batch-list';
import { getVoucherBatchDetailsData } from '@/features/management/services/voucher-batches/voucher-batch-details';
import { getVoucherBatchStatusesData } from '@/features/management/services/voucher-batches/dictionaries';
import { untilLocationChange, CommonError, dictionaryEffectHelper } from '@/common/utils';
import { voucherBatchReducer } from './reducers';
import { voucherBatchActions } from './actions';

const getVoucherBatchList: Epic<voucherBatchActions, voucherBatchActions, voucherBatchReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(voucherBatchActions.list.request)),
    switchMap(() =>
      getVoucherBatchListData(state$.value.voucherBatch.list.params).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return voucherBatchActions.list.failure(response);
          }

          return voucherBatchActions.list.success(response);
        })
      )
    )
  );

const getVoucherBatchDetails: Epic<voucherBatchActions, voucherBatchActions, voucherBatchReducer> = action$ =>
  action$.pipe(
    filter(isActionOf(voucherBatchActions.details.request)),
    switchMap(action =>
      getVoucherBatchDetailsData(action.payload.params.id)
        .pipe(untilLocationChange(action$))
        .pipe(
          map(response => {
            if (response instanceof CommonError) {
              return voucherBatchActions.details.failure(response);
            }

            return voucherBatchActions.details.success(response);
          })
        )
    )
  );

const getStatusDictionary: Epic<voucherBatchActions, voucherBatchActions, voucherBatchReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(voucherBatchActions.statuses.request)),
    switchMap(() => {
      const state = state$.value.voucherBatch.statuses;
      return dictionaryEffectHelper(() => getVoucherBatchStatusesData(), state, voucherBatchActions.statuses);
    })
  ) as any;

export const voucherBatchEpics = [getVoucherBatchList, getStatusDictionary, getVoucherBatchDetails];
