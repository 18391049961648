import { useCallback, useState, useRef, useEffect } from 'react';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { AutocompleteOption, AutocompleteOnInputChangeFunc, AutocompleteOnChangeFunc, FilterOptionsFunc } from '@/common/components/autocomplete';
import { getSmartMapsAutocompleteOptionList, smartMapsAssignMapsheet } from '@/features/accounts/services/entitlements';
import { entitlementsActions } from '@/store/entitlements';
import { toast } from '@/core/services/toast';
import { CommonError } from '@/common/utils';

type SmartMapsAutocomplete = {
  refreshData: () => void;
  userId?: string;
  initialOptions?: AutocompleteOption[];
};

export const useSmartMapAutocomplete = (params: SmartMapsAutocomplete) => {
  const { initialOptions, userId, refreshData } = params;

  const destroy$ = useRef(new Subject());

  const [options, setOptions] = useState<AutocompleteOption[]>(initialOptions || []);
  const [isOptionListLoading, setIsOptionListLoading] = useState<boolean>(false);
  const [isAssignProcessInProgress, setIsAssignProcessInProgress] = useState<boolean>(false);
  const dispatch = useDispatch();

  const [t] = useTranslation();

  const handleOnInputChange = useCallback<AutocompleteOnInputChangeFunc>((_, value, reason) => {
    if (reason === 'reset') {
      return;
    }

    if ((value?.length || 0) < 1) {
      setOptions([]);
      return;
    }

    setIsOptionListLoading(true);
    getSmartMapsAutocompleteOptionList(value)
      .pipe(takeUntil(destroy$.current))
      .subscribe(data => {
        if (data instanceof CommonError) {
          toast.show({ type: 'error', text: t('error.mapSheetList') });
        } else {
          setOptions(data.list);
        }

        setIsOptionListLoading(false);
      });
  }, []);

  const handleOnChange = useCallback<AutocompleteOnChangeFunc>(
    event => {
      if (userId === undefined) {
        return;
      }

      if (event) {
        setIsAssignProcessInProgress(true);
        smartMapsAssignMapsheet({ userId, productId: String(event.value) })
          .pipe(takeUntil(destroy$.current))
          .subscribe(data => {
            if (data instanceof CommonError) {
              if (data.code === 'CSP-106') {
                toast.show({ type: 'warning', text: t('errorCode.CSP-106') });
              } else if (data.code === 'CSP-144') {
                toast.show({ type: 'error', text: t('errorCode.CSP-144') });
              } else {
                toast.show({ type: 'error', text: t('error.assignMapSheet') });
              }
            } else {
              toast.show({ type: 'success', text: t('success.assignMapSheet') });
              dispatch(entitlementsActions.smartMapsTotalCount.request({ params: { userId } }));
              refreshData();
            }

            setIsAssignProcessInProgress(false);
          });
      }
    },
    [userId, refreshData]
  );

  const handleFilterOptions = useCallback<FilterOptionsFunc>(data => data, []);

  useEffect(
    () => () => {
      destroy$.current.next();
      destroy$.current.complete();
    },
    []
  );

  const clearOptions = useCallback(() => {
    setOptions([]);
  }, []);

  return { options, isOptionListLoading, isAssignProcessInProgress, handleOnInputChange, handleOnChange, handleFilterOptions, clearOptions };
};
