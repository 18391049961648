import type { AccountInfo, PublicClientApplication } from '@azure/msal-browser';

import { APPLICATION_LOGOUT_STORAGE_KEY, APPLICATION_LOGOUT_STORAGE_VALUE, APPLICATION_LOGIN_STORAGE_KEY, APPLICATION_LOGIN_STORAGE_VALUE } from '../constants';

const checkAccountsAssignedToOneUser = (accounts: AccountInfo[]) => !(accounts || []).every(account => account.localAccountId === accounts[0].localAccountId);

export const getActiveAccount = (instance: PublicClientApplication): { valid: boolean; account?: AccountInfo } => {
  const accounts = instance.getAllAccounts();
  if (accounts.length === 0) {
    return { valid: true };
  }

  if (checkAccountsAssignedToOneUser(accounts)) {
    return { valid: false };
  }

  const activeAccount = instance.getActiveAccount();
  if (activeAccount) {
    return { valid: true, account: activeAccount };
  }

  instance.setActiveAccount(accounts[0]);
  return { valid: true, account: accounts[0] };
};

export const triggerLocalStorageLogoutEvent = () => {
  window.localStorage.setItem(APPLICATION_LOGOUT_STORAGE_KEY, APPLICATION_LOGOUT_STORAGE_VALUE);
  window.localStorage.removeItem(APPLICATION_LOGOUT_STORAGE_KEY);
};

export const triggerLocalStorageLoginEvent = () => {
  window.localStorage.setItem(APPLICATION_LOGIN_STORAGE_KEY, APPLICATION_LOGIN_STORAGE_VALUE);
  window.localStorage.removeItem(APPLICATION_LOGIN_STORAGE_KEY);
};
