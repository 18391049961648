import { combineReducers } from 'redux';

import { FeatureStateType } from '@/store/helpers';
import { routeAnalyticsListReducer } from './route-analytics';

export type routeAnalyticsReducer = FeatureStateType<typeof routeAnalyticsReducer>;

export const routeAnalyticsReducer = {
  routeAnalytics: combineReducers({
    ...routeAnalyticsListReducer,
  }),
};
