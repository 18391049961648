import { useLayoutEffect, useMemo } from 'react';
import { ACTIONS, EVENTS, LIFECYCLE, STATUS } from 'react-joyride';

import { StepWithoutTarget, useOnboardingState, useTourStepId, UseTourStepOptions, useTourStepRef } from '@/common/components/onboarding';
import { Enum } from '@/common/utils';
import { ONBOARDING_ICON_ID } from '@/common/components/onboarding/components';
import { HorizontalScrollDirection } from '@/common/components/table/components/horizontal-scroll';
import { i18n } from '@/core/services/i18n';
import { ROUTE_DETAILS_ID, ROUTE_OS_MAPS_ID } from '../components/routes-actions';
import { NAME_HEADER_ID, PUBLIC_HEADER_ID } from '../constants';

const OnboardingStepNames = Enum('searchBox', 'sorting', 'filters', 'public', 'osMaps', 'edit', 'addRoute', 'onboardingIcon');
type OnboardingStepNames = Enum<typeof OnboardingStepNames>;

const onboardingSteps: { [K in OnboardingStepNames]: StepWithoutTarget } = {
  searchBox: {
    index: 1,
    title: i18n.t('onboarding.routesList.step1.title') as string,
    content: i18n.t('onboarding.routesList.step1.content') as string,
  },
  sorting: {
    index: 2,
    title: i18n.t('onboarding.routesList.step2.title') as string,
    content: i18n.t('onboarding.routesList.step2.content') as string,
    options: {
      placement: 'right-start',
    },
  },
  filters: {
    index: 3,
    title: i18n.t('onboarding.routesList.step3.title') as string,
    content: i18n.t('onboarding.routesList.step3.content') as string,
    options: {
      placement: 'right-start',
    },
  },
  public: {
    index: 4,
    title: i18n.t('onboarding.routesList.step4.title') as string,
    content: i18n.t('onboarding.routesList.step4.content') as string,
    options: {
      placement: 'right-start',
    },
  },
  osMaps: {
    index: 5,
    title: i18n.t('onboarding.routesList.step5.title') as string,
    content: i18n.t('onboarding.routesList.step5.content') as string,
    options: {
      placement: 'left-start',
    },
  },
  edit: {
    index: 6,
    title: i18n.t('onboarding.routesList.step6.title') as string,
    content: i18n.t('onboarding.routesList.step6.content') as string,
    options: {
      placement: 'left-start',
    },
  },
  addRoute: {
    index: 7,
    title: i18n.t('onboarding.routesList.step7.title') as string,
    content: i18n.t('onboarding.routesList.step7.content') as string,
    options: {
      placement: 'left-start',
    },
  },
  onboardingIcon: {
    index: 8,
    title: i18n.t('onboarding.routesList.step8.title') as string,
    content: i18n.t('onboarding.routesList.step8.content') as string,
    options: {
      placement: 'left-start',
    },
  },
};

export const getOnboardingSteps = (key: OnboardingStepNames) => onboardingSteps[key];

const FIRST_BUTTON_ID = 0;

export const useRoutesListOnboarding = (tourStepOption: UseTourStepOptions, routeListDataLength: number) => {
  useTourStepId(onboardingSteps.sorting, NAME_HEADER_ID, tourStepOption);
  useTourStepId(onboardingSteps.public, PUBLIC_HEADER_ID, tourStepOption);
  useTourStepId(onboardingSteps.osMaps, `${ROUTE_OS_MAPS_ID}${FIRST_BUTTON_ID}`, tourStepOption);
  useTourStepId(onboardingSteps.edit, `${ROUTE_DETAILS_ID}${FIRST_BUTTON_ID}`, tourStepOption);
  const [addRouteRef] = useTourStepRef<HTMLAnchorElement>(onboardingSteps.addRoute);
  useTourStepId(onboardingSteps.onboardingIcon, ONBOARDING_ICON_ID);

  const onboardingState = useOnboardingState();
  useLayoutEffect(() => {
    if (!onboardingState) return;
    const { status, action, lifecycle } = onboardingState;
    if (status === STATUS.RUNNING && action === ACTIONS.START && lifecycle === LIFECYCLE.INIT) {
      document.querySelector('div.layout__content')?.scrollTo({ top: 0 });
    }
  }, [onboardingState]);

  const onboardingIndexes = useMemo(() => Object.values(onboardingSteps).map(step => step.index), [onboardingSteps]);
  const toRight = useMemo(
    () =>
      onboardingIndexes.filter(
        stepIndex => stepIndex === onboardingSteps.osMaps.index || stepIndex === onboardingSteps.edit.index || stepIndex === onboardingSteps.public.index
      ),
    [onboardingIndexes]
  );
  const toLeft = useMemo(() => onboardingIndexes.filter(stepIndex => stepIndex === onboardingSteps.sorting.index), [onboardingIndexes]);

  const scrollTo = useMemo<HorizontalScrollDirection | undefined>(() => {
    if (!onboardingState) return void 0;
    const { status, type, id } = onboardingState;

    if (status === STATUS.RUNNING && type === EVENTS.STEP_BEFORE) {
      if (toRight.includes(id)) return HorizontalScrollDirection.right;
      if (toLeft.includes(id)) return HorizontalScrollDirection.left;
    }

    return void 0;
  }, [onboardingState, routeListDataLength, toRight, toLeft]);

  return { addRouteRef, scrollTo };
};
