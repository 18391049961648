import React, { FCC, useReducer } from 'react';

import { OnboardingComponent, OnboardingProps } from './components/onboarding-component';
import { OnboardingProvider } from './components/onboarding-context';
import { INITIAL_STATE, onboardingReducer } from './contextStore';

export const Onboarding: FCC<OnboardingProps> = ({ children, ...props }) => {
  const [state, dispatch] = useReducer(onboardingReducer, INITIAL_STATE);

  return (
    <OnboardingProvider value={{ state, dispatch }}>
      <OnboardingComponent {...props} />
      {children}
    </OnboardingProvider>
  );
};
