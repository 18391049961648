import dayjs, { Dayjs } from 'dayjs';

import { DEFAULT_DATE_FORMAT } from '@/common/utils/date';
import { PageableListResponse, PageableListVM } from '@/common/utils/pageable';
import { Rating, RoutesEntityResponse, RoutesParams, StatsSummary } from '@/features/content/models/routes';
import { getRouteDistanceInKilometers } from '@/features/content/services/routes';
import { AutocompleteOption } from '@/common/components/autocomplete';

export type RouteAnalyticsParams = Omit<RoutesParams, 'createdBy'> & {
  authorId?: AutocompleteOption<string>;
  minRating?: number;
  maxRating?: number;
  statsFrom?: Dayjs;
  statsTo?: Dayjs;
  includeWithoutRating?: boolean;
  isOrganisational?: boolean;
};

type CreatedBy = {
  displayName: string;
  email: string;
  userId: string;
};

type Metadata = {
  createdAt: string;
  deleted: boolean;
  description: string;
  moderationStatus: string;
  modifiedAt: string;
  name: string;
  origin?: {
    createdBy?: { userId: string; displayName: string; email: string };
    provider?: { id: string; logoUrl: string; name: string; website: string };
    sourceApplication?: { applicationName: string; appVersion: string; platform: string };
  };
  premium: boolean;
  rating: { count: number; average: number };
  visibility: string;
};

export type RouteAnalyticsEntityResponse = Pick<RoutesEntityResponse, 'activity' | 'difficulty' | 'distance' | 'id' | 'name'> & {
  createdAt: string;
  createdBy: CreatedBy;
  rating: Rating;
  stats: StatsSummary;
  metadata?: Metadata;
};

export interface RouteAnalyticsElementVM
  extends Omit<RouteAnalyticsEntityResponse, 'distance' | 'stats' | 'rating' | 'metadata'>,
    Pick<Rating, 'count'>,
    StatsSummary {
  distance: string;
  average: string;
  provider?: { id: string; name: string };
}

export class RouteAnalyticsElementVM {
  constructor({ createdAt, distance, stats, rating, metadata, ...props }: RouteAnalyticsEntityResponse) {
    Object.assign<this, RouteAnalyticsElementVM>(this, {
      ...props,
      ...stats,
      ...rating,
      average: rating.average.toFixed(2),
      createdAt: createdAt ? dayjs(createdAt).format(DEFAULT_DATE_FORMAT) : '',
      distance: getRouteDistanceInKilometers(distance),
      provider: metadata?.origin?.provider ? { id: metadata.origin.provider.id, name: metadata.origin.provider.name } : undefined,
    });
  }
}

export type RouteAnalyticsListResponse = PageableListResponse<RouteAnalyticsEntityResponse> & { hasNextPage: boolean };

export class RouteAnalyticsListVM extends PageableListVM<RouteAnalyticsElementVM, RouteAnalyticsListResponse> {
  public hasNextPage: boolean;
  constructor(props: {
    VM: new (params: RouteAnalyticsListResponse extends PageableListResponse<infer R> ? R : any) => RouteAnalyticsElementVM;
    data: RouteAnalyticsListResponse;
  }) {
    super(props);
    this.hasNextPage = props.data.hasNextPage;
  }
}
