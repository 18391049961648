import { dashboardRoutes, accountsRoutes, contentRoutes, managementRoutes, ReportingRoutes } from '@/core/navigation';
import { Enum } from '../utils';

export const DEFAULT_COUNTRY = 'GB';

const getIds = <T extends object>(ids: T): string[] => Object.values(ids);

export const PAGE_ID = Enum(
  ...getIds(dashboardRoutes),
  ...getIds(accountsRoutes),
  'ROUTE_DETAILS',
  'ROUTE_UPLOAD',
  ...getIds(contentRoutes),
  ...getIds(managementRoutes),
  ...getIds(ReportingRoutes)
);
export type PAGE_ID = Enum<typeof PAGE_ID>;
