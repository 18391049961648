import { createReducer } from 'typesafe-actions';

import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { TipsDictionaryVM } from '@/models/payments';
import { FeatureStateType } from '../../helpers';
import { toursActions } from '../actions';

type tipsState = DictionaryState<TipsDictionaryVM>;

const INITIAL_STATE: tipsState = getDictionaryInitialState<TipsDictionaryVM>();

const reducer = createReducer<tipsState, toursActions>(INITIAL_STATE)
  .handleAction(toursActions.tips.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(toursActions.tips.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(toursActions.tips.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(toursActions.tips.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(toursActions.tips.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const tipsDictionaryReducer = { tips: reducer };
export type tipsDictionaryReducer = FeatureStateType<typeof tipsDictionaryReducer>;
