import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { stringify } from 'qs';
import omit from 'lodash/omit';

import { httpClient } from '@/core/services/http-client';
import { getEnv, CommonError, formatDateToISOString, DateType } from '@/common/utils';
import { SmartMapsListElementVM, SmartMapsListRequest, SmartMapsListResponse, SmartMapsListVM } from '@/models/smartmaps';

const config = getEnv();

const SMART_MAPS_LIST_ENDPOINT = `${config.REACT_APP_API_URL}/entitlement-api/v1/adminPanel/products/smartMaps-query`;

export const getSmartMapsListData = ({ publishDateFrom, publishDateTo, ...data }: SmartMapsListRequest) => {
  const payload = {
    ...data,
    publishDateFrom: publishDateFrom ? formatDateToISOString(publishDateFrom, DateType.FROM) : undefined,
    publishDateTo: publishDateTo ? formatDateToISOString(publishDateTo, DateType.TO) : undefined,
    productName: data.productName?.label,
  };

  return httpClient()
    .authorized.post<SmartMapsListResponse>(SMART_MAPS_LIST_ENDPOINT, payload, {
      params: { ...omit(payload, ['isbn', 'active', 'productName', 'publishDateFrom', 'publishDateTo', 'name']) },
      paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' }),
    })
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new SmartMapsListVM({ data, VM: SmartMapsListElementVM });
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};
