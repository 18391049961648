import { MotionProps } from 'framer-motion';

import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';
import { CARD_BASE_HEIGHT } from '../../constants';

export const editorContentStyle: StyleFunction = ({ theme }) => ({
  '&.content': {
    width: 750,
    minHeight: 640,
    padding: `0 ${constants.offset.small}px ${constants.offset.small}px`,
    backgroundColor: theme.backgroundThird,
    borderRadius: constants.borderRadius.small,
    borderWidth: 2,
    borderColor: theme.borderSecondary,
    borderStyle: 'dashed',
    transitionProperty: 'border-color, background-color',
    transitionDuration: '.2s',

    '&--dragging-over': {
      borderColor: theme.brandPrimary,
      backgroundColor: theme.backgroundThirdFocus,
    },
  },

  '.notice': {
    padding: `${constants.offset.small}px ${constants.offset.medium}px`,
    fontSize: constants.fontSize.h4,
    lineHeight: 1.5,
    fontStyle: 'italic',
    color: theme.textThird,
  },
});

export const motionElementInitialStyle: MotionProps['initial'] = {
  opacity: 0,
  height: CARD_BASE_HEIGHT * 0.5,
};

export const motionElementAnimateStyle: MotionProps['animate'] = {
  opacity: 1,
  height: 'auto',
};

export const motionElementExitStyle: MotionProps['exit'] = {
  opacity: 0,
  height: 0,
};
