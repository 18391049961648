import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { httpClient } from '@/core/services/http-client';
import { getEnv, CommonError } from '@/common/utils';
import { ModerationDetailsResponse, ModerationElementVM } from '@/models/moderation';

const config = getEnv();

const CONTENT_MODERATION_DETAILS_ENDPOINT = `${config.REACT_APP_API_URL}/rating-and-review-api/v1/admin/moderation-requests`;

export const getModerationDetailsData = (id: string) =>
  httpClient()
    .authorized.get<ModerationDetailsResponse>(`${CONTENT_MODERATION_DETAILS_ENDPOINT}/${id}`)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new ModerationElementVM({ ...data });
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: e.code, message: e.errorMessage })))
    );

export const updateModerationReportStatus = (reportId: string, status: string) =>
  httpClient()
    .authorized.put<undefined>(`${CONTENT_MODERATION_DETAILS_ENDPOINT}/${reportId}/status`, { status })
    .pipe(
      map(response => {
        if (response.status === 200 || response.status === 201 || response.status === 202) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
