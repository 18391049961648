import React, { forwardRef, InputHTMLAttributes } from 'react';

import { useStyles } from '@/styles/hooks';
import { inputStyles } from './styles';

export type InputProps = InputHTMLAttributes<HTMLInputElement>;

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { styles } = useStyles(inputStyles);

  return <input css={styles} {...props} />;
});
