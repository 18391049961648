import { combineReducers } from 'redux';

import { FeatureStateType } from '@/store/helpers';
import { moderationListReducer } from './list';
import { moderationDetailsReducer } from './details';

export type moderationReducer = FeatureStateType<typeof moderationReducer>;

export const moderationReducer = {
  moderation: combineReducers({
    ...moderationListReducer,
    ...moderationDetailsReducer,
  }),
};
