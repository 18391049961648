import { combineReducers } from 'redux';

import { FeatureStateType } from '@/store/helpers';
import { routesListReducer } from './list';
import { routeDetailsReducer } from './details';
import { bulkChangeReducer } from './bulk-change';
import { routesTotalCountReducer } from './routes-total-count';
import { routeThumbnailReducer } from './thumbnail';

export type routesReducer = FeatureStateType<typeof routesReducer>;
export const routesReducer = {
  routes: combineReducers({ ...routesListReducer, ...routeDetailsReducer, ...bulkChangeReducer, ...routesTotalCountReducer, ...routeThumbnailReducer }),
};
