import { createReducer } from 'typesafe-actions';

import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { ReviewStatusDictionaryVM } from '@/models/reviews';
import { FeatureStateType } from '../../helpers';
import { reviewsActions } from '../actions';

type statusState = DictionaryState<ReviewStatusDictionaryVM>;

const INITIAL_STATE: statusState = getDictionaryInitialState<ReviewStatusDictionaryVM>();

const reducer = createReducer<statusState, reviewsActions>(INITIAL_STATE)
  .handleAction(reviewsActions.status.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(reviewsActions.status.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(reviewsActions.status.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(reviewsActions.status.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(reviewsActions.status.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const statusDictionaryReducer = { status: reducer };
export type statusDictionaryReducer = FeatureStateType<typeof statusDictionaryReducer>;
