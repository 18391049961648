import upperFirst from 'lodash/upperFirst';

import { SelectOption } from '@/common/components/form-controls-deprecated';

type DifficultiesResponse = {
  id: string;
  displayName: string;
};

export type DifficultiesListResponse = DifficultiesResponse[];

export interface DifficultiesDictionaryElementVM extends SelectOption {}
export class DifficultiesDictionaryElementVM {
  constructor({ id, displayName }: DifficultiesResponse) {
    this.value = id;
    this.label = upperFirst(displayName);
  }
}

export interface DifficultiesDictionaryVM {
  list: DifficultiesDictionaryElementVM[];
}

export class DifficultiesDictionaryVM {
  constructor(list: DifficultiesListResponse) {
    this.list = (list || []).map(element => new DifficultiesDictionaryElementVM(element));
  }
}
