import { createSelector } from 'reselect';

import { VoucherBatchListVM } from '@/models/voucher-batch';
import { getDataStatus } from '@/common/utils';
import { voucherBatchReducer } from './reducers';
import { addVoucherBatchReducer } from './reducers/add-voucher-batch';

const getVoucherBatchList = (state: voucherBatchReducer) => state.voucherBatch.list;

type VoucherBatchList = voucherBatchReducer['voucherBatch']['list'];
type MakeGetVoucherBatchListData = VoucherBatchListVM['list'];
type MakeGetVoucherBatchListPaginationData = VoucherBatchListVM['listPaginationData'] | undefined;
type MakeGetVoucherBatchListFilters = VoucherBatchList['params'];

export const makeGetVoucherBatchListData = () =>
  createSelector<voucherBatchReducer, VoucherBatchList, MakeGetVoucherBatchListData>(getVoucherBatchList, ({ data }) => (data === undefined ? [] : data.list));

export const makeGetVoucherBatchListStatus = () =>
  createSelector<voucherBatchReducer, VoucherBatchList, ReturnType<typeof getDataStatus>>(getVoucherBatchList, getDataStatus);

export const makeGetVoucherBatchListPaginationData = () =>
  createSelector<voucherBatchReducer, VoucherBatchList, MakeGetVoucherBatchListPaginationData>(getVoucherBatchList, ({ data }) => data?.listPaginationData);

export const makeGetVoucherBatchListFilters = () =>
  createSelector<voucherBatchReducer, VoucherBatchList, MakeGetVoucherBatchListFilters>(getVoucherBatchList, ({ params }) => params);

const getVoucherBatchDetails = (state: voucherBatchReducer) => state.voucherBatch.details;

type VoucherBatchDetails = voucherBatchReducer['voucherBatch']['details'];
type MakeGetVoucherBatchDetailsData = VoucherBatchDetails['data'];

export const makeGetVoucherBatchDetailsData = () =>
  createSelector<voucherBatchReducer, VoucherBatchDetails, MakeGetVoucherBatchDetailsData>(getVoucherBatchDetails, ({ data }) => data);

export const makeGetVoucherBatchDetailsStatus = () =>
  createSelector<voucherBatchReducer, VoucherBatchDetails, ReturnType<typeof getDataStatus>>(getVoucherBatchDetails, getDataStatus);

type VoucherBatchStatusesDictionary = voucherBatchReducer['voucherBatch']['statuses'];
type MakeGetVoucherBatchStatusesDictionaryData = voucherBatchReducer['voucherBatch']['statuses']['data'];

const getVoucherBatchStatusesDictionary = (state: voucherBatchReducer) => state.voucherBatch.statuses;

export const makeGetVoucherBatchStatusesDictionaryData = () =>
  createSelector<voucherBatchReducer, VoucherBatchStatusesDictionary, MakeGetVoucherBatchStatusesDictionaryData>(
    getVoucherBatchStatusesDictionary,
    ({ data }) => data
  );

export const makeGetVoucherBatchStatusesDictionaryStatus = () =>
  createSelector<voucherBatchReducer, VoucherBatchStatusesDictionary, ReturnType<typeof getDataStatus>>(getVoucherBatchStatusesDictionary, getDataStatus);

const getVoucherBatchConfiguration = (state: voucherBatchReducer) => state.voucherBatch.addBatch.steps.batchConfiguration;

type VoucherBatchConfiguration = addVoucherBatchReducer['addBatch']['steps']['batchConfiguration'];
type MakeGetVoucherBatchConfigurationValue = VoucherBatchConfiguration['stringifiedBatchConfig'];
type MakeGetVoucherBatchType = VoucherBatchConfiguration['batchType'];

export const makeGetVoucherBatchConfigurationValue = () =>
  createSelector<voucherBatchReducer, VoucherBatchConfiguration, MakeGetVoucherBatchConfigurationValue>(
    getVoucherBatchConfiguration,
    ({ stringifiedBatchConfig }) => stringifiedBatchConfig
  );

export const makeGetVoucherBatchType = () =>
  createSelector<voucherBatchReducer, VoucherBatchConfiguration, MakeGetVoucherBatchType>(getVoucherBatchConfiguration, ({ batchType }) => batchType);

const getVoucherBatchSize = (state: voucherBatchReducer) => state.voucherBatch.addBatch.steps.batchSize;

type VoucherBatchSize = addVoucherBatchReducer['addBatch']['steps']['batchSize'];
type MakeGetVoucherBatchSizeValue = VoucherBatchSize['batchList'];

export const makeGetVoucherBatchSizeValue = () =>
  createSelector<voucherBatchReducer, VoucherBatchSize, MakeGetVoucherBatchSizeValue>(getVoucherBatchSize, ({ batchList }) => batchList);
