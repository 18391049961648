import { createSelector } from 'reselect';

import { CommunityStoriesListVM } from '@/features/content/models/community-stories';
import { getDataStatus } from '@/common/utils';
import { communityStoriesReducer } from './reducers';

type CommunityStoriesList = communityStoriesReducer['communityStories']['list'];
type MakeGetCommunityStoriesListData = CommunityStoriesListVM['list'];
type MakeGetCommunityStoriesListPaginationData = CommunityStoriesListVM['listPaginationData'] | undefined;
type MakeGetCommunityStoriesListLength = { length: number };
type MakeGetCommunityStoriesListFilters = CommunityStoriesList['params'];

const getCommunityStoriesList = (state: communityStoriesReducer) => state.communityStories.list;

export const makeGetCommunityStoriesList = () =>
  createSelector<communityStoriesReducer, CommunityStoriesList, MakeGetCommunityStoriesListData>(getCommunityStoriesList, ({ data }) =>
    data === undefined ? [] : data.list
  );

export const makeGetCommunityStoriesListLength = () =>
  createSelector<communityStoriesReducer, CommunityStoriesList, MakeGetCommunityStoriesListLength>(getCommunityStoriesList, ({ data }) => ({
    length: (data?.list || []).length,
  }));

export const makeGetCommunityStoriesListStatus = () =>
  createSelector<communityStoriesReducer, CommunityStoriesList, ReturnType<typeof getDataStatus>>(getCommunityStoriesList, getDataStatus);

export const makeGetCommunityStoriesListPaginationData = () =>
  createSelector<communityStoriesReducer, CommunityStoriesList, MakeGetCommunityStoriesListPaginationData>(
    getCommunityStoriesList,
    ({ data }) => data?.listPaginationData
  );

export const makeGetCommunityStoriesListFilters = () =>
  createSelector<communityStoriesReducer, CommunityStoriesList, MakeGetCommunityStoriesListFilters>(getCommunityStoriesList, ({ params }) => params);

const getCommunityStoryDetails = (state: communityStoriesReducer) => state.communityStories.details;

type CommunityStoryDetails = communityStoriesReducer['communityStories']['details'];
type MakeGetCommunityStoryDetailsData = CommunityStoryDetails['data'];

export const makeGetCommunityStoryDetailsData = () =>
  createSelector<communityStoriesReducer, CommunityStoryDetails, MakeGetCommunityStoryDetailsData>(getCommunityStoryDetails, ({ data }) => data);
export const makeGetCommunityStoryDetailsStatus = () =>
  createSelector<communityStoriesReducer, CommunityStoryDetails, ReturnType<typeof getDataStatus>>(getCommunityStoryDetails, getDataStatus);
