import { ReactElement } from 'react';

import { HidableColumn } from '@/common/components/table';
import { DropdownOption } from '@/common/components/search-box';
import { RoutesElementVM } from '../../models/routes';

export const ROUTE_LIST_PAGE_SIZE = 50;
export const BULK_CHANGE_LIMIT = 50;

export type TableEntityData = Omit<RoutesElementVM, 'visibility' | 'recommended'> & {
  actions?: ReactElement;
  recommended: ReactElement | string;
  visibility: string;
};

export const PUBLIC_HEADER_ID = 'metadata\\.visibility';
export const NAME_HEADER_ID = 'metadata\\.name';

export const TABLE_COLUMN_DEFINITIONS: HidableColumn<TableEntityData>[] = [
  {
    Header: 'label.name',
    accessor: 'metadata.name' as any,
    disableSortBy: false,
    defaultVisibility: true,
    alwaysVisible: true,
  },
  {
    Header: 'label.routeType',
    accessor: 'characteristics.activity' as any,
    disableSortBy: false,
  },
  {
    Header: 'label.difficulty',
    accessor: 'characteristics.difficulty' as any,
    disableSortBy: false,
  },
  {
    Header: 'label.distance',
    accessor: 'characteristics.distance' as any,
    disableSortBy: false,
  },
  {
    Header: 'label.created',
    accessor: 'metadata.createdAt' as any,
    disableSortBy: false,
  },
  {
    Header: 'label.updated',
    accessor: 'metadata.modifiedAt' as any,
    disableSortBy: false,
  },
  {
    Header: 'label.organisation',
    accessor: 'metadata.origin.provider.id' as any,
    disableSortBy: false,
  },
  {
    Header: 'label.author',
    accessor: 'characteristics.author' as any,
    disableSortBy: true,
  },
  {
    Header: 'label.averageRating',
    accessor: 'metadata.rating.average',
    disableSortBy: false,
  },
  {
    Header: 'label.visibility',
    accessor: 'metadata.visibility' as any,
    disableSortBy: false,
  },
  {
    Header: 'label.recommended',
    accessor: 'metadata.recommended' as any,
    disableSortBy: true,
  },
  {
    Header: 'label.routeType',
    accessor: 'characteristics.looped' as any,
    disableSortBy: true,
  },
  {
    Header: '',
    accessor: 'actions',
    disableSortBy: true,
  },
];

export const SEARCH_BY_OPTION_LIST: DropdownOption[] = [
  {
    value: 'NAME',
    label: 'Name',
    translationId: 'label.name',
  },
  {
    value: 'ID',
    label: 'ID',
    translationId: 'label.id',
  },
];
