import { createReducer } from 'typesafe-actions';

import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { ReviewTargetTypesDictionaryVM } from '@/models/reviews';
import { FeatureStateType } from '../../helpers';
import { reviewsActions } from '../actions';

type targetTypeState = DictionaryState<ReviewTargetTypesDictionaryVM>;

const INITIAL_STATE: targetTypeState = getDictionaryInitialState<ReviewTargetTypesDictionaryVM>();

const reducer = createReducer<targetTypeState, reviewsActions>(INITIAL_STATE)
  .handleAction(reviewsActions.targetType.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(reviewsActions.targetType.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(reviewsActions.targetType.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(reviewsActions.targetType.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(reviewsActions.targetType.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const targetTypeDictionaryReducer = { targetType: reducer };
export type targetTypeDictionaryReducer = FeatureStateType<typeof targetTypeDictionaryReducer>;
