import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import omit from 'lodash/omit';

import { useStyles } from '@/styles/hooks';
import { Checkbox, CheckboxProps, CheckedChangedTarget } from '@/common/components/form-controls-deprecated';
import { Slider, SliderProps, OnSliderChangeFunc } from '@/common/components/slider';
import { useInstanceValue } from '@/common/utils';
import { RangeValue, Range } from '../range-filter';
import { sliderFilterStyles } from './styles';

const DEBOUNCE_TIME = 1000;

export type OnSliderFilterChangeParams = { rangeValue: RangeValue; checkboxValue?: boolean };
export type OnSliderFilterChangeFunc = (value: OnSliderFilterChangeParams) => void;

type Props = Omit<SliderProps, 'value' | 'defaultValue' | 'onChange'> & {
  value?: RangeValue;
  defaultValue: Range<number>;
  checkboxProps?: CheckboxProps;
  onChange: OnSliderFilterChangeFunc;
};

export const SliderFilter: FC<Props> = ({ value, defaultValue, disabled, checkboxProps, onChange, ...props }) => {
  const checked = checkboxProps?.checked;
  const [{ rangeValue, checkboxValue }, setInnerState] = useState<OnSliderFilterChangeParams>({ rangeValue: defaultValue, checkboxValue: checked });
  const change$ = useInstanceValue(() => new Subject<OnSliderFilterChangeParams>());
  const { styles } = useStyles(sliderFilterStyles);

  useEffect(() => {
    if (value && value !== rangeValue) {
      setInnerState(prev => ({
        ...prev,
        rangeValue: { start: value.start ?? defaultValue.start, end: value.end ?? defaultValue.end },
      }));
    }
  }, [value]);

  useEffect(() => {
    if (checked !== undefined && checked !== checkboxValue) {
      setInnerState(prev => ({ ...prev, checkboxValue: checked }));
    }
  }, [checked]);

  const handleSliderChange = useCallback<OnSliderChangeFunc<Range<number>>>(rangeValue => {
    setInnerState(prev => {
      const next: OnSliderFilterChangeParams = { ...prev, rangeValue };
      change$.current.next(next);
      return next;
    });
  }, []);

  const handleCheckedChange = useCallback(({ checked }: CheckedChangedTarget) => {
    setInnerState(prev => {
      const next: OnSliderFilterChangeParams = { ...prev, checkboxValue: checked };
      change$.current.next(next);
      return next;
    });
  }, []);

  useEffect(() => {
    const subscription = change$.current.pipe(debounceTime(DEBOUNCE_TIME)).subscribe(value => {
      onChange(value);
    });

    return () => subscription.unsubscribe();
  }, [onChange, !!checkboxProps?.onCheckedChange]);

  const sliderValue = useMemo<Range<number>>(
    () => ({
      start: rangeValue.start ?? defaultValue.start,
      end: rangeValue.end ?? defaultValue.end,
    }),
    [rangeValue, defaultValue]
  );

  const checkbox = useMemo(() => {
    if (!checkboxProps) return null;

    return (
      <div className='checkbox-wrapper'>
        <Checkbox checked={checkboxValue} onCheckedChange={handleCheckedChange} disabled={disabled} {...omit(checkboxProps, 'checked')} />
      </div>
    );
  }, [checkboxProps, disabled, checkboxValue, handleCheckedChange]);

  return (
    <div css={styles}>
      {checkbox}
      <div className='slider-label-wrapper'>
        <span>{rangeValue.start?.toFixed(1)}</span>
        <span>{rangeValue.end?.toFixed(1)}</span>
      </div>
      <Slider value={sliderValue} onChange={handleSliderChange as OnSliderChangeFunc} disabled={disabled} {...props} />
    </div>
  );
};
