import { switchMap, filter, map } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { getRouteListData, getRoutesDetailsData, getRoutesTotalCountData, getRouteThumbnailData } from '@/features/content/services/routes';
import { untilLocationChange, CommonError } from '@/common/utils';
import { routesReducer } from './reducers';
import { routesActions } from './actions';

const getRouteList: Epic<routesActions, routesActions, routesReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(routesActions.list.request)),
    switchMap(() =>
      getRouteListData(state$.value.routes.list.params).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return routesActions.list.failure(response);
          }

          return routesActions.list.success(response);
        })
      )
    )
  );

const getRouteDetails: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(routesActions.details.request)),
    switchMap(action =>
      getRoutesDetailsData(action.payload.params)
        .pipe(untilLocationChange(action$))
        .pipe(
          map(response => {
            if (response instanceof CommonError) {
              return routesActions.details.failure(response);
            }

            return routesActions.details.success(response);
          })
        )
    )
  );

const getRoutesTotalCount: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(routesActions.totalCount.request)),
    switchMap(action =>
      getRoutesTotalCountData(action.payload.params).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return routesActions.totalCount.failure(response);
          }

          return routesActions.totalCount.success(response);
        })
      )
    )
  );

const getRouteThumbnail: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(routesActions.thumbnail.request)),
    switchMap(action =>
      getRouteThumbnailData(action.payload.params)
        .pipe(untilLocationChange(action$))
        .pipe(
          map(response => {
            if (response instanceof CommonError) {
              return routesActions.thumbnail.failure(response);
            }

            return routesActions.thumbnail.success(response);
          })
        )
    )
  );

export const routesEpics = [getRouteList, getRouteDetails, getRoutesTotalCount, getRouteThumbnail];
