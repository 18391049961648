import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { getEnv, CommonError } from '@/common/utils';
import { httpClient } from '@/core/services/http-client';
import { ReviewDetailsRequest, ReviewDetailsResponse, ReviewDetailsVM } from '@/models/reviews';

const config = getEnv();

const REVIEWS_DETAILS_ENDPOINT = `${config.REACT_APP_API_URL}/rating-and-review-api/v1/admin/ratings`;

export const getReviewsDetailsData = (param: ReviewDetailsRequest) =>
  httpClient()
    .authorized.get<ReviewDetailsResponse>(`${REVIEWS_DETAILS_ENDPOINT}/${param.id}`)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new ReviewDetailsVM(data);
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: e.code, message: e.errorMessage })))
    );

export const deleteReviewThumbnail = (ratingId: string, mediaId: string) => {
  return httpClient()
    .authorized.delete(`${REVIEWS_DETAILS_ENDPOINT}/${ratingId}/media/${mediaId}`)
    .pipe(
      map(({ status }) => {
        if ([200, 202, 204].includes(status)) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};
