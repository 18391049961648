import { createReducer } from 'typesafe-actions';

import { BulkChangeRoutesList } from '@/features/content/models/routes';
import { UploadStatus } from '@/common/components/upload-status-icon';
import { FeatureStateType } from '../../helpers';
import { routesActions } from '../actions';

export type bulkChangeState = { list: BulkChangeRoutesList };

const INITIAL_STATE: bulkChangeState = {
  list: [],
};

const reducer = createReducer<bulkChangeState, routesActions>(INITIAL_STATE)
  .handleAction(routesActions.bulkChangeList, (_, { payload }) => ({
    list: payload,
  }))
  .handleAction(routesActions.setRouteBulkChangeStatus, (state, { payload }) => ({
    list:
      payload.status === 'failure'
        ? updateRoute(moveFailedRouteToTop(state.list, payload.routeId), payload.routeId, payload.status, payload.message)
        : updateRoute(state.list, payload.routeId, payload.status),
  }))
  .handleAction(routesActions.refreshRouteName, (state, { payload }) => ({
    list: state.list.map(route => (route.id === payload.routeId ? { ...route, name: payload.name } : route)),
  }));

const moveFailedRouteToTop = (list: BulkChangeRoutesList, failedRouteId: string) => {
  list.forEach((item, i) => {
    if (item.id === failedRouteId) {
      list.splice(i, 1);
      list.unshift(item);
    }
  });
  return list;
};

const updateRoute = (list: BulkChangeRoutesList, updatedRouteId: string, status: UploadStatus, errorMessage?: string) => {
  return list.map(route => (route.id === updatedRouteId ? { ...route, status, errorMessage } : route));
};

export const bulkChangeReducer = { bulkChangeList: reducer };
export type bulkChangeReducer = FeatureStateType<typeof bulkChangeReducer>;
