import { createReducer } from 'typesafe-actions';

import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { VoucherBatchDetailsVM } from '@/models/voucher-batch';
import { FeatureStateType } from '../../helpers';
import { voucherBatchActions } from '../actions';

export type voucherBatchDetailsState = FetchState<VoucherBatchDetailsVM>;

const INITIAL_STATE: voucherBatchDetailsState = getFetchInitialState<VoucherBatchDetailsVM>();

const reducer = createReducer<voucherBatchDetailsState, voucherBatchActions>(INITIAL_STATE)
  .handleAction(voucherBatchActions.details.request, (state, { payload }) => {
    const newState = fetchReducerHelpers.request(INITIAL_STATE, state);
    const { isNotInitialFetch } = payload.params;

    return {
      ...newState,
      isLoading: isNotInitialFetch ? false : true,
    };
  })
  .handleAction(voucherBatchActions.details.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(voucherBatchActions.details.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(voucherBatchActions.clearVoucherBatchDetails, () => ({ ...INITIAL_STATE }));

export const voucherBatchDetailsReducer = { details: reducer };
export type voucherBatchDetailsReducer = FeatureStateType<typeof voucherBatchDetailsReducer>;
