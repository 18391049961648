import { createReducer } from 'typesafe-actions';

import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { UserListSearchByOptionListVM } from '@/features/accounts/models/users';
import { FeatureStateType } from '../../helpers';
import { usersActions } from '../actions';

type searchByOptionsState = DictionaryState<UserListSearchByOptionListVM>;

const INITIAL_STATE: searchByOptionsState = getDictionaryInitialState<UserListSearchByOptionListVM>();

const reducer = createReducer<searchByOptionsState, usersActions>(INITIAL_STATE)
  .handleAction(usersActions.searchByOptions.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(usersActions.searchByOptions.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(usersActions.searchByOptions.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(usersActions.searchByOptions.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(usersActions.searchByOptions.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const searchByOptionsReducer = { searchByOptions: reducer };
export type searchByOptionsReducer = FeatureStateType<typeof searchByOptionsReducer>;
