import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { UploadTourFileResponse } from '@/features/content/models/tours';
import { httpClient } from '@/core/services/http-client';
import { getEnv, CommonError, Enum } from '@/common/utils';

const config = getEnv();

const GPX_UPLOAD_ENDPOINT = `${config.REACT_APP_API_URL}/story-api/v1/admin/tours/route-files`;
const TOUR_COVER_UPLOAD_ENDPOINT = `${config.REACT_APP_API_URL}/media-access-api/v2/images/tours`;
const CLONE_ROUTE_ENDPOINT = `${config.REACT_APP_API_URL}/story-api/v1/admin/tours/route-files/copy`;

export const TourFileName = Enum('coverImageId', 'routeFileId');
export type TourFileName = Enum<typeof TourFileName>;

type UploadTourFileId = Pick<UploadTourFileResponse, 'id'>;
type UploadGpxParams = { gpxFile: File };
type UploadTourCoverParams = { tourCoverFile: File };
export type UploadedFile = { name: TourFileName; data: CommonError | UploadTourFileId };
export type TourFileResponse = Observable<UploadedFile>;

export const uploadGPX = ({ gpxFile }: UploadGpxParams): TourFileResponse => {
  const formData = new FormData();
  formData.append('file', gpxFile);
  formData.append('metadata', new Blob(['{}'], { type: 'application/json' }));
  return httpClient()
    .authorized.post<UploadTourFileId>(GPX_UPLOAD_ENDPOINT, formData)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 || status === 201 || (status === 202 && data !== undefined)) {
          return data;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e }))),
      map(data => ({ data, name: TourFileName.routeFileId }))
    );
};

export const uploadTourCover = ({ tourCoverFile }: UploadTourCoverParams): TourFileResponse => {
  const formData = new FormData();
  formData.append('file', tourCoverFile);
  formData.append('metadata', new Blob(['{}'], { type: 'application/json' }));
  return httpClient()
    .authorized.post<UploadTourFileResponse>(TOUR_COVER_UPLOAD_ENDPOINT, formData)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 || status === 201 || (status === 202 && data !== undefined)) {
          return data;
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e }))),
      map(data => ({ data, name: TourFileName.coverImageId }))
    );
};

export const cloneRoute = (routeId: string) => {
  return httpClient()
    .authorized.post<UploadTourFileId>(CLONE_ROUTE_ENDPOINT, { routeId })
    .pipe(
      map(({ data, status }) => {
        if ([200, 201, 202].includes(status) && data !== undefined) {
          return data;
        }
        throw undefined;
      }),
      catchError(error => of(new CommonError({ code: '500', message: error }))),
      map(data => ({ data, name: TourFileName.routeFileId }))
    );
};
