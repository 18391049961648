import { constants, Styles, theme } from '@/styles';

export const statusStyle: Styles = {
  '&.status': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '100%',

    '&--ERROR, &--VALIDATION_ERROR': {
      '.status': {
        '&__icon': {
          color: theme.error,
        },

        '&__text': {
          color: theme.error,
        },
      },
    },

    '&--SUCCESS': {
      '.status': {
        '&__icon': {
          color: theme.success,
        },

        '&__text': {
          color: theme.success,
        },
      },
    },

    '&--LOADING': {
      '.status': {
        '&__icon': {
          color: theme.brandPrimary,
        },

        '&__text': {
          color: theme.brandPrimary,
        },
      },
    },
  },

  '.status': {
    '&__icon': {
      fontSize: constants.fontSize.icon,
    },

    '&__text': {
      flexShrink: 1,
      marginLeft: constants.offset.small,
      maxWidth: '100%',
      overflow: 'hidden',
      fontSize: constants.fontSize.medium,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
};
