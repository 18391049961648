import React, { FC, Ref } from 'react';
import cn from 'classnames';

import { useStyles } from '@/styles/hooks';
import { Field, FieldProps } from '../field';
import { RichText, RichTextAttributes } from './rich-text';
import { richTextStyles } from './styles';

type FieldRichTextProps = Omit<FieldProps<RichTextAttributes>, 'children'> & { instanceRef: Ref<any> };

export const FieldRichText: FC<FieldRichTextProps> = ({ name, label, labelIcon, isFocusOnLabelEnabled, className, instanceRef, ...props }) => {
  const { styles } = useStyles(richTextStyles);
  return (
    <Field name={name} label={label} labelIcon={labelIcon} isFocusOnLabelEnabled={false} className={className}>
      {({ field, meta, form }) => {
        const inputClassName = cn([{ error: meta.touched && meta.error }]);
        return <RichText css={styles} {...props} className={inputClassName} form={form} {...field} ref={instanceRef} />;
      }}
    </Field>
  );
};
