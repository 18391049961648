import { createReducer } from 'typesafe-actions';

import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { GalleryListVM, GalleryListRequest } from '@/models/gallery';
import { DEFAULT_LIST_PAGINATION_DATA } from '@/common/utils';
import { galleryActions } from '../actions';
import { FeatureStateType } from '../../helpers';

export type galleryListState = FetchState<GalleryListVM, GalleryListRequest>;

const INITIAL_STATE: galleryListState = getFetchInitialState<GalleryListVM, GalleryListRequest>({ reviewed: ['NO'] });

const reducer = createReducer<galleryListState, galleryActions>(INITIAL_STATE)
  .handleAction(galleryActions.list.request, (state, { payload }) => fetchReducerHelpers.request(INITIAL_STATE, state, payload))
  .handleAction(galleryActions.list.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(galleryActions.list.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(galleryActions.clearGalleryList, state => ({
    ...state,
    data: {
      ...state.data,
      list: [],
      listPaginationData: state.data?.listPaginationData || DEFAULT_LIST_PAGINATION_DATA,
    },
  }))
  .handleAction(galleryActions.changeGalleryItemReviewedStatus, (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      list: state.data?.list.map(item => (item.media.id === payload.mediaId ? { ...item, reviewed: payload.isReviewed } : item)) || [],
      listPaginationData: state.data?.listPaginationData || DEFAULT_LIST_PAGINATION_DATA,
    },
  }))
  .handleAction(galleryActions.removeGalleryItem, (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      list: state.data?.list ? state.data?.list.filter(item => item.media.id !== payload) : [],
      listPaginationData: state.data?.listPaginationData || DEFAULT_LIST_PAGINATION_DATA,
    },
  }));
export const galleryListReducer = { list: reducer };
export type galleryListReducer = FeatureStateType<typeof galleryListReducer>;
