import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { stringify } from 'qs';
import { Dayjs } from 'dayjs';

import { CommonError, pickNotEmptyStringValues } from '@/common/utils';
import { httpClient } from '@/core/services/http-client';
import { getRouteListDistanceParamValue } from '@/features/content/services/routes';
import { RouteAnalyticsElementVM, RouteAnalyticsListResponse, RouteAnalyticsListVM, RouteAnalyticsParams } from '../../models/route-analytics';

const ROUTE_ANALYTICS_LIST_ENDPOINT = '/route-api/v2/local/adminPanel/routes/stats';

const PARAMS_MAP = [
  { key: 'name', value: 'route.metadata.name' },
  { key: 'activity', value: 'route.characteristics.activity' },
  { key: 'difficulty', value: 'route.characteristics.difficulty' },
  { key: 'distance', value: 'route.characteristics.distance' },
  { key: 'views', value: 'stats.views' },
  { key: 'follows', value: 'stats.follows' },
  { key: 'count', value: 'route.metadata.rating.count' },
  { key: 'average', value: 'route.metadata.rating.average' },
];

const mapSortParams = (sort?: string[]) =>
  sort?.map(entity => {
    const value = PARAMS_MAP.find(param => entity.match(param.key))?.value;
    return value ? `${value},${entity.split(',')[1]}` : entity;
  });

type DateParams<T extends string | Dayjs> = {
  statsFrom?: T;
  statsTo?: T;
};

const formatDateParams = ({ statsFrom, statsTo }: DateParams<Dayjs>): DateParams<string> => ({
  statsFrom: statsFrom?.format('YYYY-MM-DD'),
  statsTo: statsTo?.format('YYYY-MM-DD'),
});

export const getRouteAnalyticsListData = ({
  currentRegionUrl,
  sort,
  authorId,
  statsFrom,
  statsTo,
  minDistance,
  maxDistance,
  organisationId,
  isOrganisational,
  ...params
}: RouteAnalyticsParams) =>
  httpClient()
    .authorized.get<RouteAnalyticsListResponse>(currentRegionUrl + ROUTE_ANALYTICS_LIST_ENDPOINT, {
      params: {
        ...pickNotEmptyStringValues({
          ...params,
          ...formatDateParams({ statsFrom, statsTo }),
          organisationId: organisationId?.value,
          isOrganisational: isOrganisational ? true : null,
          minDistance: getRouteListDistanceParamValue(minDistance),
          maxDistance: getRouteListDistanceParamValue(maxDistance),
          sort: mapSortParams(sort),
          authorId: authorId?.value,
        }),
      },
      paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' }),
    })
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data) {
          return new RouteAnalyticsListVM({ data, VM: RouteAnalyticsElementVM });
        }

        throw undefined;
      }),
      catchError(error => of(new CommonError({ code: '500', message: error })))
    );
