import React, { FCC, ReactNode } from 'react';

import { style } from './styles';

interface Props {
  sideActions?: ReactNode;
}

export const FieldGroup: FCC<Props> = ({ children, sideActions }) => (
  <div css={style}>
    {children}
    {sideActions}
  </div>
);
