import React, { FC, useMemo } from 'react';
import { FieldArray, FieldArrayConfig, useField } from 'formik';

import { EditorContainer, EditorDroppableContextProvider } from '../editor-container';
import { DisabledStoryContentEditorContext } from './disabled-story-content-editor-context';
import { styles } from './styles';

type Props = {
  name: string;
  disabled: boolean;
};

export const StoryContentField: FC<Props> = ({ name, disabled }) => {
  const storyContentField = useField(name);
  const [, meta] = storyContentField;

  const fieldError = useMemo(
    () => typeof meta.error === 'string' && meta.touched && <div className='error-message'>{meta.error}</div>,
    [meta.error, meta.touched]
  );

  return (
    <DisabledStoryContentEditorContext.Provider value={disabled}>
      <div css={styles}>
        {disabled && <div className={'overlay'} />}
        <EditorDroppableContextProvider>
          <FieldArray name={name} component={EditorContainer as FieldArrayConfig['component']} />
          {fieldError}
        </EditorDroppableContextProvider>
      </div>
    </DisabledStoryContentEditorContext.Provider>
  );
};
