import { createReducer } from 'typesafe-actions';

import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { SmartMapsDetailsVM } from '@/models/smartmaps';
import { FeatureStateType } from '../../helpers';
import { smartMapsActions } from '../actions';

export type smartMapsDetailsState = FetchState<SmartMapsDetailsVM>;

const INITIAL_STATE: smartMapsDetailsState = getFetchInitialState<SmartMapsDetailsVM>();

const reducer = createReducer<smartMapsDetailsState, smartMapsActions>(INITIAL_STATE)
  .handleAction(smartMapsActions.details.request, state => fetchReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(smartMapsActions.details.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(smartMapsActions.details.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(smartMapsActions.clearSmartMapsDetails, () => ({ ...INITIAL_STATE }));

export const smartMapsDetailsReducer = { details: reducer };
export type smartMapsDetailsReducer = FeatureStateType<typeof smartMapsDetailsReducer>;
