import { Subject } from 'rxjs';
import { filter, tap, takeUntil } from 'rxjs/operators';

import { history } from '@/store';
import { rootRoutePaths, rootRoutes } from '@/core/navigation';
import { Emission } from '../storage-management/models';
import { APPLICATION_LOGIN_STORAGE_KEY, APPLICATION_LOGIN_STORAGE_VALUE, APPLICATION_LOGOUT_STORAGE_KEY, APPLICATION_LOGOUT_STORAGE_VALUE } from './constants';
import { userProfile } from './profile';

const destroy$ = new Subject<string>();

export const authenticationStorageEvents = ({ event: { key, newValue }, store }: Emission) => {
  if (window.opener) {
    return;
  }

  if (key === APPLICATION_LOGOUT_STORAGE_KEY && newValue === APPLICATION_LOGOUT_STORAGE_VALUE) {
    window.location.reload();
  }

  if (key === APPLICATION_LOGIN_STORAGE_KEY && newValue === APPLICATION_LOGIN_STORAGE_VALUE) {
    destroy$.next(APPLICATION_LOGIN_STORAGE_KEY);
    userProfile({ store })
      .pipe(
        tap(data => {
          if (data !== undefined) {
            history.push(rootRoutePaths[rootRoutes.AUTHENTICATED]);
          }
        }),
        takeUntil(destroy$.pipe(filter(value => value === APPLICATION_LOGIN_STORAGE_KEY)))
      )
      .subscribe();
  }
};
