import React, { ReactElement, useState, MouseEvent, useCallback, PropsWithChildren } from 'react';
import { FieldProps as FormikFieldProps } from 'formik';
import cn from 'classnames';

import { useRefState } from '@/common/utils';
import { getClassName } from '@/styles';
import { useStyles } from '@/styles/hooks';
import { Dialog, DialogProps } from '../../dialog';
import { Field, FieldProps } from '../field';
import { DropContainer, DropContainerProps } from '../file-input/components/drop-container';
import { ControlContainer, ControlContainerProps } from '../file-input/components/control-container';
import { FileType } from '../file-input';
import { fieldModalStyles } from './styles';

type Props<T> = PropsWithChildren<
  Omit<FieldProps, 'children'> &
    DropContainerProps & {
      disabled?: boolean;
      setConfirmFieldValue: () => T;
      setClearFieldValue?: () => T;
      imageContainerProps: Omit<DropContainerProps, 'fileName'> & {
        placeholderText?: string;
      };
      controlContainerProps: Omit<ControlContainerProps, 'accept' | 'multiple'>;
      dialogProps: Partial<DialogProps>;
      hasValue: boolean;
      showDownload?: boolean;
    }
>;

export const FieldModal = <T,>({
  name,
  disabled,
  hasValue,
  showDownload = false,
  imageContainerProps: { placeholderText, ...imageContainerProps },
  controlContainerProps: { buttonProps, infoSectionProps, ...controlContainerProps },
  dialogProps: { onClose, onConfirm, ...dialogProps },
  setConfirmFieldValue,
  children,
  ...fieldProps
}: Props<T>) => {
  const FieldComponent = ({ form }: FormikFieldProps) => {
    const [modalHeight, setModalHeight] = useRefState(0);

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    const { styles } = useStyles(fieldModalStyles);

    const handleClick = useCallback(
      (e: MouseEvent) => {
        e.preventDefault();
        setIsDialogOpen(true);
      },
      [form, name]
    );

    const handleClose = useCallback(() => {
      setIsDialogOpen(false);
      onClose?.();
    }, [form, name, onClose]);

    const handleConfirm = useCallback(() => {
      setIsDialogOpen(false);
      form.setFieldValue(name, setConfirmFieldValue());
      onConfirm?.();
    }, [form, name, onConfirm]);

    const setConstHeight = useCallback(element => {
      if (!element) return;
      setModalHeight(element.getBoundingClientRect().height);
    }, []);

    return (
      <div css={styles}>
        <div className={cn('field', { 'on-right': !hasValue })}>
          <div className='field-content'>
            {hasValue ? (
              <DropContainer
                getRootProps={() => ({})}
                isDragActive={false}
                showDownload={showDownload}
                fileName={placeholderText}
                fileType={FileType.Map}
                disabled={disabled}
                {...imageContainerProps}
              />
            ) : (
              <div className='placeholder' style={{ height: 125 }} />
            )}
            <ControlContainer
              disabled={disabled}
              controlContainerProps={{ className: cn({ 'on-right': !hasValue }) }}
              buttonProps={{
                onClick: handleClick,
                className: getClassName('open-button', { '--compact': hasValue }),
                ...buttonProps,
              }}
              hasValue={hasValue}
              infoSectionProps={{ className: cn({ distant: !hasValue }), ...infoSectionProps }}
              {...controlContainerProps}
            />
          </div>
        </div>

        <Dialog
          open={isDialogOpen}
          onClose={handleClose}
          onConfirm={handleConfirm}
          cancelLabel={'button.cancel'}
          confirmLabel={'button.ok'}
          getDialogElement={setConstHeight}
          dialogProps={{ style: { height: modalHeight || undefined } }}
          {...dialogProps}
        >
          {children as ReactElement}
        </Dialog>
      </div>
    );
  };

  return (
    <Field name={name} isFocusOnLabelEnabled={false} {...fieldProps}>
      {FieldComponent}
    </Field>
  );
};
