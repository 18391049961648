import { SelectOption } from '@/common/components/form-controls-deprecated';

type StatusesResponse = {
  id: string;
  displayName: string;
};

export type StatusesListResponse = StatusesResponse[];

export interface StatusesDictionaryElementVM extends SelectOption {}
export class StatusesDictionaryElementVM {
  constructor({ id, displayName }: StatusesResponse) {
    this.value = id;
    this.label = displayName;
  }
}

export interface StatusesDictionaryVM {
  list: StatusesDictionaryElementVM[];
}

export class StatusesDictionaryVM {
  constructor(list: StatusesListResponse) {
    this.list = (list || []).map(element => new StatusesDictionaryElementVM(element));
  }
}
