import React, { FCC, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { Enum, useForceUpdate } from '@/common/utils';
import { style } from './styles';

const useScrollListener = (element: HTMLDivElement | Document | undefined | null, listener: () => void) => {
  useEffect(() => {
    element?.addEventListener('scroll', listener);

    return () => {
      element?.removeEventListener('scroll', listener);
    };
  }, [!!element]);
};

export const HorizontalScrollDirection = Enum('left', 'right');
export type HorizontalScrollDirection = Enum<typeof HorizontalScrollDirection>;

type Props = {
  tableElement: HTMLTableElement | null;
  canScrollHorizontally?: boolean;
  scrollTo?: HorizontalScrollDirection;
};

export type HorizontalScrollProps = Omit<Props, 'tableElement'>;

export const HorizontalScroll: FCC<Props> = ({ tableElement, canScrollHorizontally, scrollTo, children }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState(containerRef.current?.getBoundingClientRect().width);
  const [left, setLeft] = useState(containerRef.current?.getBoundingClientRect().left);
  const [contentWidth, setContentWidth] = useState(tableElement?.getBoundingClientRect().width);
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    if (scrollTo && scrollRef.current) {
      setScroll(scrollTo === 'left' ? 0 : scrollRef.current.scrollWidth);
    }
  }, [scrollTo]);

  useLayoutEffect(() => {
    setWidth(containerRef.current?.getBoundingClientRect().width);
  }, [containerRef.current?.getBoundingClientRect().width]);

  useLayoutEffect(() => {
    setLeft(containerRef.current?.getBoundingClientRect().left);
  }, [containerRef.current?.getBoundingClientRect().left]);

  useLayoutEffect(() => {
    setContentWidth(tableElement?.getBoundingClientRect().width);
  }, [tableElement?.getBoundingClientRect()]);

  useScrollListener(document, () => setLeft(containerRef.current?.getBoundingClientRect().left));
  useScrollListener(scrollRef.current, () => setScroll(scrollRef.current?.scrollLeft || 0));
  useScrollListener(containerRef.current, () => setScroll(containerRef.current?.scrollLeft || 0));

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    window.addEventListener('resize', forceUpdate);

    return () => {
      window.removeEventListener('resize', forceUpdate);
    };
  });

  useEffect(() => {
    containerRef.current?.scrollTo({ left: scroll });
    scrollRef.current?.scrollTo({ left: scroll });
  }, [scroll]);

  return canScrollHorizontally ? (
    <div css={style} ref={containerRef}>
      {children}

      <div className='scrollbar-wrapper'>
        <div ref={scrollRef} className='scrollbar' style={{ width, left }}>
          <div className='scrollbar__content' style={{ width: contentWidth }} />
        </div>
      </div>
    </div>
  ) : (
    <>{children}</>
  );
};
