import { ActionType, Action, createAction } from 'typesafe-actions';

import {
  UserListVM,
  UserListParams,
  UserDetailsVM,
  UserListSearchByOptionListVM,
  UsersAppsListVM,
  AppDictionaryVM,
  PlatformDictionaryVM,
  HistoryChangesListParams,
  HistoryChangesListVM,
} from '@/features/accounts/models/users';
import { createFetchActions, createDictionaryActions } from '@/common/utils/store';

export const usersActions = {
  list: createFetchActions('FETCH_USERS_LIST_REQUEST', 'FETCH_USERS_LIST_SUCCESS', 'FETCH_USERS_LIST_FAILURE')<UserListParams, UserListVM>(),
  clearUsersList: createAction('CLEAR_USERS_LIST')(),
  details: createFetchActions('FETCH_USER_DETAILS_REQUEST', 'FETCH_USER_DETAILS_SUCCESS', 'FETCH_USER_DETAILS_FAILURE')<string, UserDetailsVM>(),
  searchByOptions: createDictionaryActions(
    'FETCH_SEARCH_BY_OPTIONS_REQUEST',
    'FETCH_SEARCH_BY_OPTIONS_SUCCESS',
    'FETCH_SEARCH_BY_OPTIONS_FAILURE',
    'FETCH_SEARCH_BY_OPTIONS_CANCEL',
    'FETCH_SEARCH_BY_OPTIONS_SILENT_REQUEST'
  )<undefined, UserListSearchByOptionListVM>(),
  usersApps: createFetchActions('FETCH_USERS_APPS_LIST_REQUEST', 'FETCH_USERS_APPS_LIST_SUCCESS', 'FETCH_USERS_APPS_LIST_FAILURE')<string, UsersAppsListVM>(),
  historyChanges: createFetchActions('FETCH_HISTORY_CHANGES_LIST_REQUEST', 'FETCH_HISTORY_CHANGES_LIST_SUCCESS', 'FETCH_HISTORY_CHANGES_LIST_FAILURE')<
    HistoryChangesListParams,
    HistoryChangesListVM
  >(),
  clearHistoryChanges: createAction('CLEAR_HISTORY_CHANGES')(),
  clearUserDetails: createAction('CLEAR_USER_DETAILS')(),
  clearUsersApps: createAction('CLEAR_USERS_APPS')(),
  appDictionary: createDictionaryActions(
    'FETCH_APP_DICTIONARY_REQUEST',
    'FETCH_APP_DICTIONARY_SUCCESS',
    'FETCH_APP_DICTIONARY_FAILURE',
    'FETCH_APP_DICTIONARY_CANCEL',
    'FETCH_APP_DICTIONARY_SILENT_REQUEST'
  )<undefined, AppDictionaryVM>(),
  platformDictionary: createDictionaryActions(
    'FETCH_PLATFORM_DICTIONARY_REQUEST',
    'FETCH_PLATFORM_DICTIONARY_SUCCESS',
    'FETCH_PLATFORM_DICTIONARY_FAILURE',
    'FETCH_PLATFORM_DICTIONARY_CANCEL',
    'FETCH_PLATFORM_DICTIONARY_SILENT_REQUEST'
  )<undefined, PlatformDictionaryVM>(),
};

export type usersActions = Action | ActionType<typeof usersActions>;
