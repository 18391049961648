import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { getEnv, CommonError } from '@/common/utils';
import { httpClient } from '@/core/services/http-client';
import { MapAttributionDetailsResponse, MapAttributionDetailsVM, MapAttributionParams } from '@/models/map-attribution';

const config = getEnv();

const MAP_ATTRIBUTION_DETAILS_ENDPOINT = `${config.REACT_APP_API_URL}/map-style-api/v1/adminPanel/attributions`;
const UPDATE_MAP_ATTRIBUTION_ENDPOINT = `${config.REACT_APP_API_URL}/map-style-api/v1/adminPanel/attributions`;
const CREATE_MAP_ATTRIBUTION_ENDPOINT = `${config.REACT_APP_API_URL}/map-style-api/v1/adminPanel/attribution`;
const DELETE_MAP_ATTRIBUTION_ENDPOINT = `${config.REACT_APP_API_URL}/map-style-api/v1/adminPanel/attributions`;

export const getMapAttributionDetailsData = (id: string) =>
  httpClient()
    .authorized.get<MapAttributionDetailsResponse>(`${MAP_ATTRIBUTION_DETAILS_ENDPOINT}/${id}`)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new MapAttributionDetailsVM(data);
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: e.code, message: e.errorMessage })))
    );

export type UpdateMapAttributionParams = { id: string; data: MapAttributionParams };
export type CreateMapAttributionParams = MapAttributionParams;

export const updateMapAttributionDetails = ({ id, data }: UpdateMapAttributionParams) => {
  return httpClient()
    .authorized.put<MapAttributionDetailsResponse>(`${UPDATE_MAP_ATTRIBUTION_ENDPOINT}/${id}`, data)
    .pipe(
      map(({ status, data }) => {
        if ([200, 201, 202].includes(status) && data !== undefined) {
          return data;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

export const createMapAttributionDetails = (data: CreateMapAttributionParams) => {
  return httpClient()
    .authorized.post<MapAttributionDetailsResponse>(`${CREATE_MAP_ATTRIBUTION_ENDPOINT}`, data)
    .pipe(
      map(({ data, status }) => {
        if ([200, 201].includes(status)) {
          return data;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

export const deleteMapAttribution = (id: string) => {
  return httpClient()
    .authorized.delete(`${DELETE_MAP_ATTRIBUTION_ENDPOINT}/${id}`)
    .pipe(
      map(({ status }) => {
        if ([202, 204].includes(status)) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};
