import { createReducer } from 'typesafe-actions';

import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { PricesDictionaryVM } from '@/models/payments';
import { FeatureStateType } from '../../helpers';
import { toursActions } from '../actions';

type pricesState = DictionaryState<PricesDictionaryVM>;

const INITIAL_STATE: pricesState = getDictionaryInitialState<PricesDictionaryVM>();

const reducer = createReducer<pricesState, toursActions>(INITIAL_STATE)
  .handleAction(toursActions.prices.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(toursActions.prices.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(toursActions.prices.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(toursActions.prices.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(toursActions.prices.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const pricesDictionaryReducer = { prices: reducer };
export type pricesDictionaryReducer = FeatureStateType<typeof pricesDictionaryReducer>;
