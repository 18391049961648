import { SelectOption } from '../components/form-controls-deprecated';

type Pageable = {
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  sort: Sort;
  unpaged: boolean;
};

type Sort = {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
};

export type PageableListResponse<T = any> = {
  content: T[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: Pageable;
  size: number;
  sort: Sort;
  totalElements: number;
  totalPages: number;
};

export interface PageableDataVM {
  pageIndex: number;
  pageSize: number;
  pageCount: number;
  totalElements: number;
}

export class PageableDataVM {
  constructor(props: Omit<PageableListResponse, 'content'>) {
    this.pageIndex = props.number;
    this.pageSize = props.size;
    this.pageCount = props.totalPages;
    this.totalElements = props.totalElements;
  }
}

export const PAGE_SIZE: SelectOption<number>[] = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 30, label: '30' },
  { value: 40, label: '40' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
];

export type PageParameters = {
  orderBy: string;
  page: number;
  size: number;
  sort: string[];
};

export type FetchDataFunc = (props: Partial<PageParameters>) => void;

export type SetBackToFirst = (props: boolean) => void;

export type ApiPagination<P = {}> = {
  name?: string;
  sort?: string[];
  shortResponse?: boolean;
  page?: number;
  size?: number;
} & P;

export const DEFAULT_API_PAGINATION: Required<ApiPagination> = {
  name: '',
  sort: [],
  shortResponse: false,
  page: 0,
  size: PAGE_SIZE[5].value,
};

export const getApiPagination = (params?: ApiPagination): ApiPagination => ({
  name: params?.name || DEFAULT_API_PAGINATION.name,
  sort: params?.sort || DEFAULT_API_PAGINATION.sort,
  shortResponse: params?.shortResponse || DEFAULT_API_PAGINATION.shortResponse,
  page: params?.page || DEFAULT_API_PAGINATION.page,
  size: params?.size || DEFAULT_API_PAGINATION.size,
});

export interface PageableListVM<T, P extends PageableListResponse> {
  list: T[];
  listPaginationData: PageableDataVM;
}

export class PageableListVM<T, P extends PageableListResponse> {
  constructor({ VM, data }: { VM: new (param: P extends PageableListResponse<infer R> ? R : any) => T; data: P }) {
    this.list = (data.content || []).map(entity => new VM(entity));
    this.listPaginationData = new PageableDataVM({ ...data }) || {};
  }
}

export const DEFAULT_LIST_PAGINATION_DATA: PageableDataVM = {
  pageIndex: 0,
  pageSize: 0,
  pageCount: 0,
  totalElements: 0,
};
