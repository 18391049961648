import React, { ReactElement, useMemo, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

import { Subject } from '@/core/models/user-roles';
import { checkUserPermissions, CheckUserPermissionScopes } from '@/common/utils';
import { makeGetUserStatus } from '@/store/authentication';

const getUserStatus = makeGetUserStatus();

type Props<T extends Subject> = PropsWithChildren<CheckUserPermissionScopes<T>>;

export const ProtectedContent = <T extends Subject>({ children, ...props }: Props<T>): ReactElement | null => {
  const userStatus = useSelector(getUserStatus);
  const isAllowed = useMemo(() => checkUserPermissions(props, userStatus.roles), []);
  return isAllowed ? <>{children}</> : null;
};
