import { Styles, theme, constants } from '@/styles';

export const styles: Styles = {
  display: 'flex',
  alignItems: 'center',
  padding: '0px 3px',

  '& > button': {
    paddingLeft: constants.offset.xsmall,
    paddingRight: constants.offset.xsmall,
  },

  '.icon-button': {
    i: {
      color: theme.primaryFocus,
    },
  },

  '.active': {
    '& > i': {
      color: theme.textWhite,
    },
  },
};
