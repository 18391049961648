import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { getEnv, genericAxiosRetry } from '@/common/utils';
import { AuthenticationVM, AuthenticationResponse } from '../../models/authentication';
import { httpClient } from '../http-client';
import { setCookies, getCookies } from './cookies-managment';
import { TOKEN_ENDPOINT_SEGMENT } from './constants';
import { AccountHubPolicy } from './urls';

const config = getEnv();

const getTokenFormData = (code: string | string[]) => {
  const parsedCode = Array.isArray(code) ? code[0] : code;
  const clientId = config.REACT_APP_AUTHENTICATION_CLIENT_ID;
  const clientSecret = config.REACT_APP_AUTHENTICATION_CLIENT_SECRET;
  const scopeUrl = config.REACT_APP_AUTHENTICATION_API_SCOPE_URL;
  const formData = new FormData();
  formData.append('grant_type', 'authorization_code');
  formData.append('client_id', clientId);
  formData.append(
    'scope',
    `openid ${scopeUrl}/platform/read ${scopeUrl}/platform/write ${scopeUrl}/platform/user_impersonation ${scopeUrl}/platform/offline_access offline_access`
  );
  formData.append('code', parsedCode);
  formData.append('client_secret', clientSecret);
  return formData;
};

export const getUserTokens = (code: string, policy: string = AccountHubPolicy.B2C_1A_signup_signin) => {
  const apiUrl = `${config.REACT_APP_AUTHENTICATION_API_URL}/${policy}${TOKEN_ENDPOINT_SEGMENT}`;
  const formData = getTokenFormData(code);
  return httpClient()
    .unauthorized.post<AuthenticationResponse>(apiUrl, formData)
    .pipe(
      map(({ data, status }) => {
        if (status === 200) {
          const authenticationData = new AuthenticationVM(data);
          setCookies(authenticationData);
          return authenticationData;
        }

        throw undefined;
      }),
      genericAxiosRetry({ excludedStatusCodes: [400] }),
      catchError(() => of(undefined))
    );
};

const refreshTokenFormData = (refreshToken: string) => {
  const clientId = config.REACT_APP_AUTHENTICATION_CLIENT_ID;
  const clientSecret = config.REACT_APP_AUTHENTICATION_CLIENT_SECRET;
  const scopeUrl = config.REACT_APP_AUTHENTICATION_API_SCOPE_URL;
  const formData = new FormData();
  formData.append('grant_type', 'refresh_token');
  formData.append('client_id', clientId);
  formData.append(
    'scope',
    `openid ${scopeUrl}/platform/read ${scopeUrl}/platform/write ${scopeUrl}/platform/user_impersonation ${scopeUrl}/platform/offline_access offline_access`
  );
  formData.append('refresh_token', refreshToken);
  formData.append('client_secret', clientSecret);
  return formData;
};

export const refreshTokens = (policy: string = AccountHubPolicy.B2C_1A_signup_signin) => {
  const cookies = getCookies();
  if (cookies === undefined) {
    return of(undefined);
  }

  const apiUrl = `${config.REACT_APP_AUTHENTICATION_API_URL}/${policy}${TOKEN_ENDPOINT_SEGMENT}`;
  const formData = refreshTokenFormData(cookies.refreshToken);
  return httpClient()
    .unauthorized.post<AuthenticationResponse>(apiUrl, formData)
    .pipe(
      map(({ data, status }) => {
        if (status === 200) {
          const authenticationData = new AuthenticationVM(data);
          setCookies(authenticationData);
          return authenticationData;
        }

        throw undefined;
      }),
      catchError(() => of(undefined))
    );
};
