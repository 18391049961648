import React, { FC } from 'react';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { Icon } from '@/common/components/icon';
import { useStyles } from '@/styles/hooks';
import { EditorCard } from '../../../../models';
import { editorToolbarCardStyle } from './styles';

type Props = {
  card: EditorCard;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  disabled: boolean;
};

export const EditorToolbarCard: FC<Props> = ({ card, provided, snapshot, disabled }) => {
  const [t] = useTranslation();
  const { styles } = useStyles(editorToolbarCardStyle);

  const cardElement = (
    <>
      <Icon name={card.iconName} className='card__icon' />
      <div className='card__title'>{t(card.title)}</div>
    </>
  );

  return (
    <div css={styles}>
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className={cn(['card', snapshot.isDragging && !snapshot.isDropAnimating && 'card--is-dragging', disabled && 'disabled'])}
      >
        {cardElement}
      </div>
      {snapshot.isDragging && <div className='card'>{cardElement}</div>}
    </div>
  );
};
