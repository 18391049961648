import { createReducer } from 'typesafe-actions';

import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { ApplicationsDictionaryVM } from '@/models/applications';
import { FeatureStateType } from '../helpers';
import { applicationsActions } from './actions';

type applicationsState = DictionaryState<ApplicationsDictionaryVM>;

const INITIAL_STATE: applicationsState = getDictionaryInitialState<ApplicationsDictionaryVM>();

const reducer = createReducer<applicationsState, applicationsActions>(INITIAL_STATE)
  .handleAction(applicationsActions.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(applicationsActions.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(applicationsActions.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(applicationsActions.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(applicationsActions.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const applicationsReducer = { applications: reducer };
export type applicationsReducer = FeatureStateType<typeof applicationsReducer>;
