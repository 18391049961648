import { Enum } from '@/common/utils';

export interface CustomFile {
  id: string;
  name?: string;
  path?: string;
}

export class CustomFile {
  constructor(props: CustomFile) {
    Object.assign(this, props);
  }
}

export type FileInputValue = File | CustomFile;
export type FileInputValueArray = File[] | CustomFile[];

export const FileType = Enum('Map', 'Image', 'Other');
export type FileType = Enum<typeof FileType>;

export const FileSizeUnit = Enum('Bytes', 'KiloBytes', 'MegaBytes');
export type FileSizeUnit = Enum<typeof FileSizeUnit>;

export type FileSizeType = {
  size: number;
  unit: FileSizeUnit;
};

export type FileSizeConstructor = number | FileSize | FileSizeType;

export interface FileSize {
  size: number;
  unit: FileSizeUnit;
}

export class FileSize {
  public unit: FileSizeUnit;
  public size: number;

  private valueConverter = 1048576;
  private MULTIPLIER = 1024;
  private bytes: number;
  private kiloBytes: number;
  private megaBytes: number;

  constructor(fileSize: number | FileSize, unit: FileSizeUnit = FileSizeUnit.MegaBytes) {
    const size = typeof fileSize === 'number' ? fileSize : fileSize.size;
    this.size = size;
    this.unit = typeof fileSize === 'number' ? unit : fileSize.unit;
    this.bytes = this.toBytes(size, unit);
    this.kiloBytes = this.toKiloBytes(size, unit);
    this.megaBytes = this.toMegaBytes(size, unit);
  }

  get VALUE_CONVERTER(): number {
    return this.valueConverter;
  }

  public get Bytes(): number {
    return this.bytes;
  }

  public get KiloBytes(): number {
    return this.kiloBytes;
  }
  public get MegaBytes(): number {
    return this.megaBytes;
  }

  public toBytes = (size: number, unit?: FileSizeUnit) => {
    const current = unit || this.unit;
    if (current === FileSizeUnit.Bytes) return size;
    if (current === FileSizeUnit.KiloBytes) return size * this.MULTIPLIER;
    return size * this.MULTIPLIER * this.MULTIPLIER;
  };

  public toKiloBytes = (size: number, unit?: FileSizeUnit) => {
    const current = unit || this.unit;
    if (current === FileSizeUnit.Bytes) return size / this.MULTIPLIER;
    if (current === FileSizeUnit.KiloBytes) return size;
    return size * this.MULTIPLIER;
  };

  public toMegaBytes = (size: number, unit?: FileSizeUnit) => {
    const current = unit || this.unit;
    if (current === FileSizeUnit.Bytes) return size / this.MULTIPLIER / this.MULTIPLIER;
    if (current === FileSizeUnit.KiloBytes) return size / this.MULTIPLIER;
    return size;
  };
}

export interface FileInputPresetProps {
  fileType: FileType;
  accept?: string[];
}

export class FileInputPresetProps {
  fileType: FileType;
  accept?: string[];

  constructor(fileType: FileType, accept?: string[]) {
    this.fileType = fileType;
    this.accept = accept;
  }

  get props() {
    return {
      fileType: this.fileType,
      accept: this.accept,
    };
  }
}
