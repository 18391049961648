import React, { FC, HTMLAttributes, ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { Button, ButtonProps } from '@/common/components/button';
import { UPLOAD_BUTTON_ID } from '../../constants';
import { styles } from './styles';

export type ControlContainerProps = {
  controlContainerProps?: HTMLAttributes<HTMLDivElement>;
  infoSectionProps?: HTMLAttributes<HTMLDivElement>;
  buttonProps: ButtonProps & { label?: string | ReactElement };
  accept?: string[];
  alterMessage?: string;
  multiple?: boolean;
  disabled?: boolean;
};

type Props = ControlContainerProps & {
  hasValue: boolean;
  max?: number;
  editorCardMode?: boolean;
};

export const ControlContainer: FC<Props> = ({
  buttonProps: { label, ...buttonProps },
  accept,
  max,
  alterMessage,
  disabled,
  multiple,
  hasValue,
  controlContainerProps,
  infoSectionProps,
  editorCardMode,
}) => {
  const [t] = useTranslation();

  const infoLines = useMemo(() => {
    if (alterMessage) return [t(alterMessage)];

    if (max) return [t('label.maximumFileSize'), `${t('label.maximumFileSizeValue', { fileSize: max })}${accept ? `, ${accept?.join(', ')}` : ''}`];

    return [];
  }, [t, alterMessage, max, accept]);

  const buttonLabel = useMemo(() => {
    if (label) return typeof label === 'string' ? t(label) : label;

    if (hasValue) {
      return t(multiple ? 'button.changeFiles' : 'button.changeFile');
    }
    return t(multiple ? 'button.chooseFiles' : 'button.chooseFile');
  }, [t, hasValue, multiple, label]);

  return (
    <div css={styles} {...controlContainerProps}>
      <Button
        id={UPLOAD_BUTTON_ID}
        type='button'
        theme={hasValue ? 'secondary' : 'primary'}
        disabled={disabled}
        className={cn(['upload-button', { 'upload-button--small': editorCardMode }])}
        {...buttonProps}
      >
        {buttonLabel}
      </Button>
      <div {...infoSectionProps} className={cn('info-section', infoSectionProps?.className)}>
        {infoLines.map((line, index) => (
          <span key={index}>{line}</span>
        ))}
      </div>
    </div>
  );
};
