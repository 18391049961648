import { Enum } from './common';

type ENV_VARS = Enum<typeof ENV_VARS>;
const ENV_VARS = Enum(
  'REACT_APP_API_URL',
  'REACT_APP_AUTHENTICATION_CLIENT_ID',
  'REACT_APP_AUTHENTICATION_CLIENT_SECRET',
  'REACT_APP_AUTHENTICATION_API_URL',
  'REACT_APP_AUTHENTICATION_KNOWN_AUTHORITIES',
  'REACT_APP_AUTHENTICATION_API_SCOPE_URL',
  'REACT_APP_OS_MAPS_URL',
  'REACT_APP_SHOW_TEST_FEATURES'
);

type Env = {
  [key in ENV_VARS]: string;
};

export const getEnv = (): Env => {
  return Object.keys(ENV_VARS).reduce((obj, value) => ({ ...obj, [value]: process.env[value] || '' }), {} as Env);
};

export const MODERATION_EMAIL = 'oshian.parris@os.uk ';
export const ADMIN_PANEL_MAIL = 'adminpanel@os.uk';

export const APP_NAME = 'OS_ADMIN_PANEL';
export const PLATFORM_NAME = 'WEB';
