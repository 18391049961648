import { Styles } from '@/styles';

export const style: Styles = {
  display: 'flex',
  flexGrow: 0,
  flexShrink: 1,

  '& > *:not(:last-child)': {
    marginRight: 8,
  },
};
