import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { OnRangeFilterChangeFunc, RangeFilter, RangeFilterProps, SanitizeInputValueFunc } from '../range-filter';

export type DistanceFilterChangeFunc = OnRangeFilterChangeFunc;

type Props = Pick<RangeFilterProps, 'value' | 'disabled' | 'onChange'>;

export const DistanceFilter: FC<Props> = ({ value, onChange, disabled }) => {
  const [t] = useTranslation();

  const sanitizeInputValue = useCallback<SanitizeInputValueFunc>(value => (value ? value.replace('-', '') : ''), []);

  return (
    <RangeFilter
      value={value}
      disabled={disabled}
      onChange={onChange}
      sanitizeInputValue={sanitizeInputValue}
      placeholders={{ start: t('placeholder.from'), end: t('placeholder.to') }}
    />
  );
};
