import React, { FC } from 'react';
import cn from 'classnames';

import { Field, FieldProps } from '../field';
import { Select, SelectOption, SelectProps } from './select';

type FieldSelectProps = Omit<FieldProps, 'children'> & Omit<SelectProps, 'onChange' | 'onBlur'> & { onBlur?: () => void };

export const FieldSelect: FC<FieldSelectProps> = ({ name, label, labelIcon, isFocusOnLabelEnabled, className, link, onChange, onBlur, ...props }) => (
  <Field name={name} label={label} labelIcon={labelIcon} isFocusOnLabelEnabled={isFocusOnLabelEnabled} className={className} link={link}>
    {({ field, meta, form }) => {
      const inputClassName = cn([{ error: meta.touched && meta.error }]);

      const handleChange = (option: SelectOption | undefined) => {
        form.setFieldValue(name, option?.value);
      };

      const handleOnBlur = () => {
        form.setFieldTouched(name);
        if (onBlur !== undefined) {
          onBlur();
        }
      };

      return <Select {...props} className={inputClassName} {...field} onChange={handleChange} onBlur={handleOnBlur} />;
    }}
  </Field>
);
