import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const dateFilterStyles: StyleFunction = ({ theme }) => ({
  '.date-range-input-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: constants.offset.small,
  },

  '.date-range-input-row': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    '&:not(:last-child)': {
      marginBottom: constants.offset.xsmall,
    },
  },

  '.react-datepicker-wrapper': {
    maxWidth: 200,
  },

  '.react-datepicker__navigation': {
    span: {
      '&::before': {
        borderColor: theme.textPrimary,
      },
    },
  },

  '.date-range-button': {
    marginRight: constants.offset.medium,

    '&--selected': {
      fontWeight: 900,
    },
  },

  '.date-range-input-label': {
    marginRight: constants.offset.small,
    color: theme.textPrimary,
  },

  '.date-range-input': {
    maxWidth: 145,
  },

  '.tabs-wrapper': {
    width: '100%',
  },

  '.date-range-button-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',

    '& > button': {
      maxWidth: 'fit-content',

      '&:not(:last-child)': {
        marginBottom: constants.offset.small,
      },
    },
  },
});
