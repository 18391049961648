import { ActionType, Action, createAction } from 'typesafe-actions';

import { ContentProvidedByUserRoutesParams, UserRoutesListVM } from '@/features/content/models/routes';
import { CommunityStoriesParams, UserCommunityStoriesListVM } from '@/features/content/models/community-stories';
import { createFetchActions } from '@/common/utils/store';

export const contentProvidedByUserActions = {
  routes: {
    ...createFetchActions('FETCH_USERS_ROUTES_REQUEST', 'FETCH_USERS_ROUTES_SUCCESS', 'FETCH_USERS_ROUTES_FAILURE')<
      ContentProvidedByUserRoutesParams,
      UserRoutesListVM
    >(),
    clear: createAction('CLEAR_USERS_ROUTES')(),
  },
  communityStories: {
    ...createFetchActions('FETCH_USERS_COMMUNITY_STORIES_REQUEST', 'FETCH_USERS_COMMUNITY_STORIES_SUCCESS', 'FETCH_USERS_COMMUNITY_STORIES_FAILURE')<
      CommunityStoriesParams,
      UserCommunityStoriesListVM
    >(),
    clear: createAction('CLEAR_USERS_COMMUNITY_STORIES')(),
  },
};

export type contentProvidedByUserActions = Action | ActionType<typeof contentProvidedByUserActions>;
