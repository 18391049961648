import { createReducer } from 'typesafe-actions';

import { DifficultiesDictionaryVM } from '@/models/difficulties';
import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { FeatureStateType } from '../helpers';
import { difficultiesActions } from './actions';

type difficultiesState = DictionaryState<DifficultiesDictionaryVM>;

const INITIAL_STATE: difficultiesState = getDictionaryInitialState<DifficultiesDictionaryVM>();

const reducer = createReducer<difficultiesState, difficultiesActions>(INITIAL_STATE)
  .handleAction(difficultiesActions.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(difficultiesActions.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(difficultiesActions.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(difficultiesActions.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(difficultiesActions.failure, (state, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const difficultiesReducer = { difficulties: reducer };
export type difficultiesReducer = FeatureStateType<typeof difficultiesReducer>;
