import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ConsentDocumentListResponse, ConsentDocumentListVM, PerformConsent } from '@/models/consents';
import { httpClient } from '@/core/services/http-client';
import { getEnv, CommonError } from '@/common/utils';

const config = getEnv();

const USERS_ENDPOINT = `${config.REACT_APP_API_URL}/user-account-api/v1/admin/users`;

export const getUserConsents = () =>
  httpClient()
    .authorized.get<ConsentDocumentListResponse>(`${USERS_ENDPOINT}/me/missingConsents`)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new ConsentDocumentListVM(data);
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const performConsent = (data: PerformConsent) => {
  const { documentId, startDate, endDate } = data;
  return httpClient()
    .authorized.put<undefined>(`${USERS_ENDPOINT}/me/consents/${documentId}`, { startDate, endDate })
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return data;
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};
