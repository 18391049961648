import { combineReducers } from 'redux';

import { FeatureStateType } from '@/store/helpers';
import { mapAttributionListReducer } from './list';
import { mapAttributionDetailsReducer } from './details';

export type mapAttributionReducer = FeatureStateType<typeof mapAttributionReducer>;

export const mapAttributionReducer = {
  mapAttribution: combineReducers({
    ...mapAttributionListReducer,
    ...mapAttributionDetailsReducer,
  }),
};
