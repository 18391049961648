import { createSelector } from 'reselect';

import { ThingsToDoListVM } from '@/features/content/models/things-to-do';
import { getDataStatus } from '@/common/utils';
import { thingsToDoReducer } from './reducers';

type ThingsToDoList = thingsToDoReducer['thingsToDo']['list'];
type MakeGetThingsToDoListData = ThingsToDoListVM['list'];
type MakeGetThingsToDoListPaginationData = ThingsToDoListVM['listPaginationData'] | undefined;
type MakeGetThingsToDoListLength = { length: number };
type MakeGetThingsToDoListFilters = ThingsToDoList['params'];

const getThingsToDoList = (state: thingsToDoReducer) => state.thingsToDo.list;

export const makeGetThingsToDoList = () =>
  createSelector<thingsToDoReducer, ThingsToDoList, MakeGetThingsToDoListData>(getThingsToDoList, ({ data }) => (data === undefined ? [] : data.list));

export const makeGetThingsToDoListLength = () =>
  createSelector<thingsToDoReducer, ThingsToDoList, MakeGetThingsToDoListLength>(getThingsToDoList, ({ data }) => ({ length: (data?.list || []).length }));

export const makeGetThingsToDoListStatus = () =>
  createSelector<thingsToDoReducer, ThingsToDoList, ReturnType<typeof getDataStatus>>(getThingsToDoList, getDataStatus);

export const makeGetThingsToDoListPaginationData = () =>
  createSelector<thingsToDoReducer, ThingsToDoList, MakeGetThingsToDoListPaginationData>(getThingsToDoList, ({ data }) => data?.listPaginationData);

export const makeGetThingsToDoListFilters = () =>
  createSelector<thingsToDoReducer, ThingsToDoList, MakeGetThingsToDoListFilters>(getThingsToDoList, ({ params }) => params);

const getThingsToDoDetails = (state: thingsToDoReducer) => state.thingsToDo.details;

type ThingsToDoDetails = thingsToDoReducer['thingsToDo']['details'];
type MakeGetThingsToDoDetailsData = ThingsToDoDetails['data'];

export const makeGetThingsToDoDetailsData = () =>
  createSelector<thingsToDoReducer, ThingsToDoDetails, MakeGetThingsToDoDetailsData>(getThingsToDoDetails, ({ data }) => data);
export const makeGetThingsToDoDetailsStatus = () =>
  createSelector<thingsToDoReducer, ThingsToDoDetails, ReturnType<typeof getDataStatus>>(getThingsToDoDetails, getDataStatus);

type PlaceCategoriesDictionary = thingsToDoReducer['thingsToDo']['placeCategories'];
type MakeGetPlaceCategoriesDictionaryData = thingsToDoReducer['thingsToDo']['placeCategories']['data'];

const getPlaceCategoriesDictionary = (state: thingsToDoReducer) => state.thingsToDo.placeCategories;

export const makeGetPlaceCategoriesDictionaryData = () =>
  createSelector<thingsToDoReducer, PlaceCategoriesDictionary, MakeGetPlaceCategoriesDictionaryData>(getPlaceCategoriesDictionary, ({ data }) => data);

export const makeGetPlaceCategoriesDictionaryStatus = () =>
  createSelector<thingsToDoReducer, PlaceCategoriesDictionary, ReturnType<typeof getDataStatus>>(getPlaceCategoriesDictionary, getDataStatus);
