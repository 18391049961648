import { createSelector } from 'reselect';

import { getDataStatus } from '@/common/utils';
import { contextTypesReducer } from './reducer';

type MakeGetContextTypesData = contextTypesReducer['contextTypes']['data'];

const getContextTypes = (state: contextTypesReducer) => state.contextTypes;

export const makeGetContextTypesData = () =>
  createSelector<contextTypesReducer, contextTypesReducer['contextTypes'], MakeGetContextTypesData>(getContextTypes, ({ data }) => data);
export const makeGetContextTypesStatus = () =>
  createSelector<contextTypesReducer, contextTypesReducer['contextTypes'], ReturnType<typeof getDataStatus>>(getContextTypes, getDataStatus);
