import { switchMap, filter, map } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { CommonError, dictionaryEffectHelper } from '@/common/utils';
import { getGalleryApplicationsData, getGalleryListData, getGalleryStatusData } from '@/features/content/services/gallery/gallery-list';
import { galleryActions } from './actions';
import { galleryReducer } from './reducers';

const getGalleryList: Epic<galleryActions, galleryActions, galleryReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(galleryActions.list.request)),
    switchMap(() => {
      return getGalleryListData(state$.value.gallery.list.params).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return galleryActions.list.failure(response);
          }

          return galleryActions.list.success(response);
        })
      );
    })
  );

const getGalleryStatusDictionary: Epic<galleryActions, galleryActions, galleryReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(galleryActions.status.request)),
    switchMap(() => {
      const state = state$.value.gallery.status;
      return dictionaryEffectHelper(() => getGalleryStatusData(), state, galleryActions.status);
    })
  ) as any;

const getGalleryApplicationsDictionary: Epic<galleryActions, galleryActions, galleryReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(galleryActions.application.request)),
    switchMap(() => {
      const state = state$.value.gallery.application;
      return dictionaryEffectHelper(() => getGalleryApplicationsData(), state, galleryActions.application);
    })
  ) as any;

export const galleryEpics = [getGalleryList, getGalleryStatusDictionary, getGalleryApplicationsDictionary];
