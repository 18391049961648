import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Row } from 'react-table';
import { useTranslation } from 'react-i18next';

import { Button, ButtonTheme } from '@/common/components/button';
import { useStyles } from '@/styles/hooks';
import { selectedActionsStyles } from './styles';

export type SelectActionsProps<T extends {}> = {
  selectedFlatRows: Row<T>[];
  maxSelectedRows?: number;
  itemsTranslation?: string;
  actionButtonText?: string;
  actionButtonType?: ButtonTheme;
  onRowSelectCancel?: () => void;
  onRowSelectConfirm?: () => void;
};

export const SelectActions = <T extends {}>({
  selectedFlatRows,
  maxSelectedRows,
  itemsTranslation,
  onRowSelectCancel,
  onRowSelectConfirm,
  actionButtonText,
  actionButtonType = ButtonTheme.primary,
}: SelectActionsProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);
  const [t] = useTranslation();
  const { styles } = useStyles(selectedActionsStyles);

  const [isFixed, setIsFixed] = useState(true);

  const message = useMemo(
    () =>
      t('table.selectedItems.message', {
        items: itemsTranslation || t('table.selectedItems.items'),
        count: selectedFlatRows.length,
        max: maxSelectedRows,
      }),
    [t, itemsTranslation, selectedFlatRows, maxSelectedRows]
  );

  useEffect(() => {
    const { current } = ref;
    if (!current) return;

    const observer = new IntersectionObserver(([event]) => setIsFixed(!event.isIntersecting), { threshold: [1, 0.5] });
    observer.observe(current);

    return () => observer.unobserve(current);
  }, []);

  return (
    <div css={styles} ref={ref}>
      <div className='select-actions-wrapper' style={{ position: isFixed ? 'fixed' : 'static' }}>
        <p className='message'>{message}</p>

        <Button theme='secondary' onClick={onRowSelectCancel}>
          {t('button.cancel')}
        </Button>

        <Button theme={actionButtonType} disabled={selectedFlatRows.length === 0} onClick={onRowSelectConfirm}>
          {actionButtonText ? actionButtonText : t('button.change')}
        </Button>
      </div>
    </div>
  );
};
