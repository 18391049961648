import { createReducer } from 'typesafe-actions';

import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { InvitationStatusDictionaryVM } from '@/models/invitations';
import { FeatureStateType } from '../../helpers';
import { invitationsActions } from '../actions';

type statusesState = DictionaryState<InvitationStatusDictionaryVM>;

const INITIAL_STATE: statusesState = getDictionaryInitialState<InvitationStatusDictionaryVM>();

const reducer = createReducer<statusesState, invitationsActions>(INITIAL_STATE)
  .handleAction(invitationsActions.statuses.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(invitationsActions.statuses.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(invitationsActions.statuses.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(invitationsActions.statuses.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(invitationsActions.statuses.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const statusesDictionaryReducer = { statuses: reducer };
export type statusesDictionaryReducer = FeatureStateType<typeof statusesDictionaryReducer>;
