import { createReducer } from 'typesafe-actions';

import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { DEFAULT_LIST_PAGINATION_DATA } from '@/common/utils/pageable';
import { InvitationListVM, InvitationListRequest } from '@/models/invitations';
import { FeatureStateType } from '../../helpers';
import { invitationsActions } from '../actions';

export type InvitationListState = FetchState<InvitationListVM, InvitationListRequest>;

const INITIAL_STATE: InvitationListState = getFetchInitialState<InvitationListVM, InvitationListRequest>({ type: 'USER', sort: ['invitationDate,DESC'] });

const reducer = createReducer<InvitationListState, invitationsActions>(INITIAL_STATE)
  .handleAction(invitationsActions.list.request, (state, { payload }) => fetchReducerHelpers.request(INITIAL_STATE, state, payload))
  .handleAction(invitationsActions.list.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(invitationsActions.list.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(invitationsActions.clearInvitationList, state => ({
    ...state,
    data: { ...state.data, list: [], listPaginationData: state.data?.listPaginationData || DEFAULT_LIST_PAGINATION_DATA },
  }));

export const invitationListReducer = { list: reducer };
export type invitationListReducer = FeatureStateType<typeof invitationListReducer>;
