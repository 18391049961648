import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const filterWrapperStyle: StyleFunction = ({ theme }) => ({
  '.filter-label': {
    display: 'flex',
    marginTop: constants.offset.small,
    lineHeight: 2,
    color: theme.primary,
    fontWeight: 600,

    '.label-icon': {
      color: theme.primary,
      paddingLeft: constants.offset.small,
      cursor: 'pointer',
      transition: 'opacity .2s',

      '&:hover': {
        opacity: 0.6,
      },

      '&--disabled': {
        color: theme.textDisabled,

        '&:hover': {
          opacity: 1,
          cursor: 'not-allowed',
        },
      },
    },
  },

  '.filter-wrapper': {
    borderTop: `1px solid ${theme.borderAccent}`,
    backgroundColor: theme.backgroundSecondary,
    padding: `${constants.offset.medium}px ${constants.offset.large}px`,
    marginTop: constants.offset.xsmall,
  },
});
