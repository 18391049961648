import { RefObject, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import uniqueId from 'lodash/uniqueId';

import { useRefState } from '@/common/utils';
import { Step, OnboardingContext } from './components';
import { onboardingActions } from './contextStore';

export type StepWithoutTarget = Omit<Step, 'target'>;
export type UseTourStepOptions = { override?: { next: (prev: Step) => Step; deps: any[] }; idPrefix?: string };

export const useTourStepOverride = (prev: Step, next: Step, deps: any[] = []) => {
  const { dispatch } = useContext(OnboardingContext);

  useEffect(() => {
    dispatch(onboardingActions.replaceStep({ prev, next }));
  }, [...deps]);
};

export const useTourStepRef = <E extends HTMLElement>(step: StepWithoutTarget): [RefObject<E>, Step] => {
  const { dispatch } = useContext(OnboardingContext);
  const ref = useRef<E>(null);
  const [isSet, setIsSet] = useState(!!ref.current);
  const tourStep = useMemo(() => ({ ...step, target: ref.current as HTMLElement }), [step, ref, ref.current]);

  useEffect(() => {
    if (ref.current && !isSet) {
      dispatch(
        onboardingActions.addStep({
          ...step,
          target: ref.current as HTMLElement,
        })
      );
      setIsSet(true);
    }
  }, [isSet]);

  return [ref, tourStep];
};

export const useTourStepId = (step: StepWithoutTarget, id?: string, options?: UseTourStepOptions): [string, Step] => {
  const { dispatch } = useContext(OnboardingContext);
  const [ID] = useState(id || uniqueId(options?.idPrefix));
  const [isNotSet, setIsNotSet] = useRefState(1);
  const [isSet, setIsSet] = useState(false);
  const tourStep = useMemo(() => ({ ...step, target: `#${ID}` }), [step, ID]);
  const target = document.querySelector(`#${ID}`);

  useEffect(() => {
    if (isNotSet && !isSet && target) {
      dispatch(onboardingActions.addStep(tourStep));
      setIsNotSet(0);
      setIsSet(true);
    } else {
      setIsNotSet(i => i + 1);
    }
  }, [isNotSet, target]);

  const { next, deps } = options?.override || { next: prev => prev, deps: [] };
  useTourStepOverride(tourStep, next(tourStep), deps);

  return [ID, tourStep];
};

export const useTourCanStart = (canStart: boolean | (() => boolean)) => {
  const { dispatch } = useContext(OnboardingContext);

  useEffect(() => {
    const start = canStart instanceof Function ? canStart() : canStart;
    if (start) dispatch(onboardingActions.setCanStart(start));
  }, [canStart]);
};

export const useOnboardingState = () => {
  const { state } = useContext(OnboardingContext);
  return state.currentStep;
};

export type UseOnboardingOptions = {
  canStart: boolean | (() => boolean);
  name?: string;
  startDelay?: number;
};

export const useOnboarding = ({ canStart, name, startDelay }: UseOnboardingOptions) => {
  const { state, dispatch } = useContext(OnboardingContext);

  const setName = useCallback(() => {
    if (!state.name && name) dispatch(onboardingActions.setName(name));
  }, [state.name, name]);

  const setStartDelay = useCallback(() => {
    if (!state.startDelay && startDelay) dispatch(onboardingActions.setStartDelay(startDelay));
  }, [state.startDelay, startDelay]);

  useEffect(() => {
    setName();
    setStartDelay();
  }, [setName, setStartDelay]);

  useTourCanStart(canStart);

  return {
    useTourStepRef,
    useTourStepId,
    useTourStepOverride,
    onboardingState: state.currentStep,
  };
};
