import { combineReducers } from 'redux';

import { FeatureStateType } from '@/store/helpers';
import { reviewsListReducer } from './list';
import { reviewsDetailsReducer } from './details';
import { statusDictionaryReducer } from './statuses';
import { targetTypeDictionaryReducer } from './target-types';
import { applicationDictionaryReducer } from './applications';
import { searchByOptionsReducer } from './search-by-options';
import { ratingContentTypeDictionaryReducer } from './rating-content-types';

export type reviewsReducer = FeatureStateType<typeof reviewsReducer>;

export const reviewsReducer = {
  reviews: combineReducers({
    ...reviewsListReducer,
    ...reviewsDetailsReducer,
    ...statusDictionaryReducer,
    ...targetTypeDictionaryReducer,
    ...applicationDictionaryReducer,
    ...searchByOptionsReducer,
    ...ratingContentTypeDictionaryReducer,
  }),
};
