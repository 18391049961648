import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';
import { CARD_BASE_HEIGHT } from '../../../../constants';

export const commonCardStyle: StyleFunction = ({ theme }) => ({
  '.entity': {
    '&__wrapper': {
      paddingTop: constants.offset.small,
    },

    '&__header': {
      display: 'flex',
      flexDirection: 'row',
      height: CARD_BASE_HEIGHT,
      borderRadius: constants.borderRadius.small,
      backgroundColor: theme.backgroundPrimary,
      border: `1px solid ${theme.borderSecondary}`,
      overflow: 'hidden',

      '.header': {
        '&__handle': {
          display: 'flex',
          flexGrow: 0,
          flexShrink: 0,
          flexBasis: 44,
          justifyContent: 'center',
          alignItems: 'center',
          outline: 0,
          borderRight: `1px solid ${theme.borderSecondary}`,
          transition: 'backgroundColor .2s',

          '&:hover': {
            '.icon': {
              color: theme.primaryHover,
            },
          },

          '.icon': {
            fontSize: constants.fontSize.icon,
            color: theme.primary,
            transition: 'color .2s',

            '&--disabled': {
              color: theme.textDisabled,
            },
          },

          '&--is-dragging': {
            backgroundColor: theme.primary,

            '.icon': {
              color: theme.backgroundPrimary,
            },
          },
        },

        '&__icon': {
          display: 'flex',
          flexGrow: 0,
          flexShrink: 0,
          flexBasis: 44,
          justifyContent: 'center',
          alignItems: 'center',
          borderRight: `1px solid ${theme.borderSecondary}`,

          '.icon': {
            fontSize: constants.fontSize.icon,
            color: theme.textPrimary,
          },
        },

        '&__content': {
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          flexShrink: 1,
          alignItems: 'center',
          padding: `0 ${constants.offset.small}px`,
          overflow: 'auto',
        },

        '&__status': {
          display: 'flex',
          flexGrow: 0,
          flexShrink: 0,
          alignItems: 'center',
          marginRight: constants.offset.xsmall,
        },

        '&__actions': {
          display: 'flex',
          flexGrow: 0,
          flexShrink: 0,
          flexDirection: 'row',
          alignItems: 'center',

          '.action': {
            height: 30,
            width: 30,
            marginRight: constants.offset.small,
            justifyContent: 'center',
          },
        },
      },

      '&--square-bottom-corners': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    },

    '&__container': {
      borderBottomLeftRadius: constants.borderRadius.small,
      borderBottomRightRadius: constants.borderRadius.small,
      backgroundColor: theme.backgroundPrimary,
      border: `1px solid ${theme.borderSecondary}`,
      borderTopWidth: 0,

      '.container': {},
    },

    '&--is-dragging': {},
  },
});
