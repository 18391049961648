import { createReducer } from 'typesafe-actions';

import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { StoryDetailsVM } from '@/models/tours';
import { FeatureStateType } from '../../helpers';
import { toursActions } from '../actions';

export type storyDetailsState = FetchState<StoryDetailsVM>;

const INITIAL_STATE: storyDetailsState = getFetchInitialState<StoryDetailsVM>();

const reducer = createReducer<storyDetailsState, toursActions>(INITIAL_STATE)
  .handleAction(toursActions.story.request, (state, { payload }) => fetchReducerHelpers.request(INITIAL_STATE, state, payload))
  .handleAction(toursActions.story.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(toursActions.story.failure, (_, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, _, payload))
  .handleAction(toursActions.clearStoryDetails, () => ({ ...INITIAL_STATE }));

export const storyDetailsReducer = { story: reducer };
export type storyDetailsReducer = FeatureStateType<typeof storyDetailsReducer>;
