import { switchMap, filter, map } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { getThingsToDoTagsData, getThingsToDoTagDetailsData } from '@/features/content/services/things-to-do-tags/things-to-do-tags';
import { untilLocationChange, CommonError } from '@/common/utils';
import { thingsToDoTagsReducer } from './reducers';
import { thingsToDoTagsActions } from './actions';

const getThingsToDoTagsList: Epic<thingsToDoTagsActions, thingsToDoTagsActions, thingsToDoTagsReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(thingsToDoTagsActions.list.request)),
    switchMap(() =>
      getThingsToDoTagsData(state$.value.thingsToDoTags.list.params).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return thingsToDoTagsActions.list.failure(response);
          }

          return thingsToDoTagsActions.list.success(response);
        })
      )
    )
  );

const getThingsToDoTagDetails: Epic<thingsToDoTagsActions, thingsToDoTagsActions, thingsToDoTagsReducer> = action$ =>
  action$.pipe(
    filter(isActionOf(thingsToDoTagsActions.details.request)),
    switchMap(action =>
      getThingsToDoTagDetailsData(action.payload.params)
        .pipe(untilLocationChange(action$))
        .pipe(
          map(response => {
            if (response instanceof CommonError) {
              return thingsToDoTagsActions.details.failure(response);
            }

            return thingsToDoTagsActions.details.success(response);
          })
        )
    )
  );

export const thingsToDoTagsEpics = [getThingsToDoTagsList, getThingsToDoTagDetails];
