import React, { FC } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

import { useStyles } from '@/styles/hooks';
import { NavGroup } from '../../../../models';
import { NavigationElement } from '../navigation-element';
import { navigationElementGroupStyles } from './styles';

type Props = {
  data: NavGroup;
};

export const NavigationElementGroup: FC<Props> = ({ data }) => {
  const match = useRouteMatch({ path: data.url });
  const { styles } = useStyles(navigationElementGroupStyles);

  return (
    <AnimatePresence>
      {match !== null && (
        <motion.div css={styles} initial={{ opacity: 0 }} animate={{ opacity: 1, position: 'absolute' }} transition={{ duration: 0.2 }} exit={{ opacity: 0 }}>
          {data.elements.map((element, index) => (
            <NavigationElement key={`navigation-element-${index}`} data={element} />
          ))}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
