import { ActionType, Action } from 'typesafe-actions';

import { createDictionaryActions } from '@/common/utils/store';
import { RegionDictionaryVM } from '@/core/models/data-center-regions';

export const dataCenterSwitcherActions = {
  regions: createDictionaryActions(
    `FETCH_REGIONS_DICTIONARY_REQUEST`,
    `FETCH_REGIONS_DICTIONARY_SUCCESS`,
    `FETCH_REGIONS_DICTIONARY_FAILURE`,
    `FETCH_REGIONS_DICTIONARY_CANCEL`,
    `FETCH_REGIONS_DICTIONARY_SILENT_REQUEST`
  )<undefined, RegionDictionaryVM>(),
};

export type dataCenterSwitcherActions = Action | ActionType<typeof dataCenterSwitcherActions>;
