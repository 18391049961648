import dayjs from 'dayjs';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';

import { DEFAULT_DATE_FORMAT } from '@/common/utils';
import { ApiPagination, PageableListResponse, PageableListVM } from '@/common/utils/pageable';

export type ModerationParams = ApiPagination<{
  contentTypes?: string[];
  contextTypes?: string[];
  contextIds?: string[];
  reasons?: string[];
  statuses?: string[];
  currentRegionUrl?: string | undefined;
}>;

type ModerationEntityResponse = {
  application: string;
  comment: string;
  contentId: string;
  contentType: string;
  contextId: string;
  contextName: string;
  contextType: string;
  createdAt: string;
  createdBy: {
    email: string;
    givenName: string;
    organisationId: string;
    roleId: string;
    surname: string;
    userId: string;
  };
  id: string;
  reason: string;
  status: string;
  reviewDetails: {
    targetId: string;
    targetName: string;
    targetType: string;
  };
};

export type ModerationListResponse = PageableListResponse<ModerationEntityResponse>;
export type ModerationDetailsResponse = ModerationEntityResponse;

export interface ModerationElementVM extends ModerationEntityResponse {}

export class ModerationElementVM {
  constructor({ createdAt, reviewDetails, ...props }: ModerationEntityResponse) {
    Object.assign(this, {
      ...props,
      createdAt: createdAt ? dayjs(createdAt).format(DEFAULT_DATE_FORMAT) : '',
      reviewDetails: {
        ...reviewDetails,
        targetType: reviewDetails && startCase(toLower(reviewDetails.targetType)),
      },
    });
  }
}

export class ModerationListVM extends PageableListVM<ModerationElementVM, ModerationListResponse> {}
