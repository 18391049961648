import React, { forwardRef } from 'react';
import cn from 'classnames';

import { useStyles } from '@/styles/hooks';
import { Anchor, AnchorProps } from '../link';
import { ButtonTheme } from './button';
import { buttonStyles } from './styles';

type Props = Omit<AnchorProps, 'to'> & {
  link: string;
  theme?: ButtonTheme;
  disabled?: boolean;
};

export const ButtonLink = forwardRef<HTMLAnchorElement, Props>(({ children, theme = 'primary', link, disabled, target, className, ...props }, ref) => {
  const { styles } = useStyles(buttonStyles);

  if (disabled) {
    return (
      <span css={styles} className={cn([className, theme, 'button-link', `button-link--${theme}-disabled`])}>
        {children}
      </span>
    );
  }
  return (
    <Anchor ref={ref} to={link} css={styles} className={cn([className, theme, 'button-link'])} target={target} {...props}>
      {children}
    </Anchor>
  );
});
