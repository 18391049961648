import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const navigationElementGroupStyles: StyleFunction = ({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  top: 0,
  bottom: 0,
  height: '100%',
  width: '100%',
  paddingLeft: constants.offset.xlarge,
  paddingRight: constants.offset.xlarge,
  backgroundColor: theme.backgroundEight,
});
