import { switchMap, filter } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { getVisibilityData } from '@/features/content/services/dictionaries';
import { dictionaryEffectHelper } from '@/common/utils';
import { visibilityActions } from './actions';

const getVisibilityList: Epic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(visibilityActions.request)),
    switchMap(() => {
      const state = state$.value.visibility;
      return dictionaryEffectHelper(() => getVisibilityData(), state, visibilityActions);
    })
  );

export const visibilityEpics = [getVisibilityList];
