import { createSelector } from 'reselect';

import { getDataStatus } from '@/common/utils';
import { applicationsReducer } from './reducer';

type MakeGetApplicationsData = applicationsReducer['applications']['data'];

const getApplications = (state: applicationsReducer) => state.applications;

export const makeGetApplicationsData = () =>
  createSelector<applicationsReducer, applicationsReducer['applications'], MakeGetApplicationsData>(getApplications, ({ data }) => data);
export const makeGetApplicationsStatus = () =>
  createSelector<applicationsReducer, applicationsReducer['applications'], ReturnType<typeof getDataStatus>>(getApplications, getDataStatus);
