import { createSelector } from 'reselect';

import { getDataStatus } from '@/common/utils';
import { TourAnalyticsListVM } from '@/features/reporting/models/tour-analytics';
import { tourAnalyticsReducer as State } from './reducers';

type Status = ReturnType<typeof getDataStatus>;
type TourAnalyticsList = State['tourAnalytics']['list'];
type MakeTourAnalyticsListData = TourAnalyticsListVM['list'];
type MakeTourAnalyticsListPaginationData = TourAnalyticsListVM['listPaginationData'] | undefined;
type MakeTourAnalyticsListFilters = TourAnalyticsList['params'];

const getListData = (state: State) => state.tourAnalytics.list;

export const makeGetTourAnalyticsList = () => createSelector<State, TourAnalyticsList, MakeTourAnalyticsListData>(getListData, ({ data }) => data?.list || []);
export const makeGetTourAnalyticsListStatus = () => createSelector<State, TourAnalyticsList, Status>(getListData, getDataStatus);
export const makeGetTourAnalyticsListPaginationData = () =>
  createSelector<State, TourAnalyticsList, MakeTourAnalyticsListPaginationData>(getListData, ({ data }) => data?.listPaginationData);
export const makeGetTourAnalyticsListFilters = () =>
  createSelector<State, TourAnalyticsList, MakeTourAnalyticsListFilters>(getListData, ({ params }) => params);
