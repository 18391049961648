import { theme, Styles } from '@/styles';

export const toggleStyle = {
  root: {
    width: 48,
    height: 22,
    padding: '6px 8px',
  },

  switchBase: {
    padding: 0,
    margin: '3px 6px',

    '&$checked $thumb': {
      backgroundColor: theme.primary,
    },

    '&$checked + $track': {
      backgroundColor: theme.primary,
    },

    '&$focusVisible': {
      boxShadow: `0px 0px 0px 3px ${theme.primaryFocus}`,
    },
  },

  thumb: {
    width: 16,
    height: 16,
    border: 'none',
    boxShadow: 'none',
    backgroundColor: theme.primaryDisabled,
  },

  track: {
    height: 10,
    margin: 0,
    backgroundColor: theme.primaryDisabled,
  },

  checked: {},
  focusVisible: {},
};

export const styles: Styles = {
  '&.toggle': {
    '&--disabled': {
      cursor: 'not-allowed',
    },
  },
};
