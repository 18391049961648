import React, { FC, HTMLAttributes, ReactNode, useMemo } from 'react';
import { Tab, Tabs as TabWrapper, TabList, TabPanel } from 'react-tabs';
import { motion, AnimatePresence } from 'framer-motion';
import cn from 'classnames';

import { Enum } from '@/common/utils';
import { useStyles } from '@/styles/hooks';
import { tabsStyles } from './styles';

const TabsTheme = Enum('default', 'variant');
type TabsTheme = Enum<typeof TabsTheme>;

const withSubClass = (className: string, theme: TabsTheme, external?: string) => cn([className, `${className}_${theme}`, external]);

export type OnSelectChangeFunc = (index: number, last: number, event: Event) => boolean | void;

export type TabsProps = HTMLAttributes<HTMLElement> & {
  tabs: string[];
  defaultIndex?: number;
  theme?: TabsTheme;
  linkButton?: ReactNode;
  onSelectChange?: OnSelectChangeFunc;
};

export const Tabs: FC<TabsProps> = ({ tabs, defaultIndex, theme = TabsTheme.default, className, linkButton, onSelectChange, children }) => {
  const { styles } = useStyles(tabsStyles);
  const renderChildren = useMemo(
    () =>
      React.Children.map(children, child => {
        return (
          <TabPanel>
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className={withSubClass('tab-content', theme, className)}
              >
                {child}
              </motion.div>
            </AnimatePresence>
          </TabPanel>
        );
      }),

    [children]
  );

  return (
    <TabWrapper css={styles} defaultIndex={defaultIndex} onSelect={onSelectChange}>
      <TabList className={withSubClass('tabs', theme)}>
        {tabs.map(tab => {
          return (
            <Tab className={withSubClass('tab', theme)} key={tab} selectedClassName={cn(['react-tabs__tab--selected', `${theme}--selected`])}>
              {tab}
            </Tab>
          );
        })}
        {linkButton}
      </TabList>
      {children && renderChildren}
    </TabWrapper>
  );
};
