import { SelectOption } from '@/common/components/form-controls-deprecated';

type ReasonsResponse = {
  id: string;
  displayName: string;
  commentMandatory: boolean;
};

export type ReasonsListResponse = ReasonsResponse[];

export interface ReasonsDictionaryElementVM extends SelectOption {}
export class ReasonsDictionaryElementVM {
  constructor({ id, displayName }: ReasonsResponse) {
    this.value = id;
    this.label = displayName;
  }
}

export interface ReasonsDictionaryVM {
  list: ReasonsDictionaryElementVM[];
}

export class ReasonsDictionaryVM {
  constructor(list: ReasonsListResponse) {
    this.list = (list || []).map(element => new ReasonsDictionaryElementVM(element));
  }
}
