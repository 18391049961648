import React, { FC, HTMLAttributes, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { msal } from '@/core/services/authentication/msal';
import { Button } from '@/common/components/button';
import { LoadingSection } from '@/common/components/loading-section';
import { makeGetUserData, makeGetUserStatus } from '@/store/authentication';
import { Avatar } from '@/common/components/avatar';
import { useStyles } from '@/styles/hooks';
import { userInfoStyles } from './styles';

const getUserData = makeGetUserData();
const getUserDataStatus = makeGetUserStatus();

type UserInfoProps = HTMLAttributes<HTMLElement> & {};

export const UserInfo: FC<UserInfoProps> = ({ className, ...props }) => {
  const [t] = useTranslation();
  const userData = useSelector(getUserData);
  const userDataStatus = useSelector(getUserDataStatus);
  const { styles } = useStyles(userInfoStyles);

  const handleLogout = useCallback(() => msal.logout({ reason: 'LOGOUT' }), []);
  const handleOnFocus = useCallback(() => document.getElementById('LOGOUT_BUTTON')?.focus(), []);

  return (
    <div {...props} css={styles}>
      <div className='info-top'>
        {userDataStatus.isDataLoading ? <LoadingSection size='small' /> : <span className='organisation'>{userData?.organisation?.name}</span>}

        <Button onClick={handleLogout} theme='ghost_primary' id='LOGOUT_BUTTON' onMouseDown={handleOnFocus}>
          {t('button.logout')}
        </Button>
      </div>

      <div className='info-bottom'>
        <Avatar firstName={userData?.firstName || ''} lastName={userData?.lastName || ''} size='medium' />

        <div className='user-info'>
          <div className='user-name'>
            {userData?.firstName} {userData?.lastName}
          </div>

          <div>
            {userDataStatus.isDataLoading ? (
              <LoadingSection size='small' className='loader' />
            ) : (
              <span className='organisation'>{userData?.role?.displayName}</span>
            )}
          </div>
          <div className='email'>{userData?.email}</div>
        </div>
      </div>
    </div>
  );
};
