import { Styles, constants } from '@/styles';

export const style: Styles = {
  borderRadius: constants.borderRadius.small,

  '&.stricted': {
    maxWidth: 31,
  },

  '&.limited': {
    maxWidth: '100%',
    maxHeight: '100%',
  },
};
