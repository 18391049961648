import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { stringify } from 'qs';
import omit from 'lodash/omit';

import { httpClient } from '@/core/services/http-client';
import { getEnv, CommonError } from '@/common/utils';
import { MapAttributionListElementVM, MapAttributionListRequest, MapAttributionListResponse, MapAttributionListVM } from '@/models/map-attribution';

const config = getEnv();

const MAP_ATTRIBUTION_LIST_ENDPOINT = `${config.REACT_APP_API_URL}/map-style-api/v1/adminPanel/attributions`;

export const getMapAttributionListData = (params: MapAttributionListRequest) => {
  return httpClient()
    .authorized.post<MapAttributionListResponse>(
      MAP_ATTRIBUTION_LIST_ENDPOINT,
      { searchPhrase: params.searchPhrase || '', mapType: params.mapType },
      {
        params: { ...omit(params, ['searchPhrase', 'shortResponse', 'name', 'mapType']) },
        paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' }),
      }
    )
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new MapAttributionListVM({ data, VM: MapAttributionListElementVM });
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};
