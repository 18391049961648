import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const sliderFilterStyles: StyleFunction = ({ theme }) => ({
  '.checkbox-wrapper': {
    marginTop: constants.offset.small,
    marginBottom: 15,
    '& > span': {
      color: theme.textPrimary,
    },
  },

  '.slider-label-wrapper': {
    display: 'flex',
    justifyContent: 'space-between',
    '& > span': {
      color: theme.textPrimary,
    },
  },
});
