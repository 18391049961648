import { createSelector } from 'reselect';

import { ReviewListSearchByOptionListVM, ReviewListVM } from '@/models/reviews';
import { getDataStatus } from '@/common/utils';
import { reviewsReducer } from './reducers';

const getReviewsList = (state: reviewsReducer) => state.reviews.list;
const getSearchByOptions = (state: reviewsReducer) => state.reviews.searchByOptions;

type ReviewsList = reviewsReducer['reviews']['list'];
type MakeGetReviewsListData = ReviewListVM['list'];
type MakeGetReviewsListPaginationData = ReviewListVM['listPaginationData'] | undefined;
type MakeGetReviewsListFilters = ReviewsList['params'];

export const makeGetReviewsListData = () => createSelector<reviewsReducer, ReviewsList, MakeGetReviewsListData>(getReviewsList, ({ data }) => data?.list || []);

export const makeGetReviewsListStatus = () => createSelector<reviewsReducer, ReviewsList, ReturnType<typeof getDataStatus>>(getReviewsList, getDataStatus);

export const makeGetReviewsListPaginationData = () =>
  createSelector<reviewsReducer, ReviewsList, MakeGetReviewsListPaginationData>(getReviewsList, ({ data }) => data?.listPaginationData);

export const makeGetReviewsListFilters = () => createSelector<reviewsReducer, ReviewsList, MakeGetReviewsListFilters>(getReviewsList, ({ params }) => params);

const getReviewsDetails = (state: reviewsReducer) => state.reviews.details;

type ReviewsDetails = reviewsReducer['reviews']['details'];
type MakeGetReviewsDetailsData = ReviewsDetails['data'];

export const makeGetReviewsDetailsData = () => createSelector<reviewsReducer, ReviewsDetails, MakeGetReviewsDetailsData>(getReviewsDetails, ({ data }) => data);

export const makeGetReviewsDetailsStatus = () =>
  createSelector<reviewsReducer, ReviewsDetails, ReturnType<typeof getDataStatus>>(getReviewsDetails, getDataStatus);

type ReviewStatusDictionary = reviewsReducer['reviews']['status'];
type MakeGetReviewStatusDictionaryData = reviewsReducer['reviews']['status']['data'];

const getReviewStatusDictionary = (state: reviewsReducer) => state.reviews.status;

export const makeGetReviewStatusDictionaryData = () =>
  createSelector<reviewsReducer, ReviewStatusDictionary, MakeGetReviewStatusDictionaryData>(getReviewStatusDictionary, ({ data }) => data);

export const makeGetReviewStatusDictionaryStatus = () =>
  createSelector<reviewsReducer, ReviewStatusDictionary, ReturnType<typeof getDataStatus>>(getReviewStatusDictionary, getDataStatus);

type RatingContentTypeDictionary = reviewsReducer['reviews']['ratingContentType'];
type MakeGetRatingContentTypeDictionaryData = reviewsReducer['reviews']['ratingContentType']['data'];

const getRatingContentTypeDictionary = (state: reviewsReducer) => state.reviews.ratingContentType;

export const makeGetRatingContentTypeDictionaryData = () =>
  createSelector<reviewsReducer, RatingContentTypeDictionary, MakeGetRatingContentTypeDictionaryData>(getRatingContentTypeDictionary, ({ data }) => data);

export const makeGetRatingContentTypeDictionaryStatus = () =>
  createSelector<reviewsReducer, RatingContentTypeDictionary, ReturnType<typeof getDataStatus>>(getRatingContentTypeDictionary, getDataStatus);

type ReviewTargetTypeDictionary = reviewsReducer['reviews']['targetType'];
type MakeGetReviewTargetTypeDictionaryData = reviewsReducer['reviews']['targetType']['data'];

const getReviewTargetTypeDictionary = (state: reviewsReducer) => state.reviews.targetType;

export const makeGetReviewTargetTypeDictionaryData = () =>
  createSelector<reviewsReducer, ReviewTargetTypeDictionary, MakeGetReviewTargetTypeDictionaryData>(getReviewTargetTypeDictionary, ({ data }) => data);

export const makeGetReviewTargetTypeDictionaryStatus = () =>
  createSelector<reviewsReducer, ReviewTargetTypeDictionary, ReturnType<typeof getDataStatus>>(getReviewTargetTypeDictionary, getDataStatus);

type ReviewApplicationDictionary = reviewsReducer['reviews']['application'];
type MakeGetReviewApplicationDictionaryData = reviewsReducer['reviews']['application']['data'];

const getReviewApplicationDictionary = (state: reviewsReducer) => state.reviews.application;

export const makeGetReviewApplicationDictionaryData = () =>
  createSelector<reviewsReducer, ReviewApplicationDictionary, MakeGetReviewApplicationDictionaryData>(getReviewApplicationDictionary, ({ data }) => data);

export const makeGetReviewApplicationDictionaryStatus = () =>
  createSelector<reviewsReducer, ReviewApplicationDictionary, ReturnType<typeof getDataStatus>>(getReviewApplicationDictionary, getDataStatus);

type SearchByOptions = reviewsReducer['reviews']['searchByOptions'];
type MakeGetSearchByOptionsData = ReviewListSearchByOptionListVM['options'];

export const makeGetReviewSearchOptionList = () =>
  createSelector<reviewsReducer, SearchByOptions, MakeGetSearchByOptionsData>(getSearchByOptions, ({ data }) => (data === undefined ? [] : data.options));

export const makeGetReviewSearchOptionStatus = () =>
  createSelector<reviewsReducer, SearchByOptions, ReturnType<typeof getDataStatus>>(getSearchByOptions, getDataStatus);
