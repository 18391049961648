import { createSelector } from 'reselect';

import { SubscriptionListVM, SmartMapDataVM, OneTimeProductDataVM } from '@/models/entitlements';
import { getDataStatus } from '@/common/utils';
import { entitlementsReducer } from './reducers';

type Subscriptions = entitlementsReducer['entitlements']['subscriptions'];
type MakeGetSubscriptionsData = SubscriptionListVM['list'];
type MakeGetSubscriptionsLength = number;
type MakeGetSubscriptionsParams = Subscriptions['params'];

const getSubscriptions = (state: entitlementsReducer) => state.entitlements.subscriptions;

export const makeGetSubscriptionList = () =>
  createSelector<entitlementsReducer, Subscriptions, MakeGetSubscriptionsData>(getSubscriptions, ({ data }) => (data === undefined ? [] : data.list));

export const makeGetSubscriptionListLength = () =>
  createSelector<entitlementsReducer, Subscriptions, MakeGetSubscriptionsLength>(getSubscriptions, ({ data }) => (data?.list || []).length);

export const makeGetSubscriptionListStatus = () =>
  createSelector<entitlementsReducer, Subscriptions, ReturnType<typeof getDataStatus>>(getSubscriptions, getDataStatus);

export const makeGetSubscriptionListPagination = () =>
  createSelector<entitlementsReducer, Subscriptions, MakeGetSubscriptionsParams>(getSubscriptions, ({ params }) => params);

type SmartMaps = entitlementsReducer['entitlements']['smartMaps'];
type MakeGetSmartMapsData = SmartMapDataVM['list'];
type MakeGetSmartMapsListPaginationData = SmartMapDataVM['listPaginationData'] | undefined;
type MakeGetSmartMapsParams = SmartMaps['params'];
type MakeGetSmartMapsLength = number;
type MakeGetSmartTotalCount = number;

const getSmartMaps = (state: entitlementsReducer) => state.entitlements.smartMaps;

export const makeGetSmartMapList = () =>
  createSelector<entitlementsReducer, SmartMaps, MakeGetSmartMapsData>(getSmartMaps, ({ data }) => (data === undefined ? [] : data.list));

export const makeGetSmartMapListPaginationData = () =>
  createSelector<entitlementsReducer, SmartMaps, MakeGetSmartMapsListPaginationData>(getSmartMaps, ({ data }) => data?.listPaginationData);

export const makeGetSmartMapApiPagination = () => createSelector<entitlementsReducer, SmartMaps, MakeGetSmartMapsParams>(getSmartMaps, ({ params }) => params);

export const makeGetSmartMapListLength = () =>
  createSelector<entitlementsReducer, SmartMaps, MakeGetSmartMapsLength>(
    getSmartMaps,
    ({ data }) => data?.listPaginationData.totalElements || (data?.list || []).length
  );

export const makeGetSmartMapTotalCount = () =>
  createSelector<entitlementsReducer, SmartMaps, MakeGetSmartTotalCount>(getSmartMaps, ({ collectionTotalCount }) => collectionTotalCount);

export const makeGetSmartMapListStatus = () => createSelector<entitlementsReducer, SmartMaps, ReturnType<typeof getDataStatus>>(getSmartMaps, getDataStatus);

type OneTimeProducts = entitlementsReducer['entitlements']['oneTimeProducts'];
type MakeGetOneTimeProductsData = OneTimeProductDataVM['list'];
type MakeGetOneTimeProductsListPaginationData = OneTimeProductDataVM['listPaginationData'] | undefined;
type MakeGetOneTimeProductsParams = OneTimeProducts['params'];
type MakeGetOneTimeProductsLength = number;
type MakeGetOneTimeProductsTotalCount = number;

const getOneTimeProducts = (state: entitlementsReducer) => state.entitlements.oneTimeProducts;

export const makeGetOneTimeProductList = () =>
  createSelector<entitlementsReducer, OneTimeProducts, MakeGetOneTimeProductsData>(getOneTimeProducts, ({ data }) => (data === undefined ? [] : data.list));

export const makeGetOneTimeProductListPaginationData = () =>
  createSelector<entitlementsReducer, OneTimeProducts, MakeGetOneTimeProductsListPaginationData>(getOneTimeProducts, ({ data }) => data?.listPaginationData);

export const makeGetOneTimeProductApiPagination = () =>
  createSelector<entitlementsReducer, OneTimeProducts, MakeGetOneTimeProductsParams>(getOneTimeProducts, ({ params }) => params);

export const makeGetOneTimeProductListLength = () =>
  createSelector<entitlementsReducer, OneTimeProducts, MakeGetOneTimeProductsLength>(
    getOneTimeProducts,
    ({ data }) => data?.listPaginationData.totalElements || (data?.list || []).length
  );

export const makeGetOneTimeProductTotalCount = () =>
  createSelector<entitlementsReducer, OneTimeProducts, MakeGetOneTimeProductsTotalCount>(
    getOneTimeProducts,
    ({ collectionTotalCount }) => collectionTotalCount
  );

export const makeGetOneTimeProductListStatus = () =>
  createSelector<entitlementsReducer, OneTimeProducts, ReturnType<typeof getDataStatus>>(getOneTimeProducts, getDataStatus);

type SubscriptionsProductsDictionary = entitlementsReducer['entitlements']['subscriptionsProducts'];
type MakeGetSubscriptionsProductsDictionaryData = entitlementsReducer['entitlements']['subscriptionsProducts']['data'];

const getSubscriptionsProductsDictionary = (state: entitlementsReducer) => state.entitlements.subscriptionsProducts;

export const makeGetSubscriptionsProductsDictionaryData = () =>
  createSelector<entitlementsReducer, SubscriptionsProductsDictionary, MakeGetSubscriptionsProductsDictionaryData>(
    getSubscriptionsProductsDictionary,
    ({ data }) => data
  );

export const makeGetSubscriptionsProductsDictionaryStatus = () =>
  createSelector<entitlementsReducer, SubscriptionsProductsDictionary, ReturnType<typeof getDataStatus>>(getSubscriptionsProductsDictionary, getDataStatus);
