import { SelectOption } from '@/common/components/form-controls-deprecated';

type TipResponse = {
  productId: string;
  productName: string;
  productPrice: number;
  productShortDescription: string;
  recommendedProduct: boolean;
};

export type TipListResponse = TipResponse[];

export interface TipsDictionaryElementVM extends SelectOption {}

export class TipsDictionaryElementVM {
  constructor({ productPrice, productId }: TipResponse) {
    this.value = productId;
    this.label = productPrice.toString();
  }
}

export interface TipsDictionaryVM {
  list: TipsDictionaryElementVM[];
}

export class TipsDictionaryVM {
  constructor(list: TipListResponse) {
    this.list = (list || []).map(element => new TipsDictionaryElementVM(element));
  }
}

type PriceResponse = {
  productId: string;
  productName: string;
  productPrice: number | null;
  productShortDescription: string;
  recommendedProduct: boolean;
  subtype: string;
};

export type PricesListResponse = PriceResponse[];

export interface PricesDictionaryElementVM extends SelectOption {}

export class PricesDictionaryElementVM {
  constructor({ productPrice, productId }: PriceResponse) {
    this.value = productId;
    this.label = productPrice ? `£${(productPrice * 0.01).toString()}` : '0';
  }
}

export interface PricesDictionaryVM {
  list: PricesDictionaryElementVM[];
}

export class PricesDictionaryVM {
  constructor(list: PricesListResponse) {
    this.list = (list || []).filter(element => element.subtype === 'PAID' && element.productPrice).map(element => new PricesDictionaryElementVM(element));
  }
}
