import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const clearableInputStyles: StyleFunction = ({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  '.input': {
    borderRadius: constants.borderRadius.small,
    border: `1px solid ${theme.borderThird}`,
    paddingLeft: constants.offset.medium,
    paddingRight: constants.offset.xlarge,
    textOverflow: 'ellipsis',
    lineHeight: '36px',
    width: '100%',
    backgroundColor: theme.backgroundNinth,
    color: theme.textPrimary,
    transitionProperty: 'border-color, box-shadow',
    transitionDuration: '.2s',

    '&:active:enabled, &:focus:enabled': {
      border: `1px solid ${theme.borderPrimaryActive}`,
      boxShadow: `0 0 1px 1px ${theme.borderPrimaryActive} inset`,
      outline: 'none',
    },

    '&:disabled': {
      backgroundColor: theme.backgroundPrimaryDisabled,
      color: theme.textDisabled,
      cursor: 'not-allowed',
    },

    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },

    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },

    '&[type=number]': {
      MozAppearance: 'textfield',
    },
  },

  '.clear-button': {
    marginLeft: -constants.offset.xlarge,

    i: {
      color: theme.primary,
    },
  },
});
