import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const tabsStyles: StyleFunction = ({ theme }) => ({
  '.tabs': {
    listStyleType: 'none',
    display: 'flex',
    flexDirection: 'row',
    lineHeight: 1.75,
    margin: 0,

    '&_default': {
      backgroundColor: theme.backgroundEight,
      padding: `0 ${constants.offset.xlarge}px`,
    },

    '&_variant': {
      padding: 0,
    },
  },

  '.tab': {
    padding: `${constants.offset.xsmall}px ${constants.offset.large}px`,
    transition: 'background-color .2s',
    backgroundColor: theme.backgroundSecondary,
    outline: 'none',
    cursor: 'pointer',
    color: theme.primary,

    '&_default': {
      backgroundColor: theme.backgroundEight,

      '&:hover': {
        backgroundColor: theme.backgroundSecondaryFocus,
      },

      '&:focus': {
        boxShadow: `0px 0px 0px 3px ${theme.backgroundFourthFocus} inset`,
      },
    },

    '&_variant': {
      backgroundColor: theme.backgroundPrimary,
      color: theme.primary,
      fontWeight: 600,

      '&:hover': {
        backgroundColor: theme.backgroundPrimaryFocus,
      },
    },
  },

  '.default--selected': {
    backgroundColor: theme.backgroundSecondary,
  },

  '.variant--selected': {
    border: `1px solid ${theme.borderThird}`,
    backgroundColor: theme.borderThird,
  },

  '.tab-content': {
    padding: `${constants.offset.large}px ${constants.offset.xlarge}px`,

    '&_default': {
      backgroundColor: theme.backgroundSecondary,
    },

    '&_variant': {
      backgroundColor: theme.backgroundPrimary,
    },
  },
});
