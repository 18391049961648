import { Enum } from '@/common/utils';
import { RoutePaths } from '../../navigation.models';
import { authenticatedRoutePaths } from './authenticated';

export type managementRoutes = Enum<typeof managementRoutes>;
export const managementRoutes = Enum(
  'MODERATION_LIST',
  'MODERATION_DETAILS',
  'VOUCHER_BATCH_LIST',
  'VOUCHER_BATCH_DETAILS',
  'VOUCHER_BATCH_CONFIGURATION',
  'VOUCHER_BATCH_SIZE',
  'ADD_SMARTMAP',
  'SMARTMAPS_LIST',
  'SMARTMAPS_DETAILS',
  'MAP_ATTRIBUTION_LIST',
  'ADD_MAP_ATTRIBUTION',
  'MAP_ATTRIBUTION_DETAILS'
);

export const managementRoutePaths: RoutePaths<managementRoutes> = {
  [managementRoutes.MODERATION_LIST]: `${authenticatedRoutePaths.MANAGEMENT}/moderation`,
  [managementRoutes.MODERATION_DETAILS]: `${authenticatedRoutePaths.MANAGEMENT}/moderation/:id`,
  [managementRoutes.VOUCHER_BATCH_LIST]: `${authenticatedRoutePaths.MANAGEMENT}/vouchers`,
  [managementRoutes.VOUCHER_BATCH_DETAILS]: `${authenticatedRoutePaths.MANAGEMENT}/vouchers/:id`,
  [managementRoutes.VOUCHER_BATCH_CONFIGURATION]: `${authenticatedRoutePaths.MANAGEMENT}/vouchers/configuration`,
  [managementRoutes.VOUCHER_BATCH_SIZE]: `${authenticatedRoutePaths.MANAGEMENT}/vouchers/set_size`,
  [managementRoutes.ADD_SMARTMAP]: `${authenticatedRoutePaths.MANAGEMENT}/smartmaps/add`,
  [managementRoutes.SMARTMAPS_LIST]: `${authenticatedRoutePaths.MANAGEMENT}/smartmaps`,
  [managementRoutes.SMARTMAPS_DETAILS]: `${authenticatedRoutePaths.MANAGEMENT}/smartmaps/:id`,
  [managementRoutes.MAP_ATTRIBUTION_LIST]: `${authenticatedRoutePaths.MANAGEMENT}/map-attribution`,
  [managementRoutes.ADD_MAP_ATTRIBUTION]: `${authenticatedRoutePaths.MANAGEMENT}/map-attribution/add`,
  [managementRoutes.MAP_ATTRIBUTION_DETAILS]: `${authenticatedRoutePaths.MANAGEMENT}/map-attribution/:id`,
};

export type managementRouteParams = {
  [managementRoutes.MODERATION_DETAILS]: { id: string };
  [managementRoutes.VOUCHER_BATCH_DETAILS]: { id: string };
  [managementRoutes.SMARTMAPS_DETAILS]: { id: string };
  [managementRoutes.MAP_ATTRIBUTION_DETAILS]: { id: string };
};
