import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { object } from 'yup';
import accept from 'attr-accept';

import { ContentFragmentType } from '@/models/tours';
import { COMMON_STRING_LENGTH_NOT_REQUIRED, STRING_REQUIRED, CommonError } from '@/common/utils';
import { useStyles } from '@/styles/hooks';
import { uploadImageFile } from '../../..';
import { EditorCard, EditorCardProps, EditorValue } from '../../../models';
import { AttributionContentComponent, CommonMediaCardPayload } from '../components/attribution-content-component';
import { CommonEditorCardStatus } from '../components/common-editor-card-status-element';
import { CommonEditorFileInput } from '../components/common-editor-file-input';
import { CommonEditorCard, CommonEditorCardInnerComponent } from '../components/common-editor-card';
import { headerStyle } from './styles';

export type ImageCardPayload = CommonMediaCardPayload;

export type ImageCardValue = EditorValue<typeof ContentFragmentType['image'], ImageCardPayload>;

const MAX_IMAGE_FILE_SIZE = 2097152;
const ALLOWED_IMAGE_FORMAT = '.jpg, .jpeg, .png';

export const IMAGE_CARD_VALIDATOR = object().shape({
  id: STRING_REQUIRED,
  attribution: COMMON_STRING_LENGTH_NOT_REQUIRED,
});

const VALIDATION_SCHEMA = IMAGE_CARD_VALIDATOR;

export const handleValidation = () => (value: ImageCardPayload) => {
  return VALIDATION_SCHEMA.validate(value, { abortEarly: false })
    .then(() => undefined)
    .catch(err => {
      return err.inner;
    });
};

const ImageCardHeaderComponent: CommonEditorCardInnerComponent<ImageCardPayload> = ({ isEditable, formField, setStatus }) => {
  const [field, meta, helpers] = formField;

  const setValueRef = useRef<typeof helpers.setValue>(helpers.setValue);

  useEffect(() => {
    setValueRef.current = helpers.setValue;
  }, [helpers]);

  const [t] = useTranslation();
  const { styles } = useStyles(headerStyle);

  const onInputChange = useCallback(
    (file: File[]) => {
      const isFormatAccepted: boolean = accept(
        {
          name: file[0].name,
          type: file[0].type,
        },
        ALLOWED_IMAGE_FORMAT
      );

      const isSizeAccepted = file[0].size < MAX_IMAGE_FILE_SIZE;

      if (!isFormatAccepted) {
        setValueRef.current({
          ...field.value,
          id: '',
        });
        setStatus({
          type: CommonEditorCardStatus.ERROR,
          text: t('storyContentEditor.card.validationMessages.error'),
        });
        return;
      }

      if (!isSizeAccepted) {
        setValueRef.current({
          ...field.value,
          id: '',
        });
        setStatus({
          type: CommonEditorCardStatus.ERROR,
          text: t('storyContentEditor.card.validationMessages.fileTooBig'),
        });
        return;
      }

      setStatus({
        type: CommonEditorCardStatus.LOADING,
        text: t('storyContentEditor.card.validationMessages.loading'),
      });

      uploadImageFile(file[0]).subscribe(response => {
        if (response instanceof CommonError) {
          setStatus({
            type: CommonEditorCardStatus.ERROR,
            text: t('storyContentEditor.card.validationMessages.error'),
          });
        } else {
          setStatus({
            type: CommonEditorCardStatus.SUCCESS,
            text: t('storyContentEditor.card.validationMessages.success'),
          });

          setValueRef.current({
            ...field.value,
            id: response,
          });
        }
      });
    },
    [field.value]
  );

  return (
    <div css={styles}>
      {isEditable ? (
        <CommonEditorFileInput onChange={onInputChange} />
      ) : meta.value?.id && meta.value?.id !== '' ? (
        <div className='value'>{t('storyContentEditor.card.image.fileUploaded')}</div>
      ) : (
        <div className='placeholder'>{t('storyContentEditor.card.image.placeholder')}</div>
      )}
    </div>
  );
};

type Props = EditorCardProps;

const ImageCardComponent: FC<Props> = props => {
  return (
    <CommonEditorCard<ImageCardPayload>
      {...props}
      headerComponent={ImageCardHeaderComponent}
      validate={handleValidation}
      contentComponent={AttributionContentComponent}
      errorType='VALIDATION_ERROR'
      errorPath='id'
    />
  );
};

export const ImageCard: EditorCard = {
  type: 'image',
  title: 'storyContentEditor.card.image.title',
  iconName: 'mdi-image',
  content: ImageCardComponent,
};
