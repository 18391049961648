import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const navigationGroupStyles: StyleFunction = ({ theme }) => ({
  backgroundColor: theme.backgroundAccent,
  color: theme.textWhite,
  textDecoration: 'none',
  padding: `0 ${constants.offset.xlarge}px`,
  transitionProperty: 'background-color, box-shadow',
  transitionTimingFunction: 'ease-in',
  transitionDuration: '.2s',
  cursor: 'pointer',
  outline: 0,
  height: 60,
  display: 'flex',
  alignItems: 'center',

  '&:hover': {
    backgroundColor: theme.backgroundAccentHover,
  },

  '&:active, &.selected ': {
    backgroundColor: theme.backgroundAccentActive,
    color: theme.primary,
    fontWeight: 600,
  },

  '&:focus': {
    boxShadow: `0px 0px 0px 3px ${theme.primaryFocus} inset`,
  },
});
