import { switchMap, filter, map } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { getMapAttributionListData } from '@/features/management/services/map-attribution/map-attribution-list';
import { untilLocationChange, CommonError } from '@/common/utils';
import { getMapAttributionDetailsData } from '@/features/management/services/map-attribution/map-attribution-details';
import { mapAttributionReducer } from './reducers';
import { mapAttributionActions } from './actions';

const getMapAttributionList: Epic<mapAttributionActions, mapAttributionActions, mapAttributionReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(mapAttributionActions.list.request)),
    switchMap(() =>
      getMapAttributionListData(state$.value.mapAttribution.list.params).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return mapAttributionActions.list.failure(response);
          }

          return mapAttributionActions.list.success(response);
        })
      )
    )
  );

const getMapAttributionDetails: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(mapAttributionActions.details.request)),
    switchMap(action =>
      getMapAttributionDetailsData(action.payload.params)
        .pipe(untilLocationChange(action$))
        .pipe(
          map(response => {
            if (response instanceof CommonError) {
              return mapAttributionActions.details.failure(response);
            }

            return mapAttributionActions.details.success(response);
          })
        )
    )
  );

export const mapAttributionEpics = [getMapAttributionList, getMapAttributionDetails];
