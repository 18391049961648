import { createReducer } from 'typesafe-actions';

import { LoggedUserDataVM, TokenStatus } from '@/core/models/authentication';
import { RegionElementVM } from '@/core/models/data-center-regions';
import { FeatureStateType } from '../helpers';
import { authenticationActions } from './actions';

type authenticationState = {
  isLoading: boolean;
  tokenStatus: TokenStatus;
  userData: LoggedUserDataVM | undefined;
  defaultRegion: RegionElementVM | undefined;
  currentRegion: RegionElementVM | undefined;
};

const INITIAL_STATE: authenticationState = {
  isLoading: false,
  tokenStatus: TokenStatus.EMPTY,
  userData: undefined,
  defaultRegion: undefined,
  currentRegion: undefined,
};

const reducer = createReducer<authenticationState, authenticationActions>(INITIAL_STATE)
  .handleAction(authenticationActions.getUserData, state => ({ ...state, isLoading: true }))
  .handleAction(authenticationActions.setUserData, (state, action) => ({ ...state, userData: action.payload, isLoading: false }))
  .handleAction(authenticationActions.getUserRole, state => ({
    ...state,
    isLoading: true,
  }))
  .handleAction(authenticationActions.setUserRole, (state, action) => ({
    ...state,
    userData: state.userData === undefined ? undefined : { ...state.userData, role: action.payload },
    isLoading: false,
  }))
  .handleAction(authenticationActions.getUserOrganisationName, state => ({
    ...state,
    isLoading: true,
  }))
  .handleAction(authenticationActions.setUserOrganisationName, (state, action) => ({
    ...state,
    userData: state.userData === undefined ? undefined : { ...state.userData, organisation: { ...state.userData.organisation, name: action.payload } },
    isLoading: false,
  }))
  .handleAction(authenticationActions.setUserConsents, (state, action) => ({
    ...state,
    userData: state.userData === undefined ? undefined : { ...state.userData, consents: action.payload?.list || [] },
    isLoading: false,
  }))
  .handleAction(authenticationActions.clearUserData, () => ({ ...INITIAL_STATE }))
  .handleAction(authenticationActions.setTokenStatus, (state, action) => ({ ...state, tokenStatus: action.payload }))
  .handleAction(authenticationActions.setUserCurrentRegion, (state, { payload }) => ({ ...state, currentRegion: payload }))
  .handleAction(authenticationActions.setUserDefaultRegion, (state, { payload }) => ({
    ...state,
    defaultRegion: payload.regionData,
    currentRegion: payload.canUserChangeRegion ? state.currentRegion ?? payload.regionData : payload.regionData,
  }))
  .handleAction(authenticationActions.restoreDefaultRegion, state => ({ ...state, currentRegion: state.defaultRegion }));

export const authenticationReducer = { authentication: reducer };
export type authenticationReducer = FeatureStateType<typeof authenticationReducer>;
