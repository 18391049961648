import { Styles, theme, constants } from '@/styles';
import { css } from '@emotion/core';

export const toastStyles: Styles = {
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',

  '.toast-icon': {
    color: theme.textWhite,
    fontSize: constants.fontSize.icon,
    marginRight: 10,
  },

  '.close-button': {
    backgroundColor: 'transparent',
    cursor: 'pointer',
    padding: `0 ${constants.offset.xsmall}px`,
  },

  '.button-icon': {
    color: theme.textWhite,
    fontSize: constants.fontSize.icon,
  },

  '.text': {
    color: theme.textWhite,
    fontSize: constants.fontSize.h4,
    padding: '2px 0',
    width: '100%',
  },
};

export const containerStyles: Styles = css({
  '.toast-container': {
    width: 450,
    padding: 0,
    bottom: constants.offset.xlarge,
    right: constants.offset.xlarge,
  },

  '.toast-wrapper': {
    borderRadius: constants.borderRadius.small,
    backgroundColor: theme.backgroundPrimary,
    cursor: 'default',
    minHeight: 50,
    padding: 12,
    margin: 0,

    '&:not(:last-child)': {
      marginBottom: constants.offset.medium,
    },

    '&--success': {
      backgroundColor: theme.success,
    },
    '&--error': {
      backgroundColor: theme.error,
    },
    '&--warning': {
      backgroundColor: theme.warning,
    },
    '&--info': {
      backgroundColor: theme.info,
    },
  },
});
