import { switchMap, filter, map } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { getUserRoutesData } from '@/features/content/services/routes';
import { getUserCommunityStories } from '@/features/content/services/community-stories/community-stories-list';
import { CommonError } from '@/common/utils';
import { contentProvidedByUserReducer } from './reducers';
import { contentProvidedByUserActions } from './actions';

const getRoutes: Epic<contentProvidedByUserActions, contentProvidedByUserActions, contentProvidedByUserReducer> = action$ =>
  action$.pipe(
    filter(isActionOf(contentProvidedByUserActions.routes.request)),
    switchMap(action => {
      return getUserRoutesData(action.payload.params).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return contentProvidedByUserActions.routes.failure(response);
          }
          return contentProvidedByUserActions.routes.success(response);
        })
      );
    })
  );

const getCommunityStories: Epic<contentProvidedByUserActions, contentProvidedByUserActions, contentProvidedByUserReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(contentProvidedByUserActions.communityStories.request)),
    switchMap(() =>
      getUserCommunityStories(state$.value.contentProvidedByUser.communityStories.params).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return contentProvidedByUserActions.communityStories.failure(response);
          }
          return contentProvidedByUserActions.communityStories.success(response);
        })
      )
    )
  );

export const contentProvidedByUserEpics = [getRoutes, getCommunityStories];
