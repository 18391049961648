import React, { HTMLAttributes, FC, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { checkUserPermissions, getAppVersion } from '@/common/utils';
import { authenticationActions, makeGetUserData, makeGetUserStatus } from '@/store/authentication';
import { NavigationGroupsBar } from './components/navigation-groups-bar';
import { NavigationElementsBar } from './components/navigation-elements-bar';
import { ProfileDropdown } from './components/navigation-dropdowns';
import { ThemeToggle } from './components/theme-toggle';
import { links } from './constants';
import { topBarStyles } from './styles';

const getUserStatus = makeGetUserStatus();
const getUserData = makeGetUserData();

export const Topbar: FC<HTMLAttributes<HTMLElement>> = () => {
  const { isLogged, roles, consents } = useSelector(getUserStatus);
  const [t] = useTranslation();
  const appVersion = getAppVersion();
  const userData = useSelector(getUserData);
  const dispatch = useDispatch();

  const hasUserAcceptedTerms = useMemo(() => consents && consents.length === 0, [consents]);

  useEffect(() => {
    if (userData) {
      dispatch(authenticationActions.getUserOrganisationName(userData.organisation.id));
      dispatch(authenticationActions.getUserRole(userData.userId));
    }
  }, [!!userData]);

  const availableLinks = useMemo(() => {
    const availableGroups: typeof links = [];
    for (const group of links) {
      const availableElements: typeof group.elements = [];
      for (const element of group.elements) {
        if (element.permissions !== undefined && element.permissions.length > 0) {
          for (const permission of element.permissions) {
            if (checkUserPermissions(permission, roles)) {
              availableElements.push(element);
              break;
            }
          }
        } else {
          availableElements.push(element);
        }
      }

      if (availableElements.length > 0) {
        availableGroups.push({
          ...group,
          elements: availableElements,
        });
      }
    }

    return availableGroups;
  }, [roles]);

  return (
    <nav css={topBarStyles}>
      <div className='topbar'>
        <div className='column__left'>
          <div className='app-logo'>
            <img className='app-logo__image' src='/images/os-logo-white.svg' />
          </div>
          <div className='app-name-wrapper'>
            <div className='app-name'>{t('topBar.appName')}</div>
            {appVersion && (
              <div className='app-version'>
                {t('topBar.version')} {appVersion}
              </div>
            )}
          </div>
        </div>

        <div className='column__right'>
          <div className='row'>{hasUserAcceptedTerms && isLogged ? <NavigationGroupsBar elements={availableLinks} /> : null}</div>

          <div className='row'>
            <ThemeToggle />
            {isLogged && <ProfileDropdown />}
          </div>
        </div>
      </div>

      {hasUserAcceptedTerms && isLogged && <NavigationElementsBar elements={availableLinks} />}
    </nav>
  );
};
