import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import VideoSnapshot from 'video-snapshot';
import { object } from 'yup';
import accept from 'attr-accept';

import { ContentFragmentType } from '@/models/tours';
import { COMMON_STRING_LENGTH_NOT_REQUIRED, STRING_REQUIRED } from '@/common/utils';
import { useStyles } from '@/styles/hooks';
import { CommonEditorCard, CommonEditorCardInnerComponent } from '../components/common-editor-card';
import { AttributionContentComponent, CommonMediaCardPayload } from '../components/attribution-content-component';
import { CommonEditorCardStatus } from '../components/common-editor-card-status-element';
import { CommonEditorFileInput } from '../components/common-editor-file-input';
import { EditorCard, EditorCardProps, EditorValue } from '../../../models';
import { addVideo } from '../../../services';
import { videoStyle } from './styles';

type VideoCardPayload = CommonMediaCardPayload;

export type VideoCardValue = EditorValue<typeof ContentFragmentType['video'], VideoCardPayload>;

const MAX_VIDEO_FILE_SIZE = 524288000;
const ALLOWED_VIDEO_FORMAT = '.mp4';
const VIDEO_TIME_TO_CAPTURE = 2;

export const VIDEO_CARD_VALIDATOR = object().shape({
  id: STRING_REQUIRED,
  attribution: COMMON_STRING_LENGTH_NOT_REQUIRED,
});

const VALIDATION_SCHEMA = VIDEO_CARD_VALIDATOR;

export const handleValidation = () => (value: VideoCardPayload) => {
  return VALIDATION_SCHEMA.validate(value, { abortEarly: false })
    .then(() => undefined)
    .catch(err => {
      return err.inner;
    });
};

const VideoCardHeaderComponent: CommonEditorCardInnerComponent<VideoCardPayload> = ({ isEditable, formField, setStatus }) => {
  const [field, meta, helpers] = formField;

  const setValueRef = useRef<typeof helpers.setValue>(helpers.setValue);

  useEffect(() => {
    setValueRef.current = helpers.setValue;
  }, [helpers]);

  const [t] = useTranslation();
  const { styles } = useStyles(videoStyle);

  const onInputChange = useCallback(
    async (files: File[]) => {
      const isFormatAccepted: boolean = accept(
        {
          name: files[0].name,
          type: files[0].type,
        },
        ALLOWED_VIDEO_FORMAT
      );

      const isSizeAccepted = files[0].size < MAX_VIDEO_FILE_SIZE;

      if (!isFormatAccepted) {
        setValueRef.current({
          ...field.value,
          id: '',
          cover: '',
        });

        setStatus({
          type: CommonEditorCardStatus.ERROR,
          text: t('storyContentEditor.card.validationMessages.wrongFormat'),
        });
        return;
      }

      if (!isSizeAccepted) {
        setValueRef.current({
          ...field.value,
          id: '',
          cover: '',
        });

        setStatus({
          type: CommonEditorCardStatus.ERROR,
          text: t('storyContentEditor.card.validationMessages.fileTooBig'),
        });
        return;
      }

      const snapshoter = new VideoSnapshot(files[0]);
      const previewSrc = await snapshoter.takeSnapshot(VIDEO_TIME_TO_CAPTURE);
      const img = document.createElement('img');
      img.style.display = 'none';
      img.src = previewSrc;
      document.body.appendChild(img);

      setStatus({
        type: CommonEditorCardStatus.LOADING,
        text: t('storyContentEditor.card.validationMessages.loading'),
      });

      const createFile = async (previewSrc: string) => {
        const res = await fetch(previewSrc);
        const blob = await res.blob();
        const file = new File([blob], 'Preview', { type: 'image/png' });
        return file;
      };

      addVideo({ coverFile: await createFile(previewSrc), videoFile: files }).subscribe(response => {
        const { type, videoCoverId, videoId } = response;
        switch (type) {
          case 'uploadCoverError':
            return setStatus({
              type: CommonEditorCardStatus.ERROR,
              text: t('storyContentEditor.card.validationMessages.error'),
            });

          case 'uploadVideoError':
            return setStatus({
              type: CommonEditorCardStatus.ERROR,
              text: t('storyContentEditor.card.validationMessages.error'),
            });

          default:
            setStatus({
              type: CommonEditorCardStatus.SUCCESS,
              text: t('storyContentEditor.card.validationMessages.success'),
            });
            videoId &&
              videoCoverId &&
              setValueRef.current({
                ...field.value,
                id: videoId,
                cover: videoCoverId,
              });
        }
      });
    },
    [field.value]
  );

  return (
    <div css={styles}>
      {isEditable ? (
        <CommonEditorFileInput onChange={onInputChange} />
      ) : meta.value?.id !== undefined && meta.value.id !== '' ? (
        <div className='value'>{t('storyContentEditor.card.video.fileUploaded')}</div>
      ) : (
        <div className='placeholder'>{t('storyContentEditor.card.video.placeholder')}</div>
      )}
    </div>
  );
};

type Props = EditorCardProps;

const VideoCardComponent: FC<Props> = props => {
  return (
    <CommonEditorCard<VideoCardPayload>
      {...props}
      headerComponent={VideoCardHeaderComponent}
      validate={handleValidation}
      contentComponent={AttributionContentComponent}
      errorType='VALIDATION_ERROR'
      errorPath='id'
    />
  );
};

export const VideoCard: EditorCard = {
  type: 'video',
  title: 'storyContentEditor.card.video.title',
  iconName: 'mdi-videocam',
  content: VideoCardComponent,
};
