import { defer, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  CreateTourParams,
  UploadTourFileResponse,
  TourDetailsResponse,
  TourDetailsVM,
  UpdateTourParams,
  paymentConfigType,
  TourThumbnailResponse,
  TourType,
} from '@/features/content/models/tours';
import { httpClient } from '@/core/services/http-client';
import { getEnv, CommonError, Enum } from '@/common/utils';
import { FileInputValue, FileInputValueArray, UnitInputValue } from '@/common/components/form-controls-deprecated';
import { cloneRoute, TourFileName, TourFileResponse, uploadGPX, uploadTourCover } from './files-upload';
import { calculateDistance, calculateTime } from './functions';

const config = getEnv();

const TOUR_DETAILS_ENDPOINT = `${config.REACT_APP_API_URL}/story-api/v1/admin/tours`;
const CREATE_TOUR_ENDPOINT = `${config.REACT_APP_API_URL}/story-api/v1/admin/tours`;
const UPDATE_TOUR_ENDPOINT = `${config.REACT_APP_API_URL}/story-api/v1/admin/tours`;
const PUBLISH_TOUR_ENDPOINT = `${config.REACT_APP_API_URL}/story-api/v1/admin/tours`;
const TOUR_THUMBNAIL_ENDPOINT = `${config.REACT_APP_API_URL}/thumbnail-api/v2/tours`;
const DOWNLOAD_GPX_ENDPOINT = `${config.REACT_APP_API_URL}/story-api/v1/admin/tours`;

const SendTourResponseType = Enum('successful', 'uploadFileError', 'uploadDetailsError');
export type SendTourResponseType = Enum<typeof SendTourResponseType>;

export type SendTourResponse = { type: SendTourResponseType; id?: string };

type TourEntityData = {
  ageRecommendation?: number;
  name: string;
  locality: string;
  descriptionShort: string;
  descriptionLong: string;
  paymentConfig: paymentConfigType;
  categoryIds: string[];
  distance: UnitInputValue;
  durationMin: UnitInputValue;
  durationMax: UnitInputValue;
  tagIds: string[];
  type?: TourType;
};

export type CreateTourEntityData = TourEntityData;

export type UpdateTourEntityData = TourEntityData & {
  storyIds: string[];
};

type CreateTourEntityParams = {
  data: CreateTourEntityData;
  tourCover: File[];
  gpx: File | string;
};

type UpdateTourEntityParams = {
  tourId: string;
  data: UpdateTourEntityData;
  tourCover: FileInputValueArray;
  gpx: FileInputValue | string;
};

type UploadedFilesIds = {
  coverImageId: UploadTourFileId;
  routeFileId: UploadTourFileId;
};

type UploadTourFileId = Pick<UploadTourFileResponse, 'id'>;
type CreatedTourId = { id: string };

export const getToursDetailsData = (id: string) =>
  httpClient()
    .authorized.get<TourDetailsResponse>(`${TOUR_DETAILS_ENDPOINT}/${id}`)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new TourDetailsVM(data);
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: e.code, message: e.errorMessage })))
    );

export const createTourEntity = ({ data, tourCover, gpx }: CreateTourEntityParams): Observable<SendTourResponse> =>
  defer(() => {
    const asyncRequestList: TourFileResponse[] = [];
    const tourCoverFile = tourCover[0];
    if (gpx) asyncRequestList.push(gpx instanceof File ? uploadGPX({ gpxFile: gpx }) : cloneRoute(gpx));
    if (tourCoverFile) asyncRequestList.push(uploadTourCover({ tourCoverFile }));

    if (asyncRequestList.length > 0) {
      return forkJoin(asyncRequestList);
    }

    return of([]);
  }).pipe(
    switchMap(responseList => {
      let isFileUploadFailed: boolean = false;
      const fileListData: UploadedFilesIds = {
        coverImageId: {
          id: '',
        },
        routeFileId: {
          id: '',
        },
      };
      for (const response of responseList) {
        if (response.data instanceof CommonError) {
          isFileUploadFailed = true;
        } else {
          fileListData[response.name] = response.data;
        }
      }

      if (isFileUploadFailed) {
        return of({ type: SendTourResponseType.uploadFileError });
      }

      const calculatedDistance: number = calculateDistance(data.distance.inputValue, data.distance.selectValue);
      const calculatedMinDuration: number = calculateTime(data.durationMin.inputValue, data.durationMin.selectValue);
      const calculatedMaxDuration: number = calculateTime(data.durationMax.inputValue, data.durationMax.selectValue);

      return createTourDetails(
        { ...data, distance: calculatedDistance, durationMin: calculatedMinDuration, durationMax: calculatedMaxDuration },
        fileListData.routeFileId.id,
        fileListData.coverImageId.id
      ).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return { type: SendTourResponseType.uploadDetailsError };
          }

          return { type: SendTourResponseType.successful, id: response.id };
        })
      );
    })
  );

export const updateTourEntity = ({ data, tourCover, gpx, tourId }: UpdateTourEntityParams): Observable<SendTourResponse> =>
  defer(() => {
    const asyncRequestList: TourFileResponse[] = [];
    const tourCoverFile = tourCover[0];

    if (gpx instanceof File) {
      asyncRequestList.push(uploadGPX({ gpxFile: gpx }));
    } else if (typeof gpx === 'string') {
      asyncRequestList.push(cloneRoute(gpx));
    } else {
      asyncRequestList.push(of({ name: TourFileName.routeFileId, data: gpx }));
    }

    if (tourCoverFile instanceof File) {
      asyncRequestList.push(uploadTourCover({ tourCoverFile }));
    } else {
      asyncRequestList.push(of({ name: TourFileName.coverImageId, data: tourCoverFile }));
    }

    if (asyncRequestList.length > 0) {
      return forkJoin(asyncRequestList);
    }

    return of([]);
  }).pipe(
    switchMap(responseList => {
      let isFileUploadFailed: boolean = false;
      const fileListData: UploadedFilesIds = {
        coverImageId: {
          id: '',
        },
        routeFileId: {
          id: '',
        },
      };
      for (const response of responseList) {
        if (response.data instanceof CommonError) {
          isFileUploadFailed = true;
        } else {
          fileListData[response.name] = response.data;
        }
      }

      if (isFileUploadFailed) {
        return of({ type: SendTourResponseType.uploadFileError });
      }

      const calculatedDistance: number = calculateDistance(data.distance.inputValue, data.distance.selectValue);
      const calculatedMinDuration: number = calculateTime(data.durationMin.inputValue, data.durationMin.selectValue);
      const calculatedMaxDuration: number = calculateTime(data.durationMax.inputValue, data.durationMax.selectValue);

      return updateTourDetails(
        { ...data, distance: calculatedDistance, durationMin: calculatedMinDuration, durationMax: calculatedMaxDuration },
        tourId,
        fileListData.routeFileId?.id,
        fileListData.coverImageId?.id
      ).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return { type: SendTourResponseType.uploadDetailsError };
          }

          return { type: SendTourResponseType.successful };
        })
      );
    })
  );

const createTourDetails = (data: CreateTourParams, routeFileId: string, coverImageId: string) => {
  return httpClient()
    .authorized.post<CreatedTourId>(`${CREATE_TOUR_ENDPOINT}`, { ...data, routeFileId, coverImageId })
    .pipe(
      map(response => {
        if (response.status === 200 || response.status === 201 || response.status === 202) {
          return response.data;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

const updateTourDetails = (data: UpdateTourParams, tourId: string, routeFileId?: string, coverImageId?: string) => {
  return httpClient()
    .authorized.put<undefined>(`${UPDATE_TOUR_ENDPOINT}/${tourId}`, { ...data, routeFileId, coverImageId })
    .pipe(
      map(response => {
        if (response.status === 200 || response.status === 201 || response.status === 202) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

export const publishTour = (id: string) =>
  httpClient()
    .authorized.put<TourDetailsResponse>(`${PUBLISH_TOUR_ENDPOINT}/${id}/publish`)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const publishTourNotification = (id: string) =>
  httpClient()
    .authorized.post<undefined>(`${PUBLISH_TOUR_ENDPOINT}/${id}/ready-to-publish-notifications`)
    .pipe(
      map(({ status }) => {
        if (status === 200) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const unpublishTour = (id: string) =>
  httpClient()
    .authorized.put<TourDetailsResponse>(`${PUBLISH_TOUR_ENDPOINT}/${id}/unpublish`)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getTourThumbnailData = (id: string) =>
  httpClient()
    .authorized.get<TourThumbnailResponse>(`${TOUR_THUMBNAIL_ENDPOINT}/${id}`)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return data;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getTourRouteGPXFileLink = (tourId: string, fileId: string) =>
  httpClient()
    .authorized.get<string>(`${DOWNLOAD_GPX_ENDPOINT}/${tourId}/route-files/${fileId}`)
    .pipe(
      map(({ status, data }) => {
        if (status === 200 && data !== undefined) {
          return data;
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
