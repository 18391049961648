import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { stringify } from 'qs';
import { omit } from 'lodash';

import { getEnv, CommonError, ApiPagination, formatDateToISOString, DateType } from '@/common/utils';
import { httpClient } from '@/core/services/http-client';
import {
  InvitationListResponse,
  InvitationListRequest,
  InvitationListVM,
  InvitationListElementVM,
  CreateInvitationParams,
  CreateInvitationResponse,
  InvitationTypeListResponse,
  InvitationTypeDictionaryVM,
  InvitationStatusListResponse,
  InvitationStatusDictionaryVM,
} from '@/models/invitations';

const config = getEnv();

const INVITATION_LIST_ENDPOINT = `${config.REACT_APP_API_URL}/user-account-api/v1/admin/invitations`;
const CANCEL_INVITATION_ENDPOINT = `${config.REACT_APP_API_URL}/user-account-api/v1/admin/invitations`;
const RESEND_INVITATION_ENDPOINT = `${config.REACT_APP_API_URL}/user-account-api/v1/admin/invitations`;
const INVITATION_TYPES_ENDPOINT = `${config.REACT_APP_API_URL}/user-account-api/v1/admin/invitations/dictionaries/types`;
const INVITATION_STATUSES_ENDPOINT = `${config.REACT_APP_API_URL}/user-account-api/v1/admin/invitations/dictionaries/statuses`;
const CREATE_INVITATION_ENDPOINT = `${config.REACT_APP_API_URL}/user-account-api/v1/admin/invitations`;

const omitRedundantParams = <P extends ApiPagination>(params: P) => omit(params, 'name', 'shortResponse');

export const getInvitationListData = (data: InvitationListRequest) =>
  httpClient()
    .authorized.get<InvitationListResponse>(INVITATION_LIST_ENDPOINT, {
      params: {
        ...omitRedundantParams(data),
        organisationId: data.organisationId?.value,
        invitationDateFrom: data.invitationDateFrom ? formatDateToISOString(data.invitationDateFrom, DateType.FROM) : undefined,
        invitationDateTo: data.invitationDateTo ? formatDateToISOString(data.invitationDateTo, DateType.TO) : undefined,
      },
      paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' }),
    })
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new InvitationListVM({ data, VM: InvitationListElementVM });
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getInvitationTypesData = () =>
  httpClient()
    .authorized.get<InvitationTypeListResponse>(INVITATION_TYPES_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new InvitationTypeDictionaryVM(data);
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const cancelInvitation = (email: string) =>
  httpClient()
    .authorized.delete(CANCEL_INVITATION_ENDPOINT, {
      params: {
        email,
      },
    })
    .pipe(
      map(({ status }) => {
        if (status === 200 || status === 204) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => {
        return of(new CommonError({ code: '500', message: e }));
      })
    );

export const resendInvitation = (email: string) =>
  httpClient()
    .authorized.put(RESEND_INVITATION_ENDPOINT, {
      email,
    })
    .pipe(
      map(({ status }) => {
        if (status === 200 || status === 201) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => {
        return of(new CommonError({ code: '500', message: e }));
      })
    );

export const getInvitationStatusesData = () =>
  httpClient()
    .authorized.get<InvitationStatusListResponse>(INVITATION_STATUSES_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) return new InvitationStatusDictionaryVM(data);
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const createInvitation = (data: CreateInvitationParams) => {
  return httpClient()
    .authorized.post<CreateInvitationResponse>(CREATE_INVITATION_ENDPOINT, data)
    .pipe(
      map(response => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: e.code, message: e.data })))
    );
};
