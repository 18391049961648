import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { makeGetNumberOfActiveActions } from '@/store/interface';
import { styles } from './styles';

const DEBOUNCE_TIME = 250;

const getNumberOfActiveActions = makeGetNumberOfActiveActions();

const useToggleBar = () => {
  const numberOfActiveActions = useSelector(getNumberOfActiveActions);
  const [status, setStatus] = useState(false);
  const barActive$ = useRef(new Subject<boolean>());
  useEffect(() => {
    const subscription = barActive$.current.pipe(debounceTime(DEBOUNCE_TIME)).subscribe(isActive => {
      setStatus(isActive);
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    barActive$.current.next(numberOfActiveActions > 0);
  }, [numberOfActiveActions]);

  return status;
};

export const LoadingIndicatorBar: FC = () => {
  const isActive = useToggleBar();
  if (!isActive) {
    return null;
  }

  return <div css={styles}></div>;
};
