import { createSelector } from 'reselect';

import { SmartMapsListVM } from '@/models/smartmaps';
import { getDataStatus } from '@/common/utils';
import { smartMapsReducer } from './reducers';

const getSmartMapsList = (state: smartMapsReducer) => state.smartMaps.list;

type SmartMapsList = smartMapsReducer['smartMaps']['list'];
type MakeGetSmartMapsListData = SmartMapsListVM['list'];
type MakeGetSmartMapsListPaginationData = SmartMapsListVM['listPaginationData'] | undefined;
type MakeGetSmartMapsListFilters = SmartMapsList['params'];

export const makeGetSmartMapsListData = () =>
  createSelector<smartMapsReducer, SmartMapsList, MakeGetSmartMapsListData>(getSmartMapsList, ({ data }) => (data === undefined ? [] : data.list));

export const makeGetSmartMapsListStatus = () =>
  createSelector<smartMapsReducer, SmartMapsList, ReturnType<typeof getDataStatus>>(getSmartMapsList, getDataStatus);

export const makeGetSmartMapsListPaginationData = () =>
  createSelector<smartMapsReducer, SmartMapsList, MakeGetSmartMapsListPaginationData>(getSmartMapsList, ({ data }) => data?.listPaginationData);

export const makeGetSmartMapsListFilters = () =>
  createSelector<smartMapsReducer, SmartMapsList, MakeGetSmartMapsListFilters>(getSmartMapsList, ({ params }) => params);

const getSmartMapsDetails = (state: smartMapsReducer) => state.smartMaps.details;

type SmartMapsDetails = smartMapsReducer['smartMaps']['details'];
type MakeGetSmartMapsDetailsData = SmartMapsDetails['data'];

export const makeGetSmartMapsDetailsData = () =>
  createSelector<smartMapsReducer, SmartMapsDetails, MakeGetSmartMapsDetailsData>(getSmartMapsDetails, ({ data }) => data);
export const makeGetSmartMapsDetailsStatus = () =>
  createSelector<smartMapsReducer, SmartMapsDetails, ReturnType<typeof getDataStatus>>(getSmartMapsDetails, getDataStatus);
