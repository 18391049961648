import { createReducer } from 'typesafe-actions';

import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { DEFAULT_LIST_PAGINATION_DATA } from '@/common/utils/pageable';
import { ReviewListRequest, ReviewListVM } from '@/models/reviews';
import { FeatureStateType } from '../../helpers';
import { reviewsActions } from '../actions';

export type reviewsListState = FetchState<ReviewListVM, ReviewListRequest>;

const INITIAL_STATE: reviewsListState = getFetchInitialState<ReviewListVM, ReviewListRequest>();

const reducer = createReducer<reviewsListState, reviewsActions>(INITIAL_STATE)
  .handleAction(reviewsActions.list.request, (state, { payload }) => fetchReducerHelpers.request(INITIAL_STATE, state, payload))
  .handleAction(reviewsActions.list.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(reviewsActions.list.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(reviewsActions.clearReviewsList, state => ({
    ...state,
    data: { ...state.data, list: [], listPaginationData: state.data?.listPaginationData || DEFAULT_LIST_PAGINATION_DATA },
  }));

export const reviewsListReducer = { list: reducer };
export type reviewsListReducer = FeatureStateType<typeof reviewsListReducer>;
