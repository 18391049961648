import React, { FC, useMemo } from 'react';
import emotionNormalize from 'emotion-normalize';
import { css, Global } from '@emotion/core';

import { theme } from './theme';
import { fontSize, fontFamily, minWindowWidth } from './constants';
import { fonts } from './fonts';
import { iconfont } from './_generated_font_definition';

export const GlobalStyles: FC = () => {
  const styles = useMemo(
    () =>
      css({
        html: {
          fontSize: fontSize.medium,
          backgroundColor: theme.backgroundPrimary,
        },
        body: {
          minWidth: minWindowWidth,
        },
        'html, span, p, div, i, button': {
          fontFamily: fontFamily.primary,
          fontWeight: 400,
          color: theme.textPrimary,
        },
        'div, input': {
          boxSizing: 'border-box',
        },
        'input, textarea': {
          '&::placeholder': {
            fontStyle: 'italic',
            color: theme.textThird,
          },

          '&:disabled': {
            '&::placeholder': {
              color: theme.textDisabled,
            },
          },
        },
        button: {
          backgroundColor: theme.backgroundPrimary,
          outline: 'none',
          border: 'none',
        },
      }),
    []
  );

  return <Global styles={[emotionNormalize, fonts, styles, iconfont]} />;
};
