import React, { forwardRef, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from '@/styles/hooks';

import { Button, ButtonTheme } from '../button';
import { filterStyle } from './styles';

type FilterProps = HTMLAttributes<HTMLElement> & {
  onClear: () => void;
  header?: string;
  disabled?: boolean;
};

export const Filter = forwardRef<HTMLDivElement, FilterProps>(({ children, header, onClear, disabled }, ref) => {
  const [t] = useTranslation();
  const { styles } = useStyles(filterStyle);

  return (
    <div css={styles}>
      <div ref={ref}>
        <div className='filter-header'>
          {header ? header : t('label.filters')}

          <Button theme={ButtonTheme.ghost_error} onClick={onClear} disabled={disabled}>
            {t('button.clearAll')}
          </Button>
        </div>

        {children}
      </div>
    </div>
  );
});
