import { defer, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  ThingsToDoDetailsResponse,
  ThingsToDoDetailsVM,
  ThingToDoChangeImageResponse,
  ThingToDoTagsAutocompleteElementVM,
  ThingToDoTagsAutocompleteListResponse,
  ThingToDoTagsAutocompleteListVM,
  UpdateThingsToDoDetailsEntityParams,
} from '@/features/content/models/things-to-do';
import { httpClient } from '@/core/services/http-client';
import { getEnv, CommonError, Enum } from '@/common/utils';
import { CustomFile } from '@/common/components/form-controls-deprecated';

const config = getEnv();

const CONTENT_THINGS_TO_DO_LIST_ENDPOINT = `${config.REACT_APP_API_URL}/tag-api/v1/admin/things-to-do`;
const CONTENT_THINGS_TO_DO_IMAGE_UPLOAD_ENDPOINT = `${config.REACT_APP_API_URL}/media-access-api/v2/images/things-to-do`;
const CONTENT_THINGS_TO_DO_TAGS_AUTOCOMPLETE_ENDPOINT = `${config.REACT_APP_API_URL}/tag-api/v1/admin/tags/search`;

export const UpdateThingToDoResponseType = Enum('successful', 'uploadImageError', 'uploadDetailsError');
export type UpdateThingToDoResponseType = Enum<typeof UpdateThingToDoResponseType>;
export type ThingsToDoResponseMessage = { type: UpdateThingToDoResponseType; id?: string };
type CreatedThingToDoId = { id: string };

export const getThingsToDoDetailsData = (id: string) =>
  httpClient()
    .authorized.get<ThingsToDoDetailsResponse>(`${CONTENT_THINGS_TO_DO_LIST_ENDPOINT}/${id}`)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new ThingsToDoDetailsVM(data);
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: e.code, message: e.errorMessage })))
    );

const uploadThingToDoImage = (files: File[] | CustomFile[]) => {
  const formData = new FormData();
  formData.append('file', files[0] as Blob);
  formData.append('metadata', new Blob(['{}'], { type: 'application/json' }));
  return httpClient()
    .authorized.post<ThingToDoChangeImageResponse>(CONTENT_THINGS_TO_DO_IMAGE_UPLOAD_ENDPOINT, formData)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 || status === 201 || (status === 202 && data !== undefined)) {
          return data;
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

const addThingTodoData = (data: UpdateThingsToDoDetailsEntityParams) =>
  httpClient()
    .authorized.post<CreatedThingToDoId>(`${CONTENT_THINGS_TO_DO_LIST_ENDPOINT}`, data)
    .pipe(
      map(response => {
        if (response.status === 200 || response.status === 201 || response.status === 202) {
          return response.data;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

const updateThingToDoDetails = (userId: string, data: UpdateThingsToDoDetailsEntityParams) => {
  return httpClient()
    .authorized.put<undefined>(`${CONTENT_THINGS_TO_DO_LIST_ENDPOINT}/${userId}`, data)
    .pipe(
      map(response => {
        if (response.status === 200 || response.status === 201 || response.status === 202) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

type AddThingToDo = { files: File[] | CustomFile[]; data: UpdateThingsToDoDetailsEntityParams };
export const addThingToDoEntity = ({ files, data }: AddThingToDo): Observable<ThingsToDoResponseMessage> =>
  defer(() => {
    if (files[0] instanceof File) {
      return uploadThingToDoImage(files);
    }

    return of(undefined);
  }).pipe(
    switchMap(response => {
      if (response instanceof CommonError) {
        return of({ type: UpdateThingToDoResponseType.uploadImageError });
      }

      const updatedData: UpdateThingsToDoDetailsEntityParams = {
        ...data,
        imageId: response?.id ? response?.id : files[0] instanceof CustomFile ? files[0].id : '',
      };

      return addThingTodoData(updatedData).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return { type: UpdateThingToDoResponseType.uploadDetailsError };
          }

          return { type: UpdateThingToDoResponseType.successful, id: response.id };
        })
      );
    })
  );

type UpdateThingToDo = { userId: string; files: File[] | CustomFile[]; data: UpdateThingsToDoDetailsEntityParams };
export const updateThingToDoEntity = ({ files, userId, data }: UpdateThingToDo) =>
  defer(() => {
    if (files[0] instanceof File) {
      return uploadThingToDoImage(files);
    }

    return of(undefined);
  }).pipe(
    switchMap(response => {
      if (response instanceof CommonError) {
        return of(UpdateThingToDoResponseType.uploadImageError);
      }

      const updatedData: UpdateThingsToDoDetailsEntityParams = {
        ...data,
        imageId: response?.id ? response?.id : files[0] instanceof CustomFile ? files[0].id : '',
      };

      return updateThingToDoDetails(userId, updatedData).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return UpdateThingToDoResponseType.uploadDetailsError;
          }

          return UpdateThingToDoResponseType.successful;
        })
      );
    })
  );

export const getThingsToDoTagsAutocompleteOptionList = (displayName: string) =>
  httpClient()
    .authorized.post<ThingToDoTagsAutocompleteListResponse>(`${CONTENT_THINGS_TO_DO_TAGS_AUTOCOMPLETE_ENDPOINT}`, { displayName })
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new ThingToDoTagsAutocompleteListVM({ data, VM: ThingToDoTagsAutocompleteElementVM });
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
