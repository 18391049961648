import { constants, rem } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const statusWrapperStyles: StyleFunction = ({ theme }) => ({
  position: 'relative',

  '> .error': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'inherit',
    backgroundColor: theme.backgroundSecondary,
    padding: constants.offset.xlarge,

    '.error__message': {
      fontSize: rem(constants.fontSize.h4),
      color: theme.primary,
      fontWeight: 'bold',
    },

    '.error__icon': {
      fontSize: constants.fontSize.large,
      color: theme.textSecondary,
      marginTop: constants.offset.large,
    },
  },

  '> .loading': {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.backgroundSecondary,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
});
