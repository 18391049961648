import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import omit from 'lodash/omit';
import { stringify } from 'qs';

import { httpClient } from '@/core/services/http-client';
import { getEnv, CommonError } from '@/common/utils';
import { VoucherBatchListElementVM, VoucherBatchListRequest, VoucherBatchListResponse, VoucherBatchListVM } from '@/models/voucher-batch';

const config = getEnv();

const CONTENT_VOUCHER_BATCH_LIST_ENDPOINT = `${config.REACT_APP_API_URL}/entitlement-api/v1/vouchers/batches`;

export const getVoucherBatchListData = (data: VoucherBatchListRequest) =>
  httpClient()
    .authorized.get<VoucherBatchListResponse>(CONTENT_VOUCHER_BATCH_LIST_ENDPOINT, {
      params: {
        ...omit(data, ['name', 'shortResponse', 'product']),
        userId: data.userId?.value,
        productId: data.voucherTypes?.[0] === 'SMARTMAP' ? data.product?.value : undefined,
      },
      paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' }),
    })
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new VoucherBatchListVM({ data, VM: VoucherBatchListElementVM });
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
