import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { httpClient } from '@/core/services/http-client';
import { getEnv, CommonError } from '@/common/utils';
import { VoucherBatchDetailsResponse, VoucherBatchDetailsVM } from '@/models/voucher-batch';

const config = getEnv();

const CONTENT_VOUCHER_DETAILS_ENDPOINT = `${config.REACT_APP_API_URL}/entitlement-api/v1/vouchers/batches`;

export const getVoucherBatchDetailsData = (batchId: string) =>
  httpClient()
    .authorized.get<VoucherBatchDetailsResponse>(`${CONTENT_VOUCHER_DETAILS_ENDPOINT}/${batchId}`)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new VoucherBatchDetailsVM({ ...data });
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: e.code, message: e.errorMessage })))
    );

export const getVoucherDetailsFileUrl = (batchId: string) =>
  httpClient()
    .authorized.get<string>(`${CONTENT_VOUCHER_DETAILS_ENDPOINT}/${batchId}/downloadUrl`)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return data;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
