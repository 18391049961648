import { createReducer } from 'typesafe-actions';

import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { ContextTypesDictionaryVM } from '@/models/context-types';
import { FeatureStateType } from '../helpers';
import { contextTypesActions } from './actions';

type contextTypesState = DictionaryState<ContextTypesDictionaryVM>;

const INITIAL_STATE: contextTypesState = getDictionaryInitialState<ContextTypesDictionaryVM>();

const reducer = createReducer<contextTypesState, contextTypesActions>(INITIAL_STATE)
  .handleAction(contextTypesActions.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(contextTypesActions.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(contextTypesActions.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(contextTypesActions.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(contextTypesActions.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const contextTypesReducer = { contextTypes: reducer };
export type contextTypesReducer = FeatureStateType<typeof contextTypesReducer>;
