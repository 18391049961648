import { ReactSelectStyleFunction, StyleFunction } from '@/styles/models';
import { constants, Styles } from '@/styles';

export const selectStyles: ReactSelectStyleFunction = ({ theme }) => ({
  menu: provided => ({
    ...provided,
    marginTop: 0,
    marginBottom: 0,
  }),

  menuList: provided => ({
    ...provided,
    backgroundColor: theme.backgroundPrimary,
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? theme.primary : state.isFocused ? theme.backgroundPrimaryHover : theme.backgroundPrimary,
    color: state.isFocused ? theme.primaryHover : state.isSelected ? theme.textWhite : theme.textPrimary,
    border: 'none',
    wordBreak: 'break-all',

    '&:hover': {
      backgroundColor: state.isSelected ? theme.primary : theme.backgroundPrimary,
    },
  }),

  control: (provided, state) => ({
    ...provided,
    // @ts-ignore
    borderRadius: state.selectProps.unitInput ? `0 ${constants.borderRadius.small}px ${constants.borderRadius.small}px 0` : constants.borderRadius.small,
    border: state.isDisabled
      ? `1px solid ${theme.borderPrimaryDisabled}`
      : state.isFocused
      ? `1px solid ${theme.borderPrimaryActive}`
      : `1px solid ${theme.borderThird}`,
    boxShadow: state.isFocused ? `0 0 1px 1px ${theme.borderPrimaryActive} inset` : 'none',
    backgroundColor: state.isDisabled ? theme.backgroundPrimaryDisabled : theme.backgroundNinth,
    paddingLeft: constants.offset.medium,
    paddingRight: constants.offset.small,
    height: constants.inputHeight,
    width: '100%',
    minWidth: 90,
    pointerEvents: 'auto',
    cursor: state.isDisabled ? 'not-allowed' : 'auto',

    '&:hover': {
      border: state.isDisabled
        ? `1px solid ${theme.borderPrimaryDisabled}`
        : state.isFocused
        ? `1px solid ${theme.borderPrimaryActive}`
        : `1px solid ${theme.borderThird}`,
    },
  }),

  dropdownIndicator: (_, state) => ({
    '& > *': {
      display: state.isDisabled ? 'none' : 'block',
      color: theme.primary,
      fontSize: 25,
    },
  }),

  clearIndicator: () => ({
    '& > *': {
      color: theme.primary,
      fontSize: 20,
    },
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),

  valueContainer: provided => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),

  singleValue: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? theme.textDisabled : theme.textPrimary,

    '&:hover': {
      cursor: state.isDisabled ? 'not-allowed' : 'default',
    },
  }),

  placeholder: provided => ({
    ...provided,
    fontStyle: 'italic',
    color: theme.textThird,
  }),

  input: provided => ({
    ...provided,
    color: theme.textPrimary,
  }),
});

export const dropdownIconStyle: Styles = {
  transition: 'transform 0.2s linear',
  transform: 'scaleY(1)',

  '&.dropdown-icon--open': {
    transform: 'scaleY(-1)',
  },
};

export const noOptionTextStyle: StyleFunction = ({ theme }) => ({
  textAlign: 'start',
  color: theme.textFourth,
});
