import { IdType } from 'react-table';

const TABLE = 'TABLE';

export type TableStorageEntityState<T> = { visible: boolean; id: IdType<T> };
export type TableStorageState<T> = Record<IdType<T>, TableStorageEntityState<T>>;

export const setTableLocalStorage = <T>(name: string, next: TableStorageState<T>) => {
  localStorage.setItem(`${TABLE}_${name}`, JSON.stringify(next));
};

export const getTableLocalStorage = <T>(name: string): TableStorageState<T> | undefined => {
  const saved = localStorage.getItem(`${TABLE}_${name}`);

  return saved ? JSON.parse(saved) : undefined;
};
