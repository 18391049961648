import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const linkStyles: StyleFunction = ({ theme }) => ({
  cursor: 'pointer',
  padding: '0px 2px',
  textDecoration: 'none',
  borderRadius: constants.borderRadius.small,
  transition: 'box-shadow .15s ease-in',

  '&:hover > i': {
    color: theme.primaryHover,
  },

  '&:hover > .link-text': {
    color: theme.primaryHover,
  },

  '&:focus': {
    outline: 'none',
    boxShadow: `0px 0px 0px 3px ${theme.primaryFocus}`,
  },

  '&:active > i': {
    color: theme.primaryActive,
  },

  '&:active > .link-text': {
    color: theme.primaryActive,
  },

  '&.no-decoration': {
    '&:focus': {
      boxShadow: 'none',
    },

    '&:hover > i': {
      color: theme.primary,
    },

    '&:hover > .link-text': {
      color: theme.primary,
    },

    '&:active > i': {
      color: theme.primary,
    },

    '&:active > .link-text': {
      color: theme.primary,
    },
  },

  '&.with-icon': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '.link-icon + .link-text': {
      paddingLeft: constants.offset.xsmall,
    },
  },

  '.link-icon': {
    color: theme.primary,
    fontSize: constants.fontSize.icon,
  },

  '.link-text': {
    textDecoration: 'underline',
    color: theme.primary,
  },
});
