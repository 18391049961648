import { StyleFunction } from '@/styles/models';

export const layoutStyles: StyleFunction = ({ theme }) => ({
  '.layout': {
    '&__container': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',

      '.loader': {
        backgroundColor: theme.backgroundPrimary,
      },
    },

    '&__content': {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      flexShrink: 1,
      overflowY: 'auto',
      backgroundColor: theme.backgroundEight,
    },
  },
});

export const loadingSectionStyles: StyleFunction = ({ theme }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: theme.backgroundPrimary,
  zIndex: 1,
});
