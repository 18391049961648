import React, { FC } from 'react';

import { SelectOption } from '../select';
import { UnitInput, UnitInputProps } from './unit-input';

const OPTIONS: SelectOption[] = [
  { value: 'miles', label: 'miles', translationId: 'units.miles' },
  { value: 'km', label: 'km', translationId: 'units.kilometers' },
];

export type DistanceInputProps = Omit<UnitInputProps, 'options'>;

export const DistanceInput: FC<DistanceInputProps> = props => <UnitInput {...props} options={OPTIONS} />;
