import dayjs, { Dayjs } from 'dayjs';

import { ApiPagination, PageableListResponse, PageableListVM } from '@/common/utils/pageable';
import { RadioOption, SelectOption } from '@/common/components/form-controls-deprecated';
import { AutocompleteOption } from '@/common/components/autocomplete';
import { DEFAULT_DATE_FORMAT } from '@/common/utils';

type InvitationListEntityResponse = {
  author: {
    displayName: string;
    userId: string;
  };
  invitedUser: {
    email: string;
    userId: string | undefined;
  };
  expirationDate: string;
  invitationDate: string;
  newOrgCreated: boolean;
  organisationId: string;
  status: string;
  type: string;
};

export type InvitationListRequest = ApiPagination<{
  type?: string;
  email?: string;
  invitationDateFrom?: Dayjs;
  invitationDateTo?: Dayjs;
  isDeleted?: boolean;
  organisationId?: AutocompleteOption;
  statuses?: string[];
}>;

export type InvitationListResponse = PageableListResponse<InvitationListEntityResponse>;

export interface InvitationListElementVM extends InvitationListEntityResponse {}
export class InvitationListElementVM {
  constructor({ expirationDate, invitationDate, ...props }: InvitationListEntityResponse) {
    Object.assign(this, {
      ...props,
      expirationDate: expirationDate ? dayjs(expirationDate).format(DEFAULT_DATE_FORMAT) : '',
      invitationDate: invitationDate ? dayjs(invitationDate).format(DEFAULT_DATE_FORMAT) : '',
    });
  }
}

export class InvitationListVM extends PageableListVM<InvitationListElementVM, InvitationListResponse> {}

type InvitationTypeResponse = {
  displayName: string;
  id: string;
  translationId: string;
};

export type InvitationTypeListResponse = { entries: InvitationTypeResponse[] };

export interface InvitationTypeDictionaryElementVM extends RadioOption {}

export class InvitationTypeDictionaryElementVM {
  constructor({ displayName, id, ...props }: InvitationTypeResponse) {
    Object.assign(this, {
      ...props,
      label: displayName,
      value: id,
    });
  }
}

export interface InvitationTypeDictionaryVM {
  list: InvitationTypeDictionaryElementVM[];
}

export class InvitationTypeDictionaryVM {
  constructor(list: InvitationTypeListResponse) {
    this.list = (list.entries || []).map(element => new InvitationTypeDictionaryElementVM(element));
  }
}

type InvitationStatusResponse = {
  displayName: string;
  id: string;
  translationId: string;
};

export type InvitationStatusListResponse = { entries: InvitationStatusResponse[] };

export interface InvitationStatusDictionaryElementVM extends SelectOption {}

export class InvitationStatusDictionaryElementVM {
  constructor({ displayName, id, translationId }: InvitationStatusResponse) {
    Object.assign(this, {
      translationId,
      label: displayName,
      value: id,
    });
  }
}

export interface InvitationStatusDictionaryVM {
  list: InvitationStatusDictionaryElementVM[];
}

export class InvitationStatusDictionaryVM {
  constructor(list: InvitationStatusListResponse) {
    this.list = (list.entries || []).map(element => new InvitationStatusDictionaryElementVM(element));
  }
}
export type CreateInvitationParams = {
  emails: string[];
  organisationId: string | undefined;
  type: string;
};

type InvitationError = { code: string; message: string };

export type InvalidInvitation = {
  email: string;
  reason: InvitationError;
};

export type CreateInvitationResponse = {
  invalidInvitations: InvalidInvitation[];
  invitations: InvitationListEntityResponse[];
};
