import { Styles, constants, theme } from '@/styles';

export const style: Styles = {
  display: 'flex',
  flexDirection: 'column',

  '.tooltip-icon': {
    color: theme.primary,
    cursor: 'pointer',
    transition: 'opacity .2s',
    fontSize: constants.fontSize.icon,

    '&:hover': {
      opacity: 0.6,
    },
  },

  '.map-button-wrapper': {
    marginTop: 8,
  },
};

export const tooltipMessageStyles: Styles = {
  '& > p': {
    color: theme.textWhite,
    fontSize: constants.fontSize.h4,
    margin: 0,
  },

  '& > p:not(:last-child)': {
    marginBottom: '1rem',
  },

  '.correct': {
    color: theme.textCorrect,
  },

  '.incorrect': {
    color: theme.textIncorrect,
  },
};
