type CommonTagResponse = {
  id: string;
  displayName: string | undefined;
  imageUrl?: string | undefined;
};

export type CommonTagListResponse = CommonTagResponse[];

export interface CommonTagElementVM extends CommonTagResponse {}

export class CommonTagElementVM {
  constructor(props: CommonTagResponse) {
    Object.assign(this, props);
  }
}

export interface CommonTagDictionaryVM {
  list: CommonTagElementVM[];
}

export class CommonTagDictionaryVM {
  constructor(list: CommonTagListResponse) {
    this.list = (list || []).map(element => new CommonTagElementVM(element));
  }
}
