import React, { FC } from 'react';

import { links } from '../../constants';
import { NavigationGroup } from './components/navigation-group';

type Props = {
  elements: typeof links;
};

export const NavigationGroupsBar: FC<Props> = ({ elements }) => {
  return (
    <>
      {elements.map((group, index) => (
        <NavigationGroup key={`navigation-group-${index}`} data={group} />
      ))}
    </>
  );
};
