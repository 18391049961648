import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const selectedActionsStyles: StyleFunction = ({ theme }) => ({
  height: 100,
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',

  '.select-actions-wrapper': {
    height: 100,
    backgroundColor: theme.backgroundSecondary,
    border: `solid 1px ${theme.primary}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: constants.offset.xlarge,
    bottom: 0,
    right: constants.offset.xlarge + constants.scrollbarWidth,

    '.message': {
      verticalAlign: 'center',
      color: theme.textPrimary,
    },

    '& > button': {
      marginLeft: constants.offset.large,
    },
  },
});
