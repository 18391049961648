import React, { useContext, FC, useMemo, useEffect } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { useStyles } from '@/styles/hooks';
import { CommonEditorCardInnerComponent } from '../../components/common-editor-card';
import { EditorDraggableContext, EditorDroppableContext, EditorInnerArrayContext } from '../../../editor-container';
import { EditorValue } from '../../../../models';
import { ListCardPayload, LIST_CARD_ID_PREFIX, LIST_ALLOWED_CARDS } from '../list-card';
import { listCardContentStyles } from './styles';

type ListBodyProps = FieldArrayRenderProps & { id: string };

const ListBodyComponent: FC<ListBodyProps> = props => {
  const [t] = useTranslation();
  const arrayContext = useContext(EditorInnerArrayContext);
  const droppableContext = useContext(EditorDroppableContext);
  const draggableContext = useContext(EditorDraggableContext);

  const innerListId = useMemo(() => `${LIST_CARD_ID_PREFIX}_${props.id}`, [props.id]);

  const droppEnabled = useMemo(() => droppableContext.activeLists && droppableContext.activeLists.includes(props.id), [droppableContext.activeLists]);

  const cardAllowed = useMemo(
    () => (draggableContext.draggedItem ? LIST_ALLOWED_CARDS.includes(draggableContext.draggedItem) : false),
    [draggableContext.draggedItem, LIST_ALLOWED_CARDS]
  );

  const { move, insert } = props;
  const list = props.form.getFieldMeta(props.name).value as EditorValue[];

  useEffect(() => {
    if (!arrayContext.data[innerListId]) {
      arrayContext.setData({
        ...arrayContext.data,
        [innerListId]: {
          insert,
          move,
        },
      });
    }
  }, [innerListId, arrayContext.data]);

  return (
    <Droppable key={innerListId} droppableId={innerListId} isDropDisabled={!droppEnabled}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} className={cn(['content', snapshot.isDraggingOver && cardAllowed && 'content--dragging-over'])}>
          {(list === undefined || list.length === 0) && <div className='notice'>{t('storyContentEditor.card.list.bodyPlaceholder')}</div>}
          {(list || []).map((item, index) => (
            <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={!droppEnabled}>
              {(provided, snapshot) => (
                <item.card.content id={item.id} provided={provided} snapshot={snapshot} item={item.card} index={index} fieldArrayRender={props} />
              )}
            </Draggable>
          ))}
        </div>
      )}
    </Droppable>
  );
};

export const ListCardContentComponent: CommonEditorCardInnerComponent<ListCardPayload> = ({ isEditable, formField, id }) => {
  const [field] = formField;
  const listBodyName = `${field.name}`;
  const editorDroppableContext = useContext(EditorDroppableContext);
  const { styles } = useStyles(listCardContentStyles);

  useEffect(() => {
    isEditable ? editorDroppableContext.addActiveList(id) : editorDroppableContext.removeActiveList(id);
  }, [isEditable]);

  useEffect(
    () => () => {
      editorDroppableContext.removeActiveList(id);
    },
    [id]
  );

  return (
    <div css={styles} className={cn([!isEditable && 'disabled'])}>
      <FieldArray name={listBodyName} render={props => <ListBodyComponent {...props} id={id} />} />
    </div>
  );
};
