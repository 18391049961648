import { jsx } from 'slate-hyperscript';
import escapeHtml from 'escape-html';
import { Transforms, Editor, Range, Element as SlateElement, Text } from 'slate';
import { LinkElement } from './text-editor';

export function serialize(node) {
  if (Text.isText(node as any)) {
    const string = escapeHtml(node.text);

    return node.bold ? `<strong>${string}</strong>` : string;
  }

  const children = node?.children?.map(n => serialize(n)).join('');

  switch (node.text) {
    case '\n':
      return '</br>';
  }

  switch (node.type) {
    case 'quote':
      return `<blockquote><p>${children}</p></blockquote>`;
    case 'paragraph':
      return `${children}`;
    case 'br':
      return '\n';
    case 'link':
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;

    default:
      return children;
  }
}

export function deserialize(el, markAttributes = {} as any) {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent);
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes = { ...markAttributes };

  switch (el.nodeName) {
    case 'STRONG':
      nodeAttributes.bold = true;
  }

  const children = Array.from(el.childNodes)
    .map(node => deserialize(node, nodeAttributes))
    .flat();

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''));
  }

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children);
    case 'BR':
      return {
        kind: 'text',
        text: '\n',
      };
    case 'BLOCKQUOTE':
      return jsx('element', { type: 'quote' }, children);
    case 'P':
      return jsx('element', { type: 'paragraph' }, children);
    case 'A':
      return jsx('element', { type: 'link', url: el.getAttribute('href') }, children);
    default:
      return children;
  }
}

export const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

export const isLinkActive = editor => {
  // @ts-ignore:next-line
  const [link] = Editor.nodes(editor, {
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && (n as any).type === 'link',
  });
  return !!link;
};

export const unwrapLink = editor => {
  Transforms.unwrapNodes(editor, {
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && (n as any).type === 'link',
  });
};

export const wrapLink = (editor, url: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: LinkElement = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};
