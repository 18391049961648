import React, { HTMLAttributes, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { styles } from './styles';

type ErrorMessageProps = HTMLAttributes<HTMLElement> & {
  error?: string;
};

export const ErrorMessage: FC<ErrorMessageProps> = ({ error, ...props }) => {
  const [t] = useTranslation();
  return (
    <div css={styles} {...props}>
      {error && t(error)}
    </div>
  );
};
