import React, { FC } from 'react';
import cn from 'classnames';

import { useStyles } from '@/styles/hooks';
import { Icon } from '../icon';
import { Anchor, AnchorProps } from '../link';
import { IconButtonTheme } from './icon-button';
import { iconButtonStyles } from './styles';

export type IconButtonLinkProps = Omit<AnchorProps, 'to'> & {
  link: string;
  iconName: string;
  theme?: IconButtonTheme;
  disabled?: boolean;
};

export const IconButtonLink: FC<IconButtonLinkProps> = ({ iconName, theme = 'primary', link, disabled, ...props }) => {
  const { styles } = useStyles(iconButtonStyles);

  if (disabled) {
    return (
      <div css={styles} id={props.id} className={props.className}>
        <Icon name={iconName} className={cn(['icon', `icon--${theme}`, 'icon--disabled'])} />
      </div>
    );
  }

  return (
    <Anchor css={styles} to={link} {...props} className={cn(['icon-button', 'icon-button-link', theme, props.className])}>
      <Icon name={iconName} className={cn(['icon', `icon--${theme}`])} />
    </Anchor>
  );
};
