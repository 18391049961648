import React, { ChangeEvent, useCallback, useState } from 'react';
import { ValidationError } from 'yup';
import { useTranslation } from 'react-i18next';

import { Input } from '@/common/components/form-controls-deprecated';
import { Icon } from '@/common/components/icon';
import { useStyles } from '@/styles/hooks';
import { CommonEditorCardStatusElement } from '../common-editor-card-status-element';
import { CommonEditorCardInnerComponent } from '../common-editor-card';
import { contentStyle } from './styles';

const ATTRIBUTION_PATH = 'attribution';

export type CommonMediaCardPayload = {
  id: string;
  cover?: string;
  attribution?: string;
};

export const AttributionContentComponent: CommonEditorCardInnerComponent<CommonMediaCardPayload> = ({ isEditable, formField }) => {
  const [field, meta, helpers] = formField;

  const [t] = useTranslation();
  const { styles } = useStyles(contentStyle);

  const [innerValue, setInnerValue] = useState<string | undefined>(field.value?.attribution);

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setInnerValue(e.target.value === '' ? undefined : e.target.value);
    },

    []
  );

  const handleOnBlur = useCallback(() => {
    helpers.setTouched(true);
    helpers.setValue({
      ...field.value,
      attribution: innerValue,
    });
  }, [innerValue, helpers.setValue, helpers.setTouched, field.value]);

  const findErrorMessage = useCallback(() => {
    const errors = meta.error as unknown as ValidationError[];
    if (errors && errors.length > 0) {
      for (const error of errors) {
        if (error.path === ATTRIBUTION_PATH) {
          return error.message;
        }
      }

      return undefined;
    }
  }, [meta.error]);

  const errorMessage: string | undefined = findErrorMessage();

  return (
    <div css={styles}>
      <Icon name='mdi-copyright' className='copyright-icon' />
      <div className='input-wrapper'>
        {isEditable ? (
          <Input value={innerValue} onChange={handleOnChange} onBlur={handleOnBlur} placeholder={t('storyContentEditor.card.attribution.placeholder')} />
        ) : meta.value?.attribution ? (
          <div className='value'>{meta.value.attribution}</div>
        ) : (
          <div className='placeholder'>{t('storyContentEditor.card.attribution.placeholder')}</div>
        )}
      </div>
      {errorMessage && meta.touched && <CommonEditorCardStatusElement status='ERROR' text={errorMessage} />}
    </div>
  );
};
