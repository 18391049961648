import React, { FC, HTMLAttributes, useMemo } from 'react';
import { TypeOptions } from 'react-toastify';
import { Global } from '@emotion/core';
import { useTranslation } from 'react-i18next';

import { Icon } from '@/common/components/icon';
import { toastStyles, containerStyles } from './styles';

export type ToastProps = HTMLAttributes<HTMLElement> & {
  text: string;
  type: TypeOptions;
  closeToast: () => void;
};

export const Toast: FC<ToastProps> = ({ text, type, closeToast }) => {
  const [t, i18n] = useTranslation();

  const iconName = useMemo(() => {
    switch (type) {
      case 'success':
        return 'mdi-check-circle';
      case 'warning':
        return 'mdi-alert';
      case 'error':
        return 'mdi-alert-circle';
      case 'info':
        return 'mdi-information';
      default:
        return 'mdi-information';
    }
  }, [type]);

  return (
    <div css={toastStyles}>
      <Global styles={containerStyles} />
      <Icon name={iconName} className='toast-icon' />
      <div className='text'>{i18n.exists(text) ? t(text) : text}</div>
      <button onClick={closeToast} className='close-button'>
        <Icon name='mdi-close' className='button-icon' />
      </button>
    </div>
  );
};
