import React, { FC, HTMLAttributes } from 'react';
import cn from 'classnames';

type IconProps = HTMLAttributes<HTMLElement> & {
  name: string;
};

export const Icon: FC<IconProps> = ({ name, className, ...props }) => {
  return <i {...props} className={cn(['icon', name, className])} />;
};
