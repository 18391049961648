import { constants, Styles, theme } from '@/styles';

export const styles: Styles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  '.upload-button': {
    '&--small': {
      paddingTop: constants.offset.xsmall,
      paddingBottom: constants.offset.xsmall,
      minHeight: 28,
    },
  },

  '.info-section': {
    display: 'flex',
    flexDirection: 'column',

    '& > span': {
      color: theme.textThird,
    },
  },
};
