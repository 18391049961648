import { createReducer } from 'typesafe-actions';

import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { DEFAULT_LIST_PAGINATION_DATA } from '@/common/utils/pageable';
import { ThingsToDoTagsListVM, ThingsToDoTagsParams } from '@/features/content/models/things-to-do-tags';
import { FeatureStateType } from '../../helpers';
import { thingsToDoTagsActions } from '../actions';

export type thingsToDoTagsListState = FetchState<ThingsToDoTagsListVM, ThingsToDoTagsParams>;

const INITIAL_STATE: thingsToDoTagsListState = getFetchInitialState<ThingsToDoTagsListVM, ThingsToDoTagsParams>();

const reducer = createReducer<thingsToDoTagsListState, thingsToDoTagsActions>(INITIAL_STATE)
  .handleAction(thingsToDoTagsActions.list.request, (state, { payload }) => fetchReducerHelpers.request(INITIAL_STATE, state, payload))
  .handleAction(thingsToDoTagsActions.list.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(thingsToDoTagsActions.list.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(thingsToDoTagsActions.clearThingsToDoTagsList, state => ({
    ...state,
    data: { ...state.data, list: [], listPaginationData: state.data?.listPaginationData || DEFAULT_LIST_PAGINATION_DATA },
  }));

export const thingsToDoTagsListReducer = { list: reducer };
export type thingsToDoTagsListReducer = FeatureStateType<typeof thingsToDoTagsListReducer>;
