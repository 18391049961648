import React, { FC, useCallback, useEffect } from 'react';
import { FieldProps as FormikFieldProps, useField } from 'formik';
import cn from 'classnames';

import { useForceUpdate, useRefState } from '@/common/utils';
import { useStyles } from '@/styles/hooks';
import { Field, FieldProps } from '../field';
import { DatePickerDeprecated, DatePickerProps } from './datepicker-deprecated';
import {
  DateBoundaries,
  DatePickerValueWithReason as ValueWithReason,
  DatePickerOnChangeErrorCallback as OnChangeErrorCallback,
  DatePickerOnChange as OnChange,
  DatePickerOnChangeErrorReason as ErrorReason,
} from './constants';
import { datePickerRangeStyles } from './styles';

type FieldDatepickerProps = Omit<FieldProps, 'label' | 'children'> &
  Omit<DatePickerProps, 'onChange' | 'value' | 'label'> & {
    className?: string;
    labelFrom?: string;
    labelTo?: string;
  };

export const DatepickerRangeFieldDeprecated: FC<FieldDatepickerProps> = ({
  name,
  labelFrom,
  labelTo,
  labelIcon,
  isFocusOnLabelEnabled,
  className,
  displayFormat,
  ...props
}) => {
  const DatePickerRangeField = ({ field: { value, ...field }, meta: { touched, error }, form }: FormikFieldProps<DateBoundaries<ValueWithReason>>) => {
    const [dates, setDates] = useRefState(value);
    const foreUpdate = useForceUpdate();
    const { styles } = useStyles(datePickerRangeStyles);

    const onDateWithErrorFromChange = useCallback<OnChangeErrorCallback>(
      (reason, value) =>
        setDates(prev => {
          const next: DateBoundaries<ValueWithReason> = { start: { value: value ? value.startOf('date') : value, reason }, end: prev.end };
          form.setFieldValue(name, next);
          return next;
        }),
      [form, name, setDates]
    );
    const onDateWithErrorToChange = useCallback<OnChangeErrorCallback>(
      (reason, value) =>
        setDates(prev => {
          const next: DateBoundaries<ValueWithReason> = { start: prev.start, end: { value: value ? value.startOf('date') : value, reason } };
          form.setFieldValue(name, next);
          return next;
        }),
      [form, name, setDates]
    );
    const onDateFromChange = useCallback<OnChange>(value => onDateWithErrorFromChange(ErrorReason.none, value), [onDateWithErrorFromChange]);
    const onDateToChange = useCallback<OnChange>(value => onDateWithErrorToChange(ErrorReason.none, value), [onDateWithErrorToChange]);

    const inputClassName = cn([{ error: touched && error }]);

    useEffect(() => {
      setDates(value);
      foreUpdate();
    }, [value]);

    return (
      <div css={styles}>
        <div className='wrapper'>
          <label>{labelFrom}</label>
          <DatePickerDeprecated
            {...props}
            {...field}
            value={dates.start?.value}
            className={inputClassName}
            onChange={onDateFromChange}
            onChangeError={onDateWithErrorFromChange}
            displayFormat={displayFormat}
            maxDate={dates.end?.value}
          />
        </div>
        <div className='wrapper'>
          <label>{labelTo}</label>
          <DatePickerDeprecated
            {...props}
            {...field}
            value={dates.end?.value}
            className={inputClassName}
            onChange={onDateToChange}
            onChangeError={onDateWithErrorToChange}
            displayFormat={displayFormat}
            minDate={dates.start?.value}
          />
        </div>
      </div>
    );
  };

  const [field] = useField<DateBoundaries<ValueWithReason>>(name);
  const { start, end } = field.value;
  const errorOnRight = start?.reason !== ErrorReason.invalidDate && end?.reason === ErrorReason.invalidDate ? 'error-on-right' : void 0;

  return (
    <Field name={name} labelIcon={labelIcon} isFocusOnLabelEnabled={isFocusOnLabelEnabled} className={className} errorClassName={errorOnRight}>
      {DatePickerRangeField}
    </Field>
  );
};
