import uniqueId from 'lodash/uniqueId';
import { defer, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { CommonError, Enum, getEnv } from '@/common/utils';
import { httpClient } from '@/core/services/http-client';
import {
  ContentFragmentTypes,
  ContentFragmentType,
  UploadAudioResponse,
  UploadImageResponse,
  UploadVideoCoverResponse,
  UploadVideoResponse,
} from '@/models/tours';
import { HeaderCardValue, HeaderCard } from './components/editor-cards/header-card';
import { ParagraphCard, ParagraphCardValue } from './components/editor-cards/paragraph-card';
import { AudioCard, AudioCardValue } from './components/editor-cards/audio-card';
import { ImageCard, ImageCardValue } from './components/editor-cards/image-card';
import { VideoCard, VideoCardValue } from './components/editor-cards/video-card';
import { AccordionCard, AccordionCardValue } from './components/editor-cards/accordion-card';
import { ListCard, ListCardPayload, ListCardValue } from './components/editor-cards/list-card';

const config = getEnv();

const AUDIO_UPLOAD_ENDPOINT = `${config.REACT_APP_API_URL}/media-access-api/v2/audios/stories`;
const VIDEO_COVER_UPLOAD_ENDPOINT = `${config.REACT_APP_API_URL}/media-access-api/v2/images/stories`;
const VIDEO_UPLOAD_ENDPOINT = `${config.REACT_APP_API_URL}/media-access-api/v2/videos/stories`;
const IMAGE_UPLOAD_ENDPOINT = `${config.REACT_APP_API_URL}/media-access-api/v2/images/stories`;

export type EditorValueList = HeaderCardValue[] &
  VideoCardValue[] &
  AudioCardValue[] &
  ImageCardValue[] &
  AccordionCardValue[] &
  ListCardValue[] &
  ParagraphCardValue[];

export const parseFromServerData = (data?: ContentFragmentTypes[]): EditorValueList => {
  const parsedList: EditorValueList = [];
  for (const item of data || []) {
    switch (item.type) {
      case ContentFragmentType.header: {
        parsedList.push({
          id: createCardId(item.type),
          card: HeaderCard,
          payload: item.value,
        });
        break;
      }

      case ContentFragmentType.paragraph: {
        parsedList.push({
          id: createCardId(item.type),
          card: ParagraphCard,
          payload: {
            text: item.value
              .replace(/$/, '<br>')
              .replace(/<span><br><\/span>/g, '<p><br></p>')
              .replace(/<span(?=.*>)/g, '<p')
              .replace(/<\/span><br>/g, '</p>'),
            attribution: item.attribution,
          },
        });
        break;
      }

      case ContentFragmentType.audio: {
        parsedList.push({
          id: createCardId(item.type),
          card: AudioCard,
          payload: {
            id: item.audio.id,
            attribution: item.attribution,
          },
        });
        break;
      }

      case ContentFragmentType.video: {
        parsedList.push({
          id: createCardId(item.type),
          card: VideoCard,
          payload: {
            id: item.video.id,
            cover: item.cover.id,
            attribution: item.attribution,
          },
        });
        break;
      }

      case ContentFragmentType.image: {
        parsedList.push({
          id: createCardId(item.type),
          card: ImageCard,
          payload: {
            id: item.image.id,
            attribution: item.attribution,
          },
        });
        break;
      }

      case ContentFragmentType.accordion: {
        parsedList.push({
          id: createCardId(item.type),
          card: AccordionCard,
          payload: {
            title: item.title,
            body: parseFromServerData(item.items),
          },
        });
        break;
      }

      case ContentFragmentType.list: {
        parsedList.push({
          id: createCardId(item.type),
          card: ListCard,
          payload: parseFromServerData(item.items),
        });
        break;
      }

      default: {
      }
    }
  }

  return parsedList;
};

export const parseFromEditorData = (data: EditorValueList): ContentFragmentTypes[] => {
  const parsedList: ContentFragmentTypes[] = [];
  for (const { card, id, payload } of data) {
    switch (card.type) {
      case ContentFragmentType.header: {
        parsedList.push({
          type: card.type,
          value: payload,
        });
        break;
      }

      case ContentFragmentType.paragraph: {
        parsedList.push({
          type: card.type,
          value: payload.text
            .replace(/<p><br><\/p>/g, '<span><br></span>')
            .replace(/<p(?=.*>)/g, '<span')
            .replace(/<\/p>/g, '</span><br>')
            .replace(/(<br>)(?!.*\1)/, ''),
          attribution: payload.attribution,
        });
        break;
      }

      case ContentFragmentType.audio: {
        parsedList.push({
          type: card.type,
          audio: {
            id: payload.id,
          },
          attribution: payload.attribution,
        });
        break;
      }

      case ContentFragmentType.video: {
        parsedList.push({
          type: card.type,
          video: {
            id: payload.id,
          },
          cover: {
            id: payload.cover || '',
          },
          attribution: payload.attribution,
        });
        break;
      }

      case ContentFragmentType.image: {
        parsedList.push({
          type: card.type,
          image: {
            id: payload.id.toString(),
          },
          attribution: payload.attribution,
        });
        break;
      }

      case ContentFragmentType.accordion: {
        parsedList.push({
          type: card.type,
          title: payload.title,
          items: parseFromEditorData(payload.body as EditorValueList),
        });
        break;
      }

      case ContentFragmentType.list: {
        parsedList.push({
          type: card.type,
          items: (payload as ListCardPayload).map(value => ({
            type: value.card.type as typeof ContentFragmentType['paragraph'],
            value: value.payload.text,
            attribution: value.payload.attribution,
          })),
        });
        break;
      }

      default: {
        break;
      }
    }
  }

  return parsedList;
};

export const uploadAudioFile = (audioFile: File) => {
  const formData = new FormData();
  formData.append('file', audioFile);
  formData.append('metadata', new Blob(['{}'], { type: 'application/json' }));
  return httpClient()
    .authorized.post<UploadAudioResponse>(AUDIO_UPLOAD_ENDPOINT, formData)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 || status === 201 || (status === 202 && data !== undefined)) {
          return data.id;
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

export const uploadImageFile = (imageFile: File) => {
  const formData = new FormData();
  formData.append('file', imageFile);
  formData.append('metadata', new Blob(['{}'], { type: 'application/json' }));
  return httpClient()
    .authorized.post<UploadImageResponse>(IMAGE_UPLOAD_ENDPOINT, formData)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 || status === 201 || (status === 202 && data !== undefined)) {
          return data.id;
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

export const createCardId = (type: ContentFragmentType) => `${type}_${uniqueId()}`;

export const uploadVideoCover = (videoFileCover: File) => {
  const formData = new FormData();
  formData.append('file', videoFileCover);
  formData.append('metadata', new Blob(['{}'], { type: 'application/json' }));
  return httpClient()
    .authorized.post<UploadVideoCoverResponse>(VIDEO_COVER_UPLOAD_ENDPOINT, formData)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 || status === 201 || (status === 202 && data !== undefined)) {
          return data;
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

export const uploadVideo = (videoFile: File[]) => {
  const formData = new FormData();
  formData.append('file', videoFile[0]);
  formData.append('metadata', new Blob(['{}'], { type: 'application/json' }));
  return httpClient()
    .authorized.post<UploadVideoResponse>(VIDEO_UPLOAD_ENDPOINT, formData)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 || status === 201 || (status === 202 && data !== undefined)) {
          return data;
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

export const AddVideoResponseType = Enum('successful', 'uploadCoverError', 'uploadVideoError');
export type AddVideoResponseType = Enum<typeof AddVideoResponseType>;
export type SendVideoResponse = { type: AddVideoResponseType; videoId?: string; videoCoverId?: string };
export type AddVidoeParams = { coverFile: File; videoFile: File[] };

export const addVideo = ({ coverFile, videoFile }: AddVidoeParams): Observable<SendVideoResponse> =>
  defer(() => {
    return uploadVideoCover(coverFile);
  }).pipe(
    switchMap(response => {
      if (response instanceof CommonError) {
        return of({ type: AddVideoResponseType.uploadCoverError });
      }

      const videoCoverId = response.id;

      return uploadVideo(videoFile).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return { type: AddVideoResponseType.uploadVideoError };
          }

          return { videoCoverId, type: AddVideoResponseType.successful, videoId: response.id };
        })
      );
    })
  );
