import { concat, EMPTY, forkJoin, of } from 'rxjs';
import { switchMap, filter, takeUntil } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { getUserProfile, getUserRoleData } from '@/core/services/authentication';
import { getRegionDetails } from '@/core/services/data-center-switcher';
import { getUserConsents } from '@/features/authentication/services/consents';
import { getOrganisationsDetailsData } from '@/features/accounts/services/organisations';
import { checkUserPermissions, CommonError } from '@/common/utils';
import { ConsentDocumentListVM } from '@/models/consents';
import { resetStoreAction } from '../middleware';
import { authenticationActions } from './actions';
import { authenticationReducer } from './reducer';

const DEFAULT_COUNTRY_CODE = 'default';

export const getUserDataEpic: Epic<authenticationActions, authenticationActions, authenticationReducer> = action$ =>
  action$.pipe(
    filter(isActionOf(authenticationActions.getUserData)),
    switchMap(() =>
      getUserProfile().pipe(
        switchMap(data => {
          const countryCode = data?.userData.country;

          return forkJoin({
            regions: getRegionDetails(countryCode || DEFAULT_COUNTRY_CODE),
            consentList: getUserConsents(),
          }).pipe(
            switchMap(({ regions, consentList }) => {
              if (regions instanceof CommonError || consentList instanceof CommonError) {
                return concat(
                  of(authenticationActions.setUserData(undefined)),
                  of(
                    authenticationActions.setUserDefaultRegion({
                      regionData: undefined,
                      canUserChangeRegion: false,
                    })
                  )
                );
              }

              return concat(
                of(
                  authenticationActions.setUserData(
                    data === undefined
                      ? undefined
                      : {
                          ...data.userData,
                          consents: consentList.list || [],
                        }
                  )
                ),
                of(
                  authenticationActions.setUserDefaultRegion({
                    regionData: regions,
                    canUserChangeRegion: checkUserPermissions({ subject: 'REGIONS', scope: 'SEARCH' }, data?.userData.roles || []),
                  })
                )
              );
            })
          );
        }),

        takeUntil(action$.pipe(filter(isActionOf(resetStoreAction))))
      )
    )
  );

const refreshConsentsEpic: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(authenticationActions.refreshUserConsents)),
    switchMap(() =>
      getUserConsents().pipe(
        switchMap((response: ConsentDocumentListVM | CommonError) => {
          if (response instanceof CommonError) {
            return EMPTY;
          }

          return of(authenticationActions.setUserConsents(response));
        })
      )
    )
  );

const getUserRole: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(authenticationActions.getUserRole)),
    switchMap(() =>
      getUserRoleData().pipe(
        switchMap(response => {
          if (response instanceof CommonError) {
            return EMPTY;
          }

          return of(authenticationActions.setUserRole(response !== undefined ? { id: response.role.id, displayName: response.role.displayName } : undefined));
        })
      )
    )
  );

const getUserOrganisationName: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(authenticationActions.getUserOrganisationName)),
    switchMap(action =>
      getOrganisationsDetailsData(action.payload).pipe(
        switchMap(response => {
          if (response instanceof CommonError) {
            return EMPTY;
          }

          return of(authenticationActions.setUserOrganisationName(response !== undefined ? response.name : undefined));
        })
      )
    )
  );

export const authenticationEpics = [getUserDataEpic, refreshConsentsEpic, getUserRole, getUserOrganisationName];
