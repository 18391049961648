import React, { FC, HTMLAttributes } from 'react';

import { Icon } from '@/common/components/icon';
import { IconButton } from '@/common/components/icon-button';
import { style } from './styles';

export type WarningBarProps = HTMLAttributes<HTMLElement> & {
  message: string;
  onClose: () => void;
};

export const WarningBar: FC<WarningBarProps> = ({ message, onClose, ...props }) => (
  <div css={style} {...props}>
    <div />
    <div className='message-wrapper'>
      <Icon name='mdi-alert' className='warning-icon' />
      {message}
    </div>
    <IconButton iconName='mdi-close' onClick={onClose} className='close-button' theme='secondary' />
  </div>
);
