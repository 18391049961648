import { createReducer } from 'typesafe-actions';

import { CommunityStoryDetailsVM } from '@/features/content/models/community-stories';
import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { FeatureStateType } from '../../helpers';
import { communityStoriesActions } from '../actions';

export type communityStoryDetailsState = FetchState<CommunityStoryDetailsVM>;

const INITIAL_STATE: communityStoryDetailsState = getFetchInitialState<CommunityStoryDetailsVM>();

const reducer = createReducer<communityStoryDetailsState, communityStoriesActions>(INITIAL_STATE)
  .handleAction(communityStoriesActions.details.request, state => fetchReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(communityStoriesActions.details.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(communityStoriesActions.details.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(communityStoriesActions.details.clearDetails, () => ({ ...INITIAL_STATE }));

export const communityStoryDetailsReducer = { details: reducer };
export type communityStoryDetailsReducer = FeatureStateType<typeof communityStoryDetailsReducer>;
