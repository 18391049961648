import { createReducer } from 'typesafe-actions';

import { SmartMapDataVM, EntitlementsParams } from '@/models/entitlements';
import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { FeatureStateType } from '../../helpers';
import { entitlementsActions } from '../actions';

export type smartMapsState = FetchState<SmartMapDataVM, EntitlementsParams>;

const INITIAL_STATE: smartMapsState = getFetchInitialState<SmartMapDataVM, EntitlementsParams>();
const reducer = createReducer<smartMapsState, entitlementsActions>(INITIAL_STATE)
  .handleAction(entitlementsActions.smartMaps.request, (state, { payload }) => fetchReducerHelpers.request(INITIAL_STATE, state, payload))
  .handleAction(entitlementsActions.smartMaps.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(entitlementsActions.smartMaps.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(entitlementsActions.clearSmartMaps, () => ({ ...INITIAL_STATE }))
  .handleAction(entitlementsActions.smartMapsTotalCount.success, (state, action) => ({ ...state, collectionTotalCount: action.payload }))
  .handleAction(entitlementsActions.smartMapsTotalCount.failure, state => ({ ...state, collectionTotalCount: 0 }));

export const smartMapsReducer = { smartMaps: reducer };
export type smartMapsReducer = FeatureStateType<typeof smartMapsReducer>;
