import { ActionType, Action } from 'typesafe-actions';

import { createDictionaryActions } from '@/common/utils/store';
import { CommonTagDictionaryVM } from '@/models/common-tag';

export const commonDictionariesActions = {
  categories: createDictionaryActions(
    `FETCH_CATEGORIES_DICTIONARY_REQUEST`,
    `FETCH_CATEGORIES_DICTIONARY_SUCCESS`,
    `FETCH_CATEGORIES_DICTIONARY_FAILURE`,
    `FETCH_CATEGORIES_DICTIONARY_CANCEL`,
    `FETCH_CATEGORIES_DICTIONARY_SILENT_REQUEST`
  )<undefined, CommonTagDictionaryVM>(),
  tags: createDictionaryActions(
    `FETCH_TAGS_DICTIONARY_REQUEST`,
    `FETCH_TAGS_DICTIONARY_SUCCESS`,
    `FETCH_TAGS_DICTIONARY_FAILURE`,
    `FETCH_TAGS_DICTIONARY_CANCEL`,
    `FETCH_TAGS_DICTIONARY_SILENT_REQUEST`
  )<undefined, CommonTagDictionaryVM>(),
};

export type commonDictionariesActions = Action | ActionType<typeof commonDictionariesActions>;
