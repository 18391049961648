import { AccountHubPolicy, RequestParams } from './models';

export const DEFAULT_REQUEST_PARAMS: RequestParams = {
  policy: AccountHubPolicy.B2C_1A_signup_signin,
};

export const WAIT_TIME = 5000;

export const ACCOUNT_HUB_ERROR = {
  resetPasswordRedirection: 'AADB2C90118',
  exceededMaximumNumberForRetriesForSignup: 'AADB2C90157',
  cancelledProcess: 'AADB2C90091',
};
