import { createReducer } from 'typesafe-actions';

import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { ThingsToDoDetailsVM } from '@/features/content/models/things-to-do';
import { FeatureStateType } from '../../helpers';
import { thingsToDoActions } from '../actions';

export type thingsToDoDetailsState = FetchState<ThingsToDoDetailsVM>;

const INITIAL_STATE: thingsToDoDetailsState = getFetchInitialState<ThingsToDoDetailsVM>();

const reducer = createReducer<thingsToDoDetailsState, thingsToDoActions>(INITIAL_STATE)
  .handleAction(thingsToDoActions.details.request, state => fetchReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(thingsToDoActions.details.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(thingsToDoActions.details.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(thingsToDoActions.clearDetails, () => ({ ...INITIAL_STATE }));

export const thingsToDoDetailsReducer = { details: reducer };
export type thingsToDoDetailsReducer = FeatureStateType<typeof thingsToDoDetailsReducer>;
