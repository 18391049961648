import { useCallback, useEffect, useState, useMemo, ForwardedRef } from 'react';
import { FormikProps } from 'formik';
import { Descendant } from 'slate';
import { useTranslation } from 'react-i18next';
import { deserialize, serialize } from '../../text-editor/functions';

type UseRichTextMethodsProps = {
  value: string;
  name?: string;
  form?: FormikProps<any>;
  onChange?: (value: string) => void;
  ref: ForwardedRef<HTMLAnchorElement>;
};

export const useSlateMethods = ({ form, name, value }: UseRichTextMethodsProps) => {
  const [t] = useTranslation();
  const [shouldInitializedSlate, setShouldInitializedSlate] = useState(false);
  const [initialValue, setInitialValue] = useState<Descendant[]>();

  const { body } = useMemo(() => new DOMParser().parseFromString(`<p>${value}</p>`, 'text/html'), [value]);
  const transformedValueToSlateFormat = useMemo(() => deserialize(body) || null, [body]);

  useEffect(() => {
    if (form?.initialValues.name.length > 1) {
      setInitialValue(transformedValueToSlateFormat);
      setShouldInitializedSlate(true);
    }
  }, [value, form, transformedValueToSlateFormat]);

  const onChangeSlate = useCallback(value => {
    const serializedHtml = serialize(value[0]);
    const serializedHtmlRest = value.map(item => serialize(item));

    const list = serializedHtmlRest.map(item => (item === '' ? '\n' : item));

    if (form !== undefined && name !== undefined) {
      form.setFieldValue(name, list.join('') || serializedHtml);
    }
  }, []);

  return {
    shouldInitializedSlate,
    initialValue,
    onChangeSlate,
    t,
  };
};
