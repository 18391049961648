import { ActionType, Action, createAction } from 'typesafe-actions';

import { createFetchActions } from '@/common/utils/store';
import { ModerationElementVM, ModerationListVM, ModerationParams } from '@/models/moderation';

export const moderationActions = {
  list: createFetchActions('FETCH_MODERATION_REQUEST', 'FETCH_MODERATION_SUCCESS', 'FETCH_MODERATION_FAILURE')<ModerationParams, ModerationListVM>(),
  clearModerationList: createAction('CLEAR_MODERATION_LIST')(),
  details: createFetchActions('FETCH_MODERATION_DETAILS_REQUEST', 'FETCH_MODERATION_DETAILS_SUCCESS', 'FETCH_MODERATION_DETAILS_FAILURE')<
    string,
    ModerationElementVM
  >(),
  clearModerationDetails: createAction('CLEAR_MODERATION_DETAILS')(),
};

export type moderationActions = Action | ActionType<typeof moderationActions>;
