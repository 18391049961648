import { css } from '@emotion/core';

const sourceSansPro = `
  @font-face {
    font-family: 'SourceSansPro';
    src: url('/fonts/SourceSansPro/SourceSansPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'SourceSansPro';
    src: url('/fonts/SourceSansPro/SourceSansPro-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
  }

  @font-face {
    font-family: 'SourceSansPro';
    src: url('/fonts/SourceSansPro/SourceSansPro-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'SourceSansPro';
    src: url('/fonts/SourceSansPro/SourceSansPro-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: 'SourceSansPro';
    src: url('/fonts/SourceSansPro/SourceSansPro-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'SourceSansPro';
    src: url('/fonts/SourceSansPro/SourceSansPro-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: 'SourceSansPro';
    src: url('/fonts/SourceSansPro/SourceSansPro-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'SourceSansPro';
    src: url('/fonts/SourceSansPro/SourceSansPro-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'SourceSansPro';
    src: url('/fonts/SourceSansPro/SourceSansPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'SourceSansPro';
    src: url('/fonts/SourceSansPro/SourceSansPro-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'SourceSansPro';
    src: url('/fonts/SourceSansPro/SourceSansPro-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'SourceSansPro';
    src: url('/fonts/SourceSansPro/SourceSansPro-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
  }
`;

export const fonts = css`
  ${sourceSansPro}
`;
