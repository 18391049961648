import { Styles, theme, constants } from '@/styles';

export const styles: Styles = {
  position: 'relative',

  '.error-message': {
    color: theme.error,
    fontSize: constants.fontSize.p,
    minHeight: 14,
    marginTop: 5,
    marginLeft: 195,
  },

  '.overlay': {
    position: 'absolute',
    width: 1200,
    height: '100%',
    zIndex: 999,
    cursor: 'not-allowed',
  },
};
