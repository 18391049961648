import { createSelector } from 'reselect';

import { ModerationListVM } from '@/models/moderation';
import { getDataStatus } from '@/common/utils';
import { moderationReducer } from './reducers';

type ModerationList = moderationReducer['moderation']['list'];
type MakeGetModerationListData = ModerationListVM['list'];
type MakeGetModerationListPaginationData = ModerationListVM['listPaginationData'] | undefined;
type MakeGetModerationListLength = { length: number };
type MakeGetModerationListFilters = ModerationList['params'];

const getModerationList = (state: moderationReducer) => state.moderation.list;

export const makeGetModerationList = () =>
  createSelector<moderationReducer, ModerationList, MakeGetModerationListData>(getModerationList, ({ data }) => (data === undefined ? [] : data.list));

export const makeGetModerationListLength = () =>
  createSelector<moderationReducer, ModerationList, MakeGetModerationListLength>(getModerationList, ({ data }) => ({ length: (data?.list || []).length }));

export const makeGetModerationListStatus = () =>
  createSelector<moderationReducer, ModerationList, ReturnType<typeof getDataStatus>>(getModerationList, getDataStatus);

export const makeGetModerationListPaginationData = () =>
  createSelector<moderationReducer, ModerationList, MakeGetModerationListPaginationData>(getModerationList, ({ data }) => data?.listPaginationData);

export const makeGetModerationListFilters = () =>
  createSelector<moderationReducer, ModerationList, MakeGetModerationListFilters>(getModerationList, ({ params }) => params);

const getModerationDetails = (state: moderationReducer) => state.moderation.details;

type ModerationDetails = moderationReducer['moderation']['details'];
type MakeGetModerationDetailsData = ModerationDetails['data'];

export const makeGetModerationDetailsData = () =>
  createSelector<moderationReducer, ModerationDetails, MakeGetModerationDetailsData>(getModerationDetails, ({ data }) => data);

export const makeGetModerationDetailsStatus = () =>
  createSelector<moderationReducer, ModerationDetails, ReturnType<typeof getDataStatus>>(getModerationDetails, getDataStatus);
