import { AutocompleteOption } from '@/common/components/autocomplete';
import { Enum } from '@/common/utils/common';
import { ApiPagination, PageableListResponse, PageableListVM } from '@/common/utils/pageable';
import { SelectOption, CustomFile } from '@/common/components/form-controls-deprecated';
import { ContentFragmentTypes } from '@/models/tours';

export type ToursParams = ApiPagination<{
  organisationId?: AutocompleteOption;
  text?: string;
  categoryIds?: string[];
  ageRecommendations?: number[];
  createdBy?: AutocompleteOption;
  tagIds?: string[];
  statuses?: string[];
  types?: string[];
}>;

type CreatedBy = {
  id: string;
  displayName: string;
  email: string;
};

type ToursEntityResponse = {
  id: string;
  name: string;
  locality: string;
  type: string;
  organisation: {
    id: string;
    name: string;
    organisationId: string;
  };
  createdBy: CreatedBy;
  status: string;
  deletable: boolean;
  paymentConfig: {
    paymentModel: string;
    priceProduct: {
      free: boolean;
      id: string;
    };
  };
};

export type ToursListResponse = PageableListResponse<ToursEntityResponse>;

export interface ToursElementVM extends ToursEntityResponse {
  email: string;
  author: string;
  authorId: string;
  organisationName: string;
  organisationId: string;
  price: string;
  status: string;
}

export class ToursElementVM {
  constructor({ organisation, createdBy, paymentConfig, ...props }: ToursEntityResponse) {
    Object.assign(this, {
      ...props,
      author: createdBy ? createdBy.displayName : '',
      authorId: createdBy ? createdBy.id : '',
      email: createdBy ? createdBy.email : '',
      organisationName: organisation ? organisation.name : '',
      organisationId: organisation ? organisation.id : '',
      price: paymentConfig.paymentModel === 'FREE' ? 'FREE' : paymentConfig.priceProduct.id,
    });
  }
}

export class ToursListVM extends PageableListVM<ToursElementVM, ToursListResponse> {}

export type paymentConfigType = {
  paymentModel: string;
  priceProductId: string | undefined;
  discountProductId: string | undefined;
  tipProductIds: string[] | undefined;
};

export type CreateTourParams = {
  name: string;
  locality: string;
  descriptionShort: string;
  descriptionLong: string;
  distance: number;
  durationMin: number;
  durationMax: number;
  paymentConfig: paymentConfigType;
  categoryIds: string[];
};

export type UpdateTourParams = CreateTourParams & {
  storyIds: string[];
};

export type UploadTourFileResponse = {
  accelerometer: null;
  captureTime: null;
  createdAt: string;
  duration: null;
  gyroscope: null;
  id: string;
  likeCounter: number;
  location: null;
  magnetometer: null;
  provider: null;
  providerUrl: null;
  sizeOriginal: number;
  sizeResized: number;
  sourceApp: null;
};

export type StoryImageUploadResponse = {
  accelerometer: string;
  captureTime: string;
  createdAt: string;
  duration: string;
  gyroscope: string;
  id: string;
  likeCounter: number;
  location: string;
  magnetometer: string;
  provider: string;
  providerUrl: string;
  sizeOriginal: number;
  sizeResized: number;
  sourceApp: string;
  type: string;
  urlOriginal: string;
  urlResized: string;
  validationStatus: string;
};

export type AddStoryParams = {
  name: string;
  coverImageId?: File[] | CustomFile[];
  address: {
    locality: string;
    streetName: string;
  };
  location: {
    type: string;
    coordinates: number[];
  };
  descriptionShort: string;
  contentFragments: ContentFragmentTypes[];
};

export type EditStoryParams = AddStoryParams;
type CoverImageType = {
  id: string;
  type: string;
  urlOrginal: string;
  urlResized: string;
};

type OrganisationType = {
  id: string;
  name: string;
};

export type StoryType = {
  id: string;
  name: string;
  storyNumber: number;
};

export interface StoreElementVM extends StoryType {}

export type CommonTagType = {
  id: string;
  displayName: string;
  imageUrl?: string;
};

type TourRedeemStatistics = {
  grossIncome: number;
  totalRedeems: number;
};

type AverageRaring = {
  averageRating: number;
  ratingCount: number;
};

export const TourStatus = Enum('ARCHIVED', 'DRAFT', 'PREVIEWABLE', 'PUBLISHED', 'DELETED');
export type TourStatus = Enum<typeof TourStatus>;

export const TourType = Enum('ACTIVITY', 'CHALLENGE', 'TOUR');
export type TourType = Enum<typeof TourType>;

export type TourDetailsResponse = {
  id: string;
  name: string;
  locality: string;
  coverImage: CoverImageType;
  createdBy: CreatedBy & {
    fullName: string;
  };
  routeFileId: string;
  descriptionLong: string;
  descriptionShort: string;
  durationMin: number;
  durationMax: number;
  distance: number;
  ageRecommendation: number | undefined;
  paymentConfig: {
    paymentModel: string;
    priceProduct: {
      id: string | undefined;
      free: boolean;
    };
    discountProductId: string | undefined;
    tipProductIds: string[] | undefined;
  };
  organisation: OrganisationType;
  redeemStatistics: TourRedeemStatistics;
  stories: StoryType[];
  categories: CommonTagType[];
  tags: CommonTagType[];
  averageRating: AverageRaring;
  status: TourStatus;
  deletable: boolean;
  type: TourType;
};

export interface TourDetailsVM extends TourDetailsResponse {}

export class TourDetailsVM {
  constructor({ tags, ...props }: TourDetailsResponse) {
    Object.assign(this, {
      ...props,
      tags: tags ? tags : [],
    });
  }
}

export type TourThumbnailResponse = {
  height: number;
  url: string;
  width: number;
};

export interface TourThumbnailVM extends TourThumbnailResponse {}

export class TourThumbnailVM {
  constructor(props: TourThumbnailResponse) {
    Object.assign(this, props);
  }
}

type TourStatusesResponse = {
  id: string;
  displayName: string;
};

export type TourStatusesListResponse = TourStatusesResponse[];

export interface TourStatusesDictionaryElementVM extends SelectOption {}
export class TourStatusesDictionaryElementVM {
  constructor({ id, displayName }: TourStatusesResponse) {
    this.value = id;
    this.label = displayName;
  }
}

export interface TourStatusesDictionaryVM {
  list: TourStatusesDictionaryElementVM[];
}

export class TourStatusesDictionaryVM {
  constructor(list: TourStatusesListResponse) {
    this.list = (list || []).map(element => new TourStatusesDictionaryElementVM(element));
  }
}

type TourTypesResponse = {
  id: string;
  displayName: string;
};

export type TourTypesListResponse = TourTypesResponse[];

export interface TourTypesDictionaryElementVM extends SelectOption {}
export class TourTypesDictionaryElementVM {
  constructor({ id, displayName }: TourTypesResponse) {
    this.value = id;
    this.label = displayName;
  }
}

export interface TourTypesDictionaryVM {
  list: TourTypesDictionaryElementVM[];
}

export class TourTypesDictionaryVM {
  constructor(list: TourTypesListResponse) {
    this.list = (list || []).map(element => new TourTypesDictionaryElementVM(element));
  }
}
