import { createMuiTheme } from '@material-ui/core/styles';

import { Styles, theme, constants } from '@/styles';
import { MuiStyleFunction } from '@/styles/models';

export const autocompleteStyle: Styles = {
  '.autocomplete-wrapper': {
    display: 'flex',
    flexDirection: 'row',

    '.MuiInputBase-root': {
      height: 40,
    },

    fieldset: {
      border: `1px solid ${theme.backgroundSixth}`,
    },
  },

  '.autocomplete': {
    width: '100%',
  },

  '.autocomplete-button': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },

  '.autocomplete-input': {
    position: 'relative',
    zIndex: 0,
  },
};

export const autocompleteListStyle: MuiStyleFunction = ({ theme }) =>
  createMuiTheme({
    overrides: {
      // @ts-ignore
      MuiAutocomplete: {
        popper: {
          zIndex: 1,
          boxShadow: constants.dialogShadow,
        },

        popperDisablePortal: {
          marginTop: 41,
        },

        popupIndicator: {
          '& > span': {
            '& > i': {
              color: `${theme.primary} !important`,
              fontSize: '25px !important',
              maxHeight: 25,
            },
          },
        },

        clearIndicator: {
          '& > span': {
            '& > svg': {
              color: `${theme.primary} !important`,
              fontSize: '20px !important',
              maxHeight: 25,
            },
          },
        },

        listbox: {
          backgroundColor: theme.backgroundPrimary,
          padding: `${constants.offset.xsmall}px 0`,
        },

        paper: {
          backgroundColor: theme.backgroundPrimary,
          margin: 0,
          position: 'relative',

          '& > *': {
            color: `${theme.textFourth} !important`,
          },
        },

        option: {
          backgroundColor: theme.backgroundPrimary,

          '& > *': {
            color: theme.textPrimary,
          },

          '&:hover': {
            backgroundColor: theme.backgroundNinth,
          },

          '&[aria-selected="true"]': {
            backgroundColor: `${theme.primary} !important`,

            '& > *': {
              color: theme.textWhite,
            },

            '&:hover': {
              '& > *': {
                color: theme.primaryHover,
              },
            },
          },

          '&[data-focus="true"]': {
            backgroundColor: theme.backgroundPrimaryHover,

            '& > *': {
              color: theme.primaryHover,
            },
          },
        },
      },
    },
  });
