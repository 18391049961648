import { unstable_createMuiStrictModeTheme } from '@material-ui/core';

import { constants } from '@/styles';
import { MuiStyleFunction } from '@/styles/models';

export const dropdownTheme: MuiStyleFunction = ({ theme }) =>
  unstable_createMuiStrictModeTheme({
    overrides: {
      MuiMenuItem: {
        root: {
          fontFamily: constants.fontFamily.primary,
          backgroundColor: theme.backgroundPrimaryHover,
          color: theme.textPrimary,

          '&:focus': {
            backgroundColor: theme.backgroundPrimary,
          },

          '&:hover': {
            backgroundColor: theme.backgroundPrimary,
            color: theme.primaryHover,
          },

          '&.Mui-selected': {
            backgroundColor: theme.primaryActive,
            color: theme.textPrimary,

            '&:focus': {
              backgroundColor: theme.primaryFocus,
            },

            '&:hover': {
              color: theme.primaryHover,
            },
          },
        },
      },
      MuiMenu: {
        list: {
          padding: `${constants.offset.xsmall}px 0`,
          backgroundColor: theme.backgroundPrimaryHover,
        },

        paper: {
          borderRadius: 0,
        },
      },
    },
  });
