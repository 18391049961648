import { ActionType, Action, createAction } from 'typesafe-actions';

import { PlaceCategoriesDictionaryVM, ThingsToDoDetailsVM, ThingsToDoListVM, ThingsToDoParams } from '@/features/content/models/things-to-do';
import { createDictionaryActions, createFetchActions } from '@/common/utils/store';

export const thingsToDoActions = {
  list: createFetchActions('FETCH_THINGS_TO_DO_LIST_REQUEST', 'FETCH_THINGS_TO_DO_LIST_SUCCESS', 'FETCH_THINGS_TO_DO_LIST_FAILURE')<
    ThingsToDoParams,
    ThingsToDoListVM
  >(),
  clearThingsToDoList: createAction('CLEAR_THINGS_TO_DO_LIST')(),
  details: createFetchActions('FETCH_THINGS_TO_DO_DETAILS_REQUEST', 'FETCH_THINGS_TO_DO_DETAILS_SUCCESS', 'FETCH_THINGS_TO_DO_DETAILS_FAILURE')<
    string,
    ThingsToDoDetailsVM
  >(),
  placeCategories: createDictionaryActions(
    'FETCH_PLACE_CATEGORIES_DICTIONARY_REQUEST',
    'FETCH_PLACE_CATEGORIES_DICTIONARY_SUCCESS',
    'FETCH_PLACE_CATEGORIES_DICTIONARY_FAILURE',
    'FETCH_PLACE_CATEGORIES_DICTIONARY_CANCEL',
    'FETCH_PLACE_CATEGORIES_DICTIONARY_SILENT_REQUEST'
  )<undefined, PlaceCategoriesDictionaryVM>(),
  clearDetails: createAction('CLEAR_THINGS_TO_DO_DETAILS')(),
};

export type thingsToDoActions = Action | ActionType<typeof thingsToDoActions>;
