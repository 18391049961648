import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const chipsStyles: StyleFunction = ({ theme }) => ({
  '.chips': {
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: constants.borderRadius.small,
    border: `1px solid ${theme.borderPrimary}`,
    padding: `${constants.offset.small}px ${constants.offset.small}px 0`,
    backgroundColor: theme.backgroundPrimary,
    minHeight: 62,
  },

  '.chips-input': {
    flexGrow: 1,
    border: 'none',
    width: 'initial',
    marginBottom: constants.offset.small,
  },

  '.chips-message': {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: constants.offset.xsmall,

    '&__error': {
      color: theme.error,
    },

    '&__max-length': {
      color: theme.textThird,
    },
  },
});

export const chipStyles: StyleFunction = ({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  maxWidth: '100%',

  padding: `${constants.offset.xsmall}px ${constants.offset.large}px`,
  paddingRight: constants.offset.small,
  marginBottom: constants.offset.small,
  marginRight: constants.offset.small,

  color: theme.textWhite,
  borderRadius: constants.borderRadius.small,

  '.chip': {
    color: theme.textWhite,
    fontWeight: 600,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  '.chip-edit': {
    padding: `0 ${constants.offset.xsmall}px`,
    lineHeight: '28px',

    '&__correct': {
      backgroundColor: theme.primaryFocus,
      color: theme.textWhite,
    },

    '&__error': {
      backgroundColor: theme.errorFocus,

      '&:focus': {
        borderColor: theme.errorActive,
        boxShadow: `0 0 1px 1px ${theme.errorActive} inset`,
      },
    },
  },

  '.chip-icon': {
    marginLeft: constants.offset.small,

    '&:focus': {
      boxShadow: `0px 0px 0px 2px ${theme.backgroundPrimaryFocus} !important`,
    },

    '& > i': {
      fontSize: constants.fontSize.h2,
    },

    '&__edit': {
      '& > i': {
        fontSize: constants.fontSize.h4,
      },
    },

    '&__remove': {
      marginLeft: constants.offset.xsmall,
    },
  },
});
