import { Enum } from '@/common/utils';
import { ApiPagination, PageableListResponse, PageableListVM } from '@/common/utils/pageable';

export const ZoomLevels = Enum(Array.from({ length: 23 }, (_, i) => i.toString()).join(','));

export const MapType = Enum('STANDARD', 'TOPO', 'AERIAL');
export type MapType = Enum<typeof MapType>;

export type MapAttributionListEntityResponse = {
  coverage: string;
  id: string;
  name: string;
  organisation: string;
  mapType: MapType;
};

export type MapAttributionListRequest = ApiPagination<{
  searchPhrase?: string;
  mapType?: MapType;
}>;

export type MapAttributionListResponse = PageableListResponse<MapAttributionListEntityResponse>;

export interface MapAttributionListElementVM extends MapAttributionListEntityResponse {}
export class MapAttributionListElementVM {
  constructor(props: MapAttributionListEntityResponse) {
    Object.assign(this, props);
  }
}

export class MapAttributionListVM extends PageableListVM<MapAttributionListElementVM, MapAttributionListResponse> {}

export const BoundingBox = Enum('WHOLE_WORLD', 'SPECIFIC');
export type BoundingBox = Enum<typeof BoundingBox>;

export const ZoomLevel = Enum('WHOLE_RANGE', 'SPECIFIC');
export type ZoomLevel = Enum<typeof ZoomLevel>;

export type MapAttributionDetailsResponse = {
  boundingBox: BoundingBox;
  coordinates: {
    maxX: number;
    maxY: number;
    minX: number;
    minY: number;
  };
  coverage: string;
  id: string;
  mapType: MapType;
  name: string;
  organisation: string;
  statement: string;
  statementUrl: string;
  zoom: {
    maxZoom: number;
    minZoom: number;
  };
  zoomLevel: ZoomLevel;
};

export interface MapAttributionDetailsVM extends MapAttributionDetailsResponse {}
export class MapAttributionDetailsVM {
  constructor(props: MapAttributionDetailsResponse) {
    Object.assign(this, props);
  }
}

export type MapAttributionParams = {
  boundingBox: BoundingBox;
  coordinates: {
    maxX: string;
    maxY: string;
    minX: string;
    minY: string;
  } | null;
  coverage: string;
  mapType: MapType;
  name: string;
  organisation: string;
  statement: string;
  statementUrl: string;
  zoom: {
    maxZoom: number;
    minZoom: number;
  } | null;
  zoomLevel: ZoomLevel;
};
