import { Enum } from '@/common/utils';
import { RoutePaths } from '../../navigation.models';
import { unauthenticatedRoutePaths } from './unauthenticated';

export type LogoutReasons = Enum<typeof LogoutReasons>;
export const LogoutReasons = Enum('PROFILE_ERROR', 'NOT_AUTHORIZED', 'COMMON_ERROR', 'LOGOUT');

export type authenticationRoutes = Enum<typeof authenticationRoutes>;
export const authenticationRoutes = Enum('LOGIN', 'ACCOUNT_HUB', 'CONSENTS', 'USER_INVITATION');

export const authenticationRoutePaths: RoutePaths<authenticationRoutes> = {
  [authenticationRoutes.LOGIN]: `${unauthenticatedRoutePaths.AUTHENTICATION}login`,
  [authenticationRoutes.ACCOUNT_HUB]: `${unauthenticatedRoutePaths.AUTHENTICATION}account-hub/:policy?`,
  [authenticationRoutes.CONSENTS]: `${unauthenticatedRoutePaths.AUTHENTICATION}consents`,
  [authenticationRoutes.USER_INVITATION]: `${unauthenticatedRoutePaths.AUTHENTICATION}user_invitation`,
};

export type authenticationRouteParams = {
  [authenticationRoutes.LOGIN]: { reason: LogoutReasons };
  [authenticationRoutes.USER_INVITATION]: { email: string };
};
