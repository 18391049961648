import { SelectOption } from '@/common/components/form-controls-deprecated';

type ContextTypesResponse = {
  id: string;
  displayName: string;
};

export type ContextTypesListResponse = ContextTypesResponse[];

export interface ContextTypesDictionaryElementVM extends SelectOption {}
export class ContextTypesDictionaryElementVM {
  constructor({ id, displayName }: ContextTypesResponse) {
    this.value = id;
    this.label = displayName;
  }
}

export interface ContextTypesDictionaryVM {
  list: ContextTypesDictionaryElementVM[];
}

export class ContextTypesDictionaryVM {
  constructor(list: ContextTypesListResponse) {
    this.list = (list || []).map(element => new ContextTypesDictionaryElementVM(element));
  }
}
