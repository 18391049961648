import { ComponentType } from 'react';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { FieldArrayRenderProps } from 'formik';

import { Enum } from '@/common/utils';
import { ContentFragmentType } from '@/models/tours';

export type EditorCardProps = {
  id: string;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  item: EditorCard;
  index: number;
  fieldArrayRender: FieldArrayRenderProps;
};

export type EditorCard = {
  type: ContentFragmentType;
  title: string;
  iconName: string;
  content: ComponentType<EditorCardProps>;
};

export type EditorCardList = EditorCard[];

export type CONTROL_LIST = Enum<typeof CONTROL_LIST>;
export const CONTROL_LIST = Enum('entityList');

export type EditorValue<T = string, P = any> = {
  id: T | string;
  card: EditorCard;
  payload: P;
};

export type ControlListDefs = {
  [CONTROL_LIST.entityList]: EditorValue[];
};
