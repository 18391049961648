import { switchMap, filter } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { getStatusesData } from '@/features/management/services/moderation/dictionaries';
import { dictionaryEffectHelper } from '@/common/utils';
import { statusesActions } from './actions';

const getStatusesList: Epic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(statusesActions.request)),
    switchMap(() => {
      const state = state$.value.statuses;
      return dictionaryEffectHelper(() => getStatusesData(), state, statusesActions);
    })
  );

export const statusesEpics = [getStatusesList];
