import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';

import { CARD_BASE_HEIGHT } from '../../../../constants';

export const editorToolbarCardStyle: StyleFunction = ({ theme }) => ({
  '&:nth-of-type(2n+1)': {
    '.card': {
      backgroundColor: theme.backgroundPrimary,
    },
  },

  '&:nth-of-type(2n)': {
    '.card': {
      backgroundColor: theme.backgroundSecondary,
    },
  },

  '.card': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: CARD_BASE_HEIGHT,
    padding: `${constants.offset.xsmall}px ${constants.offset.medium}px`,
    transitionProperty: 'opacity, background-color',
    transitionDuration: '.2s',

    '&--is-dragging': {
      opacity: 0.9,
      backgroundColor: `${theme.primaryHover} !important`,
    },

    '&--clone': {},

    '&__icon': {
      fontSize: constants.fontSize.icon,
      color: theme.brandPrimary,
    },

    '&__title': {
      fontSize: constants.fontSize.medium,
      lineHeight: '28px',
      color: theme.brandPrimary,
      marginLeft: constants.offset.small,
    },

    '&.disabled': {
      opacity: 0.5,
    },
  },
});
