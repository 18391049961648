import { combineReducers } from 'redux';

import { FeatureStateType } from '@/store/helpers';
import { tourAnalyticsListReducer } from './tour-analytics';

export type tourAnalyticsReducer = FeatureStateType<typeof tourAnalyticsReducer>;

export const tourAnalyticsReducer = {
  tourAnalytics: combineReducers({
    ...tourAnalyticsListReducer,
  }),
};
