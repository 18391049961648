import { map } from 'rxjs/operators';

import { userProfile } from '@/core/services/authentication';
import { Subject } from '@/core/models/user-roles';
import { toast } from '@/core/services/toast';
import { checkUserPermissions, CheckUserPermissionScopes } from '@/common/utils';
import { authenticatedRoutes, authenticatedRoutePaths } from '../routes';
import { Guard } from '../navigation.models';

export const makePermissionGuard: <T extends Subject>(permission: CheckUserPermissionScopes<T>, id?: string) => Guard =
  (permission, id) =>
  ({ store }) =>
    userProfile({ store }).pipe(
      map(data => {
        if (data !== undefined && checkUserPermissions(permission, data?.roles || [])) {
          return id !== undefined ? { guardProps: { id } } : null;
        }

        toast.show({ type: 'warning', text: 'error.accessDenied' });
        return { redirectUrl: authenticatedRoutePaths[authenticatedRoutes.DASHBOARD] };
      })
    );
