const MILES_TO_KILOMETERS_RATIO = 1.609344;

export const calculateDistance = (distance: string | undefined, unit: string | undefined) => {
  if (distance === undefined || unit === undefined) {
    return 0;
  }

  if (unit === 'miles') {
    return parseFloat(distance) * MILES_TO_KILOMETERS_RATIO;
  }
  return parseFloat(distance);
};

export const calculateTime = (time: string | undefined, unit: string | undefined, timeInFraction: boolean = false) => {
  if (time === undefined || unit === undefined) {
    return 0;
  }

  if (unit === 'hr') {
    if (timeInFraction) {
      return parseFloat(time) * 60;
    }

    return Math.round(parseFloat(time) * 60);
  }
  return parseInt(time, 10);
};
