import { createReducer } from 'typesafe-actions';

import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { FeatureStateType } from '../../helpers';
import { routesActions } from '../actions';

export type routesTotalCountState = FetchState<number>;

const INITIAL_STATE: routesTotalCountState = getFetchInitialState<number>();

const reducer = createReducer<routesTotalCountState, routesActions>(INITIAL_STATE)
  .handleAction(routesActions.totalCount.request, state => fetchReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(routesActions.totalCount.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(routesActions.totalCount.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload));

export const routesTotalCountReducer = { totalCount: reducer };
export type routesTotalCountReducer = FeatureStateType<typeof routesTotalCountReducer>;
