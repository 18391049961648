import dayjs, { Dayjs } from 'dayjs';

import { ApiPagination, PageableListResponse, PageableListVM } from '@/common/utils/pageable';
import { AutocompleteOption } from '@/common/components/autocomplete';
import { DATE_YEAR_MONTH_FORMAT } from '@/common/utils';
import { OrganisationElementVM } from '@/models/organisations';

export type TourAnalyticsParams = ApiPagination<{
  dateFrom?: Dayjs;
  dateTo?: Dayjs;
  title?: string;
  minPrice?: number;
  maxPrice?: number;
  organisationId?: AutocompleteOption;
  createdAt?: string;
}>;

type AverageRaring = {
  averageRating: number;
  ratingCount: number;
};

type TourRedeems = {
  createdAt: string;
  organisationId: string;
  redeems: number;
  targetId: string;
  title: string;
  productPrice: number;
  totalPrice: number;
};

type Author = {
  displayName: string;
  email: string;
  id: string;
};

export type TourAnalyticsEntityResponse = {
  createdBy: Author;
  averageRating: AverageRaring;
  organisationDetails: OrganisationElementVM;
  organisationName: string;
  tourRedeems: TourRedeems;
};

export type TourAnalyticsListResponse = PageableListResponse<TourAnalyticsEntityResponse>;

export interface TourAnalyticsElementVM {
  title: TourAnalyticsEntityResponse['tourRedeems']['title'];
  createdBy: TourAnalyticsEntityResponse['createdBy'];
  organisation: TourAnalyticsEntityResponse['organisationDetails'];
  redeems: TourAnalyticsEntityResponse['tourRedeems']['redeems'];
  ratingCount: TourAnalyticsEntityResponse['averageRating']['ratingCount'];
  averageRating: TourAnalyticsEntityResponse['averageRating']['averageRating'];
  productPrice: TourAnalyticsEntityResponse['tourRedeems']['productPrice'];
  totalPrice: TourAnalyticsEntityResponse['tourRedeems']['totalPrice'];
  targetId: TourAnalyticsEntityResponse['tourRedeems']['targetId'];
  createdAt: TourAnalyticsEntityResponse['tourRedeems']['createdAt'];
}

export class TourAnalyticsElementVM {
  constructor({
    createdBy,
    averageRating,
    organisationDetails,
    tourRedeems: { redeems, targetId, title, totalPrice, productPrice, createdAt },
  }: TourAnalyticsEntityResponse) {
    Object.assign<this, TourAnalyticsElementVM>(this, {
      targetId,
      title,
      redeems,
      productPrice,
      totalPrice,
      organisation: organisationDetails,
      createdBy,
      ratingCount: averageRating?.ratingCount,
      averageRating: averageRating?.averageRating,
      createdAt: createdAt ? dayjs(createdAt).format(DATE_YEAR_MONTH_FORMAT) : '',
    });
  }
}

export class TourAnalyticsListVM extends PageableListVM<TourAnalyticsElementVM, TourAnalyticsListResponse> {}
