import { createSelector } from 'reselect';

import { getDataStatus } from '@/common/utils';
import { rolesReducer } from './reducer';

type MakeGetRolesData = rolesReducer['roles']['data'];

const getRoles = (state: rolesReducer) => state.roles;

export const makeGetRolesData = () => createSelector<rolesReducer, rolesReducer['roles'], MakeGetRolesData>(getRoles, ({ data }) => data);
export const makeGetRolesStatus = () => createSelector<rolesReducer, rolesReducer['roles'], ReturnType<typeof getDataStatus>>(getRoles, getDataStatus);
