import { createReducer } from 'typesafe-actions';

import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { UsersAppsListVM } from '@/features/accounts/models/users';
import { FeatureStateType } from '../../helpers';
import { usersActions } from '../actions';

export type usersAppsState = FetchState<UsersAppsListVM>;

const INITIAL_STATE: usersAppsState = getFetchInitialState<UsersAppsListVM>();

const reducer = createReducer<usersAppsState, usersActions>(INITIAL_STATE)
  .handleAction(usersActions.usersApps.request, state => fetchReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(usersActions.usersApps.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(usersActions.usersApps.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(usersActions.clearUsersApps, () => ({ ...INITIAL_STATE }));

export const userAppsReducer = { usersApps: reducer };
export type userAppsReducer = FeatureStateType<typeof userAppsReducer>;
