import { of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Store } from 'redux';

import { getEnv, genericAxiosRetry } from '@/common/utils';
import { LoggedUserDataResponse, LoggedUserDataVM, UserRoleResponse, UserRoleVM } from '../../models/authentication';
import { httpClient } from '../http-client';
import { msal } from './msal';

const config = getEnv();

const USER_PROFILE_ENDPOINT = `${config.REACT_APP_API_URL}/oauth/me/profile`;
const USER_ROLE_ENDPOINT = `${config.REACT_APP_API_URL}/user-account-api/v1/admin/users/me/role`;
const PROFILE_EXCLUDED_STATUS_CODES = [401, 403];

export const getUserProfile = () =>
  httpClient()
    .authorized.get<LoggedUserDataResponse>(USER_PROFILE_ENDPOINT)
    .pipe(
      genericAxiosRetry({ excludedStatusCodes: PROFILE_EXCLUDED_STATUS_CODES }),
      catchError(() => of({ status: 403, data: undefined })),
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return {
            status,
            userData: new LoggedUserDataVM(data),
          };
        }

        return undefined;
      })
    );

export const getUserRoleData = () =>
  httpClient()
    .authorized.get<UserRoleResponse>(USER_ROLE_ENDPOINT)
    .pipe(
      catchError(() => of({ status: 403, data: undefined })),
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return {
            status,
            role: new UserRoleVM(data),
          };
        }

        return undefined;
      })
    );

type UserProfileParams = {
  store: Store;
};

export const userProfile = ({ store }: UserProfileParams) => {
  return msal.getSession().pipe(
    switchMap(authenticationResult => {
      if (authenticationResult === undefined) {
        return of<LoggedUserDataVM | undefined>(undefined);
      }

      return msal.userProfile({ store });
    })
  );
};
