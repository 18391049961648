import { constants, theme, rgba, Styles } from '@/styles';

export const topBarStyles: Styles = {
  display: 'flex',
  flexDirection: 'column',

  '.topbar': {
    padding: `0 ${constants.offset.xlarge}px`,
    backgroundColor: theme.backgroundAccent,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 60,
  },

  '.column': {
    '&__left': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingRight: constants.leftPanel.offset,
      flexBasis: constants.leftPanel.width,
      flexShrink: 0,
      flexGrow: 0,
      whiteSpace: 'nowrap',

      '.text': {
        fontWeight: 300,
        padding: `0 ${constants.offset.xsmall}px`,
      },
    },

    '&__right': {
      display: 'flex',
      flexGrow: 1,
      flexShrink: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',

      '.row': {
        display: 'flex',
        flexDirection: 'row',
      },
    },
  },

  '.app-logo': {
    paddingRight: constants.offset.large,

    '&__image': {
      display: 'block',
    },
  },

  '.app-name-wrapper': {
    paddingLeft: constants.offset.small,
    maxWidth: 100,
    borderLeft: `1px solid ${rgba(theme.borderThird, 0.5)}`,
  },

  '.app-name': {
    color: theme.textWhite,
    opacity: 0.5,
    fontSize: constants.fontSize.h6,
    whiteSpace: 'normal',
  },

  '.app-version': {
    color: theme.textWhite,
    opacity: 0.5,
    fontSize: constants.fontSize.p,
  },

  '.dropdown-icon': {
    color: theme.textWhite,
    marginLeft: constants.offset.xsmall,
  },

  '.topbar-dropdown': {
    marginRight: constants.offset.large,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  '.topbar-button': {
    backgroundColor: theme.primary,
    color: theme.textWhite,
    textDecoration: 'none',
    padding: constants.offset.medium,
    transition: 'background-color .15s ease-in',
    cursor: 'pointer',

    '&:disabled': {
      backgroundColor: theme.backgroundSecondaryDisabled,
      cursor: 'not-allowed',
    },

    '&:hover:not(:disabled)': {
      backgroundColor: theme.primaryHover,
    },

    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${theme.primaryFocus} inset`,
      outline: 0,
    },
  },
};
