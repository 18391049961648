import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const accordionCardContentStyles: StyleFunction = ({ theme }) => ({
  background: theme.backgroundPrimary,
  paddingLeft: constants.offset.medium,
  paddingBottom: constants.offset.medium,

  '.content': {
    minHeight: 150,
    padding: `0 ${constants.offset.small}px ${constants.offset.small}px`,
    backgroundColor: theme.backgroundPrimary,
    borderRadius: `0 0 0 ${constants.borderRadius.small}px`,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.borderSecondary,
    transitionProperty: 'border-color, background-color',
    transitionDuration: '.2s',
    borderTop: 'none',
    borderRight: 'none',

    '&--dragging-over': {
      borderColor: 'green',
      backgroundColor: theme.backgroundThirdFocus,
    },
  },
});
