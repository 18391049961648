import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const tableStyles: StyleFunction = ({ theme }) => ({
  width: '100%',

  table: {
    width: 'inherit',
    minWidth: 'inherit',
    borderCollapse: 'collapse',
    borderSpacing: 0,
  },

  thead: {
    tr: {
      th: {
        color: theme.primary,
        textAlign: 'left',
        padding: `${constants.offset.medium}px ${constants.offset.small}px `,
        paddingTop: 0,
        borderBottom: `2px solid ${theme.primary}`,
        lineHeight: 2,
        whiteSpace: 'nowrap',

        '.header-wrapper': {
          color: theme.primary,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          fontWeight: 600,
          minHeight: 25,

          '&__align-left': {
            justifyContent: 'flex-end',

            '& > button': {
              position: 'absolute',
            },
          },
        },

        '.sorting-indicator': {
          marginLeft: constants.offset.xsmall,
          fontSize: constants.fontSize.icon,
          color: theme.primary,
          height: 20,

          '::before': {
            maxHeight: 20,
            position: 'relative',
            bottom: 10,
          },

          '&--sortable': {
            color: theme.textSecondary,
          },
        },
      },
    },
  },

  tbody: {
    tr: {
      '&:nth-of-type(even)': {
        td: {
          backgroundColor: theme.backgroundSecondary,
        },
      },

      '&.clickable': {
        cursor: 'pointer',

        '&:hover > *': {
          color: theme.primaryHover,
        },
        // '&:active > *': {
        //   backgroundColor: theme.primaryActive,
        //   color: theme.textWhite,
        // },
      },

      '&.selected > td': {
        backgroundColor: theme.primary,
        color: theme.textWhite,
      },

      td: {
        color: theme.textPrimary,
        padding: constants.offset.small,
        lineHeight: 1.75,
        whiteSpace: 'break-spaces',

        '&.drag-handle': {
          width: 30,

          '&.hidden': {
            display: 'none',
          },

          '.reorder': {
            color: theme.primary,
            fontSize: constants.fontSize.icon,
          },

          '&:first-of-type': {
            paddingLeft: constants.offset.large,
            paddingRight: constants.offset.large,
          },
        },
      },
    },
  },

  '.row-selection-checkbox': {
    '& > *': {
      width: 20,
      height: 26,
      bottom: -1,
    },
  },
});

export const loadMoreStyles: StyleFunction = ({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: constants.offset.xlarge,
  marginBottom: constants.offset.xlarge,
  minHeight: 50,

  button: {
    borderRadius: constants.borderRadius.small,
    cursor: 'pointer',

    ':focus': {
      boxShadow: `0px 0px 0px 3px ${theme.primaryFocus}`,
    },
  },

  '.label': {
    color: theme.primary,
  },

  '.load-more-button': {
    backgroundColor: 'transparent',
  },

  '.icon': {
    color: theme.primary,
    fontSize: constants.fontSize.h2,
  },
});
