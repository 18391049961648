import React, { FC } from 'react';
import cn from 'classnames';

import { Field, FieldProps } from '../field';
import { TimeInput } from './time-input';

type FieldTimeInputProps = Omit<FieldProps, 'onChange' | 'value' | 'children'>;

export const FieldTimeInput: FC<FieldTimeInputProps> = ({ name, className, label, labelIcon, isFocusOnLabelEnabled, ...props }) => (
  <Field name={name} label={label} labelIcon={labelIcon} isFocusOnLabelEnabled={false} className={className}>
    {({ field, meta, form }) => {
      const handleFieldBlur = () => form.setFieldTouched(name);

      const inputClassName = cn([{ error: meta.touched && meta.error }]);
      return <TimeInput {...props} className={inputClassName} form={form} {...field} onBlur={handleFieldBlur} />;
    }}
  </Field>
);
