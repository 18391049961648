import { createReducer } from 'typesafe-actions';

import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { TourStatusesDictionaryVM } from '@/features/content/models/tours';
import { FeatureStateType } from '../../helpers';
import { toursActions } from '../actions';

type statusesState = DictionaryState<TourStatusesDictionaryVM>;

const INITIAL_STATE: statusesState = getDictionaryInitialState<TourStatusesDictionaryVM>();

const reducer = createReducer<statusesState, toursActions>(INITIAL_STATE)
  .handleAction(toursActions.statuses.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(toursActions.statuses.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(toursActions.statuses.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(toursActions.statuses.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(toursActions.statuses.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const tourStatusesDictionaryReducer = { statuses: reducer };
export type tourStatusesDictionaryReducer = FeatureStateType<typeof tourStatusesDictionaryReducer>;
