import { createSelector } from 'reselect';

import { getDataStatus } from '@/common/utils';
import { ThingsToDoTagsListVM } from '@/features/content/models/things-to-do-tags';
import { thingsToDoTagsReducer } from './reducers';

type ThingsToDoTagsList = thingsToDoTagsReducer['thingsToDoTags']['list'];
type MakeGetThingsToDoTagsListData = ThingsToDoTagsListVM['list'];
type MakeGetThingsToDoTagsListPaginationData = ThingsToDoTagsListVM['listPaginationData'] | undefined;
type MakeGetThingsToDoTagsListLength = { length: number };
type MakeGetThingsToDoTagsListFilters = ThingsToDoTagsList['params'];

const getThingsToDoTagsList = (state: thingsToDoTagsReducer) => state.thingsToDoTags.list;

export const makeGetThingsToDoTagsList = () =>
  createSelector<thingsToDoTagsReducer, ThingsToDoTagsList, MakeGetThingsToDoTagsListData>(getThingsToDoTagsList, ({ data }) =>
    data === undefined ? [] : data.list
  );

export const makeGetThingsToDoTagsListLength = () =>
  createSelector<thingsToDoTagsReducer, ThingsToDoTagsList, MakeGetThingsToDoTagsListLength>(getThingsToDoTagsList, ({ data }) => ({
    length: (data?.list || []).length,
  }));

export const makeGetThingsToDoTagsListStatus = () =>
  createSelector<thingsToDoTagsReducer, ThingsToDoTagsList, ReturnType<typeof getDataStatus>>(getThingsToDoTagsList, getDataStatus);

export const makeGetThingsToDoTagsListPaginationData = () =>
  createSelector<thingsToDoTagsReducer, ThingsToDoTagsList, MakeGetThingsToDoTagsListPaginationData>(
    getThingsToDoTagsList,
    ({ data }) => data?.listPaginationData
  );

export const makeGetThingsToDoTagsListFilters = () =>
  createSelector<thingsToDoTagsReducer, ThingsToDoTagsList, MakeGetThingsToDoTagsListFilters>(getThingsToDoTagsList, ({ params }) => params);

const getThingsToDoTagDetails = (state: thingsToDoTagsReducer) => state.thingsToDoTags.details;

type GetThingsToDoTagDetails = thingsToDoTagsReducer['thingsToDoTags']['details'];
type MakeGetThingsToDoTagDetailsData = GetThingsToDoTagDetails['data'];

export const makeGetThingsToDoTagDetailsData = () =>
  createSelector<thingsToDoTagsReducer, GetThingsToDoTagDetails, MakeGetThingsToDoTagDetailsData>(getThingsToDoTagDetails, ({ data }) => data);
export const makeGetThingsToDoTagDetailsStatus = () =>
  createSelector<thingsToDoTagsReducer, GetThingsToDoTagDetails, ReturnType<typeof getDataStatus>>(getThingsToDoTagDetails, getDataStatus);
