import { createReducer } from 'typesafe-actions';

import { RoleDictionaryVM } from '@/models/roles';
import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { FeatureStateType } from '../helpers';
import { rolesActions } from './actions';

type rolesState = DictionaryState<RoleDictionaryVM>;

const INITIAL_STATE: rolesState = getDictionaryInitialState<RoleDictionaryVM>();

const reducer = createReducer<rolesState, rolesActions>(INITIAL_STATE)
  .handleAction(rolesActions.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(rolesActions.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(rolesActions.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(rolesActions.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(rolesActions.failure, (state, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const rolesReducer = { roles: reducer };
export type rolesReducer = FeatureStateType<typeof rolesReducer>;
