import React, { ChangeEventHandler, ClipboardEventHandler, FocusEventHandler, KeyboardEventHandler, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidationError } from 'yup';
import uniqWith from 'lodash/uniqWith';

import { useStyles } from '@/styles/hooks';
import { arrayRemove, arrayReplace } from '@/common/utils';
import { ErrorMessage } from '../form-controls-deprecated/field/components/error-message';
import { Input } from '../form-controls-deprecated';
import { Chip } from './chip';
import { SEPARATORS } from './constants';
import { ChipValue } from './types';
import { chipsStyles } from './styles';

type ErrorMessagesType = {
  maxLimit?: string;
  someInvalid?: string;
};

export type ChipsProps<T = string, E = ValidationError> = {
  values?: ChipValue<T, E>[];
  onChange: (options: ChipValue<T, E>[]) => void;
  onAddValidator?: (value: T) => E | null;
  mapInputToValue?: (value: string) => T;
  maxLength?: number;
  placeholder?: string;
  addOnlyValid?: boolean;
  noDuplicates?: boolean;
  keepDuplicates?: boolean;
  separator?: string;
  errorMessages?: ErrorMessagesType;
  addActionTrigger?: string | RegExp;
  removeCharacters?: string | RegExp;
  disableOnPaste?: boolean;
  disableOnBlur?: boolean;
  noOverflow?: boolean;
  error?: string;
  disabled?: boolean;
  withErrorTooltip?: boolean;
  isEditable?: boolean;
};

export const Chips = <T extends any = string, E extends Error = ValidationError>({
  values = [],
  onChange,
  onAddValidator,
  mapInputToValue = value => value as T,
  placeholder,
  maxLength,
  noDuplicates = true,
  addOnlyValid = false,
  separator = ' ',
  errorMessages,
  addActionTrigger = SEPARATORS,
  removeCharacters = new RegExp(`(${addActionTrigger instanceof RegExp ? addActionTrigger.source : addActionTrigger})+`, 'gm'),
  disableOnPaste,
  disableOnBlur,
  noOverflow = true,
  error,
  disabled,
  isEditable,
}: ChipsProps<T, E>) => {
  const { styles } = useStyles(chipsStyles);
  const [t] = useTranslation();

  const setValues = useCallback(onChange, [onChange]);
  const [text, setText] = useState<string>('');
  const [hasError, setHasError] = useState<string>();

  const messages = useMemo<Required<ErrorMessagesType>>(
    () => ({
      maxLimit: 'validationMessage.maxElements',
      someInvalid: 'validationMessage.someInvalid',
      ...errorMessages,
    }),
    [errorMessages]
  );

  const filterDuplicates = useCallback(
    (next: ChipValue<T, E>[]) => uniqWith(next, (a, b) => a.value === b.value).filter(entity => !values.find(({ value }) => value === entity.value)),
    [values]
  );

  const getDuplicated = useCallback(
    (filtered: ChipValue<T, E>[], original: ChipValue<T, E>[]) => original.filter(entity => !filtered.find(({ value }) => value === entity.value)),
    []
  );

  const filterValid = useCallback(
    (values: ChipValue<T, E>[]) => ({
      valid: values.filter(value => !value.error),
      invalid: values.filter(value => value.error),
    }),
    []
  );

  const reduceNextToMaxLength = useCallback(
    (next: ChipValue<T, E>[]) => {
      if (!maxLength) return { reduced: next, cut: [] };
      if (values.length >= maxLength) return { reduced: [], cut: next };
      const slicer = maxLength - values.length;
      return { reduced: next.slice(0, slicer), cut: next.slice(slicer) };
    },
    [values, maxLength]
  );

  const processInputString = useCallback(
    (input: string) => {
      const parts = input
        .replace(removeCharacters, ' ')
        .trim()
        .split(' ')
        .map((text: string): ChipValue<T, E> => {
          const value = mapInputToValue(text);
          return {
            value,
            label: text,
            error: onAddValidator?.(value),
          };
        });

      const filtered = noDuplicates ? filterDuplicates(parts) : parts;
      const { valid, invalid } = filterValid(filtered);
      const { reduced, cut } = reduceNextToMaxLength(addOnlyValid ? valid : filtered);

      return { processed: reduced, rest: addOnlyValid ? [...cut, ...invalid] : cut, duplicates: getDuplicated(filtered, parts), invalid, cut };
    },
    [noDuplicates, addOnlyValid, mapInputToValue, filterDuplicates, filterValid, reduceNextToMaxLength, onAddValidator, removeCharacters]
  );

  const hasInvalidElements = useCallback((values: ChipValue<T, E>[]) => values.some(({ error }) => !!error), []);

  const addChipHandler = useCallback(
    (value: string) => {
      if (!value) return;

      const { processed, rest, duplicates, invalid } = processInputString(value);

      const next = [...values, ...processed].map(entity => ({
        ...entity,
        duplicates: (entity.duplicates || 0) + +duplicates.some(({ value }) => value === entity.value),
      }));
      setValues(next);

      setText(noOverflow ? '' : rest.map(entity => entity.value).join(separator));

      if (!addOnlyValid) return setHasError(prev => (hasInvalidElements(next) ? messages.someInvalid : prev));
      setHasError(invalid.length ? t(invalid.length === 1 ? t(invalid[0].error!.message) : messages.someInvalid) : '');
    },
    [t, processInputString, addOnlyValid, noOverflow, messages]
  );

  const handleKeyPress = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    event => {
      const { key, currentTarget } = event;
      if (key === 'Enter' || key.match(addActionTrigger)) {
        event.preventDefault();

        addChipHandler(currentTarget.value);
      }
    },
    [values, addChipHandler, addActionTrigger]
  );

  const handlePaste = useCallback<ClipboardEventHandler<HTMLInputElement>>(
    event => {
      event.preventDefault();

      addChipHandler(event.clipboardData.getData('text'));
    },
    [values, addChipHandler]
  );

  const handleOnBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
    event => {
      event.preventDefault();

      addChipHandler(event.target.value);
    },
    [values, addChipHandler]
  );

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(({ target: { value } }) => setText(value), []);

  const handleRemove = useCallback(
    (index: number) => () => {
      const next = arrayRemove(values, index);
      setValues(next);
      setHasError(hasInvalidElements(next) ? messages.someInvalid : undefined);
    },
    [values]
  );

  const handleChipChange = useCallback(
    (index: number) => (text: string) => {
      if (text === '') setValues(arrayRemove(values, index));

      const value = mapInputToValue(text);
      if (noDuplicates) {
        const duplicated = values.findIndex(entity => entity.value === value);
        if (duplicated >= 0)
          return setValues(
            arrayReplace(arrayRemove(values, index), duplicated, {
              ...values[duplicated],
              duplicates: (values[duplicated].duplicates || 0) + 1,
            })
          );
      }
      const next = arrayReplace(values, index, { value, label: text, error: onAddValidator?.(value) });
      setValues(next);
      setHasError(prev => (hasInvalidElements(next) ? messages.someInvalid : prev));
    },
    [values, noDuplicates, setValues]
  );

  return (
    <div css={styles}>
      <div className='chips'>
        {values.map((value, index) => (
          <Chip
            key={value.label + index}
            {...value}
            disabled={disabled}
            isEditable={isEditable}
            onRemove={handleRemove(index)}
            onChange={handleChipChange(index)}
          />
        ))}
        {!(noOverflow && maxLength && values.length >= maxLength) && (
          <Input
            className='chips-input'
            value={text}
            placeholder={placeholder}
            disabled={disabled || (!!maxLength && values.length >= maxLength)}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            onBlur={disableOnBlur ? undefined : handleOnBlur}
            onPaste={disableOnPaste ? undefined : handlePaste}
          />
        )}
      </div>
      {maxLength && (
        <div className='chips-message'>
          {error || hasError ? <ErrorMessage error={t(error || hasError || '')} /> : <span />}
          <span className='chips-message__max-length'>{`${values.length} / ${maxLength} (${t('label.max')})`}</span>
        </div>
      )}
    </div>
  );
};
