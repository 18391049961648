import { Styles, constants, theme } from '@/styles';

export const style: Styles = {
  border: `3px solid ${theme.borderThird}`,
  borderTop: `3px solid ${theme.brandPrimary}`,
  borderRadius: constants.borderRadius.xlarge,
  width: '20px',
  height: '20px',
  animation: 'spin 1s linear infinite',

  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
};
