import React, { ButtonHTMLAttributes, forwardRef } from 'react';
import cn from 'classnames';

import { Enum } from '@/common/utils';
import { useStyles } from '@/styles/hooks';
import { buttonStyles } from './styles';

export type ButtonTheme = Enum<typeof ButtonTheme>;
export const ButtonTheme = Enum('primary', 'secondary', 'error', 'ghost_primary', 'ghost_error');

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  theme?: ButtonTheme;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ children, theme = 'primary', className, ...props }, ref) => {
  const { styles } = useStyles(buttonStyles);

  return (
    <button css={styles} ref={ref} className={cn([className, theme])} {...props}>
      {children}
    </button>
  );
});
