import { switchMap, filter } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { getApplicationsData } from '@/features/management/services/moderation/dictionaries';
import { dictionaryEffectHelper } from '@/common/utils';
import { applicationsActions } from './actions';

const getApplicationsList: Epic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(applicationsActions.request)),
    switchMap(() => {
      const state = state$.value.applications;
      return dictionaryEffectHelper(() => getApplicationsData(), state, applicationsActions);
    })
  );

export const applicationsEpics = [getApplicationsList];
