import { createReducer } from 'typesafe-actions';

import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { RegionDictionaryVM } from '@/core/models/data-center-regions';
import { FeatureStateType } from '../helpers';
import { dataCenterSwitcherActions } from './actions';

type DataCenterSwitcherState = DictionaryState<RegionDictionaryVM>;

const INITIAL_STATE: DataCenterSwitcherState = {
  ...getDictionaryInitialState<RegionDictionaryVM>(),
};

const reducer = createReducer<DataCenterSwitcherState, dataCenterSwitcherActions>(INITIAL_STATE)
  .handleAction(dataCenterSwitcherActions.regions.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(dataCenterSwitcherActions.regions.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(dataCenterSwitcherActions.regions.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(dataCenterSwitcherActions.regions.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(dataCenterSwitcherActions.regions.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const regionsReducer = { regions: reducer };
export type regionsReducer = FeatureStateType<typeof regionsReducer>;
