import { Axios } from 'axios-observable';
import { Store } from 'redux';

import { rootState } from '@/store';
import { APP_NAME, getAppVersion, getBrowserData, PLATFORM_NAME } from '@/common/utils';
import { loadingIndicatorInterceptor, authenticationInterceptor } from './interceptors';

let httpInstances: ReturnType<typeof createHttpInstances>;

const headers = {
  'app-name': APP_NAME,
  'app-version': `${getAppVersion?.()}`,
  platform: PLATFORM_NAME,
  'device-model': getBrowserData(),
};

export const createHttpInstances = (store: Store<rootState>) => {
  const unauthorized = Axios.create({ headers });
  const authorized = Axios.create({ headers });
  loadingIndicatorInterceptor(unauthorized, store);
  loadingIndicatorInterceptor(authorized, store);
  authenticationInterceptor(authorized, store);
  const instances = {
    unauthorized,
    authorized,
  };
  httpInstances = instances;
  return instances;
};

const getHttpInstances = () => httpInstances;

export const httpClient = getHttpInstances;
