import { createSelector } from 'reselect';

import { getDataStatus } from '@/common/utils';
import { regionsReducer } from './reducer';

type MakeGetRegionsListData = regionsReducer['regions']['data'];

const getRegionsList = (state: regionsReducer) => state.regions;

export const MakeGetRegionsListData = () =>
  createSelector<regionsReducer, regionsReducer['regions'], MakeGetRegionsListData>(getRegionsList, ({ data }) => data);

export const MakeGetRegionsListStatus = () =>
  createSelector<regionsReducer, regionsReducer['regions'], ReturnType<typeof getDataStatus>>(getRegionsList, getDataStatus);
