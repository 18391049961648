import { createReducer } from 'typesafe-actions';

import { SubscriptionListVM } from '@/models/entitlements';
import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { FeatureStateType } from '../../helpers';
import { entitlementsActions } from '../actions';

export type subscriptionsState = FetchState<SubscriptionListVM>;

const INITIAL_STATE: subscriptionsState = getFetchInitialState<SubscriptionListVM>({ sort: ['expirationDate,DESC'] });

const reducer = createReducer<subscriptionsState, entitlementsActions>(INITIAL_STATE)
  .handleAction(entitlementsActions.subscriptions.request, state => fetchReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(entitlementsActions.subscriptions.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(entitlementsActions.subscriptions.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(entitlementsActions.clearSubscriptions, () => ({ ...INITIAL_STATE }));

export const subscriptionsReducer = { subscriptions: reducer };
export type subscriptionsReducer = FeatureStateType<typeof subscriptionsReducer>;
