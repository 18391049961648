import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const filterStyle: StyleFunction = ({ theme }) => ({
  paddingRight: constants.leftPanel.offset,
  paddingBottom: constants.offset.xlarge,
  flexBasis: constants.leftPanel.width,
  maxWidth: constants.leftPanel.width,
  flexShrink: 0,
  flexGrow: 0,

  '.filter-header': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: constants.offset.medium,
    borderBottom: `1px solid ${theme.borderAccent}`,
    color: theme.primary,
    lineHeight: 2,
    fontWeight: 600,
  },
});
