import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';
import { dropContainerSize } from '../file-input/components/drop-container';

export const fieldModalStyles: StyleFunction = ({ theme }) => ({
  '.field': {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: constants.offset.xlarge,
    width: 390,

    '.field-content': {
      display: 'flex',
      flexDirection: 'row',

      '.drop-zone': {
        '&:hover': {
          cursor: 'default',
          backgroundColor: theme.backgroundPrimaryHover,
        },
      },

      '.placeholder': {
        height: dropContainerSize.regular.height,
      },

      '.open-button': {
        width: 'fit-content',

        '&--compact': {
          padding: `${constants.offset.small}px`,
        },
      },

      '.distant': {
        marginBottom: constants.fontSize.medium * 1.15,
      },
    },
  },

  '.on-right': {
    alignItems: 'end',
  },
});
