import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipRenderProps } from 'react-joyride';
import { useStyles } from '@/styles/hooks';

import { Button } from '../../button';
import { IconButton } from '../../icon-button';
import { tooltipStyles } from '../styles';

export const OnboardingTooltip: FC<TooltipRenderProps> = ({ continuous, index, isLastStep, step, backProps, primaryProps, skipProps, tooltipProps, size }) => {
  const [t] = useTranslation();
  const title = useMemo(() => (typeof step.title === 'string' ? t(step.title) : step.title), [t, step.title]);
  const content = useMemo(() => (typeof step.content === 'string' ? t(step.content) : step.content), [t, step.content]);
  const { styles } = useStyles(tooltipStyles);

  return (
    <div css={styles}>
      <div className='content' {...tooltipProps}>
        <div className='content__left'>
          <h2 className='title'>
            {`(${index + 1}/${size})`} {title}
          </h2>
          <p className='paragraph'>{content}</p>
        </div>
        <div className='content__right'>
          <IconButton iconName='mdi-close' theme='primary' {...skipProps} />
        </div>
      </div>
      <div className='footer'>
        <div className='footer__left'>
          {!isLastStep && (
            <Button theme='ghost_primary' {...skipProps}>
              {t('button.skip')}
            </Button>
          )}
        </div>
        <div className='footer__right'>
          {index > 0 && (
            <Button theme='secondary' {...backProps}>
              {t('button.back')}
            </Button>
          )}

          <Button theme='primary' {...primaryProps}>
            {continuous && t(`button.${!isLastStep ? 'next' : 'close'}`)}
          </Button>
        </div>
      </div>
    </div>
  );
};
