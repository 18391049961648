import { createReducer } from 'typesafe-actions';

import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { ReviewListSearchByOptionListVM } from '@/models/reviews';
import { FeatureStateType } from '../../helpers';
import { reviewsActions } from '../actions';

type searchByOptionsState = DictionaryState<ReviewListSearchByOptionListVM>;

const INITIAL_STATE: searchByOptionsState = getDictionaryInitialState<ReviewListSearchByOptionListVM>();

const reducer = createReducer<searchByOptionsState, reviewsActions>(INITIAL_STATE)
  .handleAction(reviewsActions.searchByOptions.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(reviewsActions.searchByOptions.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(reviewsActions.searchByOptions.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(reviewsActions.searchByOptions.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(reviewsActions.searchByOptions.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const searchByOptionsReducer = { searchByOptions: reducer };
export type searchByOptionsReducer = FeatureStateType<typeof searchByOptionsReducer>;
