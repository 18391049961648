import uniqueId from 'lodash/uniqueId';

import { CustomFile, FileInputPresetProps, FileType } from './models';

export const FileInputPreset = {
  [FileType.Map]: new FileInputPresetProps(FileType.Map, ['.gpx', '.kml']).props,
  [FileType.Image]: new FileInputPresetProps(FileType.Image, ['.jpg', '.jpeg', '.png']).props,
  [FileType.Other]: new FileInputPresetProps(FileType.Other).props,
};

export const FileInputPresetOverride = {
  [FileType.Map]: (accept: string[]) => new FileInputPresetProps(FileType.Map, accept).props,
  [FileType.Image]: (accept: string[]) => new FileInputPresetProps(FileType.Image, accept).props,
  [FileType.Other]: (accept: string[]) => new FileInputPresetProps(FileType.Other, accept).props,
};

export const isCustomFile = function (file: File | CustomFile): file is CustomFile {
  return !!(file as CustomFile).id;
};

export const DRAG_AREA_ID = uniqueId('DRAG_AREA');
export const UPLOAD_BUTTON_ID = uniqueId('UPLOAD_BUTTON');
