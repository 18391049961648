import { ActionType, Action } from 'typesafe-actions';

import { DifficultiesDictionaryVM } from '@/models/difficulties';
import { createDictionaryActions } from '@/common/utils/store';

export const difficultiesActions = createDictionaryActions(
  `FETCH_DIFFICULTIES_DICTIONARY_REQUEST`,
  `FETCH_DIFFICULTIES_DICTIONARY_SUCCESS`,
  `FETCH_DIFFICULTIES_DICTIONARY_FAILURE`,
  `FETCH_DIFFICULTIES_DICTIONARY_CANCEL`,
  `FETCH_DIFFICULTIES_DICTIONARY_SILENT_REQUEST`
)<undefined, DifficultiesDictionaryVM>();

export type difficultiesActions = Action | ActionType<typeof difficultiesActions>;
