import React, { memo } from 'react';
import { Switch } from 'react-router-dom';

import { unauthenticatedRoutePaths, unauthenticatedRoutes } from './routes';
import { RoutesDef } from './navigation.models';
import { generateRoutes, lazy } from './navigation.helpers';

const unauthenticatedRoutesDef: RoutesDef<unauthenticatedRoutes> = {
  [unauthenticatedRoutes.AUTHENTICATION]: {
    path: unauthenticatedRoutePaths[unauthenticatedRoutes.AUTHENTICATION],
    component: lazy(import(/* webpackChunkName: "authentication" */ '@/features/authentication')),
  },
};

const unauthenticatedRoutesElement = generateRoutes(
  unauthenticatedRoutesDef,
  'unauthenticated',
  unauthenticatedRoutePaths[unauthenticatedRoutes.AUTHENTICATION]
);

export const UnauthenticatedNavigation = memo(() => <Switch>{unauthenticatedRoutesElement}</Switch>);
