import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const buttonStyle: StyleFunction = ({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.backgroundAccent,
  color: theme.textWhite,
  textDecoration: 'none',
  transition: 'background-color .15s ease-in',
  cursor: 'pointer',
  height: 60,

  '&:disabled': {
    backgroundColor: theme.backgroundSecondaryDisabled,
    cursor: 'not-allowed',
  },

  '&:hover:not(:disabled)': {
    backgroundColor: theme.primaryHover,
  },

  '&:focus': {
    boxShadow: `0px 0px 0px 3px ${theme.primaryFocus} inset`,
    outline: 0,
  },

  '.avatar': {
    marginRight: constants.offset.small,
  },
});

export const menuStyle: StyleFunction = ({ theme }) => ({
  '.MuiButtonBase-root': {
    cursor: 'auto',
    backgroundColor: `${theme.backgroundPrimary} !important`,
  },
});
