import React, { useCallback } from 'react';
import { FieldProps } from 'formik';

import { Table, TableProps, OnRowClickFunc } from '../../table';
import { Field } from '../field';

type Props<T> = TableProps<T> & {
  name: string;
  disabled?: boolean;
};

export const FieldTable = <T,>({ name, disabled, onRowClick, ...tableProps }: Props<T>) => {
  const FieldComponent = ({ form }: FieldProps) => {
    const handleRowClick = useCallback<OnRowClickFunc>((index, row) => {
      form.setFieldValue(name, (row?.original as any).id);
      onRowClick?.(index, row);
    }, []);

    return <Table onRowClick={handleRowClick} {...tableProps} />;
  };

  return (
    <Field name={name} isFocusOnLabelEnabled={false}>
      {FieldComponent}
    </Field>
  );
};
