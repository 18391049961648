import { RoutesListResponse, RoutesListResponseTemp } from '../../models/routes';
import { RouteTypeValues } from '../../pages/routes-list/components/routes-list-filters';

export const getRouteDistanceInKilometers = (distance?: number): string => {
  if (distance === undefined) {
    return '';
  }

  return `${(Math.floor(distance * 100) / 100000).toFixed(2)} km`;
};

export const getRouteAscentInMeters = (ascent?: number): string => {
  if (ascent === undefined) {
    return '';
  }

  return `${ascent.toFixed(1)} m`;
};

export const getRouteListDistanceParamValue = (distance: number | undefined): number | undefined => {
  if (distance === undefined) {
    return undefined;
  }

  return distance * 1000;
};

export const getRouteTypeCircular = (routeType?: RouteTypeValues[]) => {
  if (routeType?.length === 1) {
    return routeType[0] === 'CIRCULAR';
  }

  return undefined;
};

/**
 * TODO
 * Remove mapping when Route list response matches desired interface
 * (see f.e. Moderation list response)
 */
export const mapTempRouteListResponse = (response: RoutesListResponseTemp): RoutesListResponse => {
  return {
    content: response.content,
    empty: !response.content.length,
    first: response.pageable.pageNumber === 0,
    last: false,
    number: response.pageable.pageNumber,
    numberOfElements: response.content.length,
    pageable: {
      ...response.pageable,
      sort: {
        empty: !response.content.length,
        sorted: false,
        unsorted: false,
      },
    },
    size: response.pageable.pageSize,
    sort: {
      empty: !response.content.length,
      sorted: false,
      unsorted: false,
    },
    totalElements: response.content.length,
    totalPages: response.content.length,
    hasNextPage: response.hasNextPage,
  };
};
