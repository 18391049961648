import { ActionType, Action, createAction } from 'typesafe-actions';

import { createFetchActions } from '@/common/utils/store';
import { CommunityStoriesListVM, CommunityStoriesParams, CommunityStoryDetailsVM } from '@/features/content/models/community-stories';

export const communityStoriesActions = {
  list: createFetchActions('FETCH_COMMUNITY_STORIES_REQUEST', 'FETCH_COMMUNITY_STORIES_SUCCESS', 'FETCH_COMMUNITY_STORIES_FAILURE')<
    CommunityStoriesParams,
    CommunityStoriesListVM
  >(),
  clearCommunityStoriesList: createAction('CLEAR_COMMUNITY_STORIES_LIST')(),
  details: {
    ...createFetchActions('FETCH_COMMUNITY_STORY_DETAILS_REQUEST', 'FETCH_COMMUNITY_STORY_DETAILS_SUCCESS', 'FETCH_COMMUNITY_STORY_DETAILS_FAILURE')<
      string,
      CommunityStoryDetailsVM
    >(),
    clearDetails: createAction('FETCH_COMMUNITY_STORY_DETAILS_CLEAR')(),
  },
};

export type communityStoriesActions = Action | ActionType<typeof communityStoriesActions>;
