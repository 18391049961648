import { combineReducers } from 'redux';

import { FeatureStateType } from '@/store/helpers';
import { thingsToDoTagsListReducer } from './list';
import { thingsToDoTagDetailsReducer } from './details';

export type thingsToDoTagsReducer = FeatureStateType<typeof thingsToDoTagsReducer>;

export const thingsToDoTagsReducer = {
  thingsToDoTags: combineReducers({
    ...thingsToDoTagsListReducer,
    ...thingsToDoTagDetailsReducer,
  }),
};
