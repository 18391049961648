import { Styles, constants } from '@/styles';

export const style: Styles = {
  '.datepicker-wrapper': {
    display: 'flex',
    alignItems: 'center',

    '.label': {
      width: 100,
    },

    '&:nth-of-type(2)': {
      marginTop: constants.offset.small,
    },
  },
};
