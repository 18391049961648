import { constants, rem } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const errorStyles: StyleFunction = ({ theme }) => ({
  position: 'relative',
  height: '100vh',

  '.error-wrapper': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '.icon-element': {
      fontSize: constants.fontSize.large,
      color: theme.textPrimary,
    },

    '.header': {
      fontSize: rem(constants.fontSize.h2),
      color: theme.primary,
      fontWeight: 'bold',
      marginTop: constants.offset.large,
    },

    '.description': {
      margintTop: constants.offset.large,
      fontSize: rem(constants.fontSize.h4),
      color: theme.textPrimary,
    },

    '.button': {
      marginTop: constants.offset.large,
    },
  },
});
