import dayjs from 'dayjs';

import { ApiPagination, DEFAULT_DATE_FORMAT, DEFAULT_DATE_FORMAT_WITH_TIME, PageableListResponse } from '@/common/utils';
import { DropdownOption } from '@/common/components/search-box';
import { AutocompleteOption } from '@/common/components/autocomplete';

export type UserListParams = ApiPagination<{
  nextBatchLink?: string;
  organisation?: AutocompleteOption;
  searchOption?: string;
  role?: string;
  currentRegionUrl?: string;
  userState?: string[];
}>;

export type HistoryChangesListParams = ApiPagination<{
  query: {
    targetType: string;
    targetId: string;
    nextPageToken?: string;
  };
  pageParams: {
    size?: number;
    requestContinuation?: string;
  };
}>;

export type UserListResponse = {
  nextBatchLink: string;
  users: UserListEntityResponse[];
  value?: UserAutocompleteListEntityResponse[];
};

export type HistoryChangesListResponse = PageableListResponse<HistoryChangesListEntityResponse> & {
  hasNext: boolean;
  nextPageToken?: string;
};

export type Changes = {
  fieldName: string;
  change: {
    oldValue: string;
    newValue: string;
  };
};

export type HistoryChangesListEntityResponse = {
  id: string;
  actionTypeDisplayName: string;
  changes: Changes[];
  createdAt: string;
  createdBy: {
    userId: string;
    email: string;
    givenName: string;
    surname: string;
    organisationId: string;
  };
};

export type UserListEntityResponse = {
  userId: string;
  email: string;
  name: string;
  country: string;
  organisation: UserOrganisation;
  role: { id: string; displayName: string };
  accountEnabled: boolean;
};

export type UserAutocompleteListEntityResponse = {
  accountEnabled: boolean;
  country: string;
  displayName: string;
  userId: string;
};

export type UserOrganisation = {
  id: string;
  name: string;
};

export interface UserVM extends Omit<UserListEntityResponse, 'role'> {
  role: string;
}

export class UserVM {
  constructor({ role, ...props }: UserListEntityResponse) {
    Object.assign(this, {
      ...props,
      role: role?.displayName || '',
    });
  }
}

export interface HistoryChangesVM extends HistoryChangesListEntityResponse {
  author: string;
  whatChanged: Changes[];
}
export class HistoryChangesVM {
  constructor({ changes, createdBy, createdAt, ...props }: HistoryChangesListEntityResponse) {
    Object.assign(this, {
      ...props,
      createdAt: createdAt ? dayjs(createdAt).format(DEFAULT_DATE_FORMAT) : '',
      author: createdBy.givenName && createdBy.surname ? `${createdBy.givenName} ${createdBy.surname}` : '',
      whatChanged: changes || [],
    });
  }
}

export interface UserListVM extends Omit<UserListResponse, 'users' | 'nextBatchLink'> {
  users: UserVM[];
  nextBatchLink?: string;
}

export class UserListVM {
  constructor(data: UserListResponse) {
    this.nextBatchLink = data.nextBatchLink || '';
    this.users = (data.users || []).map(entity => new UserVM(entity));
  }
}

export interface HistoryChangesListVM {
  list: HistoryChangesVM[];
  pagination?: {
    hasNext: boolean;
    nextPageToken?: string;
  };
}
export class HistoryChangesListVM {
  constructor({ content, hasNext, nextPageToken }: HistoryChangesListResponse) {
    this.list = (content || []).map(element => new HistoryChangesVM(element));
    this.pagination = {
      hasNext: hasNext || false,
      nextPageToken,
    };
  }
}

export type UserDetailsResponse = {
  userId: string;
  name: string;
  email: string;
  organisation: { id: string; name: string } | null;
  role: { id: string; displayName: string } | null;
  accountEnabled: boolean;
  country: string;
};

export interface UserDetailsVM extends UserDetailsResponse {}

export class UserDetailsVM {
  constructor(props: UserDetailsResponse) {
    Object.assign(this, props);
  }
}

export type UpdateUserDetailsParams = {
  email?: string;
  organisation?: string;
  role?: string;
};

export type UserListSearchByOptionResponse = {
  displayName: string;
  id: string;
  translationId: string;
};

export type UserListSearchByOptionListResponse = UserListSearchByOptionResponse[];

export interface UserListSearchByOptionVM extends DropdownOption {}

export class UserListSearchByOptionVM {
  constructor(option: UserListSearchByOptionResponse) {
    this.value = option.id;
    this.label = option.displayName;
    this.translationId = option.translationId;
  }
}

export interface UserListSearchByOptionListVM {
  options: UserListSearchByOptionVM[];
}

export class UserListSearchByOptionListVM {
  constructor(options: UserListSearchByOptionListResponse) {
    this.options = (options || []).map(element => new UserListSearchByOptionVM(element));
  }
}

export interface UsersAutocompleteElementVM extends AutocompleteOption {}
export class UsersAutocompleteElementVM {
  constructor({ userId, displayName }: UserAutocompleteListEntityResponse) {
    this.label = displayName || '';
    this.value = userId || '';
  }
}

export interface UsersAutocompleteListVM {
  list: UsersAutocompleteElementVM[];
}

export class UsersAutocompleteListVM {
  constructor(data: UserListResponse) {
    this.list = (data.value || []).map(element => new UsersAutocompleteElementVM(element));
  }
}

export type UsersAppsEntityResponse = {
  appName: string;
  platform: string;
  appVersion: number;
  osVersion: string;
  deviceModel: string;
  lastUsage: string;
};

export type UsersAppsListResponse = {
  userId: string;
  appsUsage: UsersAppsEntityResponse[];
};

export interface UsersAppsElementVM extends UsersAppsEntityResponse {}

export class UsersAppsElementVM {
  constructor({ lastUsage, ...props }: UsersAppsEntityResponse) {
    Object.assign(this, {
      ...props,
      lastUsage: lastUsage ? dayjs(lastUsage).format(DEFAULT_DATE_FORMAT_WITH_TIME) : '',
    });
  }
}

export interface UsersAppsListVM {
  list: UsersAppsElementVM[];
}

export class UsersAppsListVM {
  constructor(appsList: UsersAppsListResponse) {
    this.list = (appsList.appsUsage || []).map(element => new UsersAppsElementVM(element));
  }
}

export type AppEntityResponse = {
  id: string;
  displayName: string;
  translationId: string;
};

export type AppListResponse = { entries: AppEntityResponse[] };

export interface AppElementVM extends AppEntityResponse {}

export class AppElementVM {
  constructor(props: AppEntityResponse) {
    Object.assign(this, props);
  }
}

export interface AppDictionaryVM {
  list: AppElementVM[];
}

export class AppDictionaryVM {
  constructor(list: AppListResponse) {
    this.list = (list.entries || []).map(element => new AppElementVM(element));
  }
}

export type PlatformEntityResponse = {
  id: string;
  displayName: string;
  translationId: string;
};

export type PlatformListResponse = { entries: PlatformEntityResponse[] };

export interface PlatformElementVM extends PlatformEntityResponse {}

export class PlatformElementVM {
  constructor(props: PlatformEntityResponse) {
    Object.assign(this, props);
  }
}

export interface PlatformDictionaryVM {
  list: AppElementVM[];
}

export class PlatformDictionaryVM {
  constructor(list: PlatformListResponse) {
    this.list = (list.entries || []).map(element => new PlatformElementVM(element));
  }
}
