import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import { stringify } from 'qs';

import { httpClient } from '@/core/services/http-client';
import { getEnv, CommonError, HttpClientCommonError } from '@/common/utils';
import {
  SmartMapsListResponse,
  AssignSmartMapParams,
  SmartMapsAutocompleteListVM,
  SmartMapsAutocompleteListResponse,
  EntitlementsParams,
  SubscriptionsListResponse,
  SubscriptionListVM,
  SmartMapsElementVM,
  SmartMapDataVM,
  OneTimeProductsListResponse,
  OneTimeProductDataVM,
  OneTimeProductsElementVM,
  SubscriptionAssignParams,
  SubscriptionAssignElementVM,
  AdminPanelManualSubsOnlyListVM,
  AdminPanelManualSubsOnlyElementVM,
  AdminPanelManualSubsOnlyResponse,
  SubscriptionsProductsListResponse,
  SubscriptionsProductsDictionaryVM,
} from '@/models/entitlements';

const config = getEnv();

const ENTITLEMENTS_SUBSCRIPTIONS_ENDPOINT = `${config.REACT_APP_API_URL}/entitlement-api/v1/adminPanel/user/subscriptions`;
const ENTITLEMENTS_SUBSCRIPTIONS_ASSIGN = `${config.REACT_APP_API_URL}/entitlement-api/v2/adminPanel/entitlements/assign/subscription`;
const ENTITLEMENTS_SMART_MAPS_ENDPOINT = `${config.REACT_APP_API_URL}/entitlement-api/v1/adminPanel/user/smartMaps`;
const ENTITLEMENTS_SMARTMAPS_AUTOCOMPLETE = `${config.REACT_APP_API_URL}/entitlement-api/v1/products/smartMaps`;
const ENTITLEMENTS_SMARTMAPS_ASSIGN = `${config.REACT_APP_API_URL}/entitlement-api/v1/adminPanel/entitlements/assign/smartMap`;
const ENTITLEMENTS_ONE_TIME_PRODUCTS_ENDPOINT = `${config.REACT_APP_API_URL}/entitlement-api/v1/adminPanel/user/oneTimeProducts`;
const ENTITLEMENTS_ENDPOINT = `${config.REACT_APP_API_URL}/entitlement-api/v1/adminPanel/entitlements`;
const ENTITLEMENTS_SUBSCRIPTIONS_PRODUCTS_ENDPOINT = `${config.REACT_APP_API_URL}/entitlement-api/v1/adminPanel/products/subscriptions`;

export const getSubscriptionsData = (data: EntitlementsParams) =>
  httpClient()
    .authorized.post<SubscriptionsListResponse>(
      ENTITLEMENTS_SUBSCRIPTIONS_ENDPOINT,
      { ...pick(data, ['userId', 'searchPhrase']) },
      { params: { ...omit(data, ['userId', 'searchPhrase']) }, paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' }) }
    )
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new SubscriptionListVM(data);
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: e.code, message: e.errorMessage })))
    );

export const assignSubscription = (data: SubscriptionAssignParams) =>
  httpClient()
    .authorized.post(ENTITLEMENTS_SUBSCRIPTIONS_ASSIGN, data)
    .pipe(
      map(({ data, status }) => {
        if (status === 202 && data !== undefined) {
          return new SubscriptionAssignElementVM(data);
        }
        throw undefined;
      }),
      catchError((e: HttpClientCommonError) => of(new CommonError(e.response?.data)))
    );

export const getAdminPanelManualSubsOnly = (userId: string) =>
  httpClient()
    .authorized.post<AdminPanelManualSubsOnlyResponse>(
      ENTITLEMENTS_ENDPOINT,
      {
        userId,
        includeSubscriptions: true,
        includeAdminPanelManualSubsOnly: true,
      },
      {
        params: {
          page: 0,
          size: 100,
        },
      }
    )
    .pipe(
      map(({ data, status }) => {
        if ([200].includes(status) && data !== undefined) {
          return new AdminPanelManualSubsOnlyListVM({ data: data.subscriptions, VM: AdminPanelManualSubsOnlyElementVM }).list;
        }
        throw undefined;
      }),
      catchError(error => of(new CommonError(error)))
    );

export const getSmartMapsData = (data: EntitlementsParams) =>
  httpClient()
    .authorized.post<SmartMapsListResponse>(
      ENTITLEMENTS_SMART_MAPS_ENDPOINT,
      { userId: data.userId, searchPhrase: data.name },
      { params: { ...omit(data, ['userId', 'name']) }, paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' }) }
    )
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new SmartMapDataVM({ data, VM: SmartMapsElementVM });
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: e.code, message: e.errorMessage })))
    );

export const getSmartMapsAutocompleteOptionList = (productName: string) =>
  httpClient()
    .authorized.get<SmartMapsAutocompleteListResponse>(ENTITLEMENTS_SMARTMAPS_AUTOCOMPLETE, { params: { productName } })
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new SmartMapsAutocompleteListVM(data);
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const smartMapsAssignMapsheet = (data: AssignSmartMapParams) =>
  httpClient()
    .authorized.post(ENTITLEMENTS_SMARTMAPS_ASSIGN, data)
    .pipe(catchError(e => of(new CommonError({ code: e.data.code, message: e.data.message }))));

export const getOneTimeProductsData = (data: EntitlementsParams) =>
  httpClient()
    .authorized.post<OneTimeProductsListResponse>(
      ENTITLEMENTS_ONE_TIME_PRODUCTS_ENDPOINT,
      { userId: data.userId, searchPhrase: data.name },
      { params: { ...omit(data, ['userId', 'name']) }, paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' }) }
    )
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new OneTimeProductDataVM({ data, VM: OneTimeProductsElementVM });
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: e.code, message: e.errorMessage })))
    );

export const getSubscriptionsProductsData = () =>
  httpClient()
    .authorized.get<SubscriptionsProductsListResponse>(ENTITLEMENTS_SUBSCRIPTIONS_PRODUCTS_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new SubscriptionsProductsDictionaryVM(data);
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
