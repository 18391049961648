import { createSelector } from 'reselect';

import { getDataStatus } from '@/common/utils';
import { reasonsReducer } from './reducer';

type MakeGetReasonsData = reasonsReducer['reasons']['data'];

const getReasons = (state: reasonsReducer) => state.reasons;

export const makeGetReasonsData = () => createSelector<reasonsReducer, reasonsReducer['reasons'], MakeGetReasonsData>(getReasons, ({ data }) => data);
export const makeGetReasonsStatus = () =>
  createSelector<reasonsReducer, reasonsReducer['reasons'], ReturnType<typeof getDataStatus>>(getReasons, getDataStatus);
