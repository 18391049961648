import { createSelector } from 'reselect';

import { getDataStatus } from '@/common/utils';
import { visibilityReducer } from './reducer';

type MakeGetVisibilityData = visibilityReducer['visibility']['data'];

const getVisibility = (state: visibilityReducer) => state.visibility;

export const makeGetVisibilityData = () =>
  createSelector<visibilityReducer, visibilityReducer['visibility'], MakeGetVisibilityData>(getVisibility, ({ data }) => data);

export const makeGetVisibilityStatus = () =>
  createSelector<visibilityReducer, visibilityReducer['visibility'], ReturnType<typeof getDataStatus>>(getVisibility, getDataStatus);
