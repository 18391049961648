import { ActionType, Action, createAction } from 'typesafe-actions';

import { ToursParams, ToursListVM, TourDetailsVM, TourThumbnailVM, TourStatusesDictionaryVM, TourTypesDictionaryVM } from '@/features/content/models/tours';
import { createDictionaryActions, createFetchActions } from '@/common/utils/store';
import { PricesDictionaryVM, TipsDictionaryVM } from '@/models/payments';
import { StoryDetailsVM } from '@/models/tours';

export const toursActions = {
  list: createFetchActions('FETCH_TOURS_REQUEST', 'FETCH_TOURS_SUCCESS', 'FETCH_TOURS_FAILURE')<ToursParams, ToursListVM>(),
  clearToursList: createAction('CLEAR_TOURS_LIST')(),
  story: createFetchActions('FETCH_STORY_DETAILS_REQUEST', 'FETCH_STORY_DETAILS_SUCCESS', 'FETCH_STORY_DETAILS_FAILURE')<string, StoryDetailsVM>(),
  details: createFetchActions('FETCH_TOUR_DETAILS_REQUEST', 'FETCH_TOUR_DETAILS_SUCCESS', 'FETCH_TOUR_DETAILS_FAILURE')<string, TourDetailsVM>(),
  thumbnail: createFetchActions('FETCH_TOUR_THUMBNAIL_REQUEST', 'FETCH_TOUR_THUMBNAIL_SUCCESS', 'FETCH_TOUR_THUMBNAIL_FAILURE')<string, TourThumbnailVM>(),
  statuses: createDictionaryActions(
    'FETCH_TOURS_STATUSES_DICTIONARY_REQUEST',
    'FETCH_TOURS_STATUSES_DICTIONARY_SUCCESS',
    'FETCH_TOURS_STATUSES_DICTIONARY_FAILURE',
    'FETCH_TOURS_STATUSES_DICTIONARY_CANCEL',
    'FETCH_TOURS_STATUSES_DICTIONARY_SILENT_REQUEST'
  )<undefined, TourStatusesDictionaryVM>(),
  tips: createDictionaryActions(
    'FETCH_TIPS_DICTIONARY_REQUEST',
    'FETCH_TIPS_DICTIONARY_SUCCESS',
    'FETCH_TIPS_DICTIONARY_FAILURE',
    'FETCH_TIPS_DICTIONARY_CANCEL',
    'FETCH_TIPS_DICTIONARY_SILENT_REQUEST'
  )<undefined, TipsDictionaryVM>(),
  prices: createDictionaryActions(
    'FETCH_PRICES_DICTIONARY_REQUEST',
    'FETCH_PRICES_DICTIONARY_SUCCESS',
    'FETCH_PRICES_DICTIONARY_FAILURE',
    'FETCH_PRICES_DICTIONARY_CANCEL',
    'FETCH_PRICES_DICTIONARY_SILENT_REQUEST'
  )<undefined, PricesDictionaryVM>(),
  types: createDictionaryActions(
    'FETCH_TYPES_DICTIONARY_REQUEST',
    'FETCH_TYPES_DICTIONARY_SUCCESS',
    'FETCH_TYPES_DICTIONARY_FAILURE',
    'FETCH_TYPES_DICTIONARY_CANCEL',
    'FETCH_TYPES_DICTIONARY_SILENT_REQUEST'
  )<undefined, TourTypesDictionaryVM>(),
  clearTourDetails: createAction('CLEAR_TOUR_DETAILS')(),
  clearStoryDetails: createAction('CLEAR_STORY_DETAILS')(),
};

export type toursActions = Action | ActionType<typeof toursActions>;
