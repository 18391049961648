import React, { ChangeEvent, FC, InputHTMLAttributes, useCallback } from 'react';
import { FormikProps } from 'formik';

import { Input } from '../input';
import { SelectOption, Select } from '../select';
import { styles } from './styles';

export type UnitInputValue = { inputValue: string | undefined; selectValue: string | undefined };

export type UnitInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'form' | 'value'> & {
  options: SelectOption[];
  value?: UnitInputValue;
  onChange: (props: UnitInputValue) => void;
  form?: FormikProps<any>;
  onBlur?: () => void;
};

export const UnitInput: FC<UnitInputProps> = ({ name, value, onChange, form, type = 'number', disabled, options, onBlur, ...props }) => {
  const onInputChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const newValue: UnitInputValue = { selectValue: undefined, ...value, inputValue: target.value };
      if (form !== undefined && name !== undefined) {
        form.setFieldTouched(name);
        form.setFieldValue(name, newValue);
      } else {
        onChange(newValue);
      }
    },
    [form?.setFieldTouched, form?.setFieldValue, onChange, value]
  );

  const onSelectChange = useCallback(
    (option: SelectOption) => {
      const newValue: UnitInputValue = { inputValue: undefined, ...value, selectValue: option.value.toString() };
      if (form !== undefined && name !== undefined) {
        form.setFieldTouched(name);
        form.setFieldValue(name, newValue);
      } else {
        onChange(newValue);
      }
    },
    [form?.setFieldTouched, form?.setFieldValue, onChange, value]
  );

  return (
    <div css={styles}>
      <Input
        {...props}
        className='unit-input'
        type={type}
        value={value?.inputValue}
        onChange={onInputChange}
        name={`${name}_input`}
        disabled={disabled}
        onBlur={() => onBlur?.()}
      />
      <Select
        options={options}
        onChange={onSelectChange}
        className='unit-select'
        value={value?.selectValue}
        name={`${name}_select`}
        disabled={disabled}
        onBlur={() => onBlur?.()}
        unitInput
      />
    </div>
  );
};
