import { switchMap, filter } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { getActivitiesData } from '@/features/content/services/dictionaries';
import { dictionaryEffectHelper } from '@/common/utils';
import { activitiesActions } from './actions';

const getActivitiesList: Epic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(activitiesActions.request)),
    switchMap(() => {
      const state = state$.value.activities;
      return dictionaryEffectHelper(() => getActivitiesData(), state, activitiesActions);
    })
  );

export const activitiesEpics = [getActivitiesList];
