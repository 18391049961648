import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { WarningBar } from '../warning-bar';

const DEBOUNCE_TIME = 100;

const isOnlineSupported = () => 'onLine' in navigator;

export const ConnectionLostWarning: FC = () => {
  const [t] = useTranslation();

  const [isVisible, setIsVisible] = useState<boolean>(() => {
    if (isOnlineSupported()) {
      return window.navigator.onLine;
    }
    return true;
  });

  useEffect(() => {
    if (!isOnlineSupported()) return;

    const onlineSubscription = fromEvent(window, 'online')
      .pipe(debounceTime(DEBOUNCE_TIME))
      .subscribe(() => setIsVisible(true));
    const offlineSubscription = fromEvent(window, 'offline')
      .pipe(debounceTime(DEBOUNCE_TIME))
      .subscribe(() => {
        setIsVisible(false);
      });

    return () => {
      onlineSubscription && onlineSubscription.unsubscribe();
      offlineSubscription && offlineSubscription.unsubscribe();
    };
  }, []);

  const handleClose = useCallback(() => setIsVisible(true), []);

  return isVisible ? null : <WarningBar message={t('warning.noInternetConnection')} onClose={handleClose} />;
};
