import { ActionType, Action } from 'typesafe-actions';

import { ActivitiesDictionaryVM } from '@/models/activities';
import { createDictionaryActions } from '@/common/utils/store';

export const activitiesActions = createDictionaryActions(
  `FETCH_ACTIVITIES_DICTIONARY_REQUEST`,
  `FETCH_ACTIVITIES_DICTIONARY_SUCCESS`,
  `FETCH_ACTIVITIES_DICTIONARY_FAILURE`,
  `FETCH_ACTIVITIES_DICTIONARY_CANCEL`,
  `FETCH_ACTIVITIES_DICTIONARY_SILENT_REQUEST`
)<undefined, ActivitiesDictionaryVM>();

export type activitiesActions = Action | ActionType<typeof activitiesActions>;
