import dayjs from 'dayjs';

import { ApiPagination, PageableListResponse, PageableListVM } from '@/common/utils/pageable';
import { AutocompleteOption } from '@/common/components/autocomplete';
import { DEFAULT_DATE_FORMAT } from '@/common/utils';
import { CommonTagElementVM } from '@/models/common-tag';

export type CommunityStoriesParams = ApiPagination<{
  createdBy?: AutocompleteOption;
  text?: string;
  categoryIds?: string[];
  tagIds?: string[];
  ageRecommendations?: number[];
}>;

type CommunityStoryEntityResponse = {
  id: string;
  title: string;
  createdBy: {
    id: string;
    displayName: string;
  };
  createdAt: string;
  status: string;
  locality?: string;
  reported?: boolean;
  likeCounter: number;
  location: {
    type: string;
    coordinates: number[];
  };
};

export type CommunityStoriesListResponse = PageableListResponse<CommunityStoryEntityResponse>;

export interface CommunityStoryElementVM extends CommunityStoryEntityResponse {}

export class CommunityStoryElementVM {
  constructor({ createdAt, ...props }: CommunityStoryEntityResponse) {
    Object.assign(this, {
      ...props,
      createdAt: createdAt ? dayjs(createdAt).format(DEFAULT_DATE_FORMAT) : '',
    });
  }
}

export class CommunityStoriesListVM extends PageableListVM<CommunityStoryElementVM, CommunityStoriesListResponse> {}

export type CommunityStoryDetailsResponse = {
  id: string;
  title: string;
  description: string;
  location: {
    type: string;
    coordinates: number[];
  };
  address: {
    locality: string;
    streetName: string;
  };
  category: CommonTagElementVM;
  coverImage: {
    id: string;
    type: string;
    urlOriginal: string;
    sizeOriginal: string;
  };
  likeCounter: number;
  tags: CommonTagElementVM[];
  ageRecommendation: number;
  createdAt: string;
  createdBy: {
    id: string;
    displayName: string;
    fullName: string;
    email: string;
  };
  userActions: {
    created: boolean;
    liked: boolean;
  };
  status: string;
  reported: boolean;
};

export interface CommunityStoryDetailsVM extends CommunityStoryDetailsResponse {
  categories: CommonTagElementVM[];
}

export class CommunityStoryDetailsVM {
  constructor({ category, createdAt, ...props }: CommunityStoryDetailsResponse) {
    Object.assign(this, {
      ...props,
      createdAt: createdAt ? dayjs(createdAt).format(DEFAULT_DATE_FORMAT) : '',
      categories: category ? [category] : [],
    });
  }
}

export type UserCommunityStoriesListResponse = CommunityStoriesListResponse;

export interface UserCommunityStoriesListVM extends CommunityStoriesListVM {}

export class UserCommunityStoriesListVM extends CommunityStoriesListVM {}
