import React, { FC, ImgHTMLAttributes, useCallback, useRef, useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import cn from 'classnames';

import { StatusWrapperStatus } from '../status-wrapper';
import { style } from './styles';

const NO_IMAGE_PATH = '/images/icons/mdi-camera.svg';
const ERROR_IMAGE_PATH = '/images/icons/mdi-camera-off.svg';
const RETRY_TIMEOUT = 1000;
const RETRY_COUNT = 3;

export type ImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  limitedDimensions?: boolean;
  noCache?: boolean;
  status?: StatusWrapperStatus;
};

const getNewSourceVersion = (src?: string) => src && `${src}?v=${Date.now()}`;

const useMemorizedSource = (src?: string): [string | undefined, (src?: string) => void] => {
  const [source, setSource] = useState(getNewSourceVersion(src));

  useEffect(() => {
    if (src !== source) {
      setSource(getNewSourceVersion(src));
    }
  }, [src]);

  const setMemorizedSource = useCallback((src: string | undefined) => setSource(getNewSourceVersion(src)), []);

  return [source, setMemorizedSource];
};

export const Image: FC<ImageProps> = ({ src, limitedDimensions = false, className, noCache, status, ...props }) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const timer = useRef<NodeJS.Timeout>();
  const error$ = useRef(new Subject());
  const success$ = useRef(new Subject());

  const [memorizedSrc, setMemorizedSrc] = useMemorizedSource(src);
  const source = noCache ? memorizedSrc : src;

  const handleError = useCallback(() => {
    if (imageRef.current) {
      imageRef.current.src = ERROR_IMAGE_PATH;
    }
    if (noCache) error$.current.next();
  }, [src]);

  const handleLoad = useCallback(() => {
    success$.current.next();
  }, [timer]);

  useEffect(() => {
    const subscription = error$.current.pipe(takeUntil(success$.current), take(RETRY_COUNT), debounceTime(RETRY_TIMEOUT)).subscribe(() => setMemorizedSrc(src));
    return () => subscription.unsubscribe();
  }, [src]);

  useEffect(() => () => success$.current.next(), []);

  return (
    <img
      {...props}
      css={style}
      className={cn([className, { limited: limitedDimensions, stricted: !limitedDimensions }])}
      onError={handleError}
      onLoad={handleLoad}
      src={source ? source : status === 'failure' ? ERROR_IMAGE_PATH : NO_IMAGE_PATH}
      ref={imageRef}
    />
  );
};
