import { ActionType, Action, createAction } from 'typesafe-actions';

import { createFetchActions } from '@/common/utils/store';
import { ThingsToDoTagDetailsResponse, ThingsToDoTagsListVM, ThingsToDoTagsParams } from '@/features/content/models/things-to-do-tags';

export const thingsToDoTagsActions = {
  list: createFetchActions('FETCH_THINGS_TO_DO_TAGS_LIST_REQUEST', 'FETCH_THINGS_TO_DO_TAGS_LIST_SUCCESS', 'FETCH_THINGS_TO_DO_TAGS_LIST_FAILURE')<
    ThingsToDoTagsParams,
    ThingsToDoTagsListVM
  >(),
  clearThingsToDoTagsList: createAction('CLEAR_THINGS_TO_DO_TAGS_LIST')(),
  details: createFetchActions('FETCH_THINGS_TO_DO_TAG_DETAILS_REQUEST', 'FETCH_THINGS_TO_DO_TAG_DETAILS_SUCCESS', 'FETCH_THINGS_TO_DO_TAG_DETAILS_FAILURE')<
    string,
    ThingsToDoTagDetailsResponse
  >(),
  clearDetails: createAction('CLEAR_THINGS_TO_DO_TAG_DETAILS')(),
};

export type thingsToDoTagsActions = Action | ActionType<typeof thingsToDoTagsActions>;
