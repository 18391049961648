import { constants, Styles } from '@/styles';

export const style: Styles = {
  width: '100%',
  overflowX: 'auto',

  '.scrollbar-wrapper': {
    position: 'fixed',
    width: '100vw',
    left: 0,
    bottom: 0,
  },

  '.scrollbar': {
    position: 'relative',
    overflowX: 'auto',
    overflowY: 'hidden',
    height: constants.scrollbarWidth,

    '&__content': {
      height: 1,
    },
  },
};
