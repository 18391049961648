import { Dayjs } from 'dayjs';
import { ApiPagination, PageableListResponse, PageableListVM } from '@/common/utils/pageable';
import { SelectOption } from '@/common/components/form-controls';
import { MediaStatus } from '@/common/components/photo-details-modal/components/top-bar';

export type GalleryFilters = {
  text?: string;
  reviewed?: string[];
  reported?: string[];
  validationStatus?: string[];
  application?: string[];
  dateFrom?: Dayjs;
  dateTo?: Dayjs;
};

export type GalleryParams = ApiPagination<GalleryFilters>;

type GalleryStatusResponse = {
  displayName: string;
  id: string;
};

export type GalleryStatusListResponse = GalleryStatusResponse[];

export interface GalleryStatusDictionaryElementVM extends SelectOption {}

export class GalleryStatusDictionaryElementVM {
  constructor({ displayName, id, ...props }: GalleryStatusResponse) {
    Object.assign(this, {
      ...props,
      label: displayName,
      value: id,
    });
  }
}

export interface GalleryStatusDictionaryVM {
  list: GalleryStatusDictionaryElementVM[];
}

export class GalleryStatusDictionaryVM {
  constructor(list: GalleryStatusListResponse) {
    this.list = (list || []).map(element => new GalleryStatusDictionaryElementVM(element));
  }
}

type GalleryApplicationsResponse = {
  displayName: string;
  id: string;
};

export type GalleryApplicationsListResponse = GalleryApplicationsResponse[];

export interface GalleryApplicationsDictionaryElementVM extends SelectOption {}

export class GalleryApplicationsDictionaryElementVM {
  constructor({ displayName, id, ...props }: GalleryApplicationsResponse) {
    Object.assign(this, {
      ...props,
      label: displayName,
      value: id,
    });
  }
}

export interface GalleryApplicationsDictionaryVM {
  list: GalleryApplicationsDictionaryElementVM[];
}

export class GalleryApplicationsDictionaryVM {
  constructor(list: GalleryApplicationsListResponse) {
    this.list = (list || []).map(element => new GalleryApplicationsDictionaryElementVM(element));
  }
}

export type GalleryListEntityResponse = {
  targetId: string;
  targetType: string;
  reported: boolean;
  media: {
    id: string;
    metadata: {};
    origin: {
      creatorUserId: string;
    };
    files: {
      small: {
        url: string;
        size: number;
      };
      medium: {
        url: string;
        size: number;
      };
      large: {
        url: string;
        size: number;
      };
    };
    type: string;
    validationStatus: string;
    validationDetails: {
      hasOffensiveLanguage: boolean;
      offensiveTerms: [];
      message: string;
      attempts: number;
      adult: boolean;
      racy: boolean;
    };
    likeCounter: number;
    createdAt: string;
    updatedAt: string;
  };
  reviewed: boolean;
};

export type GalleryListResponse = PageableListResponse<GalleryListEntityResponse>;

export type GalleryListRequest = GalleryFilters & ApiPagination;

export interface GalleryListElementVM extends GalleryListEntityResponse {}

export class GalleryListElementVM {
  constructor({ ...props }: GalleryListEntityResponse) {
    Object.assign(this, {
      ...props,
    });
  }
}

export class GalleryListVM extends PageableListVM<GalleryListElementVM, GalleryListResponse> {}

export type ModalImagesElement = {
  original: string;
  thumbnail: string;
  isReviewed: boolean;
  isReviewedStatus: MediaStatus;
  deleteStatus: MediaStatus;
  isReported: boolean;
  mediaId: string;
  targetType: string;
  targetId: string;
};

export type ModalImagesList = ModalImagesElement[];
