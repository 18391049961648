import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';

import { Styles, theme } from '@/styles';

const TRACK_HEIGHT = 12;
const THUMB_RADIUS = 8;
const THUMB_RECT = 2 * THUMB_RADIUS;

export const styles: Styles = {
  '& span': {
    color: theme.primary,
  },

  '.wrapper': {
    width: '100%',
    padding: `0 ${THUMB_RADIUS}px`,

    '&--disabled': {
      cursor: 'not-allowed',
    },
  },

  '.disabled': {
    filter: 'grayscale(1)',
  },
};

export const sliderStyles: CreateCSSProperties = {
  '& .Mui-disabled': {
    filter: 'contrast(0)',
  },

  '& .MuiSlider-track': {
    height: TRACK_HEIGHT,
    color: theme.backgroundSeventh,
    borderRadius: TRACK_HEIGHT,
  },

  '& .MuiSlider-rail': {
    height: TRACK_HEIGHT,
    borderRadius: TRACK_HEIGHT,
    backgroundColor: theme.backgroundThirdDisabled,
  },

  '& .MuiSlider-thumb': {
    height: THUMB_RECT,
    width: THUMB_RECT,
    marginTop: -2,
    marginLeft: -THUMB_RADIUS,

    '& .Mui-disabled': {
      marginLeft: -THUMB_RADIUS,
    },
  },
};
