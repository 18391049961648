import { switchMap, filter } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { getContentTypesData } from '@/features/management/services/moderation/dictionaries';
import { dictionaryEffectHelper } from '@/common/utils';
import { contentTypesActions } from './actions';

const getContentTypesList: Epic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(contentTypesActions.request)),
    switchMap(() => {
      const state = state$.value.contentTypes;
      return dictionaryEffectHelper(() => getContentTypesData(), state, contentTypesActions);
    })
  );

export const contentTypesEpics = [getContentTypesList];
