import { createReducer } from 'typesafe-actions';

import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { VoucherBatchListRequest, VoucherBatchListVM } from '@/models/voucher-batch';
import { DEFAULT_LIST_PAGINATION_DATA } from '@/common/utils/pageable';
import { FeatureStateType } from '../../helpers';
import { voucherBatchActions } from '../actions';

export type voucherBatchListState = FetchState<VoucherBatchListVM, VoucherBatchListRequest>;

const INITIAL_STATE: voucherBatchListState = getFetchInitialState<VoucherBatchListVM, VoucherBatchListRequest>({ voucherTypes: ['SMARTMAP'] });

const reducer = createReducer<voucherBatchListState, voucherBatchActions>(INITIAL_STATE)
  .handleAction(voucherBatchActions.list.request, (state, { payload }) => fetchReducerHelpers.request(INITIAL_STATE, state, payload))
  .handleAction(voucherBatchActions.list.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(voucherBatchActions.list.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(voucherBatchActions.clearVoucherBatchList, state => ({
    ...state,
    data: { ...state.data, list: [], listPaginationData: state.data?.listPaginationData || DEFAULT_LIST_PAGINATION_DATA },
  }));

export const voucherBatchListReducer = { list: reducer };
export type voucherBatchListReducer = FeatureStateType<typeof voucherBatchListReducer>;
