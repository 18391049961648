import { createReducer } from 'typesafe-actions';

import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { MapAttributionDetailsVM } from '@/models/map-attribution';
import { FeatureStateType } from '../../helpers';
import { mapAttributionActions } from '../actions';

export type mapAttributionDetailsState = FetchState<MapAttributionDetailsVM>;

const INITIAL_STATE: mapAttributionDetailsState = getFetchInitialState<MapAttributionDetailsVM>();

const reducer = createReducer<mapAttributionDetailsState, mapAttributionActions>(INITIAL_STATE)
  .handleAction(mapAttributionActions.details.request, (state, { payload }) => fetchReducerHelpers.request(INITIAL_STATE, state, payload))
  .handleAction(mapAttributionActions.details.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(mapAttributionActions.details.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(mapAttributionActions.clearMapAttributionForm, () => ({ ...INITIAL_STATE }));

export const mapAttributionDetailsReducer = { details: reducer };
export type mapAttributionDetailsReducer = FeatureStateType<typeof mapAttributionDetailsReducer>;
