import React, { FCC, Suspense, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';

import { makeGetLoadingSectionActive } from '@/store/interface';
import { LoadingSection } from '@/common/components/loading-section';
import { useStyles } from '@/styles/hooks';
import { LoadingIndicatorBar } from '../loading-indicator-bar';
import { Topbar } from '../topbar';
import { PageWrapper } from '../page-wrapper';
import { ConnectionLostWarning } from '../connection-lost-warning';
import { loadingSectionStyles, layoutStyles } from './styles';

const getLoadingSectionActive = makeGetLoadingSectionActive();

export const Layout: FCC = ({ children }) => {
  const loadingSectionActive = useSelector(getLoadingSectionActive);
  const { pathname } = useLocation();
  const layoutContentRef = useRef<HTMLDivElement>(null);
  const LayoutStyle = useStyles(layoutStyles);
  const LoadingSectionStyle = useStyles(loadingSectionStyles);

  useEffect(() => {
    layoutContentRef.current?.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div css={LayoutStyle.styles}>
      <div className='layout__container'>
        <Suspense fallback={<LoadingSection className='loader' />}>
          <Topbar />

          <div className='layout__content' ref={layoutContentRef}>
            <PageWrapper>{children}</PageWrapper>
          </div>

          <LoadingIndicatorBar />
          <ToastContainer className='toast-container' />

          {loadingSectionActive ? <LoadingSection css={LoadingSectionStyle.styles} /> : null}
          <ConnectionLostWarning />
        </Suspense>
      </div>
    </div>
  );
};
