import React, { FC } from 'react';

import { SelectOption } from '../select';
import { UnitInput, UnitInputProps } from './unit-input';

const OPTIONS: SelectOption[] = [
  { value: 'min', label: 'min', translationId: 'units.minutes' },
  { value: 'hr', label: 'hr', translationId: 'units.hours' },
];

export type TimeInputProps = Omit<UnitInputProps, 'options'>;

export const TimeInput: FC<TimeInputProps> = props => <UnitInput {...props} options={OPTIONS} />;
