import { createReducer } from 'typesafe-actions';

import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { CommunityStoriesListVM, CommunityStoriesParams } from '@/features/content/models/community-stories';
import { DEFAULT_LIST_PAGINATION_DATA } from '@/common/utils/pageable';
import { FeatureStateType } from '../../helpers';
import { communityStoriesActions } from '../actions';

export type communityStoriesListState = FetchState<CommunityStoriesListVM, CommunityStoriesParams>;

const INITIAL_STATE: communityStoriesListState = getFetchInitialState<CommunityStoriesListVM, CommunityStoriesParams>();

const reducer = createReducer<communityStoriesListState, communityStoriesActions>(INITIAL_STATE)
  .handleAction(communityStoriesActions.list.request, (state, { payload }) => fetchReducerHelpers.request(INITIAL_STATE, state, payload))
  .handleAction(communityStoriesActions.list.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(communityStoriesActions.list.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(communityStoriesActions.clearCommunityStoriesList, state => ({
    ...state,
    data: { ...state.data, list: [], listPaginationData: state.data?.listPaginationData || DEFAULT_LIST_PAGINATION_DATA },
  }));

export const communityStoriesListReducer = { list: reducer };
export type communityStoriesListReducer = FeatureStateType<typeof communityStoriesListReducer>;
