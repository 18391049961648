interface Theme {
  brandPrimary: string;
  brandSecondary: string;
  brandThird: string;
  success: string;
  warning: string;
  info: string;
  error: string;
  errorFocus: string;
  errorHover: string;
  errorActive: string;
  primary: string;
  primaryActive: string;
  primaryFocus: string;
  primaryHover: string;
  primaryDisabled: string;
  backgroundPrimary: string;
  backgroundPrimaryActive: string;
  backgroundPrimaryHover: string;
  backgroundPrimaryFocus: string;
  backgroundPrimaryDisabled: string;
  backgroundSecondary: string;
  backgroundSecondaryDisabled: string;
  backgroundThird: string;
  backgroundThirdFocus: string;
  backgroundThirdDisabled: string;
  backgroundFourth: string;
  backgroundFourthActive: string;
  backgroundFourthFocus: string;
  backgroundFourthHover: string;
  backgroundFifth: string;
  backgroundFifthActive: string;
  backgroundFifthHover: string;
  backgroundSixth: string;
  backgroundSeventh: string;
  backgroundAccent: string;
  backgroundAccentActive: string;
  backgroundAccentFocus: string;
  backgroundAccentHover: string;
  backgroundAccentDisabled: string;
  borderPrimary: string;
  borderPrimaryActive: string;
  borderPrimaryFocus: string;
  borderPrimaryDisabled: string;
  borderAccent: string;
  borderSecondary: string;
  borderThird: string;
  textWhite: string;
  textPrimary: string;
  textSecondary: string;
  textThird: string;
  textAccent: string;
  textDisabled: string;
  textCorrect: string;
  textIncorrect: string;
  shadowPrimary: string;
  overlay: string;
}

class Theme {
  constructor(theme: Theme) {
    Object.assign(this, theme);
  }
}

const palette = {
  primary01: '#4D43A1',
  primary02: '#b2abf0',
  primary03: '#746cac',
  primary04: '#302965',
  primary05: '#a8a3cb',
  primary06: '#7B83EB',
  secondary: '#ff5f00',
  third: '#e6007d',
  white: '#ffffff',
  black: '#000000',
  grey01: '#fafafa',
  grey02: '#f6f5f9',
  grey03: '#f5f5f5',
  grey04: '#e3e3ed',
  grey05: '#dddddd',
  grey06: '#999999',
  grey07: '#1d1c1c',
  grey08: '#e7e6ee',
  grey09: '#141414',
  grey10: '#1d1c1c',
  grey11: '#494747',
  grey12: '#606060',
  warmGrey01: '#eeebeb',
  warmGrey02: '#e8e3e3',
  warmGrey03: '#dbd9d9',
  warmGrey04: '#d4cdcd',
  warmGrey05: '#e1deeb',
  success01: '#39a339',
  success02: '#7bd57b',
  information: '#2979af',
  warning: '#ffb000',
  error01: '#d40058',
  error02: '#ee99bc',
  error03: '#d56073',
  error04: '#b3014b',
  error05: '#ef9a9f',
};

export const theme = new Theme({
  brandPrimary: palette.primary01,
  brandSecondary: palette.secondary,
  brandThird: palette.third,
  success: palette.success01,
  warning: palette.warning,
  info: palette.information,
  error: palette.error01,
  errorActive: palette.error04,
  errorFocus: palette.error02,
  errorHover: palette.error03,
  primary: palette.primary01,
  primaryActive: palette.primary04,
  primaryFocus: palette.primary03,
  primaryHover: palette.primary02,
  primaryDisabled: palette.grey06,
  backgroundPrimary: palette.white,
  backgroundPrimaryActive: palette.warmGrey02,
  backgroundPrimaryHover: palette.grey03,
  backgroundPrimaryFocus: palette.grey02,
  backgroundPrimaryDisabled: palette.grey03,
  backgroundSecondary: palette.grey02,
  backgroundSecondaryDisabled: palette.grey06,
  backgroundThird: palette.grey01,
  backgroundThirdFocus: palette.white,
  backgroundThirdDisabled: palette.grey05,
  backgroundFourth: palette.warmGrey01,
  backgroundFourthActive: palette.warmGrey03,
  backgroundFourthFocus: palette.warmGrey04,
  backgroundFourthHover: palette.warmGrey02,
  backgroundFifth: palette.grey03,
  backgroundFifthActive: palette.grey08,
  backgroundFifthHover: palette.warmGrey05,
  backgroundSixth: palette.grey04,
  backgroundSeventh: palette.primary05,
  backgroundAccent: palette.primary01,
  backgroundAccentActive: palette.white,
  backgroundAccentFocus: palette.primary03,
  backgroundAccentHover: palette.primary02,
  backgroundAccentDisabled: palette.grey06,
  borderPrimary: palette.grey04,
  borderPrimaryActive: palette.primary01,
  borderPrimaryFocus: palette.primary02,
  borderPrimaryDisabled: palette.grey06,
  borderSecondary: palette.grey06,
  borderThird: palette.white,
  borderAccent: palette.grey05,
  textWhite: palette.white,
  textPrimary: palette.grey07,
  textSecondary: palette.warmGrey04,
  textThird: palette.grey06,
  textDisabled: palette.grey06,
  textAccent: palette.primary01,
  textCorrect: palette.success02,
  textIncorrect: palette.error05,
  shadowPrimary: palette.grey06,
  overlay: palette.black,
});

export type ThemeType = {
  brandPrimary: string;
  brandSecondary: string;
  brandThird: string;
  success: string;
  warning: string;
  info: string;
  error: string;
  errorFocus: string;
  errorHover: string;
  errorActive: string;
  primary: string;
  primaryActive: string;
  primaryFocus: string;
  primaryHover: string;
  primaryDisabled: string;
  backgroundPrimary: string;
  backgroundPrimaryActive: string;
  backgroundPrimaryHover: string;
  backgroundPrimaryFocus: string;
  backgroundPrimaryDisabled: string;
  backgroundSecondary: string;
  backgroundSecondaryDisabled: string;
  backgroundSecondaryFocus: string;
  backgroundThird: string;
  backgroundThirdFocus: string;
  backgroundThirdDisabled: string;
  backgroundFourth: string;
  backgroundFourthActive: string;
  backgroundFourthFocus: string;
  backgroundFourthHover: string;
  backgroundFifth: string;
  backgroundFifthActive: string;
  backgroundFifthHover: string;
  backgroundSixth: string;
  backgroundSeventh: string;
  backgroundEight: string;
  backgroundNinth: string;
  backgroundTenth: string;
  backgroundTenthHover: string;
  backgroundAccent: string;
  backgroundAccentActive: string;
  backgroundAccentFocus: string;
  backgroundAccentHover: string;
  backgroundAccentDisabled: string;
  backgroundContrast: string;
  borderPrimary: string;
  borderPrimaryActive: string;
  borderPrimaryFocus: string;
  borderPrimaryDisabled: string;
  borderAccent: string;
  borderSecondary: string;
  borderThird: string;
  textWhite: string;
  textPrimary: string;
  textSecondary: string;
  textThird: string;
  textFourth: string;
  textAccent: string;
  textDisabled: string;
  textCorrect: string;
  textIncorrect: string;
  shadowPrimary: string;
  shadowSecondary: string;
  overlay: string;
};

export const themeLight: ThemeType = {
  brandPrimary: palette.primary01,
  brandSecondary: palette.secondary,
  brandThird: palette.third,
  success: palette.success01,
  warning: palette.warning,
  info: palette.information,
  error: palette.error01,
  errorActive: palette.error04,
  errorFocus: palette.error02,
  errorHover: palette.error03,
  primary: palette.primary01,
  primaryActive: palette.primary04,
  primaryFocus: palette.primary02,
  primaryHover: palette.primary02,
  primaryDisabled: palette.grey06,
  backgroundPrimary: palette.white,
  backgroundPrimaryActive: palette.warmGrey02,
  backgroundPrimaryHover: palette.grey03,
  backgroundPrimaryFocus: palette.grey02,
  backgroundPrimaryDisabled: palette.grey03,
  backgroundSecondary: palette.grey02,
  backgroundSecondaryDisabled: palette.grey06,
  backgroundSecondaryFocus: palette.grey04,
  backgroundThird: palette.grey01,
  backgroundThirdFocus: palette.white,
  backgroundThirdDisabled: palette.grey05,
  backgroundFourth: palette.warmGrey01,
  backgroundFourthActive: palette.warmGrey03,
  backgroundFourthFocus: palette.warmGrey04,
  backgroundFourthHover: palette.warmGrey02,
  backgroundFifth: palette.grey03,
  backgroundFifthActive: palette.grey08,
  backgroundFifthHover: palette.warmGrey05,
  backgroundSixth: palette.grey04,
  backgroundSeventh: palette.primary05,
  backgroundEight: palette.white,
  backgroundNinth: palette.white,
  backgroundTenth: palette.warmGrey01,
  backgroundTenthHover: palette.grey03,
  backgroundAccent: palette.primary01,
  backgroundAccentActive: palette.white,
  backgroundAccentFocus: palette.primary03,
  backgroundAccentHover: palette.primary02,
  backgroundAccentDisabled: palette.grey06,
  backgroundContrast: palette.black,
  borderPrimary: palette.grey04,
  borderPrimaryActive: palette.primary01,
  borderPrimaryFocus: palette.primary02,
  borderPrimaryDisabled: palette.grey06,
  borderSecondary: palette.grey06,
  borderThird: palette.grey04,
  borderAccent: palette.grey05,
  textWhite: palette.white,
  textPrimary: palette.grey07,
  textSecondary: palette.warmGrey04,
  textThird: palette.grey06,
  textFourth: palette.grey07,
  textDisabled: palette.grey06,
  textAccent: palette.primary01,
  textCorrect: palette.success02,
  textIncorrect: palette.error05,
  shadowPrimary: palette.grey06,
  shadowSecondary: palette.grey05,
  overlay: palette.black,
};

export const themeDark: ThemeType = {
  brandPrimary: palette.primary01,
  brandSecondary: palette.secondary,
  brandThird: palette.third,
  success: palette.success01,
  warning: palette.warning,
  info: palette.information,
  error: palette.error01,
  errorActive: palette.error04,
  errorFocus: palette.error02,
  errorHover: palette.error03,
  primary: palette.primary06,
  primaryActive: palette.primary04,
  primaryFocus: palette.primary02,
  primaryHover: palette.primary02,
  primaryDisabled: palette.grey06,
  backgroundPrimary: palette.grey10,
  backgroundPrimaryActive: palette.warmGrey02,
  backgroundPrimaryHover: palette.grey11,
  backgroundPrimaryFocus: palette.grey11,
  backgroundPrimaryDisabled: palette.grey07,
  backgroundSecondary: palette.grey10,
  backgroundSecondaryDisabled: palette.grey06,
  backgroundSecondaryFocus: palette.grey11,
  backgroundThird: palette.grey07,
  backgroundThirdFocus: palette.grey07,
  backgroundThirdDisabled: palette.grey05,
  backgroundFourth: palette.warmGrey01,
  backgroundFourthActive: palette.warmGrey03,
  backgroundFourthFocus: palette.warmGrey04,
  backgroundFourthHover: palette.warmGrey02,
  backgroundFifth: palette.grey09,
  backgroundFifthActive: palette.grey08,
  backgroundFifthHover: palette.warmGrey05,
  backgroundSixth: palette.grey07,
  backgroundSeventh: palette.primary05,
  backgroundEight: palette.grey09,
  backgroundNinth: palette.grey07,
  backgroundTenth: palette.grey11,
  backgroundTenthHover: palette.grey12,
  backgroundAccent: palette.primary01,
  backgroundAccentActive: palette.grey09,
  backgroundAccentFocus: palette.primary03,
  backgroundAccentHover: palette.primary02,
  backgroundAccentDisabled: palette.grey06,
  backgroundContrast: palette.white,
  borderPrimary: palette.grey06,
  borderPrimaryActive: palette.primary01,
  borderPrimaryFocus: palette.primary02,
  borderPrimaryDisabled: palette.grey11,
  borderSecondary: palette.grey06,
  borderThird: palette.white,
  borderAccent: palette.grey05,
  textWhite: palette.white,
  textPrimary: palette.white,
  textSecondary: palette.warmGrey04,
  textThird: palette.grey06,
  textFourth: palette.grey06,
  textDisabled: palette.grey06,
  textAccent: palette.white,
  textCorrect: palette.success02,
  textIncorrect: palette.error05,
  shadowPrimary: palette.grey06,
  shadowSecondary: palette.grey07,
  overlay: palette.black,
};
