import { createSelector } from 'reselect';

import { RegionElementVM } from '@/core/models/data-center-regions';
import { ConsentDocumentVM } from '@/models/consents';
import { authenticationReducer } from './reducer';

export type MakeGetUserData = authenticationReducer['authentication']['userData'];

export const getAuthentication = (state: authenticationReducer) => state.authentication;
export const getTokenStatus = (state: authenticationReducer) => getAuthentication(state).tokenStatus;

export const makeGetUserData = () =>
  createSelector<authenticationReducer, authenticationReducer['authentication'], MakeGetUserData>(getAuthentication, ({ userData }) =>
    userData !== undefined ? userData : undefined
  );

export type MakeGetUserStatus = {
  isLogged: boolean;
  isDataLoading: boolean;
  roles: string[];
  consents: ConsentDocumentVM[];
  currentRegion: RegionElementVM | undefined;
  defaultRegion: RegionElementVM | undefined;
};

export const makeGetUserStatus = () =>
  createSelector<authenticationReducer, authenticationReducer['authentication'], MakeGetUserStatus>(
    getAuthentication,
    ({ userData, isLoading, defaultRegion, currentRegion }) => ({
      defaultRegion,
      currentRegion,
      isLogged: userData !== undefined,
      isDataLoading: isLoading,
      roles: userData?.roles || [],
      consents: userData?.consents || [],
    })
  );
