import { createReducer } from 'typesafe-actions';

import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { ReviewApplicationsDictionaryVM } from '@/models/reviews';
import { FeatureStateType } from '../../helpers';
import { galleryActions } from '../actions';

type applicationState = DictionaryState<ReviewApplicationsDictionaryVM>;

const INITIAL_STATE: applicationState = getDictionaryInitialState<ReviewApplicationsDictionaryVM>();

const reducer = createReducer<applicationState, galleryActions>(INITIAL_STATE)
  .handleAction(galleryActions.application.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(galleryActions.application.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(galleryActions.application.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(galleryActions.application.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(galleryActions.application.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const applicationDictionaryReducer = { application: reducer };
export type applicationDictionaryReducer = FeatureStateType<typeof applicationDictionaryReducer>;
