import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const checkBoxStyles: StyleFunction = ({ theme }) => ({
  position: 'relative',

  '.checkbox-control': {
    position: 'absolute',
    opacity: 0,
    height: 0,
    width: 0,
  },

  '.label': {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',

    '&__text': {
      paddingLeft: constants.offset.small,
      fontSize: constants.fontSize.medium,
      color: theme.textPrimary,
      transition: 'color .2s',
    },

    '&__mark': {
      position: 'relative',
      width: 18,
      height: 18,
      border: `1px solid ${theme.borderSecondary}`,
      borderRadius: constants.borderRadius.small,
      backgroundColor: theme.backgroundPrimary,
      transitionProperty: 'background-color, border-color, box-shadow',
      transitionDuration: '.2s',

      ':after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: 'block',
        backgroundImage: 'url(/images/icons/mdi-check.svg)',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '90% auto',
        opacity: 0,
        transition: 'opacity .2s',
      },
    },
  },

  '.checkbox-control:checked + .label > .label__mark': {
    backgroundColor: theme.primary,

    ':after': {
      opacity: 1,
    },
  },

  '.checkbox-control:not(:disabled):focus + .label > .label__mark': {
    boxShadow: `0px 0px 0px 3px ${theme.primaryFocus}`,
  },

  '.checkbox-control:disabled + .label': {
    cursor: 'not-allowed',

    '> .label__text': {
      color: theme.primaryDisabled,
    },

    '> .label__mark': {
      borderColor: theme.primaryDisabled,
      backgroundColor: theme.primaryDisabled,
    },
  },

  '.error': {
    '.label': {
      '&__mark': {
        borderColor: theme.error,
        backgroundColor: theme.error,
      },
    },
  },

  '& + &': {
    marginTop: constants.offset.small,
  },

  '&.inline': {
    marginTop: constants.offset.large,
    marginRight: 40,
  },
});
