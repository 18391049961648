import { combineReducers } from 'redux';

import { FeatureStateType } from '@/store/helpers';
import { userDetailsReducer } from './details';
import { userListReducer } from './list';
import { searchByOptionsReducer } from './search-by-options';
import { userAppsReducer } from './users-apps';
import { appDictionaryReducer } from './app-dictionary';
import { platformDictionaryReducer } from './platform-dictionary';
import { historyChangesReducer } from './history-changes';

export type usersReducer = FeatureStateType<typeof usersReducer>;
export const usersReducer = {
  users: combineReducers({
    ...userDetailsReducer,
    ...userListReducer,
    ...searchByOptionsReducer,
    ...userAppsReducer,
    ...appDictionaryReducer,
    ...platformDictionaryReducer,
    ...historyChangesReducer,
  }),
};
