import { createReducer } from 'typesafe-actions';

import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { TourTypesDictionaryVM } from '@/features/content/models/tours';
import { FeatureStateType } from '../../helpers';
import { toursActions } from '../actions';

type typesState = DictionaryState<TourTypesDictionaryVM>;

const INITIAL_STATE: typesState = getDictionaryInitialState<TourTypesDictionaryVM>();

const reducer = createReducer<typesState, toursActions>(INITIAL_STATE)
  .handleAction(toursActions.types.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(toursActions.types.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(toursActions.types.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(toursActions.types.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(toursActions.types.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const tourTypeDictionaryReducer = { types: reducer };
export type tourTypeDictionaryReducer = FeatureStateType<typeof tourTypeDictionaryReducer>;
