import { Epic } from 'redux-observable';
import { switchMap, filter, map } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { CommonError } from '@/common/utils';
import { getRouteAnalyticsListData } from '@/features/reporting/services';
import { routeAnalyticsActions } from './actions';
import { routeAnalyticsReducer } from './reducers';

const getRouteAnalyticsList: Epic<routeAnalyticsActions, routeAnalyticsActions, routeAnalyticsReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(routeAnalyticsActions.list.request)),
    switchMap(() =>
      getRouteAnalyticsListData(state$.value.routeAnalytics.list.params).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return routeAnalyticsActions.list.failure(response);
          }

          return routeAnalyticsActions.list.success(response);
        })
      )
    )
  );

export const routeAnalyticsEpic = [getRouteAnalyticsList];
