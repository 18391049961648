import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { getEnv, CommonError } from '@/common/utils';
import { httpClient } from '@/core/services/http-client';
import { StatusesDictionaryVM, StatusesListResponse } from '@/models/statuses';
import { ReasonsDictionaryVM, ReasonsListResponse } from '@/models/reasons';
import { ContentTypesDictionaryVM, ContentTypesListResponse } from '@/models/content-types';
import { ContextTypesDictionaryVM, ContextTypesListResponse } from '@/models/context-types';
import { ApplicationsDictionaryVM, ApplicationsListResponse } from '@/models/applications';

const config = getEnv();

const STATUSES_ENDPOINT = `${config.REACT_APP_API_URL}/rating-and-review-api/v1/admin/moderation-requests/statuses`;
const REASONS_ENDPOINT = `${config.REACT_APP_API_URL}/rating-and-review-api/v1/admin/moderation-requests/reasons`;
const CONTENT_TYPES_ENDPOINT = `${config.REACT_APP_API_URL}/rating-and-review-api/v1/admin/moderation-requests/content-types`;
const CONTEXT_TYPES_ENDPOINT = `${config.REACT_APP_API_URL}/rating-and-review-api/v1/admin/moderation-requests/context-types`;
const APPLICATIONS_ENDPOINT = `${config.REACT_APP_API_URL}/rating-and-review-api/v1/admin/moderation-requests/applications`;

export const getStatusesData = () =>
  httpClient()
    .authorized.get<StatusesListResponse>(STATUSES_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new StatusesDictionaryVM(data);
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getReasonsData = () =>
  httpClient()
    .authorized.get<ReasonsListResponse>(REASONS_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new ReasonsDictionaryVM(data);
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getContentTypesData = () =>
  httpClient()
    .authorized.get<ContentTypesListResponse>(CONTENT_TYPES_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new ContentTypesDictionaryVM(data);
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getContextTypesData = () =>
  httpClient()
    .authorized.get<ContextTypesListResponse>(CONTEXT_TYPES_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new ContextTypesDictionaryVM(data);
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getApplicationsData = () =>
  httpClient()
    .authorized.get<ApplicationsListResponse>(APPLICATIONS_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new ApplicationsDictionaryVM(data);
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
