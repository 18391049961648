import { EditorCard } from '../../models';
import { HeaderCard } from './header-card';
import { ParagraphCard } from './paragraph-card';
import { AudioCard } from './audio-card';
import { ImageCard } from './image-card';
import { VideoCard } from './video-card';
import { AccordionCard } from './accordion-card';
import { ListCard } from './list-card';

export const CardList: EditorCard[] = [HeaderCard, ParagraphCard, ListCard, AudioCard, ImageCard, VideoCard, AccordionCard];
