import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { stringify } from 'qs';

import { CommonError, DateType, formatDateToISOString, getEnv } from '@/common/utils';
import { httpClient } from '@/core/services/http-client';
import { TourAnalyticsElementVM, TourAnalyticsListResponse, TourAnalyticsListVM, TourAnalyticsParams } from '../../models/tour-analytics';

const config = getEnv();

const TOUR_ANALYTICS_LIST_ENDPOINT = `${config.REACT_APP_API_URL}/entitlement-api/v1/adminPanel/entitlements/tour/redeems`;

export const getTourAnalyticsListData = ({ dateFrom, dateTo, organisationId, ...params }: TourAnalyticsParams) =>
  httpClient()
    .authorized.get<TourAnalyticsListResponse>(TOUR_ANALYTICS_LIST_ENDPOINT, {
      params: {
        dateFrom: dateFrom ? formatDateToISOString(dateFrom, DateType.FROM) : undefined,
        dateTo: dateTo ? formatDateToISOString(dateTo, DateType.TO) : undefined,
        organisationId: organisationId?.value,
        ...params,
      },
      paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' }),
    })
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== void 0) {
          return new TourAnalyticsListVM({ data, VM: TourAnalyticsElementVM });
        }

        throw void 0;
      }),
      catchError(error => of(new CommonError({ code: '500', message: error })))
    );
