import { combineReducers } from 'redux';

import { FeatureStateType } from '@/store/helpers';
import { categoriesDictionaryReducer } from './categories';
import { tagsDictionaryReducer } from './tags';

export type commonDictionariesReducer = FeatureStateType<typeof commonDictionariesReducer>;

export const commonDictionariesReducer = {
  commonDictionaries: combineReducers({
    ...categoriesDictionaryReducer,
    ...tagsDictionaryReducer,
  }),
};
