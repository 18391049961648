import { createAction } from 'typesafe-actions';
import { StoreEnhancer, Reducer } from 'redux';

export const resetStoreAction = createAction('[ROOT] reset store')();

export const resetMiddleware = (): StoreEnhancer<any, any> => next => (reducer, preloadedState) => {
  const enhanceReducer: Reducer = (state, action) => {
    if (action.type === resetStoreAction().type) {
      return reducer(undefined, action as any);
    }

    return reducer(state, action as any);
  };

  return next(enhanceReducer, preloadedState);
};
