import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const navigationStyles: StyleFunction = ({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  textDecoration: 'none',
  marginRight: constants.offset.large,
  color: theme.textPrimary,
  fontSize: constants.fontSize.h6,
  boxSizing: 'border-box',
  borderBottom: '3px solid transparent',

  '&.selected': {
    borderColor: theme.primary,
    color: theme.primary,

    span: {
      color: theme.primary,
    },

    '&:focus': {
      borderColor: theme.primaryFocus,
      color: theme.primaryFocus,
      outline: 'none',
    },
  },

  '&:hover': {
    borderColor: theme.primaryHover,
    color: theme.primaryHover,
  },

  '&:focus:not(.selected)': {
    color: theme.primaryFocus,
    outline: 'none',

    '.inner-text': {
      boxShadow: `0px 0px 0px 3px ${theme.primaryFocus}`,
    },
  },

  '&.disabled': {
    color: theme.textDisabled,
    cursor: 'not-allowed',

    '&:hover': {
      color: theme.textDisabled,
    },
  },

  '.inner-text': {
    padding: '2px 4px',
    transition: 'box-shadow .2s',
    borderRadius: constants.borderRadius.small,
    color: theme.textPrimary,
  },
});
