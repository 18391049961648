import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { stringify } from 'qs';
import pick from 'lodash/pick';

import { httpClient } from '@/core/services/http-client';
import { getEnv, CommonError } from '@/common/utils';
import {
  CommunityStoriesListResponse,
  CommunityStoriesListVM,
  CommunityStoriesParams,
  CommunityStoryElementVM,
  UserCommunityStoriesListVM,
} from '../../models/community-stories';

const config = getEnv();

const CONTENT_COMMUNITY_STORIES_LIST_ENDPOINT = `${config.REACT_APP_API_URL}/story-api/v1/admin/standalone-stories/search`;

export const getCommunityStoriesData = (data: CommunityStoriesParams) => {
  return httpClient()
    .authorized.post<CommunityStoriesListResponse>(
      CONTENT_COMMUNITY_STORIES_LIST_ENDPOINT,
      {
        createdBy: data.createdBy?.value,
        text: data.text,
        categoryIds: data.categoryIds,
        tagIds: data.tagIds,
        ageRecommendations: data.ageRecommendations,
      },
      {
        params: pick(data, 'page', 'size', 'sort'),
        paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' }),
      }
    )
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new CommunityStoriesListVM({ data, VM: CommunityStoryElementVM });
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

export const getUserCommunityStories = (params: CommunityStoriesParams) =>
  httpClient()
    .authorized.post(
      CONTENT_COMMUNITY_STORIES_LIST_ENDPOINT,
      { createdBy: params.createdBy?.value },
      {
        params: pick(params, 'page', 'size', 'sort'),
        paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' }),
      }
    )
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new UserCommunityStoriesListVM({ data, VM: CommunityStoryElementVM });
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getOSMapsCommunityStoryUrl = (lon: number, lat: number) => `${config.REACT_APP_OS_MAPS_URL}/pin?lat=${lat}&lon=${lon}&zoom=15`;
