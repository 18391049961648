import { switchMap, filter } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { getDifficultiesData } from '@/features/content/services/dictionaries';
import { dictionaryEffectHelper } from '@/common/utils';
import { difficultiesActions } from './actions';

const getDifficultiesList: Epic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(difficultiesActions.request)),
    switchMap(() => {
      const state = state$.value.difficulties;
      return dictionaryEffectHelper(() => getDifficultiesData(), state, difficultiesActions);
    })
  );

export const difficultiesEpics = [getDifficultiesList];
