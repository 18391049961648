import React, { useCallback, useState, ComponentType, createElement, ReactElement, Dispatch, SetStateAction, useContext } from 'react';
import { useField, FieldInputProps, FieldMetaProps, FieldHelperProps, FieldValidator } from 'formik';
import { ValidationError } from 'yup';
import cn from 'classnames';

import { IconButton } from '@/common/components/icon-button';
import { Icon } from '@/common/components/icon';
import { Enum } from '@/common/utils';
import { useStyles } from '@/styles/hooks';
import { EditorCardProps } from '../../../../models';
import { DisabledStoryContentEditorContext } from '../../../story-content-field/disabled-story-content-editor-context';
import { CommonEditorCardStatus, CommonEditorCardStatusElement } from '../common-editor-card-status-element';
import { commonCardStyle } from './styles';

export type ErrorTypes = Enum<typeof ErrorTypes>;
export const ErrorTypes = Enum('STRING', 'VALIDATION_ERROR');

type InnerStatus = {
  type: CommonEditorCardStatus;
  text?: string;
};

type CommonEditorCardBag<T = any> = {
  isEditable: boolean;
  formField: [FieldInputProps<T>, FieldMetaProps<T>, FieldHelperProps<T>];
  setStatus: Dispatch<SetStateAction<InnerStatus>>;
  id: string;
};

export type CommonEditorCardInnerComponent<T = any> = ComponentType<CommonEditorCardBag<T>>;

type Props<T = any> = EditorCardProps & {
  headerComponent: CommonEditorCardInnerComponent<T>;
  contentComponent?: CommonEditorCardInnerComponent<T>;
  errorType?: ErrorTypes;
  errorPath?: string | undefined;
  validate: () => FieldValidator;
};

export function CommonEditorCard<T = any>({
  id,
  provided,
  item,
  snapshot,
  fieldArrayRender,
  index,
  headerComponent,
  contentComponent,
  validate,
  errorType = 'STRING',
  errorPath = undefined,
}: Props<T>): ReactElement {
  const [isEditable, setIsEditable] = useState<CommonEditorCardBag['isEditable']>(false);
  const [innerStatus, setInnerStatus] = useState<InnerStatus>({ type: CommonEditorCardStatus.VALIDATION_ERROR });

  const handleOnDelete = useCallback(() => fieldArrayRender.remove(index), [fieldArrayRender.remove, index]);
  const handleOnEdit = useCallback(() => setIsEditable(oldValue => !oldValue), []);

  const { styles } = useStyles(commonCardStyle);

  const formField = useField({ validate: validate(), name: `${fieldArrayRender.name}[${index}].payload` });
  const [, meta] = formField;

  const commonEditorCardBag: CommonEditorCardBag = {
    isEditable,
    formField,
    id,
    setStatus: setInnerStatus,
  };

  const headerComponentElement = createElement(headerComponent, commonEditorCardBag);

  let contentComponentElement: ReturnType<typeof createElement> | undefined = undefined;
  if (contentComponent !== undefined) {
    contentComponentElement = createElement(contentComponent, commonEditorCardBag);
  }

  const findErrorMessage = useCallback(() => {
    const errors = meta.error as unknown as ValidationError[];
    if (errors && errors.length > 0) {
      for (const error of errors) {
        if (error.path === errorPath) {
          return error.message;
        }
      }
      return undefined;
    }
  }, [meta.error]);

  const isDisabled = useContext(DisabledStoryContentEditorContext);

  const errorMessage: string | undefined = errorType === 'VALIDATION_ERROR' ? findErrorMessage() : undefined;
  return (
    <div {...provided.draggableProps} ref={provided.innerRef} css={styles} className='entity'>
      <div className='entity__wrapper'>
        <div className={cn(['entity__header header', contentComponentElement !== undefined && 'entity__header--square-bottom-corners'])}>
          <div className={cn(['header__handle', snapshot.isDragging && 'header__handle--is-dragging'])} {...provided.dragHandleProps}>
            <Icon name='mdi-view-headline' className={cn(['icon', isDisabled && 'icon--disabled'])} />
          </div>

          <div className='header__icon'>
            <Icon name={item.iconName} className='icon' />
          </div>

          <div className='header__content'>{headerComponentElement}</div>

          <div className='header__status'>
            {errorType === 'STRING' && innerStatus.type === CommonEditorCardStatus.VALIDATION_ERROR && meta.error && meta.touched && (
              <CommonEditorCardStatusElement status='ERROR' text={innerStatus.text || meta.error} />
            )}
            {errorMessage && innerStatus.type === CommonEditorCardStatus.VALIDATION_ERROR && meta.error && meta.touched && (
              <CommonEditorCardStatusElement status='ERROR' text={errorMessage} />
            )}
            {innerStatus.type !== CommonEditorCardStatus.VALIDATION_ERROR && (
              <CommonEditorCardStatusElement status={innerStatus.type} text={innerStatus.text || ''} />
            )}
          </div>

          <div className='header__actions'>
            <IconButton type='button' theme='error' iconName='mdi-delete' className='action' onClick={handleOnDelete} disabled={isDisabled} />
            <IconButton type='button' iconName={isEditable ? 'mdi-pencil-off' : 'mdi-pencil'} className='action' onClick={handleOnEdit} disabled={isDisabled} />
          </div>
        </div>

        {contentComponentElement !== undefined ? <div className='entity__container'>{contentComponentElement}</div> : null}
      </div>
    </div>
  );
}
