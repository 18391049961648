import { switchMap, filter, map } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { CommonError, dictionaryEffectHelper } from '@/common/utils';
import { getSubscriptionsData, getSmartMapsData, getOneTimeProductsData, getSubscriptionsProductsData } from '@/features/accounts/services/entitlements';
import { entitlementsReducer } from './reducers';
import { entitlementsActions } from './actions';

export const getSubscriptionsList: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(entitlementsActions.subscriptions.request)),
    switchMap(action =>
      getSubscriptionsData(action.payload.params).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return entitlementsActions.subscriptions.failure(response);
          }

          return entitlementsActions.subscriptions.success(response);
        })
      )
    )
  );

export const getSmartMapsList: Epic<entitlementsActions, entitlementsActions, entitlementsReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(entitlementsActions.smartMaps.request)),
    switchMap(() =>
      getSmartMapsData(state$.value.entitlements.smartMaps.params).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return entitlementsActions.smartMaps.failure(response);
          }

          return entitlementsActions.smartMaps.success(response);
        })
      )
    )
  );

export const getSmartMapsTotalCount: Epic<entitlementsActions, entitlementsActions, entitlementsReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(entitlementsActions.smartMapsTotalCount.request)),
    switchMap(() =>
      getSmartMapsData({ ...state$.value.entitlements.smartMaps.params, name: '' }).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return entitlementsActions.smartMapsTotalCount.failure(response);
          }

          return entitlementsActions.smartMapsTotalCount.success(response.listPaginationData.totalElements);
        })
      )
    )
  );

export const getOneTimeProductsTotalCount: Epic<entitlementsActions, entitlementsActions, entitlementsReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(entitlementsActions.oneTimeProductsTotalCount.request)),
    switchMap(() =>
      getOneTimeProductsData({ ...state$.value.entitlements.oneTimeProducts.params, name: '' }).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return entitlementsActions.oneTimeProductsTotalCount.failure(response);
          }

          return entitlementsActions.oneTimeProductsTotalCount.success(response.listPaginationData.totalElements);
        })
      )
    )
  );

export const getOneTimeProductsList: Epic<entitlementsActions, entitlementsActions, entitlementsReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(entitlementsActions.oneTimeProducts.request)),
    switchMap(() =>
      getOneTimeProductsData(state$.value.entitlements.oneTimeProducts.params).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return entitlementsActions.oneTimeProducts.failure(response);
          }

          return entitlementsActions.oneTimeProducts.success(response);
        })
      )
    )
  );

const getSubscriptionsProductsDictionary: Epic<entitlementsActions, entitlementsActions, entitlementsReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(entitlementsActions.subscriptionsProducts.request)),
    switchMap(() => {
      const state = state$.value.entitlements.subscriptionsProducts;
      return dictionaryEffectHelper(() => getSubscriptionsProductsData(), state, entitlementsActions.subscriptionsProducts);
    })
  ) as any;

export const entitlementsEpics = [
  getSubscriptionsList,
  getSmartMapsList,
  getOneTimeProductsList,
  getSmartMapsTotalCount,
  getOneTimeProductsTotalCount,
  getSubscriptionsProductsDictionary,
];
