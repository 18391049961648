import { BehaviorSubject, defer, from, of, timer } from 'rxjs';
import { catchError, filter, switchMap, take, tap, map, mapTo } from 'rxjs/operators';
import { type AuthError, PublicClientApplication } from '@azure/msal-browser';

import { getAuthentication, authenticationActions } from '@/store/authentication';
import { rxStore, checkUserPermissions } from '@/common/utils';
import { loginRedirectRequestConfig, logoutRedirectRequestConfig, silentRequestConfig, ssoSilentRequestConfig, msalConfig } from './config';
import { ACCOUNT_HUB_ERROR, WAIT_TIME } from './constants';
import { getActiveAccount, triggerLocalStorageLoginEvent } from './helpers';
import { AccountHubPolicy, HandleRedirectPromiseParams, LoginParams, LogoutParams, RequestParams, Status, UserProfileParams } from './models';

const createMSAL = () => {
  const msalInstance = new PublicClientApplication(msalConfig);
  const status$ = new BehaviorSubject<Status>(Status.initial);

  const changeStatus = (status: Status) => status$.next(status);

  const handleRedirectPromise = ({ store }: HandleRedirectPromiseParams) => {
    changeStatus(Status.handleRedirect);
    return from(msalInstance.handleRedirectPromise()).pipe(
      switchMap(authenticationResult => {
        const activeAccount = getActiveAccount(msalInstance);
        const accountData = activeAccount.valid && activeAccount.account ? authenticationResult : null;
        if (!activeAccount.valid) {
          return from(logout({ reason: 'LOGOUT' })).pipe(mapTo(null));
        }

        changeStatus('none');
        return userProfile({ store }).pipe(
          switchMap(userData => {
            if (userData) {
              triggerLocalStorageLoginEvent();
            }

            return of(accountData);
          })
        );
      }),
      catchError((error: AuthError) => {
        if (error?.errorMessage) {
          const errorCode = /^[^:]*/.exec(error.errorMessage);
          if (errorCode) {
            switch (errorCode[0]) {
              case ACCOUNT_HUB_ERROR.resetPasswordRedirection: {
                msalInstance.loginRedirect(loginRedirectRequestConfig({ policy: AccountHubPolicy.B2C_1A_PasswordReset }));
                break;
              }

              case ACCOUNT_HUB_ERROR.exceededMaximumNumberForRetriesForSignup:
              case ACCOUNT_HUB_ERROR.cancelledProcess: {
                console.warn('[ADB2C] - process error');
                break;
              }

              default: {
              }
            }
          }
        }

        return timer(WAIT_TIME).pipe(
          mapTo(null),
          tap(() => changeStatus('none'))
        );
      })
    );
  };

  const getSession = () =>
    status$.pipe(
      filter(status => status === Status.none || status === Status.initial),
      take(1),
      switchMap(() => {
        changeStatus(Status.sso);
        const activeAccount = getActiveAccount(msalInstance);
        if (!activeAccount.valid) {
          return from(logout({ reason: 'LOGOUT' })).pipe(mapTo(undefined));
        }

        return defer(() =>
          activeAccount.account ? from(msalInstance.acquireTokenSilent(silentRequestConfig())).pipe(catchError(() => of(undefined))) : of(undefined)
        ).pipe(
          switchMap(authenticationResult =>
            authenticationResult === undefined
              ? from(msalInstance.ssoSilent(ssoSilentRequestConfig())).pipe(catchError(() => of(undefined)))
              : of(authenticationResult)
          )
        );
      }),
      tap(() => changeStatus('none'))
    );

  const login = (loginParams?: LoginParams, adb2cRequestParams?: Omit<RequestParams, 'state'>) => {
    const initialUrl = loginParams?.initialUrl;
    let state: string | undefined = undefined;

    if (initialUrl) {
      state = `{"initialUrl": "${initialUrl}"}`;
    }

    return msalInstance.loginRedirect(loginRedirectRequestConfig({ ...adb2cRequestParams, state }));
  };

  const logout = (props?: LogoutParams) => {
    return msalInstance.logoutRedirect(logoutRedirectRequestConfig({ reason: props?.reason }));
  };

  const userProfile = ({ store }: UserProfileParams) =>
    rxStore(store).pipe(
      map(getAuthentication),
      filter(({ isLoading }) => !isLoading),
      take(1),
      switchMap(({ userData }) => {
        if (userData === undefined) {
          store.dispatch(authenticationActions.getUserData());
          return rxStore(store).pipe(
            map(getAuthentication),
            filter(({ isLoading }) => !isLoading),
            map(({ userData }) => userData),
            take(1)
          );
        }

        return of(userData);
      }),
      switchMap(userData => {
        const emptyUserData = userData === undefined;
        const accessDenied = !checkUserPermissions({ subject: 'ADMIN_PANEL', scope: 'ACCESS' }, userData?.roles || []);
        if (emptyUserData || accessDenied) {
          return from(logout({ reason: emptyUserData ? 'PROFILE_ERROR' : 'NOT_AUTHORIZED' })).pipe(mapTo(undefined));
        }

        return of(userData);
      })
    );

  return {
    login,
    logout,
    handleRedirectPromise,
    getSession,
    userProfile,
    instance: msalInstance,
  };
};

export default createMSAL();
