import { switchMap, filter, map } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { untilLocationChange, CommonError } from '@/common/utils';
import { getCommunityStoriesData, getCommunityStoryDetailsData } from '@/features/content/services/community-stories';
import { communityStoriesReducer } from './reducers';
import { communityStoriesActions } from './actions';

const getCommunityStoriesList: Epic<communityStoriesActions, communityStoriesActions, communityStoriesReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(communityStoriesActions.list.request)),
    switchMap(() =>
      getCommunityStoriesData(state$.value.communityStories.list.params).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return communityStoriesActions.list.failure(response);
          }

          return communityStoriesActions.list.success(response);
        })
      )
    )
  );

const getCommunityStoryDetails: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(communityStoriesActions.details.request)),
    switchMap(action =>
      getCommunityStoryDetailsData(action.payload.params)
        .pipe(untilLocationChange(action$))
        .pipe(
          map(response => {
            if (response instanceof CommonError) {
              return communityStoriesActions.details.failure(response);
            }

            return communityStoriesActions.details.success(response);
          })
        )
    )
  );

export const communityStoriesEpics = [getCommunityStoriesList, getCommunityStoryDetails];
