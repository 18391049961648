import React, { FC } from 'react';
import { FieldProps as FormikFieldProps } from 'formik';
import cn from 'classnames';

import { Field, FieldProps } from '../field';
import { DatePickerDeprecated, DatePickerProps } from './datepicker-deprecated';
import { DatePickerValueWithReason as ValueWithReason } from './constants';

type FieldDatepickerProps = Omit<FieldProps, 'children'> &
  Omit<DatePickerProps, 'onChange' | 'value'> & {
    className?: string;
  };

export const FieldDatepickerDeprecated: FC<FieldDatepickerProps> = ({ name, label, labelIcon, isFocusOnLabelEnabled, className, displayFormat, ...props }) => {
  const DatePickerField = ({ field: { value, ...field }, meta: { touched, error }, form }: FormikFieldProps<ValueWithReason>) => {
    const inputClassName = cn([{ error: touched && error }]);
    return <DatePickerDeprecated {...props} {...field} value={value?.value} className={inputClassName} form={form} name={name} displayFormat={displayFormat} />;
  };

  return (
    <Field name={name} label={label} labelIcon={labelIcon} isFocusOnLabelEnabled={isFocusOnLabelEnabled} className={className}>
      {DatePickerField}
    </Field>
  );
};
