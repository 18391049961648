import { AxiosRequestConfig } from 'axios';
import { Axios } from 'axios-observable';
import { Store } from 'redux';
import { map, filter, take, switchMap } from 'rxjs/operators';
import { timer } from 'rxjs/internal/observable/timer';
import type { AuthenticationResult } from '@azure/msal-browser';

import { rxStore } from '@/common/utils';
import { getTokenStatus, authenticationActions } from '@/store/authentication';
import { REQ_AUTHORIZATION_HEADER } from '../../authentication';
import { msal } from '../../authentication/msal';

const AUTHENTICATION_PROMISE_REJECT = { response: { status: 403 } };

function configClientWithAccessToken(config: AxiosRequestConfig, authenticationResult: AuthenticationResult) {
  config.headers = {
    ...config.headers,
    [REQ_AUTHORIZATION_HEADER]: `${authenticationResult.tokenType} ${authenticationResult.accessToken}`,
  };

  return config;
}

export const authenticationInterceptor = (instance: Axios, storeInstance: Store) => {
  instance.interceptors.request.use(async config => {
    const isTokenValid = await rxStore(storeInstance)
      .pipe(
        map(getTokenStatus),
        filter(tokenStatus => tokenStatus !== 'REFRESHING'),
        take(1)
      )
      .toPromise();

    if (isTokenValid !== 'INVALID') {
      const authenticationResult = await msal.getSession().toPromise();
      if (authenticationResult !== undefined) {
        if (isTokenValid === 'EMPTY') {
          storeInstance.dispatch(authenticationActions.setTokenStatus('VALID'));
        }

        return configClientWithAccessToken(config, authenticationResult);
      }
    }

    return Promise.reject(AUTHENTICATION_PROMISE_REJECT);
  });

  instance.interceptors.response.use(undefined, async error => {
    if (error?.response?.status === 401) {
      storeInstance.dispatch(authenticationActions.setTokenStatus('REFRESHING'));
      const authenticationResult = await msal.getSession().toPromise();
      if (authenticationResult !== undefined) {
        storeInstance.dispatch(authenticationActions.setTokenStatus('VALID'));
        return instance.request(configClientWithAccessToken(error?.response.config, authenticationResult)).toPromise();
      }

      storeInstance.dispatch(authenticationActions.setTokenStatus('INVALID'));
      await msal.logout({ reason: 'LOGOUT' });
    }

    if (error?.response?.status === 429) {
      return timer(2000)
        .pipe(switchMap(() => instance.request(error.response.config)))
        .toPromise();
    }

    return Promise.reject({ errorMessage: error?.message, code: error?.response?.status, data: error?.response?.data });
  });
};
