import React, { FC } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { useStyles } from '@/styles/hooks';
import { NavGroup } from '../../../../models';
import { navigationGroupStyles } from './styles';

type Props = {
  data: NavGroup;
};

export const NavigationGroup: FC<Props> = ({ data }) => {
  const [t] = useTranslation();
  const match = useRouteMatch({ path: data.url });
  const { styles } = useStyles(navigationGroupStyles);

  return (
    <Link to={data.url} css={styles} className={cn({ selected: match })}>
      {t(data.label)}
    </Link>
  );
};
