import React, { ReactText } from 'react';
import { TypeOptions, toast as createToast } from 'react-toastify';
import remove from 'lodash/remove';
import find from 'lodash/find';

import { Toast } from '../components/toast';

const MAX_TOASTS = 5;
const AUTO_CLOSE_DELAY = 5000;

export const createToastInstance = () => {
  const toastList: { type: TypeOptions; id: ReactText; index: number }[] = [];
  type ShowToast = {
    text: string;
    type: TypeOptions;
    displayTime?: number;
  };

  const showToast = ({ text, type, displayTime }: ShowToast) => {
    const index = toastList.length;
    const id = createToast(({ closeToast }: any) => <Toast text={text} type={type} closeToast={closeToast} />, {
      autoClose: displayTime || AUTO_CLOSE_DELAY,
      className: `toast-wrapper toast-wrapper--${type}`,
      closeButton: false,
      closeOnClick: false,
      draggable: false,
      hideProgressBar: true,
      pauseOnHover: true,
      position: 'bottom-right',
      onClose: () => {
        remove(toastList, toast => toast.id === id);
      },
    });

    if (toastList.length >= MAX_TOASTS) {
      const id = (find(toastList, toast => toast.type !== 'error') || toastList[0])?.id;
      if (id) {
        remove(toastList, toast => toast.id === id);
        createToast.dismiss(id);
      }
    }
    toastList.push({ type, id, index });
  };

  const show = (toastParams: ShowToast) => showToast(toastParams);

  return { show };
};

export const toast = createToastInstance();
