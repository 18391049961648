import { ActionType, Action, createAction } from 'typesafe-actions';

import { createDictionaryActions, createFetchActions } from '@/common/utils/store';
import {
  VoucherBatchDetailsRequest,
  VoucherBatchDetailsVM,
  VoucherBatchStatusesDictionaryVM,
  VoucherBatchListVM,
  GeneratedVoucherBatchElementVM,
  VoucherBatchListRequest,
} from '@/models/voucher-batch';

export const voucherBatchActions = {
  list: createFetchActions('FETCH_VOUCHER_BATCH_LIST_REQUEST', 'FETCH_VOUCHER_BATCH_LIST_SUCCESS', 'FETCH_VOUCHER_BATCH_LIST_FAILURE')<
    VoucherBatchListRequest,
    VoucherBatchListVM
  >(),
  clearVoucherBatchList: createAction('CLEAR_VOUCHER_BATCH_LIST')(),
  details: createFetchActions('FETCH_VOUCHER_BATCH_DETAILS_REQUEST', 'FETCH_VOUCHER_BATCH_DETAILS_SUCCESS', 'FETCH_VOUCHER_BATCH_DETAILS_FAILURE')<
    VoucherBatchDetailsRequest,
    VoucherBatchDetailsVM
  >(),
  statuses: createDictionaryActions(
    '[VOUCHER STATUSES DICTIONARY] FETCH REQUEST',
    '[VOUCHER STATUSES DICTIONARY] FETCH SUCCESS',
    '[VOUCHER STATUSES DICTIONARY] FETCH FAILURE',
    '[VOUCHER STATUSES DICTIONARY] FETCH CANCEL',
    '[VOUCHER STATUSES DICTIONARY] FETCH SILENT REQUEST'
  )<undefined, VoucherBatchStatusesDictionaryVM>(),
  clearVoucherBatchDetails: createAction('CLEAR_VOUCHER_BATCH_DETAILS')(),
  setVoucherBatchConfigValue: createAction('SET_VOUCHER_BATCH_CONFIG_STATE')<{ stringifiedBatchConfig: string; batchType: string }>(),
  setVoucherBatchListValue: createAction('SET_VOUCHER_BATCH_LIST_STATE')<GeneratedVoucherBatchElementVM[]>(),
  updateVoucherBatchEntityCount: createAction('UPDATE_VOUCHER_BATCH_ENTITY_COUNT')<{ id: string; count: number }>(),
  clearAddVoucherBatchData: createAction('CLEAR_ADD_VOUCHER_BATCH_DATA')(),
};

export type voucherBatchActions = Action | ActionType<typeof voucherBatchActions>;
