import React, { FC, HTMLAttributes, useCallback, ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UsePaginationInstanceProps } from 'react-table';

import { PAGE_SIZE } from '@/common/utils';
import { useStyles } from '@/styles/hooks';
import { IconButton } from '../../../icon-button';
import { Select, SelectOption } from '../../../form-controls-deprecated';
import { paginatorStyles } from './styles';

export type PaginatorData = {
  pageIndex: number;
  pageSize: number;
};

export type PaginatorProps = HTMLAttributes<HTMLElement> & Omit<UsePaginationInstanceProps<object>, 'page'> & PaginatorData;

export const Paginator: FC<PaginatorProps> = ({
  canNextPage,
  canPreviousPage,
  setPageSize,
  pageOptions,
  pageCount,
  pageIndex,
  pageSize,
  nextPage,
  previousPage,
  gotoPage,
}) => {
  const [t] = useTranslation();
  const [inputValue, setInputValue] = useState<number | string>(pageIndex + 1);
  const { styles } = useStyles(paginatorStyles);

  useEffect(() => {
    setInputValue(pageIndex + 1);
  }, [pageIndex]);

  useEffect(() => {
    if (pageIndex >= pageCount) {
      gotoPage(0);
    }
  }, [pageIndex, pageCount]);

  const handleSelectChange = useCallback(
    (option: SelectOption) => {
      const pageSize = Number(option.value);
      setPageSize(pageSize);
    },
    [setPageSize]
  );

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value !== '' ? Number(e.target.value || 0) : ''), []);

  const handlePageIndexChange = useCallback<(e: FormEvent) => void>(
    e => {
      e.preventDefault();
      const value = typeof inputValue === 'number' ? inputValue : 0;
      const calculatedPageIndex = (value > pageCount ? pageCount : value < 1 ? 1 : value) - 1;
      if (calculatedPageIndex === pageIndex) {
        setInputValue(calculatedPageIndex + 1);
      } else {
        gotoPage(calculatedPageIndex);
      }
    },
    [gotoPage, inputValue, pageIndex]
  );

  const handleGoToFirstPage = useCallback(() => gotoPage(0), [gotoPage]);
  const handleGoToLastPage = useCallback(() => gotoPage(pageCount - 1), [gotoPage, pageCount]);

  return (
    <div className='paginator' css={styles}>
      <div className='button-wrapper'>
        <IconButton onClick={handleGoToFirstPage} disabled={!canPreviousPage} iconName='mdi-page-first' />
        <IconButton onClick={previousPage} disabled={!canPreviousPage} iconName='mdi-chevron-left' />
        <IconButton onClick={nextPage} disabled={!canNextPage} iconName='mdi-chevron-right' />
        <IconButton onClick={handleGoToLastPage} disabled={!canNextPage} iconName='mdi-page-last' />
      </div>

      <div className='page-info'>
        <span className='label'>{t('table.pagination.page')}</span>
        <span className='page-number'>{t('table.pagination.oneOfMany', { current: pageIndex + 1, total: pageOptions.length })}</span>
      </div>

      <div className='page-input-wrapper'>
        <span className='label'> {t('table.pagination.goToPage')}</span>
        <form onSubmit={handlePageIndexChange} onBlur={handlePageIndexChange}>
          <input className='page-input' type='number' value={inputValue} onChange={handleInputChange} disabled={pageCount === 1} />
        </form>
      </div>

      <div className='page-select-wrapper'>
        <span className='label'> {t('table.pagination.show')}</span>
        <Select className='page-select' options={PAGE_SIZE} onChange={handleSelectChange} value={pageSize} />
      </div>
    </div>
  );
};
