import { createReducer } from 'typesafe-actions';

import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { ThingsToDoTagDetailsVM } from '@/features/content/models/things-to-do-tags';
import { FeatureStateType } from '../../helpers';
import { thingsToDoTagsActions } from '../actions';

export type thingsToDoTagDetailsState = FetchState<ThingsToDoTagDetailsVM>;

const INITIAL_STATE: thingsToDoTagDetailsState = getFetchInitialState<ThingsToDoTagDetailsVM>();

const reducer = createReducer<thingsToDoTagDetailsState, thingsToDoTagsActions>(INITIAL_STATE)
  .handleAction(thingsToDoTagsActions.details.request, state => fetchReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(thingsToDoTagsActions.details.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(thingsToDoTagsActions.details.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(thingsToDoTagsActions.clearDetails, () => ({ ...INITIAL_STATE }));

export const thingsToDoTagDetailsReducer = { details: reducer };
export type thingsToDoTagDetailsReducer = FeatureStateType<typeof thingsToDoTagDetailsReducer>;
