import { switchMap, filter, map } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { getModerationDetailsData } from '@/features/management/services/moderation/moderation-details';
import { getModerationData } from '@/features/management/services/moderation/moderation-list';
import { untilLocationChange, CommonError } from '@/common/utils';
import { moderationReducer } from './reducers';
import { moderationActions } from './actions';

const getModerationList: Epic<moderationActions, moderationActions, moderationReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(moderationActions.list.request)),
    switchMap(() =>
      getModerationData(state$.value.moderation.list.params).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return moderationActions.list.failure(response);
          }

          return moderationActions.list.success(response);
        })
      )
    )
  );

const getModerationDetails: Epic<moderationActions, moderationActions, moderationReducer> = action$ =>
  action$.pipe(
    filter(isActionOf(moderationActions.details.request)),
    switchMap(action =>
      getModerationDetailsData(action.payload.params)
        .pipe(untilLocationChange(action$))
        .pipe(
          map(response => {
            if (response instanceof CommonError) {
              return moderationActions.details.failure(response);
            }

            return moderationActions.details.success(response);
          })
        )
    )
  );

export const moderationEpics = [getModerationList, getModerationDetails];
