import { createReducer } from 'typesafe-actions';

import { fetchReducerHelpers, FetchState, getFetchInitialState } from '@/common/utils/store';
import { DEFAULT_LIST_PAGINATION_DATA } from '@/common/utils/pageable';
import { TourAnalyticsListVM, TourAnalyticsParams } from '@/features/reporting/models/tour-analytics';
import { FeatureStateType } from '@/store/helpers';
import { tourAnalyticsActions } from '../actions';

export type TourAnalyticsListState = FetchState<TourAnalyticsListVM, TourAnalyticsParams>;

const INITIAL_STATE: TourAnalyticsListState = getFetchInitialState<TourAnalyticsListVM, TourAnalyticsParams>({ sort: ['createdAt,DESC'] });

const reducer = createReducer<TourAnalyticsListState, tourAnalyticsActions>(INITIAL_STATE)
  .handleAction(tourAnalyticsActions.list.request, (state, { payload }) => fetchReducerHelpers.request(INITIAL_STATE, state, payload))
  .handleAction(tourAnalyticsActions.list.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(tourAnalyticsActions.list.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(tourAnalyticsActions.clearTourAnalyticsList, state => ({
    ...state,
    data: { ...state.data, list: [], listPaginationData: state.data?.listPaginationData || DEFAULT_LIST_PAGINATION_DATA },
  }));

export const tourAnalyticsListReducer = { list: reducer };
export type tourAnalyticsListReducer = FeatureStateType<typeof tourAnalyticsListReducer>;
