import React, { FC } from 'react';

import { ContentFragmentType } from '@/models/tours';
import { ACCORDION_VALIDATOR } from '@/common/utils';
import { CommonEditorCard } from '../components/common-editor-card';
import { EditorCard, EditorCardProps, EditorValue } from '../../../models';
import { AccordionCardHeaderComponent } from './accordion-card-header';
import { AccordionCardContentComponent } from './accordion-card-content';

export const ACCORDION_CARD_ID_PREFIX = 'ACCORDION_CARD';
export const ACCORDION_ALLOWED_CARDS = [
  ContentFragmentType.header,
  ContentFragmentType.paragraph,
  ContentFragmentType.image,
  ContentFragmentType.audio,
  ContentFragmentType.video,
] as ContentFragmentType[];

export type AccordionCardPayload = {
  title: string;
  body: EditorValue[];
};
export type AccordionCardValue = EditorValue<typeof ContentFragmentType['accordion'], AccordionCardPayload>;

const VALIDATION_SCHEMA = ACCORDION_VALIDATOR;

export const handleValidation = () => (value: AccordionCardPayload) => {
  return VALIDATION_SCHEMA.validate(value, { abortEarly: false })
    .then(() => undefined)
    .catch(err => {
      return err.errors;
    });
};

type Props = EditorCardProps;

const AccordionCardComponent: FC<Props> = props => {
  return (
    <CommonEditorCard<AccordionCardPayload>
      {...props}
      headerComponent={AccordionCardHeaderComponent}
      contentComponent={AccordionCardContentComponent}
      validate={handleValidation}
    />
  );
};

export const AccordionCard: EditorCard = {
  type: 'accordion',
  title: 'storyContentEditor.card.accordion.title',
  iconName: 'ap-accordion-content',
  content: AccordionCardComponent,
};
