import React, { FC, HTMLAttributes } from 'react';
import cn from 'classnames';
import { Interpolation } from '@emotion/core';

import { useStyles } from '@/styles/hooks';
import { loadingStyles } from './styles';

type Size = 'small' | 'medium';

type Props = HTMLAttributes<HTMLDivElement> & {
  size?: Size;
  css?: Interpolation<undefined>;
};

export const LoadingSection: FC<Props> = ({ size = 'medium', css, ...props }) => {
  const { styles } = useStyles(loadingStyles);

  return (
    <div css={[styles, css]} {...props}>
      <div className={cn(['wrapper', size])}>
        <div className='circle circle--first' />
        <div className='circle circle--second' />
        <div className='circle circle--third' />
      </div>
    </div>
  );
};
