import { Styles, theme, constants } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const paginatorStyles: StyleFunction = ({ theme }) => ({
  marginTop: constants.offset.xlarge,
  width: 'inherit',
  minWidth: 'inherit',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',

  '.button-wrapper': {
    height: constants.inputHeight,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: constants.offset.xsmall,
  },

  '.label': {
    marginRight: constants.offset.xsmall,
    color: theme.primary,
  },

  '.page-info': {
    height: constants.inputHeight,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  '.page-number': {
    marginRight: 40,
    color: theme.primary,
  },

  '.page-input': {
    backgroundColor: theme.backgroundNinth,
    color: theme.textFourth,
    borderRadius: constants.borderRadius.small,
    border: `1px solid ${theme.borderPrimary}`,
    padding: `0 ${constants.offset.medium}px`,
    height: 38,
    width: 80,
    marginRight: constants.offset.large,
    MozAppearance: 'textfield',

    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },

    '&:active:enabled, &:focus:enabled': {
      border: `1px solid ${theme.borderPrimaryActive}`,
      boxShadow: `0 0 1px 1px ${theme.borderPrimaryActive} inset`,
      outline: 'none',
    },

    '&:disabled': {
      backgroundColor: theme.backgroundPrimaryDisabled,
      cursor: 'not-allowed',
    },
  },

  '.page-select-wrapper, .page-input-wrapper': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  '.page-select': {
    width: 80,
  },
});

export const loadMore: Styles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: constants.offset.xlarge,
  marginBottom: constants.offset.xlarge,
  cursor: 'pointer',

  '.label': {
    color: theme.primary,
  },

  '.icon': {
    color: theme.primary,
    fontSize: constants.fontSize.h2,
  },
};
