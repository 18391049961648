import dayjs from 'dayjs';

import { DEFAULT_DATE_FORMAT } from '@/common/utils';
import { AutocompleteOption } from '@/common/components/autocomplete';
import { RadioOption } from '@/common/components/form-controls-deprecated';
import { PageableListResponse, ApiPagination, PageableListVM } from '@/common/utils/pageable';

export type EntitlementsParams = ApiPagination<{
  userId: string;
}>;

type SubscriptionEntityResponse = {
  id: string;
  application: string;
  datePurchased: string;
  startDate: string;
  endDate: string;
  channel: string;
  subscriptionType: string;
  autoRenewing: boolean;
  isRenewable: boolean;
  cancellationDate: string;
  isRefunded: boolean;
};

export type SubscriptionsListResponse = PageableListResponse<SubscriptionEntityResponse>;

export interface SubscriptionsElementVM extends SubscriptionEntityResponse {
  expirationDate: string;
}

export class SubscriptionsElementVM {
  constructor({ datePurchased, startDate, endDate, cancellationDate, ...props }: SubscriptionEntityResponse) {
    Object.assign(this, {
      ...props,
      datePurchased: datePurchased ? dayjs(datePurchased).format(DEFAULT_DATE_FORMAT) : '',
      startDate: startDate ? dayjs(startDate).format(DEFAULT_DATE_FORMAT) : '',
      expirationDate: endDate ? dayjs(endDate).format(DEFAULT_DATE_FORMAT) : '',
      cancellationDate: cancellationDate ? dayjs(cancellationDate).format(DEFAULT_DATE_FORMAT) : '',
    });
  }
}

export interface SubscriptionListVM {
  list: SubscriptionsElementVM[];
}

export class SubscriptionListVM {
  constructor({ content }: SubscriptionsListResponse) {
    this.list = (content || []).map(element => new SubscriptionsElementVM(element));
  }
}

type DateString = string;

export type SubscriptionAssignParams = {
  userId: string;
  productId: string;
};

export type SubscriptionAssignResponse = {
  effectiveSubscriptionEndDate: DateString;
  subscriptionEndDate: DateString;
};

export interface SubscriptionAssignElementVM extends SubscriptionAssignResponse {}

export class SubscriptionAssignElementVM {
  constructor({ effectiveSubscriptionEndDate, subscriptionEndDate }: SubscriptionAssignResponse) {
    Object.assign(this, {
      subscriptionEndDate: dayjs(subscriptionEndDate).format(DEFAULT_DATE_FORMAT),
      effectiveSubscriptionEndDate: dayjs(effectiveSubscriptionEndDate).format(DEFAULT_DATE_FORMAT),
    });
  }
}

type SmartMapEntityResponse = {
  id: string;
  name: string;
  title: string;
  publishedDate: string;
  voucherCode: string;
  redeemDate: DateString;
  isRefunded: boolean;
};

export type SmartMapsListResponse = PageableListResponse<SmartMapEntityResponse>;

export interface SmartMapsElementVM extends SmartMapEntityResponse {}

export class SmartMapsElementVM {
  constructor({ publishedDate, redeemDate, ...props }: SmartMapEntityResponse) {
    Object.assign(this, {
      ...props,
      publishedDate: publishedDate ? dayjs(publishedDate).format(DEFAULT_DATE_FORMAT) : '',
      redeemDate: redeemDate ? dayjs(redeemDate).format(DEFAULT_DATE_FORMAT) : '',
    });
  }
}

export class SmartMapDataVM extends PageableListVM<SmartMapsElementVM, SmartMapsListResponse> {}

export type SmartMapsAutocompleteElementResponse = {
  isbn: string;
  mapCode: string;
  productId: string;
  productName: string;
  publishDate: DateString;
};

export type SmartMapsAutocompleteListResponse = SmartMapsAutocompleteElementResponse[];

export interface SmartMapsAutocompleteElementVM extends AutocompleteOption {}

export class SmartMapsAutocompleteElementVM {
  constructor({ productName, productId }: SmartMapsAutocompleteElementResponse) {
    this.label = productName || '';
    this.value = productId || '';
  }
}

export interface SmartMapsAutocompleteListVM {
  list: SmartMapsAutocompleteElementVM[];
}

export class SmartMapsAutocompleteListVM {
  constructor(data: SmartMapsAutocompleteListResponse) {
    this.list = (data || []).map(element => new SmartMapsAutocompleteElementVM(element));
  }
}

export type AssignSmartMapParams = {
  productId: string;
  userId: string;
};

type OneTimeProductEntityResponse = {
  product: string;
  name: string;
  datePurchased: DateString;
  price: number;
  isRefunded: boolean;
};

export type OneTimeProductsListResponse = PageableListResponse<OneTimeProductEntityResponse>;

export interface OneTimeProductsElementVM extends OneTimeProductEntityResponse {}

export class OneTimeProductsElementVM {
  constructor({ datePurchased, ...props }: OneTimeProductEntityResponse) {
    Object.assign(this, {
      ...props,
      datePurchased: datePurchased ? dayjs(datePurchased).format(DEFAULT_DATE_FORMAT) : '',
    });
  }
}

export class OneTimeProductDataVM extends PageableListVM<OneTimeProductsElementVM, OneTimeProductsListResponse> {}

export const ONE_YEAR_FREE_SUBSCRIPTION = '1yearadminpanelfreetrial';

export type AdminPanelManualSubsOnlyEntityResponse = {
  productId: string;
  expirationDate: string;
};

export type AdminPanelManualSubsOnlyListResponse = PageableListResponse<AdminPanelManualSubsOnlyEntityResponse>;

export type AdminPanelManualSubsOnlyResponse = {
  subscriptions: AdminPanelManualSubsOnlyListResponse;
};

export interface AdminPanelManualSubsOnlyElementVM {
  hasActiveFreeSubscription: boolean;
}

export class AdminPanelManualSubsOnlyElementVM {
  constructor({ productId, expirationDate }: AdminPanelManualSubsOnlyEntityResponse) {
    Object.assign<this, AdminPanelManualSubsOnlyElementVM>(this, {
      hasActiveFreeSubscription: productId === ONE_YEAR_FREE_SUBSCRIPTION && dayjs().isBefore(dayjs(expirationDate)),
    });
  }
}

export class AdminPanelManualSubsOnlyListVM extends PageableListVM<AdminPanelManualSubsOnlyElementVM, AdminPanelManualSubsOnlyListResponse> {}

type SubscriptionsProductsResponse = {
  productId: string;
  productName: string;
  productShortDescription: string;
  productPrice: number;
  productDuration: number;
  recommendedProduct: boolean;
};

export type SubscriptionsProductsListResponse = SubscriptionsProductsResponse[];

export interface SubscriptionsProductsDictionaryElementVM extends RadioOption {}
export class SubscriptionsProductsDictionaryElementVM {
  constructor({ productName, productId }: SubscriptionsProductsResponse) {
    this.value = productId;
    this.label = productName;
  }
}
export interface SubscriptionsProductsDictionaryVM {
  list: SubscriptionsProductsDictionaryElementVM[];
}
export class SubscriptionsProductsDictionaryVM {
  constructor(list: SubscriptionsProductsListResponse) {
    this.list = (list || []).map(element => new SubscriptionsProductsDictionaryElementVM(element));
  }
}
