import { ActionType, Action, createAction } from 'typesafe-actions';

import { SubscriptionListVM, SmartMapDataVM, EntitlementsParams, OneTimeProductDataVM, SubscriptionsProductsDictionaryVM } from '@/models/entitlements';
import { createDictionaryActions, createFetchActions } from '@/common/utils/store';

export const entitlementsActions = {
  smartMaps: createFetchActions('FETCH_ENTITLEMENTS_SMART_MAPS_REQUEST', 'FETCH_ENTITLEMENTS_SMART_MAPS_SUCCESS', 'FETCH_ENTITLEMENTS_SMART_MAPS_FAILURE')<
    EntitlementsParams,
    SmartMapDataVM
  >(),
  subscriptions: createFetchActions(
    'FETCH_ENTITLEMENTS_SUBSCRIPTIONS_REQUEST',
    'FETCH_ENTITLEMENTS_SUBSCRIPTIONS_SUCCESS',
    'FETCH_ENTITLEMENTS_SUBSCRIPTIONS_FAILURE'
  )<EntitlementsParams, SubscriptionListVM>(),
  oneTimeProducts: createFetchActions(
    'FETCH_ENTITLEMENTS_ONE_TIME_PRODUCTS_REQUEST',
    'FETCH_ENTITLEMENTS_ONE_TIME_PRODUCTS_SUCCESS',
    'FETCH_ENTITLEMENTS_ONE_TIME_PRODUCTS_FAILURE'
  )<EntitlementsParams, OneTimeProductDataVM>(),
  clearSubscriptions: createAction('CLEAR_SUBSCRIPTIONS')(),
  clearSmartMaps: createAction('CLEAR_SMART_MAPS')(),
  clearOneTimeProducts: createAction('CLEAR_SMART_MAPS')(),
  smartMapsTotalCount: createFetchActions(
    'FETCH_ENTITLEMENTS_SMART_MAPS_TOTAL_COUNT_REQUEST',
    'FETCH_ENTITLEMENTS_SMART_MAPS_TOTAL_COUNT_SUCCESS',
    'FETCH_ENTITLEMENTS_SMART_MAPS_TOTAL_COUNT_FAILURE'
  )<EntitlementsParams, number>(),
  oneTimeProductsTotalCount: createFetchActions(
    'FETCH_ENTITLEMENTS_ONE_TIME_PRODUCTS_TOTAL_COUNT_REQUEST',
    'FETCH_ENTITLEMENTS_ONE_TIME_PRODUCTS_TOTAL_COUNT_SUCCESS',
    'FETCH_ENTITLEMENTS_ONE_TIME_PRODUCTS_TOTAL_COUNT_FAILURE'
  )<EntitlementsParams, number>(),
  subscriptionsProducts: createDictionaryActions(
    'FETCH_ENTITLEMENTS_SUBSCRIPTION_PRODUCTS_DICTIONARY_REQUEST',
    'FETCH_ENTITLEMENTS_SUBSCRIPTION_PRODUCTS_DICTIONARY_SUCCESS',
    'FETCH_ENTITLEMENTS_SUBSCRIPTION_PRODUCTS_DICTIONARY_FAILURE',
    'FETCH_ENTITLEMENTS_SUBSCRIPTION_PRODUCTS_DICTIONARY_CANCEL',
    'FETCH_ENTITLEMENTS_SUBSCRIPTION_PRODUCTS_DICTIONARY_SILENT_REQUEST'
  )<undefined, SubscriptionsProductsDictionaryVM>(),
};

export type entitlementsActions = Action | ActionType<typeof entitlementsActions>;
