import { ActionType, Action } from 'typesafe-actions';

import { VisibilityDictionaryVM } from '@/models/visibility';
import { createDictionaryActions } from '@/common/utils/store';

export const visibilityActions = createDictionaryActions(
  `FETCH_VISIBILITY_DICTIONARY_REQUEST`,
  `FETCH_VISIBILITY_DICTIONARY_SUCCESS`,
  `FETCH_VISIBILITY_DICTIONARY_FAILURE`,
  `FETCH_VISIBILITY_DICTIONARY_CANCEL`,
  `FETCH_VISIBILITY_DICTIONARY_SILENT_REQUEST`
)<undefined, VisibilityDictionaryVM>();

export type visibilityActions = Action | ActionType<typeof visibilityActions>;
