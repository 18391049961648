import { createMuiTheme, createStyles, makeStyles } from '@material-ui/core';

import { theme, constants, ObjectStyles } from '@/styles';
import { MuiStyleFunction, StyleFunction } from '@/styles/models';

export const datepickerInputStyles: StyleFunction = ({ theme }) => ({
  '&:hover .MuiInputBase-root:not(.Mui-focused):not(.Mui-disabled)': {
    fieldset: {
      borderColor: theme.borderPrimary,
    },
  },

  '.MuiFormControl-root': {
    marginTop: 0,
    marginBottom: 0,
    backgroundColor: theme.backgroundNinth,
    width: '100%',

    '.MuiInputBase-root': {
      height: 40,
      fontSize: 13,
      borderRadius: `${constants.borderRadius.small}px`,

      '&.Mui-disabled': {
        backgroundColor: theme.backgroundPrimaryDisabled,
        color: `${theme.borderPrimaryDisabled} !important`,
        cursor: 'not-allowed',

        button: {
          display: 'none',
        },

        input: {
          cursor: 'not-allowed',
        },

        '.MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${theme.borderPrimaryDisabled}`,
        },
      },

      '&.Mui-focused': {
        fieldset: {
          borderColor: theme.borderPrimaryActive,
          outline: 'none',
        },
      },

      '.MuiInputAdornment-root': {
        '.MuiSvgIcon-root': {
          fill: theme.textPrimary,
        },
      },
    },
  },

  fieldset: {
    border: `1px solid ${theme.borderThird}`,
    transitionProperty: 'border-color, box-shadow',
    transitionDuration: '.2s',
  },

  input: {
    color: theme.textPrimary,

    ':disabled': {
      color: theme.borderPrimaryDisabled,
    },
  },
});

export const useStyles = makeStyles(() =>
  createStyles({
    disabled: {
      '& div, & svg': {
        backgroundColor: theme.backgroundPrimaryDisabled,
        color: `${theme.textPrimary} !important`,
        fill: theme.textDisabled,
        cursor: 'not-allowed',
      },
    },
  })
);

export const datePickerRangeStyles: StyleFunction = ({ theme }) => ({
  display: 'flex',

  '.wrapper': {
    width: 390,
    paddingRight: constants.offset.xlarge,

    label: {
      fontWeight: 'bold',
      display: 'flex',
      marginBottom: constants.offset.small,
      color: theme.primary,
    },
  },
});

export const errorOnRight: ObjectStyles = {
  '.error-on-right': {
    marginLeft: 390,
  },
};

export const datepickerStyle: MuiStyleFunction = ({ theme }) =>
  createMuiTheme({
    overrides: {
      // @ts-ignoreker: {
      MuiPickersBasePicker: {
        pickerView: {
          backgroundColor: theme.backgroundThird,
        },
      },
      MuiPickersFadeTransition: {
        root: {
          color: theme.textFourth,
        },
      },
      MuiPickersArrowSwitcher: {
        iconButton: {
          backgroundColor: theme.backgroundThird,
        },
      },
      MuiPickersCalendar: {
        weekDayLabel: {
          color: theme.textFourth,
        },
      },
      MuiPickersDay: {
        root: {
          backgroundColor: theme.backgroundThird,
        },
        dayLabel: {
          color: theme.textFourth,
        },
        today: {
          borderColor: `${theme.textFourth} !important`,
        },
      },
      MuiPickersYear: {
        root: {
          color: theme.textFourth,
        },
      },
      MuiPickersMonth: {
        root: {
          color: theme.textFourth,
        },
      },
      Mui: {
        selected: {
          color: theme.textFourth,
        },
      },
      MuiPickersCalendarHeader: {
        month: {
          color: theme.textFourth,
        },
      },
    },
  });
