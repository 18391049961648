import dayjs from 'dayjs';

import { DEFAULT_DATE_FORMAT } from '@/common/utils';
import { ApiPagination, PageableListResponse, PageableListVM } from '@/common/utils/pageable';
import { CustomFile, SelectOption } from '@/common/components/form-controls-deprecated';
import { AutocompleteOption } from '@/common/components/autocomplete';
import { DatePickerValueWithReason as DateWithReason } from '@/common/components/form-controls-deprecated/datepicker-deprecated';
import { CommonTagElementVM } from '@/models/common-tag';

export type ThingsToDoParams = ApiPagination<{
  displayedFrom?: string;
  displayedTo?: string;
  name?: string;
  placeCategories?: string[];
  recurring?: boolean;
  tagIds?: string[];
}>;

type ThingsToDoEntityResponse = {
  description: string;
  displayedFrom: string;
  displayedTo: string;
  id: number;
  media: Media;
  name: string;
  pinned: boolean;
  priority: number;
  recurring: boolean;
  tags: CommonTagElementVM[];
};

type Media = {
  duration: number;
  id: string;
  sizeOriginal: number;
  sizeResized: number;
  type: string;
  urlOriginal: string;
  urlResized: string;
};

export type ThingsToDoListResponse = PageableListResponse<ThingsToDoEntityResponse>;

export interface ThingsToDoElementVM extends ThingsToDoEntityResponse {}
export class ThingsToDoElementVM {
  constructor({ displayedFrom, displayedTo, ...props }: ThingsToDoEntityResponse) {
    Object.assign(this, {
      ...props,
      displayedFrom: displayedFrom ? dayjs(displayedFrom).format(DEFAULT_DATE_FORMAT) : '',
      displayedTo: displayedTo ? dayjs(displayedTo).format(DEFAULT_DATE_FORMAT) : '',
    });
  }
}

export class ThingsToDoListVM extends PageableListVM<ThingsToDoElementVM, ThingsToDoListResponse> {}

type PlaceCategoriesResponse = {
  code: string;
  name: string;
};

export type PlaceCategoriesListResponse = PlaceCategoriesResponse[];

export interface PlaceCategoriesDictionaryElementVM extends SelectOption {}
export class PlaceCategoriesDictionaryElementVM {
  constructor({ code, name }: PlaceCategoriesResponse) {
    this.value = code;
    this.label = name;
  }
}

export interface PlaceCategoriesDictionaryVM {
  list: PlaceCategoriesDictionaryElementVM[];
}

export class PlaceCategoriesDictionaryVM {
  constructor(list: PlaceCategoriesListResponse) {
    this.list = (list || []).map(element => new PlaceCategoriesDictionaryElementVM(element));
  }
}

export type UpdateThingsToDoDetailsEntityParams = {
  description: string;
  displayedFrom?: string;
  displayedTo?: string;
  imageId?: string;
  mediaId?: string;
  imageFile: File[] | CustomFile[];
  name: string;
  pinned: boolean;
  priority?: number | string;
  recurring: boolean;
  tags: CommonTagElementVM[];
};

export type ThingToDoChangeImageResponse = {
  accelerometer: null;
  captureTime: null;
  createdAt: string;
  duration: null;
  gyroscope: null;
  id: string;
  likeCounter: number;
  location: null;
  magnetometer: null;
  provider: null;
  providerUrl: null;
  sizeOriginal: number;
  sizeResized: number;
  sourceApp: null;
  type: string;
  urlOriginal: string;
  urlResized: string;
  validationStatus: string;
};

export type ThingsToDoDetailsResponse = ThingsToDoEntityResponse;

export interface ThingsToDoDetailsVM extends Omit<ThingsToDoEntityResponse, 'displayedFrom' | 'displayedTo'> {
  displayedFrom?: DateWithReason;
  displayedTo?: DateWithReason;
}

export class ThingsToDoDetailsVM {
  constructor({ tags, displayedFrom, displayedTo, ...props }: ThingsToDoEntityResponse) {
    const dateFrom = dayjs(displayedFrom || '').utc(true);
    const dateTo = dayjs(displayedTo || '').utc(true);
    Object.assign<this, ThingsToDoDetailsVM>(this, {
      ...props,
      tags: tags ?? [],
      displayedFrom: dateFrom.isValid() ? { value: dateFrom } : void 0,
      displayedTo: dateTo.isValid() ? { value: dateTo } : void 0,
    });
  }
}

type PlaceCategories = {
  code: string;
  name: string;
};

export type ThingToDoTagsAutocompleteElementResponse = {
  displayName: string;
  id: string;
  placeCategories: PlaceCategories;
};

export type ThingToDoTagsAutocompleteListResponse = PageableListResponse<ThingToDoTagsAutocompleteElementResponse>;

export interface ThingToDoTagsAutocompleteElementVM extends AutocompleteOption {}

export class ThingToDoTagsAutocompleteElementVM {
  constructor({ displayName, id }: ThingToDoTagsAutocompleteElementResponse) {
    this.label = displayName || '';
    this.value = id || '';
  }
}

export interface ThingToDoTagsAutocompleteListVM {
  list: ThingToDoTagsAutocompleteElementVM[];
}

export class ThingToDoTagsAutocompleteListVM extends PageableListVM<ThingToDoTagsAutocompleteElementVM, ThingToDoTagsAutocompleteListResponse> {}
