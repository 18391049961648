import dayjs from 'dayjs';

import { Enum } from '@/common/utils';
import { ConsentDocumentVM } from '@/models/consents';

const COOKIES_MAX_EXPIRED_ON = 10 * 365 * 24 * 60 * 60;

export type TokenStatus = Enum<typeof TokenStatus>;
export const TokenStatus = Enum('INVALID', 'VALID', 'REFRESHING', 'EMPTY');

export type LoggedUserDataResponse = {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  userId: string;
  objectId: string;
  organisationId: string;
  roles: string[];
  country?: string;
};

export type Role = { id: string; displayName: string };

export type OrganisationData = {
  name?: string;
  id: string;
};

export interface LoggedUserDataVM extends Omit<LoggedUserDataResponse, 'organisationId'> {
  consents: ConsentDocumentVM[];
  role?: Role;
  organisation: OrganisationData;
}

export class LoggedUserDataVM {
  constructor({ roles, organisationId, ...props }: LoggedUserDataResponse) {
    Object.assign(this, { ...props, roles: roles || [], organisation: { id: organisationId, name: undefined } });
  }
}

export type AuthenticationResponse = {
  access_token: string;
  id_token: string;
  token_type: string;
  not_before: number;
  expires_in: number;
  expires_on: number;
  resource: string;
  id_token_expires_in: number;
  profile_info: string;
  refresh_token: string;
  refresh_token_expires_in: number;
};

export class AuthenticationVM {
  accessToken: string;
  refreshToken: string;
  expiredOn: Date;
  tokenType: string;
  expiredIn: number;
  constructor({ access_token, refresh_token, refresh_token_expires_in, token_type }: AuthenticationResponse) {
    this.accessToken = access_token;
    this.refreshToken = refresh_token;
    this.expiredOn = getExpiredOnValue(refresh_token_expires_in || COOKIES_MAX_EXPIRED_ON);
    this.expiredIn = refresh_token_expires_in;
    this.tokenType = token_type;
  }
}

const getExpiredOnValue = (value: number) => dayjs().add(value, 's').toDate();

export type UserRoleResponse = {
  displayName: string;
  id: string;
  permissions: string[];
};

export interface UserRoleVM extends UserRoleResponse {}

export class UserRoleVM {
  constructor(props: UserRoleResponse) {
    Object.assign(this, props);
  }
}
