import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FormikProps } from 'formik';
import { Subject } from 'rxjs';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker as MuiDatePicker, DatePickerView, LocalizationProvider } from '@material-ui/pickers';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';

import { postponeEmission, useInstanceValue } from '@/common/utils';
import { useMuiStyles, useStyles } from '@/styles/hooks';
import { ThemeProvider } from '@material-ui/core';
import {
  parseDate,
  DATE_FORMATS,
  MONTH_DATE_FORMATS_LIST,
  DateValueDeprecated,
  DateFormat,
  DateBoundaries,
  DatePickerOnChangeErrorReason as ErrorReason,
  DatePickerOnChangeErrorCallback as OnChangeErrorCallback,
  DatePickerOnChangeHandlerParams as OnChangeHandlerParams,
  DatePickerOnChangeHandler as OnChangeHandler,
  DatePickerOnInputChange as OnInputChange,
  DatePickerOnChange as OnChange,
} from './constants';
import { DateInputDeprecated, DateInputProps } from './components';
import { datepickerInputStyles, datepickerStyle } from './styles';

export type DatePickerProps = Omit<DateInputProps, 'onChange' | 'lastReason' | 'displayFormat'> &
  Partial<Pick<DateInputProps, 'displayFormat'>> & {
    label?: string;
    name?: string;
    form?: FormikProps<any>;
    className?: string;
    onChange: OnChange;
    onChangeError?: OnChangeErrorCallback;
    views: DatePickerView[];
    minDate?: DateValueDeprecated;
    maxDate?: DateValueDeprecated;
    validationFormats?: DateFormat[];
    setFocusState?: (value: boolean) => void;
  };

// TODO: https://github.com/mui-org/material-ui/issues/13394

export const DatePickerDeprecated: FC<DatePickerProps> = ({
  form,
  name,
  value,
  onChange,
  onChangeError,
  minDate,
  maxDate,
  disabled,
  displayFormat = DATE_FORMATS.MMMM_YYYY,
  validationFormats = MONTH_DATE_FORMATS_LIST,
  placeholder,
  setFocusState,
  onBlur,
  ...props
}) => {
  const [lastReason, setLastReason] = useState<ErrorReason>(ErrorReason.none);
  const pickerSelectionComplete$ = useInstanceValue(() => new Subject<boolean>());
  const onChange$ = useInstanceValue(() => new Subject<OnChangeHandlerParams>());
  const dateBoundaries = useRef<DateBoundaries>({ start: minDate, end: maxDate });
  const DatepickerStyle = useMuiStyles(datepickerStyle);
  const DatepickerInputStyle = useStyles(datepickerInputStyles);

  useEffect(() => void (dateBoundaries.current.start = minDate), [minDate]);
  useEffect(() => void (dateBoundaries.current.end = maxDate), [maxDate]);

  const handleOnChangeCallback = useCallback<OnChangeHandler<ErrorReason>>(
    ({ value, inputValue, minDate, maxDate }) => {
      const errorHandler = form && name ? (reason: ErrorReason, value: DateValueDeprecated) => form.setFieldValue(name, { reason, value }) : onChangeError;
      const changeHandler = form && name ? (value: Dayjs) => form.setFieldValue(name, { value }) : onChange;

      if (!value && !inputValue) {
        errorHandler?.(ErrorReason.noValue, void 0);
        return ErrorReason.noValue;
      }
      const date = value?.isValid() ? value : parseDate(inputValue, validationFormats, {});

      if (!date) {
        errorHandler?.(ErrorReason.invalidDate, null);
        return ErrorReason.invalidDate;
      }

      if (minDate && date.isBefore(minDate)) {
        errorHandler?.(ErrorReason.minDate, date);
        return ErrorReason.minDate;
      }

      if (maxDate && date.isAfter(maxDate)) {
        errorHandler?.(ErrorReason.maxDate, date);
        return ErrorReason.maxDate;
      }

      changeHandler(date);
      return ErrorReason.none;
    },
    [form, name, minDate, maxDate, onChangeError]
  );

  const handleOnChange = useCallback<OnInputChange>(
    (value: Date | null, inputValue: string | undefined) => {
      const dayjsValue = value ? dayjs(value).utc(true) : value;

      onChange$.current.next({
        value: dayjsValue,
        inputValue,
        minDate: minDate || dateBoundaries.current.start,
        maxDate: maxDate || dateBoundaries.current.end,
      });
    },
    [form, name, minDate, maxDate, onChangeError]
  );

  const handleOnYearChange = useCallback(() => pickerSelectionComplete$.current.next(false), []);

  useEffect(() => {
    const subscription = onChange$.current
      .pipe(postponeEmission(1, pickerSelectionComplete$.current))
      .subscribe(emission => setLastReason(handleOnChangeCallback(emission)));
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <div css={DatepickerInputStyle.styles}>
      <ThemeProvider theme={DatepickerStyle.styles}>
        <LocalizationProvider dateAdapter={DateFnsUtils}>
          <MuiDatePicker
            {...props}
            value={value?.toDate() || null}
            onChange={handleOnChange}
            onYearChange={handleOnYearChange}
            minDate={minDate?.toDate()}
            maxDate={maxDate?.toDate()}
            disabled={disabled}
            renderInput={DateInputDeprecated({ disabled, placeholder, value, lastReason, displayFormat, onBlur, onChange: handleOnChange })}
          />
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  );
};
