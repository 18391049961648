import { createSelector } from 'reselect';

import { getDataStatus } from '@/common/utils';
import { commonDictionariesReducer } from './reducers';

type CategoriesDictionary = commonDictionariesReducer['commonDictionaries']['categories'];
type MakeGetCategoriesDictionaryData = commonDictionariesReducer['commonDictionaries']['categories']['data'];

const getCategoriesDictionary = (state: commonDictionariesReducer) => state.commonDictionaries.categories;

export const makeGetCategoriesDictionaryData = () =>
  createSelector<commonDictionariesReducer, CategoriesDictionary, MakeGetCategoriesDictionaryData>(getCategoriesDictionary, ({ data }) => data);

export const makeGetCategoriesDictionaryStatus = () =>
  createSelector<commonDictionariesReducer, CategoriesDictionary, ReturnType<typeof getDataStatus>>(getCategoriesDictionary, getDataStatus);

type TagsDictionary = commonDictionariesReducer['commonDictionaries']['tags'];
type MakeGetTagsDictionaryData = commonDictionariesReducer['commonDictionaries']['tags']['data'];

const getTagsDictionary = (state: commonDictionariesReducer) => state.commonDictionaries.tags;

export const makeGetTagsDictionaryData = () =>
  createSelector<commonDictionariesReducer, TagsDictionary, MakeGetTagsDictionaryData>(getTagsDictionary, ({ data }) => data);

export const makeGetTagsDictionaryStatus = () =>
  createSelector<commonDictionariesReducer, TagsDictionary, ReturnType<typeof getDataStatus>>(getTagsDictionary, getDataStatus);
