import upperFirst from 'lodash/upperFirst';

import { SelectOption } from '@/common/components/form-controls-deprecated';

type VisibilityResponse = {
  id: string;
  displayName: string;
};

export type VisibilityListResponse = VisibilityResponse[];

export interface VisibilityDictionaryElementVM extends SelectOption {}
export class VisibilityDictionaryElementVM {
  constructor({ id, displayName }: VisibilityResponse) {
    this.value = id;
    this.label = upperFirst(displayName);
  }
}

export interface VisibilityDictionaryVM {
  list: VisibilityDictionaryElementVM[];
}

export class VisibilityDictionaryVM {
  constructor(list: VisibilityListResponse) {
    this.list = (list || []).map(element => new VisibilityDictionaryElementVM(element));
  }
}
