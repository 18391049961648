import { createSelector } from 'reselect';

import { getDataStatus } from '@/common/utils';
import { RouteAnalyticsListVM } from '@/features/reporting/models/route-analytics';
import { routeAnalyticsReducer as State } from './reducers';

type Status = ReturnType<typeof getDataStatus>;
type List = State['routeAnalytics']['list'];
type MakeRouteAnalyticsListData = RouteAnalyticsListVM['list'];
type MakeGetHasRouteAnalyticsListNextPage = RouteAnalyticsListVM['hasNextPage'];
type MakeRouteAnalyticsListFilters = List['params'];

const getListData = (state: State) => state.routeAnalytics.list;

export const makeGetRouteAnalyticsList = () => createSelector<State, List, MakeRouteAnalyticsListData>(getListData, ({ data }) => data?.list || []);

export const makeGetRouteAnalyticsListStatus = () => createSelector<State, List, Status>(getListData, getDataStatus);

export const makeGetHasRouteAnalyticsListNextPage = () =>
  createSelector<State, List, MakeGetHasRouteAnalyticsListNextPage>(getListData, ({ data }) => data?.hasNextPage || false);

export const makeGetRouteAnalyticsListFilters = () => createSelector<State, List, MakeRouteAnalyticsListFilters>(getListData, ({ params }) => params);
