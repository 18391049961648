import { createReducer } from 'typesafe-actions';

import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { ReasonsDictionaryVM } from '@/models/reasons';
import { FeatureStateType } from '../helpers';
import { reasonsActions } from './actions';

type reasonsState = DictionaryState<ReasonsDictionaryVM>;

const INITIAL_STATE: reasonsState = getDictionaryInitialState<ReasonsDictionaryVM>();

const reducer = createReducer<reasonsState, reasonsActions>(INITIAL_STATE)
  .handleAction(reasonsActions.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(reasonsActions.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(reasonsActions.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(reasonsActions.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(reasonsActions.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const reasonsReducer = { reasons: reducer };
export type reasonsReducer = FeatureStateType<typeof reasonsReducer>;
