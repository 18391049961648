import { createReducer } from 'typesafe-actions';

import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { RatingContentTypeDictionaryVM } from '@/models/reviews';
import { FeatureStateType } from '../../helpers';
import { reviewsActions } from '../actions';

type ratingContentTypeState = DictionaryState<RatingContentTypeDictionaryVM>;

const INITIAL_STATE: ratingContentTypeState = getDictionaryInitialState<RatingContentTypeDictionaryVM>();

const reducer = createReducer<ratingContentTypeState, reviewsActions>(INITIAL_STATE)
  .handleAction(reviewsActions.ratingContentType.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(reviewsActions.ratingContentType.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(reviewsActions.ratingContentType.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(reviewsActions.ratingContentType.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(reviewsActions.ratingContentType.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const ratingContentTypeDictionaryReducer = { ratingContentType: reducer };
export type ratingContentTypeDictionaryReducer = FeatureStateType<typeof ratingContentTypeDictionaryReducer>;
