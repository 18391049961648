import React, { lazy as lazyCore } from 'react';
import { Route, Redirect } from 'react-router';
import { timer } from 'rxjs';

import { PrivateRoute } from '../components/private-route';
import { RoutesDef, PrivateRouteProps } from './navigation.models';

const LOADING_DELAY = 500;

export const generateRoutes = (routesDef: RoutesDef<any>, name: string, defaultPath?: React.ReactNode) => {
  const routes = Object.values(routesDef).map((props, index) =>
    !(props as PrivateRouteProps).guard ? (
      <Route key={`[${name}_route] ${index}`} {...props} />
    ) : (
      <PrivateRoute key={`[${name}_route] ${index}`} {...(props as PrivateRouteProps)} />
    )
  );

  if (defaultPath && typeof defaultPath === 'string') {
    routes.push(<Redirect key={`[${name}_route] default`} to={defaultPath} />);
  }

  if (React.isValidElement(defaultPath)) {
    routes.push(defaultPath);
  }

  return routes;
};

export const getRoutePath = (routePath: string, pathParams: { [key in string]: string }) => {
  let evaluatedRoutePath = routePath;

  Object.keys(pathParams).forEach(key => {
    evaluatedRoutePath = evaluatedRoutePath.replace(`:${key}`, pathParams[key]);
  });

  return evaluatedRoutePath;
};

export const lazy = (promise: ReturnType<Parameters<typeof lazyCore>['0']>) =>
  lazyCore(() => Promise.all([promise, timer(LOADING_DELAY).toPromise()]).then(([module]) => module));
