import { constants, rgba } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const dialogStyles: StyleFunction = ({ theme }) => ({
  '& > div': {
    '&:first-of-type': {
      backgroundColor: `${rgba(theme.backgroundContrast, 0.4)} !important`,
    },
  },

  '.dialog': {
    minWidth: 400,
    maxHeight: '90vh',
    alignItems: 'center',
    backgroundColor: theme.backgroundEight,
    display: 'flex',
    flexDirection: 'column',
    left: '50%',
    outline: 0,
    padding: 0,
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: constants.dialogShadow,
  },

  '.dialog-content': {
    padding: constants.offset.large,
    paddingBottom: constants.offset.xlarge,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    overflow: 'auto',

    '.description': {
      color: theme.textPrimary,
    },

    '> *': {
      whiteSpace: 'pre-wrap',
      color: theme.textPrimary,
    },
  },

  '.title': {
    fontSize: constants.fontSize.h2,
    color: theme.textPrimary,
    marginBottom: constants.offset.large,
    marginRight: 30,
  },

  '.close-button': {
    backgroundColor: theme.backgroundPrimary,
    cursor: 'pointer',
    fontSize: constants.fontSize.iconMedium,
    padding: 0,
    position: 'absolute',
    right: 17,
    top: 17,
  },

  '.button-icon': {
    color: theme.textAccent,
  },

  '.buttons': {
    backgroundColor: theme.backgroundSecondary,
    padding: `${constants.offset.medium}px ${constants.offset.large}px`,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',

    '&__common': {
      display: 'flex',
      justifyContent: 'flex-end',

      '& > *': {
        marginLeft: constants.offset.large,
        minWidth: 120,
      },
    },
  },
});
