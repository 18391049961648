import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const videoStyle: StyleFunction = ({ theme }) => ({
  display: 'flex',
  flexShrink: 1,
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  padding: `${constants.offset.xsmall}px 0`,

  '.value, .placeholder': {
    fontSize: constants.fontSize.h4,
    color: theme.textThird,
  },

  '.value': {
    color: theme.textPrimary,
  },

  '.placeholder': {
    fontStyle: 'italic',
  },
});
