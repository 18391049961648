import { createReducer } from 'typesafe-actions';

import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { CommonTagDictionaryVM } from '@/models/common-tag';
import { FeatureStateType } from '../../helpers';
import { commonDictionariesActions } from '../actions';

type categoriesState = DictionaryState<CommonTagDictionaryVM>;

const INITIAL_STATE: categoriesState = getDictionaryInitialState<CommonTagDictionaryVM>();

const reducer = createReducer<categoriesState, commonDictionariesActions>(INITIAL_STATE)
  .handleAction(commonDictionariesActions.categories.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(commonDictionariesActions.categories.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(commonDictionariesActions.categories.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(commonDictionariesActions.categories.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(commonDictionariesActions.categories.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const categoriesDictionaryReducer = { categories: reducer };
export type categoriesDictionaryReducer = FeatureStateType<typeof categoriesDictionaryReducer>;
