import { createReducer } from 'typesafe-actions';

import { GeneratedVoucherBatchElementVM } from '@/models/voucher-batch';
import { FeatureStateType } from '../../helpers';
import { voucherBatchActions } from '../actions';

export type addVoucherBatchState = {
  steps: {
    batchConfiguration: { stringifiedBatchConfig: string; batchType: string };
    batchSize: { batchList: GeneratedVoucherBatchElementVM[] };
  };
};

const INITIAL_STATE: addVoucherBatchState = {
  steps: {
    batchConfiguration: { stringifiedBatchConfig: '', batchType: 'SmartMap' },
    batchSize: { batchList: [] },
  },
};

const reducer = createReducer<addVoucherBatchState, voucherBatchActions>(INITIAL_STATE)
  .handleAction(voucherBatchActions.clearAddVoucherBatchData, () => ({ ...INITIAL_STATE }))
  .handleAction(voucherBatchActions.updateVoucherBatchEntityCount, (state, { payload }) => ({
    ...state,
    steps: {
      ...state.steps,
      batchSize: {
        batchList: state.steps.batchSize.batchList.map(batch =>
          batch.productId === payload.id ? { ...batch, quantity: payload.count ? payload.count : 0 } : batch
        ),
      },
    },
  }))
  .handleAction(voucherBatchActions.setVoucherBatchConfigValue, (state, { payload }) => ({
    ...state,
    steps: {
      ...state.steps,
      batchConfiguration: {
        stringifiedBatchConfig: payload.stringifiedBatchConfig,
        batchType: payload.batchType,
      },
    },
  }))
  .handleAction(voucherBatchActions.setVoucherBatchListValue, (state, { payload }) => ({
    ...state,
    steps: {
      ...state.steps,
      batchSize: {
        batchList: payload,
      },
    },
  }));

export const addVoucherBatchReducer = { addBatch: reducer };
export type addVoucherBatchReducer = FeatureStateType<typeof addVoucherBatchReducer>;
