import { SelectOption } from '../components/form-controls-deprecated';

export const AGE_RECOMMENDATIONS_OPTIONS: SelectOption<number>[] = [
  {
    value: 4,
    label: '4+',
  },
  {
    value: 7,
    label: '7+',
  },
  {
    value: 11,
    label: '11+',
  },
  {
    value: 14,
    label: '14+',
  },
];
