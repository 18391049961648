import dayjs from 'dayjs';

import { ApiPagination, PageableListResponse, PageableListVM } from '@/common/utils/pageable';
import { SelectOption } from '@/common/components/form-controls-deprecated';
import { AutocompleteOption } from '@/common/components/autocomplete';
import { DEFAULT_DATE_FORMAT } from '@/common/utils';

type VoucherBatchEntityResponse = {
  authorDetails: AuthorDetails;
  batchId: string;
  batchStatus: BatchStatus;
  totalVolume: number;
};

type VoucherBatchListEntityResponse = VoucherBatchEntityResponse & {
  creationDate: string;
  productsQuantity: number;
};

type VoucherBatchDetailsEntityResponse = VoucherBatchEntityResponse & {
  fileUrl: string;
  files: VoucherBatchFilesEntityResponse[];
  generated: string;
  productsQuantity: number;
};

type VoucherBatchFilesEntityResponse = {
  fileStatus: FileStatus;
  fileUrl: string;
  generated: string;
  productId: string;
  productName: string;
  userId: string;
  volume: number;
};

type AuthorDetails = {
  givenName: string;
  surname: string;
  userId: string;
};

type BatchStatus = {
  displayName: string;
  id: string;
  translationId: string;
};

type FileStatus = {
  displayName: string;
  id: string;
  translationId: string;
};

export type VoucherBatchListRequest = ApiPagination<{
  statuses?: string[];
  userId?: AutocompleteOption;
  product?: AutocompleteOption;
  voucherTypes?: string[];
}>;

export type VoucherBatchDetailsRequest = {
  id: string;
  isNotInitialFetch?: boolean;
};

export type VoucherAddMapRequest = {
  isbn: string;
  productId: string;
  publishDate: string;
};

export type VoucherBatchListResponse = PageableListResponse<VoucherBatchListEntityResponse>;

export interface VoucherBatchListElementVM extends VoucherBatchListEntityResponse {
  author: string;
  status: string;
}
export class VoucherBatchListElementVM {
  constructor({ creationDate, authorDetails, batchStatus, ...props }: VoucherBatchListEntityResponse) {
    Object.assign(this, {
      ...props,
      status: batchStatus.displayName,
      author: authorDetails.givenName && authorDetails.surname ? `${authorDetails.givenName} ${authorDetails.surname}` : '',
      creationDate: creationDate ? dayjs(creationDate).format(DEFAULT_DATE_FORMAT) : '',
    });
  }
}

export class VoucherBatchListVM extends PageableListVM<VoucherBatchListElementVM, VoucherBatchListResponse> {}

export type VoucherBatchDetailsResponse = VoucherBatchDetailsEntityResponse;

export interface VoucherBatchDetailsVM extends VoucherBatchDetailsEntityResponse {
  author: string;
}
export class VoucherBatchDetailsVM {
  constructor({ generated, authorDetails, ...props }: VoucherBatchDetailsEntityResponse) {
    Object.assign(this, {
      ...props,
      author: authorDetails.givenName && authorDetails.surname ? `${authorDetails.givenName} ${authorDetails.surname}` : '',
      generated: generated ? dayjs(generated).format(DEFAULT_DATE_FORMAT) : '',
    });
  }
}

export type VoucherBatchFilesResponse = VoucherBatchFilesEntityResponse;

export interface VoucherBatchFilesElementVM extends VoucherBatchFilesEntityResponse {}

export class VoucherBatchFilesElementVM {
  constructor({ generated, ...props }: VoucherBatchFilesEntityResponse) {
    Object.assign(this, {
      ...props,
      generated: generated ? dayjs(generated).format(DEFAULT_DATE_FORMAT) : '',
    });
  }
}

type VoucherBatchStatusesResponse = {
  id: string;
  displayName: string;
  translationId: string;
};

export type VoucherBatchStatusesListResponse = VoucherBatchStatusesResponse[];

export interface VoucherBatchStatusesDictionaryElementVM extends SelectOption {}
export class VoucherBatchStatusesDictionaryElementVM {
  constructor({ id, displayName }: VoucherBatchStatusesResponse) {
    this.value = id;
    this.label = displayName;
  }
}

export interface VoucherBatchStatusesDictionaryVM {
  list: VoucherBatchStatusesDictionaryElementVM[];
}

export class VoucherBatchStatusesDictionaryVM {
  constructor(list: VoucherBatchStatusesListResponse) {
    this.list = (list || []).map(element => new VoucherBatchStatusesDictionaryElementVM(element));
  }
}

type GeneratedVoucherBatchEntityResponse = {
  productId: string;
  productName: string;
  publishedDate: string;
  quantity: number;
};

export type GeneratedVoucherBatchListResponse = {
  validatedProducts: GeneratedVoucherBatchEntityResponse[];
  invalidIds: string[];
};

export interface GeneratedVoucherBatchElementVM {
  productId: string;
  productName: string;
  publishedDate: string;
  quantity: number;
}
export class GeneratedVoucherBatchElementVM {
  constructor({ publishedDate, ...props }: GeneratedVoucherBatchEntityResponse) {
    Object.assign(this, {
      ...props,
      productId: props.productId,
      publishedDate: publishedDate ? dayjs(publishedDate).format(DEFAULT_DATE_FORMAT) : '',
    });
  }
}

export interface GeneratedVoucherBatchListVM {
  validatedProducts: GeneratedVoucherBatchElementVM[];
  invalidIds: string[];
}

export class GeneratedVoucherBatchListVM {
  constructor(generatedBatches: GeneratedVoucherBatchListResponse) {
    this.validatedProducts = (generatedBatches.validatedProducts || []).map(element => new GeneratedVoucherBatchElementVM(element));
    this.invalidIds = generatedBatches.invalidIds;
  }
}

export type CreatedVoucherBatchResponse = {
  batchId: string;
};
