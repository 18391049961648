import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { dataCenterSwitcherActions, MakeGetRegionsListData, MakeGetRegionsListStatus } from '@/store/data-center-regions';
import { authenticationActions, makeGetUserStatus } from '@/store/authentication';
import { CommonRadioValue, RadioGroup } from '@/common/components/form-controls-deprecated';
import { ProtectedContent } from '@/common/components/protected-content';
import { useWithPermission } from '@/common/hooks/permission';
import { CollapsibleFilter } from '@/common/components/collapsible-filter';

type Props = {
  disabled: boolean;
  defaultOpen?: boolean;
};

const getRegionsListData = MakeGetRegionsListData();
const getRegionsListStatus = MakeGetRegionsListStatus();
const getUserStatus = makeGetUserStatus();

export const useHandleClearCurrentRegion = () => {
  const { defaultRegion } = useSelector(getUserStatus);
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(authenticationActions.restoreDefaultRegion());

    return defaultRegion;
  }, []);
};

export const RegionFilter: FC<Props> = ({ disabled, defaultOpen }) => {
  const regionsListData = useSelector(getRegionsListData);
  const regionsListStatus = useSelector(getRegionsListStatus);
  const { currentRegion } = useSelector(getUserStatus);

  const dispatch = useDispatch();
  const [t] = useTranslation();

  const [isSearchAllowed, withPermission] = useWithPermission({ subject: 'REGIONS', scope: 'SEARCH' });
  const regionFilterData = useMemo(
    () =>
      regionsListData?.list.map(region => ({
        value: region.url,
        label: t(`region.${region.regionCode}`),
      })) || [],
    [regionsListData]
  );

  const handleChangeCurrentRegion = useCallback(
    (value?: CommonRadioValue) => {
      if (!value || !regionsListData) {
        return;
      }
      const region = regionsListData.list.find(entity => entity.url === value);
      region && dispatch(authenticationActions.setUserCurrentRegion(region));
    },
    [regionsListData]
  );

  useEffect(() => {
    withPermission(() => dispatch(dataCenterSwitcherActions.regions.request()));
  }, [isSearchAllowed]);

  return (
    <ProtectedContent subject='ADMIN_PANEL' scope='ACCESS'>
      <CollapsibleFilter label={t('label.country')} activeFiltersCount={1} defaultOpen={defaultOpen}>
        <RadioGroup
          data={regionFilterData}
          value={currentRegion?.url}
          onChange={handleChangeCurrentRegion}
          disabled={disabled || regionsListStatus === 'loading'}
        />
      </CollapsibleFilter>
    </ProtectedContent>
  );
};
