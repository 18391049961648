import { createReducer } from 'typesafe-actions';

import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { PlatformDictionaryVM } from '@/features/accounts/models/users';
import { FeatureStateType } from '../../helpers';
import { usersActions } from '../actions';

type platformDictionaryState = DictionaryState<PlatformDictionaryVM>;

const INITIAL_STATE: platformDictionaryState = getDictionaryInitialState<PlatformDictionaryVM>();

const reducer = createReducer<platformDictionaryState, usersActions>(INITIAL_STATE)
  .handleAction(usersActions.platformDictionary.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(usersActions.platformDictionary.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(usersActions.platformDictionary.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(usersActions.platformDictionary.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(usersActions.platformDictionary.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const platformDictionaryReducer = { platformDictionary: reducer };
export type platformDictionaryReducer = FeatureStateType<typeof platformDictionaryReducer>;
