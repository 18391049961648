import { ActionType, Action, createAction } from 'typesafe-actions';

import { createDictionaryActions, createFetchActions } from '@/common/utils/store';
import { InvitationListRequest, InvitationListVM, InvitationStatusDictionaryVM, InvitationTypeDictionaryVM } from '@/models/invitations';

export const invitationsActions = {
  list: createFetchActions('FETCH_INVITATION_LIST_REQUEST', 'FETCH_INVITATION_LIST_SUCCESS', 'FETCH_INVITATION_LIST_FAILURE')<
    InvitationListRequest,
    InvitationListVM
  >(),
  clearInvitationList: createAction('CLEAR_INVITATION_LIST')(),
  types: createDictionaryActions(
    'FETCH_INVITATION_TYPES_DICTIONARY_REQUEST',
    'FETCH_INVITATION_TYPES_DICTIONARY_SUCCESS',
    'FETCH_INVITATION_TYPES_DICTIONARY_FAILURE',
    'FETCH_INVITATION_TYPES_DICTIONARY_CANCEL',
    'FETCH_INVITATION_TYPES_DICTIONARY_SILENT_REQUEST'
  )<undefined, InvitationTypeDictionaryVM>(),
  statuses: createDictionaryActions(
    'FETCH_INVITATION_STATUSES_DICTIONARY_REQUEST',
    'FETCH_INVITATION_STATUSES_DICTIONARY_SUCCESS',
    'FETCH_INVITATION_STATUSES_DICTIONARY_FAILURE',
    'FETCH_INVITATION_STATUSES_DICTIONARY_CANCEL',
    'FETCH_INVITATION_STATUSES_DICTIONARY_SILENT_REQUEST'
  )<undefined, InvitationStatusDictionaryVM>(),
};

export type invitationsActions = Action | ActionType<typeof invitationsActions>;
