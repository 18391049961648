import { ActionType, Action, createAction } from 'typesafe-actions';

import { createFetchActions } from '@/common/utils/store';
import { SmartMapsDetailsVM, SmartMapsListRequest, SmartMapsListVM } from '@/models/smartmaps';

export const smartMapsActions = {
  list: createFetchActions('FETCH_MANAGEMENT_SMARTMAPS_LIST_REQUEST', 'FETCH_MANAGEMENT_SMARTMAPS_LIST_SUCCESS', 'FETCH_MANAGEMENT_SMARTMAPS_LIST_FAILURE')<
    SmartMapsListRequest,
    SmartMapsListVM
  >(),
  clearSmartMapsList: createAction('CLEAR_MANAGEMENT_SMARTMAPS_LIST')(),
  details: createFetchActions(
    'FETCH_MANAGEMENT_SMARTMAPS_DETAILS_REQUEST',
    'FETCH_MANAGEMENT_SMARTMAPS_DETAILS_SUCCESS',
    'FETCH_MANAGEMENT_SMARTMAPS_DETAILS_FAILURE'
  )<string, SmartMapsDetailsVM>(),
  clearSmartMapsDetails: createAction('CLEAR_MANAGEMENT_SMARTMAPS_DETAILS')(),
};

export type smartMapsActions = Action | ActionType<typeof smartMapsActions>;
