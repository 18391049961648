import { ActionType, Action, createAction } from 'typesafe-actions';

import { createFetchActions } from '@/common/utils/store';
import { MapAttributionDetailsVM, MapAttributionListRequest, MapAttributionListVM } from '@/models/map-attribution';

export const mapAttributionActions = {
  list: createFetchActions('FETCH_MAP_ATTRIBUTION_LIST_REQUEST', 'FETCH_MAP_ATTRIBUTION_LIST_SUCCESS', 'FETCH_MAP_ATTRIBUTION_LIST_FAILURE')<
    MapAttributionListRequest,
    MapAttributionListVM
  >(),
  clearMapAttributionList: createAction('CLEAR_MAP_ATTRIBUTION_LIST')(),
  details: createFetchActions('FETCH_MAP_ATTRIBUTION_DETAILS_REQUEST', 'FETCH_MAP_ATTRIBUTION_DETAILS_SUCCESS', 'FETCH_MAP_ATTRIBUTION_DETAILS_FAILURE')<
    string,
    MapAttributionDetailsVM
  >(),
  clearMapAttributionForm: createAction('CLEAR_MAP_ATTRIBUTION_FORM')(),
};

export type mapAttributionActions = Action | ActionType<typeof mapAttributionActions>;
