import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { actions, CallBackProps, events, lifecycle, status } from 'react-joyride';

import { arrayReplace, Enum } from '@/common/utils';
import { Step, Steps } from './components/onboarding-component';

export interface CurrentStep extends Omit<CallBackProps, 'step' | 'controlled'> {
  action: Enum<actions>;
  status: Enum<status>;
  type: Enum<events>;
  lifecycle: Enum<lifecycle>;
  id: number;
}

export const onboardingActions = {
  addStep: createAction('ONBOARDING_ADD_STEP')<Step | Steps>(),
  replaceStep: createAction('ONBOARDING_REPLACE_STEP')<{ prev: Step; next: Step }>(),
  setCanStart: createAction('ONBOARDING_SET_CAN_START')<boolean>(),
  setCurrentStep: createAction('ONBOARDING_SET_CURRENT_STEP')<CurrentStep>(),
  setStartDelay: createAction('ONBOARDING_SET_START_DELAY')<number>(),
  setName: createAction('ONBOARDING_SET_NAME')<string>(),
};
export type OnboardingActions = ActionType<typeof onboardingActions>;

export type OnboardingState = {
  steps: Steps;
  canStart: boolean;
  currentStep?: CurrentStep;
  startDelay?: number;
  name?: string;
};

export const ONBOARDING_START_DELAY = 500;
export const INITIAL_STATE: OnboardingState = { steps: [], canStart: false, startDelay: ONBOARDING_START_DELAY };

export const onboardingReducer = createReducer<OnboardingState, OnboardingActions>(INITIAL_STATE)
  .handleAction(onboardingActions.addStep, (state, { payload }) => ({
    ...state,
    steps: state.steps.concat(payload),
  }))
  .handleAction(onboardingActions.replaceStep, (state, { payload }) => {
    const index = state.steps.findIndex(step => step.index === payload.prev.index);
    if (index < 0) return state;
    return {
      ...state,
      steps: arrayReplace(state.steps, index, payload.next),
    };
  })
  .handleAction(onboardingActions.setCanStart, (state, { payload }) => ({
    ...state,
    canStart: payload,
  }))
  .handleAction(onboardingActions.setCurrentStep, (state, { payload }) => ({
    ...state,
    currentStep: payload,
  }))
  .handleAction(onboardingActions.setStartDelay, (state, { payload }) => ({
    ...state,
    startDelay: payload,
  }))
  .handleAction(onboardingActions.setName, (state, { payload }) => ({
    ...state,
    name: payload,
  }));
