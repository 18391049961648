import { createReducer } from 'typesafe-actions';

import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { PlaceCategoriesDictionaryVM } from '@/features/content/models/things-to-do';
import { FeatureStateType } from '../../helpers';
import { thingsToDoActions } from '../actions';

type placeCategoriesState = DictionaryState<PlaceCategoriesDictionaryVM>;

const INITIAL_STATE: placeCategoriesState = getDictionaryInitialState<PlaceCategoriesDictionaryVM>();

const reducer = createReducer<placeCategoriesState, thingsToDoActions>(INITIAL_STATE)
  .handleAction(thingsToDoActions.placeCategories.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(thingsToDoActions.placeCategories.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(thingsToDoActions.placeCategories.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(thingsToDoActions.placeCategories.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(thingsToDoActions.placeCategories.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const placeCategoriesDictionaryReducer = { placeCategories: reducer };
export type placeCategoriesDictionaryReducer = FeatureStateType<typeof placeCategoriesDictionaryReducer>;
