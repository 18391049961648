import { switchMap, filter, map } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { CommonError, dictionaryEffectHelper } from '@/common/utils';
import { getInvitationListData, getInvitationStatusesData, getInvitationTypesData } from '@/features/accounts/services/invitations';
import { invitationsActions } from './actions';
import { invitationsReducer } from './reducers';

const getInvitationList: Epic<invitationsActions, invitationsActions, invitationsReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(invitationsActions.list.request)),
    switchMap(() =>
      getInvitationListData(state$.value.invitations.list.params).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return invitationsActions.list.failure(response);
          }

          return invitationsActions.list.success(response);
        })
      )
    )
  );

const getInvitationTypeDictionary: Epic<invitationsActions, invitationsActions, invitationsReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(invitationsActions.types.request)),
    switchMap(() => {
      const state = state$.value.invitations.types;
      return dictionaryEffectHelper(() => getInvitationTypesData(), state, invitationsActions.types);
    })
  ) as any;

const getInvitationStatusDictionary: Epic<invitationsActions, invitationsActions, invitationsReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(invitationsActions.statuses.request)),
    switchMap(() => {
      const state = state$.value.invitations.statuses;
      return dictionaryEffectHelper(() => getInvitationStatusesData(), state, invitationsActions.statuses);
    })
  ) as any;

export const invitationsEpics = [getInvitationList, getInvitationTypeDictionary, getInvitationStatusDictionary];
