import React, { FC, useCallback, useState, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';

import { Input } from '@/common/components/form-controls-deprecated';
import { ContentFragmentType } from '@/models/tours';
import { STRING_REQUIRED } from '@/common/utils/form-validators';
import { useStyles } from '@/styles/hooks';
import { EditorCard, EditorCardProps, EditorValue } from '../../../models';
import { CommonEditorCard, CommonEditorCardInnerComponent } from '../components/common-editor-card';
import { headerStyle } from './styles';

type HeaderCardPayload = string;
export type HeaderCardValue = EditorValue<typeof ContentFragmentType['header'], HeaderCardPayload>;

const VALIDATION_SCHEMA = STRING_REQUIRED;

const handleValidation = () => (value: HeaderCardPayload) => {
  return VALIDATION_SCHEMA.validate(value, { abortEarly: false })
    .then(() => undefined)
    .catch(err => {
      return err.errors;
    });
};

const HeaderCardHeaderComponent: CommonEditorCardInnerComponent<HeaderCardPayload> = ({ isEditable, formField }) => {
  const [field, meta, helpers] = formField;

  const [innerValue, setInnerValue] = useState<HeaderCardPayload>(field.value || '');

  const [t] = useTranslation();
  const { styles } = useStyles(headerStyle);

  const handleOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setInnerValue(e.target.value), []);

  const handleOnBlur = useCallback(() => {
    helpers.setTouched(true);
    helpers.setValue(innerValue, true);
  }, [innerValue, helpers.setValue, helpers.setTouched]);

  useEffect(() => {
    if (!isEqual(innerValue, field.value)) {
      setInnerValue(field.value);
    }
  }, [field.value]);

  return (
    <div css={styles}>
      {isEditable ? (
        <Input
          {...field}
          value={innerValue || ''}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          placeholder={t('storyContentEditor.card.header.placeholder')}
        />
      ) : meta.value ? (
        <div className='value'>{meta.value || ''}</div>
      ) : (
        <div className='placeholder'>{t('storyContentEditor.card.header.placeholder')}</div>
      )}
    </div>
  );
};

type Props = EditorCardProps;

const HeaderCardComponent: FC<Props> = props => {
  return <CommonEditorCard<HeaderCardPayload> {...props} headerComponent={HeaderCardHeaderComponent} validate={handleValidation} />;
};

export const HeaderCard: EditorCard = {
  type: 'header',
  title: 'storyContentEditor.card.header.title',
  iconName: 'mdi-format-title',
  content: HeaderCardComponent,
};
