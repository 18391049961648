import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import mapValues from 'lodash/mapValues';
import { CommonAuthorizationUrlRequest } from '@azure/msal-common';
import type { EndSessionRequest, Configuration, RedirectRequest, SilentRequest, SsoSilentRequest } from '@azure/msal-browser';

import { getEnv, getLocationOrigin } from '@/common/utils';
import { authenticationRoutePaths, authenticationRoutes } from '../../../navigation';
import { DEFAULT_REQUEST_PARAMS } from './constants';
import { RequestParams } from './models';

const config = getEnv();

export const msalConfig: Configuration = {
  auth: {
    clientId: config.REACT_APP_AUTHENTICATION_CLIENT_ID,
    knownAuthorities: config.REACT_APP_AUTHENTICATION_KNOWN_AUTHORITIES.split(/[,|(, )]/g),
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

const SCOPES: CommonAuthorizationUrlRequest['scopes'] = [
  'openid',
  'offline_access',
  `${config.REACT_APP_AUTHENTICATION_API_SCOPE_URL}/platform/read`,
  `${config.REACT_APP_AUTHENTICATION_API_SCOPE_URL}/platform/write`,
  `${config.REACT_APP_AUTHENTICATION_API_SCOPE_URL}/platform/user_impersonation`,
];

export const loginRedirectRequestConfig = ({
  policy = DEFAULT_REQUEST_PARAMS.policy,
  state,
  extraQueryParameters,
}: RequestParams = DEFAULT_REQUEST_PARAMS): RedirectRequest => ({
  authority: `${config.REACT_APP_AUTHENTICATION_API_URL}/${policy}`,
  scopes: SCOPES,
  redirectUri: `${getLocationOrigin()}/account-hub`,
  extraQueryParameters,
  state,
});

export const logoutRedirectRequestConfig = ({
  policy = DEFAULT_REQUEST_PARAMS.policy,
  state,
  preventEmission,
  reason,
}: RequestParams & { reason?: string; preventEmission?: boolean } = DEFAULT_REQUEST_PARAMS): EndSessionRequest => {
  const prepareQueryString = new URLSearchParams(mapValues(omitBy({ reason, emission: !preventEmission }, isNil), String));
  return {
    authority: `${config.REACT_APP_AUTHENTICATION_API_URL}/${policy}`,
    postLogoutRedirectUri: `${getLocationOrigin()}${authenticationRoutePaths[authenticationRoutes.LOGIN]}${prepareQueryString ? `?${prepareQueryString}` : ''}`,
    state,
  };
};

export const ssoSilentRequestConfig = ({ policy = DEFAULT_REQUEST_PARAMS.policy }: RequestParams = DEFAULT_REQUEST_PARAMS): SsoSilentRequest => ({
  authority: `${config.REACT_APP_AUTHENTICATION_API_URL}/${policy}`,
  scopes: SCOPES,
  redirectUri: `${getLocationOrigin()}/account-hub`,
});

export const silentRequestConfig = ({ policy = DEFAULT_REQUEST_PARAMS.policy }: RequestParams = DEFAULT_REQUEST_PARAMS): SilentRequest => ({
  authority: `${config.REACT_APP_AUTHENTICATION_API_URL}/${policy}`,
  scopes: SCOPES,
  redirectUri: `${getLocationOrigin()}/account-hub`,
});
