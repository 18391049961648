import { constants, rem } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const datepickerStyles: StyleFunction = ({ theme }) => ({
  '.react-datepicker': {
    border: `solid 1px ${theme.borderPrimary}`,
    fontFamily: constants.fontFamily.primary,
    fontSize: rem(constants.fontSize.h6),
    backgroundColor: theme.backgroundPrimary,
    borderRadius: 0,
  },

  '.react-datepicker-wrapper': {
    '.react-datepicker__input-container': {
      '.clear-button::after': {
        color: theme.primary,
        backgroundColor: 'transparent',
        fontSize: 32,
        fontWeight: 300,
        cursor: 'pointer',
        paddingRight: constants.offset.xsmall,

        '&:hover': {
          backgroundColor: theme.backgroundPrimaryHover,
        },
      },
      '.clear-button--disabled': {
        display: 'none',
      },
    },
  },

  '.react-datepicker__header': {
    backgroundColor: theme.backgroundThird,
    borderRadius: '0 !important',
    borderBottom: `solid 1px ${theme.borderPrimary}`,
    fontSize: rem(constants.fontSize.h6),

    '& > *': {
      color: theme.textPrimary,
    },
  },

  '.react-datepicker__day-names': {
    '& > *': {
      color: theme.textPrimary,
    },
  },

  '.react-datepicker__current-month': {
    paddingBottom: 7,
  },

  '.react-datepicker__day': {
    color: theme.textPrimary,
  },

  '.react-datepicker__month-text': {
    color: theme.textPrimary,
  },

  '.react-datepicker-popper[data-placement^=bottom]': {
    paddingTop: 0,
  },

  '.react-datepicker__month--disabled, .react-datepicker__day--disabled': {
    color: theme.textDisabled,
  },

  '.react-datepicker-year-header': {
    paddingBottom: 7,
    color: theme.textPrimary,
  },

  '.react-datepicker__navigation': {
    '&--previous': {
      borderRightColor: theme.textPrimary,
    },

    '&--next': {
      borderLeftColor: theme.textPrimary,
    },

    '&:hover': {
      cursor: 'pointer',
    },
  },

  '.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected': {
    backgroundColor: theme.backgroundPrimary,
    outline: 'none',
    color: theme.textPrimary,

    '&:hover': {
      backgroundColor: theme.backgroundPrimaryHover,
    },
  },

  '.react-datepicker__day, .react-datepicker__month-text': {
    '&:hover': {
      backgroundColor: theme.backgroundPrimaryHover,
    },

    '&:focus-visible': {
      outline: `2px solid ${theme.borderPrimaryFocus}`,
    },
  },

  '.react-datepicker__day--selected, .react-datepicker__month--selected': {
    backgroundColor: theme.primary,
    color: theme.textWhite,

    '&:hover': {
      backgroundColor: `${theme.primaryHover} !important`,
    },
  },
});
