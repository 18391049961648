import { Enum, getAppVersion, getBrowserData, getEnv, getLocationOrigin } from '@/common/utils';

type Params = {
  policy: AccountHubPolicy;
  initialUrl?: string;
};

export type AccountHubPolicy = Enum<typeof AccountHubPolicy>;
export const AccountHubPolicy = Enum('B2C_1A_signup_signin', 'B2C_1A_PasswordReset');

const config = getEnv();

const getAccountHubUrls = ({ policy, initialUrl }: Params) => {
  const urlArray = [
    config.REACT_APP_AUTHENTICATION_API_URL,
    `/oauth2/v2.0/authorize?p=${policy}&`,
    `client_id=${config.REACT_APP_AUTHENTICATION_CLIENT_ID}&`,
    'nonce=defaultNonce&',
    `redirect_uri=${`${getLocationOrigin()}/account-hub/${policy}`}&`,
    `scope=openid%20${config.REACT_APP_AUTHENTICATION_API_SCOPE_URL}%2Fplatform%2Fread%20${config.REACT_APP_AUTHENTICATION_API_SCOPE_URL}%2Fplatform%2Fuser_impersonation%20${config.REACT_APP_AUTHENTICATION_API_SCOPE_URL}%2Fplatform%2Fwrite%20${config.REACT_APP_AUTHENTICATION_API_SCOPE_URL}%2Fplatform%2Foffline_access%20offline_access&`,
    'response_type=code&',
    'prompt=login&',
    `appVersion=${getAppVersion?.()}&`,
    `deviceModel=${getBrowserData()}`,
  ];

  if (initialUrl !== undefined) {
    urlArray.push(`&state={"initialUrl": "${initialUrl}"}`);
  }

  return urlArray.join('');
};

export const getLoginPageUrl = (initialUrl?: string) => getAccountHubUrls({ initialUrl, policy: AccountHubPolicy.B2C_1A_signup_signin });
export const getResetPasswordPageUrl = (initialUrl?: string) => getAccountHubUrls({ initialUrl, policy: AccountHubPolicy.B2C_1A_PasswordReset });
