import { map } from 'rxjs/operators';

import { userProfile } from '@/core/services/authentication';
import { interfaceActions } from '@/store/interface';
import { authenticationRoutePaths, authenticationRoutes } from '../routes';
import { Guard } from '../navigation.models';

export const authenticatedGuard: Guard = ({ store, location }) =>
  userProfile({ store }).pipe(
    map(data => {
      store.dispatch(interfaceActions.setInitialUrl(location.pathname));

      if (data && data.consents && data.consents.length > 0) {
        return { redirectUrl: authenticationRoutePaths[authenticationRoutes.CONSENTS] };
      }

      if (data !== undefined) {
        return null;
      }

      return { redirectUrl: authenticationRoutePaths[authenticationRoutes.LOGIN] };
    })
  );
