import { createSelector } from 'reselect';

import { GalleryListVM } from '@/models/gallery';
import { getDataStatus } from '@/common/utils';
import { galleryReducer } from './reducers';

const getGalleryList = (state: galleryReducer) => state.gallery.list;

type GalleryList = galleryReducer['gallery']['list'];
type MakeGetGalleryListData = GalleryListVM['list'];
type MakeGetGalleryListPaginationData = GalleryListVM['listPaginationData'] | undefined;
type MakeGetGalleryListFilters = GalleryList['params'];

export const makeGetGalleryListData = () => createSelector<galleryReducer, GalleryList, MakeGetGalleryListData>(getGalleryList, ({ data }) => data?.list || []);

export const makeGetGalleryListStatus = () => createSelector<galleryReducer, GalleryList, ReturnType<typeof getDataStatus>>(getGalleryList, getDataStatus);

export const makeGetGalleryListPaginationData = () =>
  createSelector<galleryReducer, GalleryList, MakeGetGalleryListPaginationData>(getGalleryList, ({ data }) => data?.listPaginationData);

export const makeGetGalleryListFilters = () => createSelector<galleryReducer, GalleryList, MakeGetGalleryListFilters>(getGalleryList, ({ params }) => params);

type GalleryStatusDictionary = galleryReducer['gallery']['status'];
type MakeGetGalleryStatusDictionaryData = galleryReducer['gallery']['status']['data'];

const getGalleryStatusDictionary = (state: galleryReducer) => state.gallery.status;

export const makeGetGalleryStatusDictionaryData = () =>
  createSelector<galleryReducer, GalleryStatusDictionary, MakeGetGalleryStatusDictionaryData>(getGalleryStatusDictionary, ({ data }) => data);

export const makeGetGalleryStatusDictionaryStatus = () =>
  createSelector<galleryReducer, GalleryStatusDictionary, ReturnType<typeof getDataStatus>>(getGalleryStatusDictionary, getDataStatus);

type GalleryApplicationsDictionary = galleryReducer['gallery']['application'];
type MakeGetGalleryApplicationsDictionaryData = galleryReducer['gallery']['status']['data'];

const getGalleryApplicationsDictionary = (state: galleryReducer) => state.gallery.application;

export const makeGetGalleryApplicationsDictionaryData = () =>
  createSelector<galleryReducer, GalleryApplicationsDictionary, MakeGetGalleryApplicationsDictionaryData>(getGalleryApplicationsDictionary, ({ data }) => data);

export const makeGetGalleryApplicationsDictionaryStatus = () =>
  createSelector<galleryReducer, GalleryApplicationsDictionary, ReturnType<typeof getDataStatus>>(getGalleryApplicationsDictionary, getDataStatus);

type GalleryModalImagesList = galleryReducer['gallery']['modalImagesList'];
type MakeGetGalleryModalImagesList = galleryReducer['gallery']['modalImagesList']['modalImagesList'];
type MakeGetGalleryModalImagesHasPrev = galleryReducer['gallery']['modalImagesList']['canLoadPrevPage'];
type MakeGetGalleryModalImagesHasNext = galleryReducer['gallery']['modalImagesList']['canLoadNextPage'];
type MakeGetGalleryModalImagesPageSize = galleryReducer['gallery']['modalImagesList']['pageSize'];

const getGalleryModalImagesList = (state: galleryReducer) => state.gallery.modalImagesList;

export const makeGetGalleryModalImagesList = () =>
  createSelector<galleryReducer, GalleryModalImagesList, MakeGetGalleryModalImagesList>(getGalleryModalImagesList, ({ modalImagesList }) => modalImagesList);
export const makeGetGalleryModalImagesHasPrev = () =>
  createSelector<galleryReducer, GalleryModalImagesList, MakeGetGalleryModalImagesHasPrev>(getGalleryModalImagesList, ({ canLoadPrevPage }) => canLoadPrevPage);
export const makeGetGalleryModalImagesHasNext = () =>
  createSelector<galleryReducer, GalleryModalImagesList, MakeGetGalleryModalImagesHasNext>(getGalleryModalImagesList, ({ canLoadNextPage }) => canLoadNextPage);
export const makeGetGalleryModalImagesListLoading = () =>
  createSelector<galleryReducer, GalleryModalImagesList, MakeGetGalleryModalImagesHasNext>(getGalleryModalImagesList, ({ isListLoading }) => isListLoading);
export const makeGetGalleryModalImagesPageSize = () =>
  createSelector<galleryReducer, GalleryModalImagesList, MakeGetGalleryModalImagesPageSize>(getGalleryModalImagesList, ({ pageSize }) => pageSize);
