import { fromEvent } from 'rxjs';
import { take } from 'rxjs/operators';

import { hotjarScript } from './hotjar';

const COOKIE_PLUGIN_PATH = 'https://www.ordnancesurvey.co.uk/os-js/cookie-control/cookie-control.min.js';
const COOKIE_CONFIG_PATH = 'https://www.ordnancesurvey.co.uk/os-js/cookie-control/osl-admin.min.js';

interface CookieConfig {
  optionalCookies: { name: string; onAccept: Function; onRevoke: Function }[];
}

interface Window {
  COOKIE_CONTROL: CookieConfig;
  CookieControl?: {
    load: (config: CookieConfig) => void;
    open: Function;
  };
}

declare const window: Window;

export const createCookieControl = () => {
  const head = document.getElementsByTagName('head')[0];

  const onPerformanceCookiesRevoke = (onRevoke: Function) => {
    onRevoke();
  };

  const onPerformanceCookiesAccept = (onAccept: Function) => {
    onAccept();

    if (process.env.NODE_ENV === 'production') {
      hotjarScript();
    }
  };

  const init = () => {
    const CControl = document.createElement('script');
    CControl.setAttribute('src', `${COOKIE_PLUGIN_PATH}?${Date.now()}`);
    CControl.setAttribute('id', 'CCPlugin');
    head.prepend(CControl);

    fromEvent(CControl, 'load')
      .pipe(take(1))
      .subscribe(() => {
        const CConfig = document.createElement('script');
        CConfig.setAttribute('src', `${COOKIE_CONFIG_PATH}?${Date.now()}`);
        CConfig.setAttribute('id', 'CCConfig');
        head.prepend(CConfig);

        fromEvent(CConfig, 'load')
          .pipe(take(1))
          .subscribe(() => {
            if (!window.COOKIE_CONTROL || !window.CookieControl) {
              return;
            }

            const config: CookieConfig = window.COOKIE_CONTROL;
            const optionalCookies = config.optionalCookies.find(cookie => cookie.name === 'performance');

            if (optionalCookies) {
              const onAcceptFunction = optionalCookies.onAccept;
              const onRevokeFunction = optionalCookies.onRevoke;
              optionalCookies.onAccept = () => onPerformanceCookiesAccept(onAcceptFunction);
              optionalCookies.onRevoke = () => onPerformanceCookiesRevoke(onRevokeFunction);
            }

            window.CookieControl.load(config);
          });
      });
  };

  return { init };
};
