import { Enum } from '@/common/utils';

const ONBOARDING = 'ONBOARDING';

export const OnboardingStorageStatus = Enum('Done', 'InProgress', 'None');
export type OnboardingStorageStatus = Enum<typeof OnboardingStorageStatus>;

export type OnboardingSaveState = {
  size: number;
  step: number;
  status: OnboardingStorageStatus;
};

const state: { [key: string]: OnboardingSaveState | undefined } = {};

export const setOnboardingLocalStorage = (name: string, { size, step, status }: OnboardingSaveState, override?: boolean) => {
  const current = state[name] as OnboardingSaveState | undefined;

  if (current && current.status === OnboardingStorageStatus.Done) {
    if (!override) return;
    if (override && current.size >= size) return;
  }

  if (!current || size !== current.size || step !== current.step) {
    localStorage.setItem(`${ONBOARDING}_${name}`, JSON.stringify({ size, step, status }));
    state[name] = { size, step, status };
  }
};

export const getOnboardingLocalStorage = (name: string): OnboardingSaveState => {
  const saved = localStorage.getItem(`${ONBOARDING}_${name}`);

  if (saved) {
    const parsed = JSON.parse(saved);
    state[name] = parsed;
    return parsed;
  }

  return { size: 0, step: 0, status: OnboardingStorageStatus.None };
};
