import { createReducer } from 'typesafe-actions';

import { RouteDetailsVM } from '@/features/content/models/routes';
import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { FeatureStateType } from '../../helpers';
import { routesActions } from '../actions';

export type routeDetailsState = FetchState<RouteDetailsVM>;

const INITIAL_STATE: routeDetailsState = getFetchInitialState<RouteDetailsVM>();

const reducer = createReducer<routeDetailsState, routesActions>(INITIAL_STATE)
  .handleAction(routesActions.details.request, state => fetchReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(routesActions.details.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(routesActions.details.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(routesActions.clearRouteDetails, () => ({ ...INITIAL_STATE }));

export const routeDetailsReducer = { details: reducer };
export type routeDetailsReducer = FeatureStateType<typeof routeDetailsReducer>;
