import { createReducer } from 'typesafe-actions';

import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { DEFAULT_LIST_PAGINATION_DATA } from '@/common/utils/pageable';
import { MapAttributionListRequest, MapAttributionListVM } from '@/models/map-attribution';
import { FeatureStateType } from '../../helpers';
import { mapAttributionActions } from '../actions';

export type mapAttributionListState = FetchState<MapAttributionListVM, MapAttributionListRequest>;

const INITIAL_STATE: mapAttributionListState = getFetchInitialState<MapAttributionListVM, MapAttributionListRequest>();

const reducer = createReducer<mapAttributionListState, mapAttributionActions>(INITIAL_STATE)
  .handleAction(mapAttributionActions.list.request, (state, { payload }) => fetchReducerHelpers.request(INITIAL_STATE, state, payload))
  .handleAction(mapAttributionActions.list.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(mapAttributionActions.list.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(mapAttributionActions.clearMapAttributionList, state => ({
    ...state,
    data: { ...state.data, list: [], listPaginationData: state.data?.listPaginationData || DEFAULT_LIST_PAGINATION_DATA },
  }));

export const mapAttributionListReducer = { list: reducer };
export type mapAttributionListReducer = FeatureStateType<typeof mapAttributionListReducer>;
