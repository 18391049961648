import { createReducer } from 'typesafe-actions';

import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { DEFAULT_LIST_PAGINATION_DATA } from '@/common/utils/pageable';
import { ModerationListVM, ModerationParams } from '@/models/moderation';
import { FeatureStateType } from '../../helpers';
import { moderationActions } from '../actions';

export type moderationListState = FetchState<ModerationListVM, ModerationParams>;

const INITIAL_STATE: moderationListState = getFetchInitialState<ModerationListVM, ModerationParams>({ statuses: ['OPEN'], size: 10, sort: ['createdAt,DESC'] });

const reducer = createReducer<moderationListState, moderationActions>(INITIAL_STATE)
  .handleAction(moderationActions.list.request, (state, { payload }) => fetchReducerHelpers.request(INITIAL_STATE, state, payload))
  .handleAction(moderationActions.list.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(moderationActions.list.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(moderationActions.clearModerationList, state => ({
    ...state,
    data: { ...state.data, list: [], listPaginationData: state.data?.listPaginationData || DEFAULT_LIST_PAGINATION_DATA },
  }));

export const moderationListReducer = { list: reducer };
export type moderationListReducer = FeatureStateType<typeof moderationListReducer>;
