import React, { useCallback } from 'react';
import { FieldProps as FormikFieldProps } from 'formik';
import { ValidationError } from 'yup';

import { Chips, ChipsProps, ChipValue } from '../../chips';
import { Field, FieldProps } from '../field';

type Props<T, E> = Omit<FieldProps, 'children' | 'onChange'> & Omit<ChipsProps<T, E>, 'onChange' | 'options'>;

export const FieldChips = <T extends any = string, E extends Error = ValidationError>({
  name,
  label,
  labelIcon,
  tooltipMessage,
  className,
  ...props
}: Props<T, E>) => {
  const FieldComponent = ({ field: { value }, form, meta: { error, touched } }: FormikFieldProps) => {
    const handleChange = useCallback((value: ChipValue<T, E>[]) => {
      form.setFieldValue(name, value);
    }, []);

    return <Chips values={value} onChange={handleChange} error={touched ? error : undefined} {...props} />;
  };

  return (
    <Field name={name} label={label} labelIcon={labelIcon} tooltipMessage={tooltipMessage} className={className} isFocusOnLabelEnabled={false} hideErrorMessage>
      {FieldComponent}
    </Field>
  );
};
