import { Enum } from '@/common/utils';
import { RoutePaths } from '../../navigation.models';
import { authenticatedRoutePaths } from './authenticated';

export type accountsRoutes = Enum<typeof accountsRoutes>;
export const accountsRoutes = Enum(
  'USER_LIST',
  'USER_DETAILS',
  'ORGANISATION_LIST',
  'ORGANISATION_DETAILS',
  'ORGANISATION_ADD',
  'MY_ORGANISATION',
  'INVITATION_LIST',
  'INVITE_PARTNER',
  'INVITE_USERS'
);

export const accountsRoutePaths: RoutePaths<accountsRoutes> = {
  [accountsRoutes.USER_LIST]: `${authenticatedRoutePaths.ACCOUNTS}/users`,
  [accountsRoutes.ORGANISATION_LIST]: `${authenticatedRoutePaths.ACCOUNTS}/organisations`,
  [accountsRoutes.USER_DETAILS]: `${authenticatedRoutePaths.ACCOUNTS}/user/:id`,
  [accountsRoutes.MY_ORGANISATION]: `${authenticatedRoutePaths.ACCOUNTS}/organisations/my`,
  [accountsRoutes.ORGANISATION_ADD]: `${authenticatedRoutePaths.ACCOUNTS}/organisations/add`,
  [accountsRoutes.ORGANISATION_DETAILS]: `${authenticatedRoutePaths.ACCOUNTS}/organisations/:id`,
  [accountsRoutes.INVITATION_LIST]: `${authenticatedRoutePaths.ACCOUNTS}/invitations`,
  [accountsRoutes.INVITE_PARTNER]: `${authenticatedRoutePaths.ACCOUNTS}/invitations/invite-partner`,
  [accountsRoutes.INVITE_USERS]: `${authenticatedRoutePaths.ACCOUNTS}/invitations/invite-users`,
};

export type accountsRouteParams = {
  [accountsRoutes.USER_DETAILS]: { id: string };
  [accountsRoutes.ORGANISATION_DETAILS]: { id: string };
};
