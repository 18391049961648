import { createSelector } from 'reselect';

import { getDataStatus } from '@/common/utils';
import { contentTypesReducer } from './reducer';

type MakeGetContentTypesData = contentTypesReducer['contentTypes']['data'];

const getContentTypes = (state: contentTypesReducer) => state.contentTypes;

export const makeGetContentTypesData = () =>
  createSelector<contentTypesReducer, contentTypesReducer['contentTypes'], MakeGetContentTypesData>(getContentTypes, ({ data }) => data);
export const makeGetContentTypesStatus = () =>
  createSelector<contentTypesReducer, contentTypesReducer['contentTypes'], ReturnType<typeof getDataStatus>>(getContentTypes, getDataStatus);
