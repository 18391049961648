import { createSelector } from 'reselect';

import { getDataStatus } from '@/common/utils';
import { statusesReducer } from './reducer';

type MakeGetStatusesData = statusesReducer['statuses']['data'];

const getStatuses = (state: statusesReducer) => state.statuses;

export const makeGetStatusesData = () => createSelector<statusesReducer, statusesReducer['statuses'], MakeGetStatusesData>(getStatuses, ({ data }) => data);
export const makeGetStatusesStatus = () =>
  createSelector<statusesReducer, statusesReducer['statuses'], ReturnType<typeof getDataStatus>>(getStatuses, getDataStatus);
