import { ActionType, Action } from 'typesafe-actions';

import { RoleDictionaryVM } from '@/models/roles';
import { createDictionaryActions } from '@/common/utils/store';

export const rolesActions = createDictionaryActions(
  `FETCH_ROLES_DICTIONARY_REQUEST`,
  `FETCH_ROLES_DICTIONARY_SUCCESS`,
  `FETCH_ROLES_DICTIONARY_FAILURE`,
  `FETCH_ROLES_DICTIONARY_CANCEL`,
  `FETCH_ROLES_DICTIONARY_SILENT_REQUEST`
)<undefined, RoleDictionaryVM>();

export type rolesActions = Action | ActionType<typeof rolesActions>;
