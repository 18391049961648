import { createReducer } from 'typesafe-actions';

import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { ReviewDetailsVM } from '@/models/reviews';
import { FeatureStateType } from '../../helpers';
import { reviewsActions } from '../actions';

export type reviewsDetailsState = FetchState<ReviewDetailsVM>;

const INITIAL_STATE: reviewsDetailsState = getFetchInitialState<ReviewDetailsVM>();

const reducer = createReducer<reviewsDetailsState, reviewsActions>(INITIAL_STATE)
  .handleAction(reviewsActions.details.request, (state, { payload }) => fetchReducerHelpers.request(INITIAL_STATE, state, payload))
  .handleAction(reviewsActions.details.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(reviewsActions.details.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(reviewsActions.clearReviewDetails, () => ({ ...INITIAL_STATE }));

export const reviewsDetailsReducer = { details: reducer };
export type reviewsDetailsReducer = FeatureStateType<typeof reviewsDetailsReducer>;
