import React, { FC } from 'react';
import cn from 'classnames';

import { Field, FieldProps } from '../field';
import { DistanceInput } from './distance-input';

type FieldDistanceInputProps = Omit<FieldProps, 'onChange' | 'value' | 'children'>;

export const FieldDistanceInput: FC<FieldDistanceInputProps> = ({ name, className, label, labelIcon, isFocusOnLabelEnabled, ...props }) => (
  <Field name={name} label={label} labelIcon={labelIcon} isFocusOnLabelEnabled={false} className={className}>
    {({ field, meta, form }) => {
      const inputClassName = cn([{ error: meta.touched && meta.error }]);

      const handleFieldBlur = () => form.setFieldTouched(name);

      return <DistanceInput {...props} className={inputClassName} form={form} {...field} onBlur={handleFieldBlur} />;
    }}
  </Field>
);
