import dayjs, { Dayjs } from 'dayjs';

import { ApiPagination, PageableListResponse, PageableListVM } from '@/common/utils/pageable';
import { AutocompleteOption } from '@/common/components/autocomplete';
import { DATE_YEAR_MONTH_FORMAT } from '@/common/utils';
import { DatePickerValueWithReason as DateWithReason } from '@/common/components/form-controls-deprecated/datepicker-deprecated';

type SmartMapsListEntityResponse = {
  bbox: number[];
  centre: number[];
  channel: string;
  dateOfPurchase: string;
  isbn: string;
  mapCode: string;
  orderId: string;
  productGroup: string;
  productId: string;
  productName: string;
  publishedDate: string;
  purchaseMechanism: string;
  purchaseReferenceId: string;
  source: string;
  subChannel: string;
  tileUrl: TileUrl;
  title: string;
  transactionId: string;
  voucherCode: string;
};

type TileUrl = {
  authority: string;
  content: object;
  defaultPort: number;
  file: string;
  host: string;
  path: string;
  port: number;
  protocol: string;
  query: string;
  ref: string;
  userInfo: string;
};

export type SmartMapsListRequest = ApiPagination<{
  isbn?: string;
  active?: boolean;
  productName?: AutocompleteOption;
  publishDateFrom?: Dayjs;
  publishDateTo?: Dayjs;
}>;

export type SmartMapsListResponse = PageableListResponse<SmartMapsListEntityResponse>;

export interface SmartMapsListElementVM extends SmartMapsListEntityResponse {}
export class SmartMapsListElementVM {
  constructor({ publishedDate, ...props }: SmartMapsListEntityResponse) {
    Object.assign(this, {
      ...props,
      publishedDate: publishedDate ? dayjs(publishedDate).format(DATE_YEAR_MONTH_FORMAT) : '',
    });
  }
}

export class SmartMapsListVM extends PageableListVM<SmartMapsListElementVM, SmartMapsListResponse> {}

export type SmartMapsDetailsResponse = {
  bbox: number[];
  centre: number[];
  isbn: string;
  mapCode: string;
  productId: string;
  productName: string;
  publishedDate: string;
};

export interface SmartMapsDetailsVM extends Omit<SmartMapsDetailsResponse, 'publishedDate'> {
  publishedDate: DateWithReason;
}
export class SmartMapsDetailsVM {
  constructor({ publishedDate, ...props }: SmartMapsDetailsResponse) {
    const date = dayjs(publishedDate).utc();
    Object.assign<this, SmartMapsDetailsVM>(this, {
      publishedDate: { value: date.isValid() ? date : void 0 },
      ...props,
    });
  }
}
