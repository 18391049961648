import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { getEnv, CommonError } from '@/common/utils';
import { httpClient } from '@/core/services/http-client';
import { VoucherBatchStatusesListResponse, VoucherBatchStatusesDictionaryVM } from '@/models/voucher-batch';

const config = getEnv();

const STATUS_ENDPOINT = `${config.REACT_APP_API_URL}/entitlement-api/v1/vouchers/batches/statuses/options`;

export const getVoucherBatchStatusesData = () =>
  httpClient()
    .authorized.get<VoucherBatchStatusesListResponse>(STATUS_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new VoucherBatchStatusesDictionaryVM(data);
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
