import React, { FC } from 'react';
import cn from 'classnames';

import { Field, FieldProps } from '../field';
import { Input } from './input';

type InputProps = Omit<FieldProps, 'children'>;

export const FieldInput: FC<InputProps> = ({ name, label, labelIcon, isFocusOnLabelEnabled, className, link, tooltipMessage, ...props }) => (
  <Field
    name={name}
    label={label}
    labelIcon={labelIcon}
    isFocusOnLabelEnabled={isFocusOnLabelEnabled}
    className={className}
    tooltipMessage={tooltipMessage}
    link={link}
  >
    {({ field, meta }) => {
      const inputClassName = cn([{ error: meta.touched && meta.error }]);
      return <Input type='text' {...props} className={inputClassName} {...field} />;
    }}
  </Field>
);
