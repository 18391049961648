import React, { FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ReactJoyride, { ACTIONS, CallBackProps, EVENTS, LIFECYCLE, STATUS, Step as JRStep } from 'react-joyride';
import { Portal } from 'react-portal';
import uniqueId from 'lodash/uniqueId';

import { useJoyrideStyles } from '@/styles/hooks';
import { TOOLBAR_INFO } from '@/common/utils/element-ids';
import { IconButton } from '../../icon-button';
import { getOnboardingLocalStorage, OnboardingStorageStatus, setOnboardingLocalStorage } from '../localStorage';
import { onboardingActions } from '../contextStore';
import { runButtonStyles, joyrideStyles } from '../styles';
import { OnboardingTooltip } from './onboarding-tooltip';
import { OnboardingContext } from './onboarding-context';

export interface Step extends Required<Pick<JRStep, 'content' | 'target'>> {
  index: number;
  title: Required<ReactNode>;
  show?: boolean;
  options?: Omit<JRStep, 'content' | 'target' | 'title'>;
}

export type Steps = Step[];

export interface OnboardingProps {
  startDelay?: number;
  name?: string;
}

export const ONBOARDING_ICON_ID = uniqueId('ONBOARDING_ICON');

const matchType = <P, T>(value: T): P => value as any as P;

export const OnboardingComponent: FC<OnboardingProps> = props => {
  const { state, dispatch } = useContext(OnboardingContext);
  const { canStart, steps, ...stored } = state;
  const [isStarted, setIsStarted] = useState(false);
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [localStatus, setLocalStatus] = useState<OnboardingStorageStatus>(OnboardingStorageStatus.None);
  const joyrideStyle = useJoyrideStyles(joyrideStyles);

  const filteredSteps = useMemo(() => steps.filter(({ show }) => show !== false).sort((a, b) => a.index - b.index), [steps]);
  const getSteps = useCallback<() => JRStep[]>(
    () =>
      filteredSteps.map(({ content, target, title, options }, index) => ({
        target,
        title,
        content,
        disableBeacon: index === 0,
        ...options,
      })),
    [filteredSteps]
  );
  const getStepIds = useCallback(() => filteredSteps.map(({ index }) => index), [filteredSteps]);

  const [jrSteps, setJrSteps] = useState(getSteps);
  const [stepIds, setStepIds] = useState(getStepIds);
  const startDelay = useMemo(() => props.startDelay || stored.startDelay, [props.startDelay, stored.startDelay]);
  const name = useMemo(() => props.name || stored.name, [props.name || stored.name]);

  useEffect(() => {
    if (!name) return;

    const { size, status, step } = getOnboardingLocalStorage(name);
    setLocalStatus(status);

    if (!canStart || isStarted) return;

    switch (status) {
      case OnboardingStorageStatus.InProgress:
        setStepIndex(step);
        break;
      case OnboardingStorageStatus.Done:
        if (size < filteredSteps.length) {
          setLocalStatus(OnboardingStorageStatus.InProgress);
          setStepIndex(0);
          handleOnShow();
        }
        break;
      default:
        break;
    }
  }, [name, filteredSteps.length, canStart, isStarted]);

  useEffect(() => setJrSteps(getSteps), [getSteps]);
  useEffect(() => setStepIds(getStepIds), [getStepIds]);

  const handleOnShow = useCallback(() => setRun(true), []);

  useEffect(() => {
    if (!canStart || isStarted || localStatus === 'Done') return;
    setIsStarted(true);
    const timer = setTimeout(handleOnShow, startDelay);

    return () => {
      clearTimeout(timer);
    };
  }, [canStart, localStatus, startDelay]);

  const joyrideCallback = useCallback(
    (data: CallBackProps) => {
      const { status, action, index, type, lifecycle, size } = data;

      dispatch(
        onboardingActions.setCurrentStep({
          index,
          size,
          action: matchType(action),
          status: matchType(status),
          type: matchType(type),
          lifecycle: matchType(lifecycle),
          id: stepIds[index],
        })
      );

      const finished = status === STATUS.FINISHED && type === EVENTS.TOUR_END && (localStatus === OnboardingStorageStatus.Done || (size && index + 1 === size));
      const skipped = status === STATUS.SKIPPED && action === ACTIONS.SKIP;

      switch (status) {
        case STATUS.FINISHED:
        case STATUS.SKIPPED:
          setRun(false);
          if (finished || skipped) {
            setStepIndex(0);
            if (name) setOnboardingLocalStorage(name, { size, step: size, status: 'Done' }, true);
          }
          break;
        case STATUS.RUNNING:
          if (action === ACTIONS.START && lifecycle === LIFECYCLE.INIT && index >= size) {
            setStepIndex(0);
          }
        default:
          if (name) setOnboardingLocalStorage(name, { size, step: index, status: 'InProgress' });
          switch (type) {
            case EVENTS.TARGET_NOT_FOUND:
            case EVENTS.STEP_AFTER:
              if (action === ACTIONS.CLOSE) {
                setRun(false);
                break;
              }
              setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
              break;
            default:
              break;
          }
      }
    },
    [localStatus, name, stepIds]
  );

  return (
    <>
      <Portal node={document && document.getElementById(TOOLBAR_INFO)}>
        <div css={runButtonStyles}>
          <IconButton id={ONBOARDING_ICON_ID} iconName='mdi-information' onClick={handleOnShow} />
        </div>
      </Portal>

      <ReactJoyride
        continuous
        disableScrolling
        disableScrollParentFix
        disableOverlayClose
        steps={jrSteps}
        run={run}
        stepIndex={stepIndex}
        floaterProps={{ hideArrow: true, disableAnimation: true }}
        styles={joyrideStyle.styles}
        tooltipComponent={OnboardingTooltip}
        callback={joyrideCallback}
      />
    </>
  );
};
