import { combineReducers } from 'redux';

import { FeatureStateType } from '@/store/helpers';
import { invitationListReducer } from './list';
import { typesDictionaryReducer } from './types';
import { statusesDictionaryReducer } from './statuses';

export type invitationsReducer = FeatureStateType<typeof invitationsReducer>;
export const invitationsReducer = {
  invitations: combineReducers({ ...invitationListReducer, ...typesDictionaryReducer, ...statusesDictionaryReducer }),
};
