import React, { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react';
import { object, ValidationError } from 'yup';
import { useTranslation } from 'react-i18next';

import { Wysiwyg } from '@/common/components/wysiwyg/wysiwyg';
import { Icon } from '@/common/components/icon';
import { Input } from '@/common/components/form-controls-deprecated';
import { COMMON_STRING_LENGTH_NOT_REQUIRED, STRING_REQUIRED } from '@/common/utils';
import { ContentFragmentType } from '@/models/tours';
import { useStyles } from '@/styles/hooks';
import { EditorCard, EditorCardProps, EditorValue } from '../../../models';
import { CommonEditorCardStatusElement } from '../components/common-editor-card-status-element';
import { CommonEditorCard, CommonEditorCardInnerComponent } from '../components/common-editor-card';
import { attributionStyle } from './styles';

const ATTRIBUTION_PATH = 'attribution';

type ParagraphCardPayload = {
  text: string;
  attribution?: string;
};

export type ParagraphCardValue = EditorValue<typeof ContentFragmentType['paragraph'], ParagraphCardPayload>;

export const PARAGRAPH_CARD_VALIDATOR = object().shape({
  text: STRING_REQUIRED,
  attribution: COMMON_STRING_LENGTH_NOT_REQUIRED,
});

const VALIDATION_SCHEMA = PARAGRAPH_CARD_VALIDATOR;

const handleValidation = () => (value: ParagraphCardPayload) => {
  return VALIDATION_SCHEMA.validate(value, { abortEarly: false })
    .then(() => undefined)
    .catch(err => {
      return err.inner;
    });
};

const ParagraphCardHeaderComponent: CommonEditorCardInnerComponent<ParagraphCardPayload> = () => {
  return null;
};

const ParagraphCardContentComponent: CommonEditorCardInnerComponent<ParagraphCardPayload> = ({ isEditable, formField }) => {
  const [field, meta, helpers] = formField;
  const [innerTextValue, setInnerTextValue] = useState<string>(field.value?.text || '');
  const [innerAttributionValue, setInnerAttributionValue] = useState<string | undefined>(field.value?.attribution);
  const initialised = useRef<boolean>();
  const [t] = useTranslation();
  const { styles } = useStyles(attributionStyle);

  useEffect(() => {
    if (initialised.current !== true) {
      initialised.current = true;
    } else {
      helpers.setTouched(true);
      helpers.setValue(
        {
          ...field.value,
          text: innerTextValue,
        },
        true
      );
    }
  }, [innerTextValue]);

  const handleOnAttributionChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setInnerAttributionValue(e.target.value === '' ? undefined : e.target.value);
    },

    []
  );

  const handleOnAttributionBlur = useCallback(() => {
    helpers.setTouched(true);
    helpers.setValue({
      ...field.value,
      attribution: innerAttributionValue,
    });
  }, [innerAttributionValue, helpers.setValue, helpers.setTouched, field.value]);

  const findAttributionErrorMessage = useCallback(() => {
    const errors = meta.error as unknown as ValidationError[];
    if (errors && errors.length > 0) {
      for (const error of errors) {
        if (error.path === ATTRIBUTION_PATH) {
          return error.message;
        }
      }

      return undefined;
    }
  }, [meta.error]);

  const attributionErrorMessage: string | undefined = findAttributionErrorMessage();

  return (
    <>
      <Wysiwyg initValue={field.value?.text || ''} isEditable={isEditable} setValue={setInnerTextValue} name={field.name} />
      <div css={styles}>
        <Icon name='mdi-copyright' className='copyright-icon' />
        <div className='input-wrapper'>
          {isEditable ? (
            <Input
              onChange={handleOnAttributionChange}
              value={innerAttributionValue}
              onBlur={handleOnAttributionBlur}
              placeholder={t('storyContentEditor.card.attribution.placeholder')}
            />
          ) : meta.value?.attribution ? (
            <div className='value'>{meta.value.attribution}</div>
          ) : (
            <div className='placeholder'>{t('storyContentEditor.card.attribution.placeholder')}</div>
          )}
        </div>
        {attributionErrorMessage && meta.touched && <CommonEditorCardStatusElement status='ERROR' text={attributionErrorMessage} />}
      </div>
    </>
  );
};

type Props = EditorCardProps;

const ParagraphCardComponent: FC<Props> = props => {
  return (
    <CommonEditorCard<ParagraphCardPayload>
      {...props}
      headerComponent={ParagraphCardHeaderComponent}
      contentComponent={ParagraphCardContentComponent}
      validate={handleValidation}
      errorType='VALIDATION_ERROR'
      errorPath='text'
    />
  );
};

export const ParagraphCard: EditorCard = {
  type: 'paragraph',
  title: 'storyContentEditor.card.paragraph.title',
  iconName: 'mdi-format-paragraph',
  content: ParagraphCardComponent,
};
