import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { getEnv, CommonError } from '@/common/utils';
import { httpClient } from '@/core/services/http-client';
import { DifficultiesListResponse, DifficultiesDictionaryVM } from '@/models/difficulties';
import { VisibilityDictionaryVM, VisibilityListResponse } from '@/models/visibility';
import { PricesDictionaryVM, PricesListResponse, TipListResponse, TipsDictionaryVM } from '@/models/payments';
import { ActivitiesListResponse, ActivitiesDictionaryVM } from '@/models/activities';
import { CommonTagListResponse, CommonTagDictionaryVM } from '@/models/common-tag';
import { PlaceCategoriesDictionaryVM, PlaceCategoriesListResponse } from '@/features/content/models/things-to-do';
import { TourStatusesDictionaryVM, TourStatusesListResponse, TourTypesDictionaryVM, TourTypesListResponse } from '../models/tours';

const config = getEnv();

const DIFFICULTIES_ENDPOINT = `${config.REACT_APP_API_URL}/route-api/v1/dictionaries/difficulty`;
const VISIBILITY_ENDPOINT = `${config.REACT_APP_API_URL}/route-api/v1/dictionaries/visibility`;
const ACTIVITIES_ENDPOINT = `${config.REACT_APP_API_URL}/route-api/v1/dictionaries/activity`;
const TIPS_ENDPOINT = `${config.REACT_APP_API_URL}/entitlement-api/v1/products/tips`;
const STATUSES_ENDPOINT = `${config.REACT_APP_API_URL}/story-api/v2/tour-statuses`;
const PRICES_ENDPOINT = `${config.REACT_APP_API_URL}/entitlement-api/v1/products/tours`;
const TOUR_CATEGORIES_ENDPOINT = `${config.REACT_APP_API_URL}/story-api/v2/categories`;
const TOUR_TAGS_ENDPOINT = `${config.REACT_APP_API_URL}/story-api/v2/tags`;
const PLACE_CATEGORIES_ENDPOINT = `${config.REACT_APP_API_URL}/tag-api/v1/place-categories`;
const TOUR_TYPES_ENDPOINT = `${config.REACT_APP_API_URL}/story-api/v2/tour-types`;

export const getDifficultiesData = () =>
  httpClient()
    .authorized.get<DifficultiesListResponse>(DIFFICULTIES_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new DifficultiesDictionaryVM(data);
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getVisibilityData = () =>
  httpClient()
    .authorized.get<VisibilityListResponse>(VISIBILITY_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new VisibilityDictionaryVM(data);
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getActivitiesData = () =>
  httpClient()
    .authorized.get<ActivitiesListResponse>(ACTIVITIES_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new ActivitiesDictionaryVM(data);
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getTipsData = () =>
  httpClient()
    .authorized.get<TipListResponse>(TIPS_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new TipsDictionaryVM(data);
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getTourStatusesData = () =>
  httpClient()
    .authorized.get<TourStatusesListResponse>(STATUSES_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new TourStatusesDictionaryVM(data);
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getPricesData = () =>
  httpClient()
    .authorized.get<PricesListResponse>(PRICES_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new PricesDictionaryVM(data);
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getTourCategoriesData = () =>
  httpClient()
    .authorized.get<CommonTagListResponse>(TOUR_CATEGORIES_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new CommonTagDictionaryVM(data);
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getTourTagsData = () =>
  httpClient()
    .authorized.get<CommonTagListResponse>(TOUR_TAGS_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new CommonTagDictionaryVM(data);
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getPlaceCategoriesData = () =>
  httpClient()
    .authorized.get<PlaceCategoriesListResponse>(PLACE_CATEGORIES_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new PlaceCategoriesDictionaryVM(data);
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getTourTypesData = () =>
  httpClient()
    .authorized.get<TourTypesListResponse>(TOUR_TYPES_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new TourTypesDictionaryVM(data);
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
