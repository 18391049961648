import { combineReducers } from 'redux';

import { FeatureStateType } from '@/store/helpers';
import { subscriptionsReducer } from './subscriptions';
import { smartMapsReducer } from './smart-maps';
import { oneTimeProductsReducer } from './one-time-products';
import { subscriptionsProductsDictionaryReducer } from './subscriptions-products';

export type entitlementsReducer = FeatureStateType<typeof entitlementsReducer>;
export const entitlementsReducer = {
  entitlements: combineReducers({ ...subscriptionsReducer, ...smartMapsReducer, ...oneTimeProductsReducer, ...subscriptionsProductsDictionaryReducer }),
};
