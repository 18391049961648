import { ActionType, Action } from 'typesafe-actions';

import { createDictionaryActions } from '@/common/utils/store';
import { ContextTypesDictionaryVM } from '@/models/context-types';

export const contextTypesActions = createDictionaryActions(
  `FETCH_CONTEXT_TYPES_DICTIONARY_REQUEST`,
  `FETCH_CONTEXT_TYPES_DICTIONARY_SUCCESS`,
  `FETCH_CONTEXT_TYPES_DICTIONARY_FAILURE`,
  `FETCH_CONTEXT_TYPES_DICTIONARY_CANCEL`,
  `FETCH_CONTEXT_TYPES_DICTIONARY_SILENT_REQUEST`
)<undefined, ContextTypesDictionaryVM>();

export type contextTypesActions = Action | ActionType<typeof contextTypesActions>;
