import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import omit from 'lodash/omit';
import { stringify } from 'qs';

import { httpClient } from '@/core/services/http-client';
import { getEnv, CommonError } from '@/common/utils';
import {
  CreateThingsToDoTagParams,
  ThingsToDoTagDetailsResponse,
  ThingsToDoTagDetailsVM,
  ThingsToDoTagsElementVM,
  ThingsToDoTagsListResponse,
  ThingsToDoTagsListVM,
  ThingsToDoTagsParams,
  UpdateThingsToDoTagDetailsParams,
} from '../../models/things-to-do-tags';

const config = getEnv();

const CONTENT_THINGS_TO_DO_TAGS_ENDPOINT = `${config.REACT_APP_API_URL}/tag-api/v1/admin/tags`;

export const getThingsToDoTagsData = (data: ThingsToDoTagsParams) => {
  const { displayName, ...payload } = data;
  const requestPayload = displayName === '' ? payload : data;

  return httpClient()
    .authorized.post<ThingsToDoTagsListResponse>(`${CONTENT_THINGS_TO_DO_TAGS_ENDPOINT}/search`, requestPayload, {
      params: { ...omit(requestPayload, ['name', 'shortResponse', 'placeCategories', 'displayName']) },
      paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' }),
    })
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new ThingsToDoTagsListVM({ data, VM: ThingsToDoTagsElementVM });
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

export const getThingsToDoTagDetailsData = (id: string) =>
  httpClient()
    .authorized.get<ThingsToDoTagDetailsResponse>(`${CONTENT_THINGS_TO_DO_TAGS_ENDPOINT}/${id}`)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new ThingsToDoTagDetailsVM(data);
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: e.code, message: e.errorMessage })))
    );

export const deleteThingToDoTag = (id: string) =>
  httpClient()
    .authorized.delete(`${CONTENT_THINGS_TO_DO_TAGS_ENDPOINT}/${id}`)
    .pipe(
      map(({ status }) => {
        if (status === 200) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => {
        return of(new CommonError({ code: '500', message: e }));
      })
    );

export const updateThingToDoTagDetails = (data: UpdateThingsToDoTagDetailsParams, id: string) => {
  return httpClient()
    .authorized.put<undefined>(`${CONTENT_THINGS_TO_DO_TAGS_ENDPOINT}/${id}`, data)
    .pipe(
      map(response => {
        if (response.status === 200 || response.status === 201) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError(e)))
    );
};

export const createThingToDoTag = (data: CreateThingsToDoTagParams) => {
  return httpClient()
    .authorized.post<{
      id?: string;
    }>(CONTENT_THINGS_TO_DO_TAGS_ENDPOINT, data)
    .pipe(
      map(response => {
        if (response.status === 200 || response.status === 201) {
          return response.data.id;
        }

        throw undefined;
      }),
      catchError(e => {
        return of(new CommonError({ code: e.data.code, message: e }));
      })
    );
};
