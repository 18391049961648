import React, { FC, InputHTMLAttributes, useCallback, useRef, KeyboardEvent } from 'react';
import { FormikProps } from 'formik';
import cn from 'classnames';

import { KEY_CODE_ESC } from '@/common/utils/key-codes';
import { useStyles } from '@/styles/hooks';
import { IconButton } from '../../icon-button';
import { clearableInputStyles } from './styles';

export type ClearableInputChangeType = 'CHANGE' | 'CLEAR';
export type ClearableInputValue = string | undefined;
export type OnClearableInputChangeFunc = (value: ClearableInputValue, changeType: ClearableInputChangeType) => void;
export type OnClearableInputKeyDownFunc = (event: KeyboardEvent<HTMLInputElement>) => void;

export type ClearableInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onKeyDown' | 'form'> & {
  onKeyDown: OnClearableInputKeyDownFunc;
  onChange: OnClearableInputChangeFunc;
  onClear?: () => void;
  form?: FormikProps<any>;
};

export const ClearableInput: FC<ClearableInputProps> = ({ onChange, onClear, onKeyDown, form, name, ...props }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { styles } = useStyles(clearableInputStyles);

  const performChange = useCallback<OnClearableInputChangeFunc>(
    (value, changeType) => {
      if (form !== undefined && name !== undefined) {
        form.setFieldTouched(name);
        form.setFieldValue(name, value);
      } else {
        onChange(value, changeType);
      }
    },
    [form, name, onChange]
  );

  const handleOnChange = useCallback(event => performChange(event.target.value, 'CHANGE'), [onChange, performChange]);

  const handleOnClear = useCallback(() => {
    performChange('', 'CLEAR');
    onClear?.();
  }, [onClear, performChange]);

  const handleOnKeyDown = useCallback<OnClearableInputKeyDownFunc>(
    event => {
      onKeyDown?.(event);
      switch (event?.which || event?.keyCode) {
        case KEY_CODE_ESC: {
          handleOnClear();
          break;
        }

        default: {
          break;
        }
      }
    },
    [onKeyDown]
  );

  return (
    <div css={styles} className='input-wrapper'>
      <input {...props} ref={inputRef} onChange={handleOnChange} onKeyDown={handleOnKeyDown} className={cn(['input', 'input--with-clear'])} />
      {props.value !== undefined && props.value !== '' && (
        <IconButton iconName='mdi-close' className='clear-button' type='reset' onClick={handleOnClear} disabled={props.disabled} tabIndex={-1} />
      )}
    </div>
  );
};
