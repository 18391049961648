import { switchMap, filter, map } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { getSmartMapsDetailsData } from '@/features/management/services/smartmaps/smartmaps-details';
import { getSmartMapsListData } from '@/features/management/services/smartmaps/smartmaps-list';
import { untilLocationChange, CommonError } from '@/common/utils';
import { smartMapsReducer } from './reducers';
import { smartMapsActions } from './actions';

const getSmartMapsList: Epic<smartMapsActions, smartMapsActions, smartMapsReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(smartMapsActions.list.request)),
    switchMap(() =>
      getSmartMapsListData(state$.value.smartMaps.list.params).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return smartMapsActions.list.failure(response);
          }

          return smartMapsActions.list.success(response);
        })
      )
    )
  );

const getSmartMapsDetails: Epic<smartMapsActions, smartMapsActions, smartMapsReducer> = action$ =>
  action$.pipe(
    filter(isActionOf(smartMapsActions.details.request)),
    switchMap(action =>
      getSmartMapsDetailsData(action.payload.params)
        .pipe(untilLocationChange(action$))
        .pipe(
          map(response => {
            if (response instanceof CommonError) {
              return smartMapsActions.details.failure(response);
            }

            return smartMapsActions.details.success(response);
          })
        )
    )
  );

export const smartMapsEpics = [getSmartMapsList, getSmartMapsDetails];
