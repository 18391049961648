import React, { InputHTMLAttributes, ReactElement, HTMLAttributes, useCallback } from 'react';
import { Field as FormikField, FieldProps as FormikFieldProps } from 'formik';
import cn from 'classnames';

import { useStyles } from '@/styles/hooks';
import { Icon } from '../../icon';
import { Tooltip } from '../../tooltip';
import { IconButtonLink } from '../../icon-button';
import { ErrorMessage } from './components/error-message';
import { LabelWrapper, LableWrapperProps } from './components/label-wrapper';
import { fieldStyles } from './styles';

export type FieldProps<T = InputHTMLAttributes<HTMLInputElement>> = T &
  LableWrapperProps & {
    name: string;
    children: (fieldProps: FormikFieldProps) => any;
    label?: string;
    labelIcon?: ReactElement;
    tooltipMessage?: string;
    filePreviewLink?: string;
    isFilePreviewDisabled?: boolean;
    errorClassName?: string;
    link?: string;
    hideErrorMessage?: boolean;
  };

export function Field<T extends Omit<HTMLAttributes<any>, 'children'> = InputHTMLAttributes<HTMLInputElement>>({
  name,
  label,
  labelIcon,
  tooltipMessage,
  filePreviewLink,
  isFilePreviewDisabled,
  className,
  errorClassName,
  link,
  children,
  isFocusOnLabelEnabled = true,
  hideErrorMessage,
}: FieldProps<T>) {
  const getErrorMessage = useCallback((error: string | object) => {
    if (typeof error === 'object') {
      return Object.values(error).join(', ');
    }

    return error;
  }, []);

  const { styles } = useStyles(fieldStyles);

  return (
    <FormikField name={name}>
      {(fieldProps: FormikFieldProps) => {
        return (
          <div css={styles} className={className}>
            <LabelWrapper isFocusOnLabelEnabled={isFocusOnLabelEnabled}>
              <>
                {label !== undefined && (
                  <div className='label'>
                    {label}
                    {labelIcon}
                    {filePreviewLink && (
                      <IconButtonLink className='link-button' iconName='mdi-eye' theme='primary' rel='noreferrer' link={filePreviewLink} external />
                    )}
                    {isFilePreviewDisabled && <Icon name='mdi-eye' className='label-icon label-icon--disabled' />}
                    {tooltipMessage && (
                      <Tooltip message={tooltipMessage} placement='right-end'>
                        <Icon name='mdi-information' className='label-icon' />
                      </Tooltip>
                    )}
                    {link && <IconButtonLink className='link-button' iconName='mdi-link' theme='primary' link={link} external />}
                  </div>
                )}

                {children(fieldProps)}
              </>
            </LabelWrapper>

            {!hideErrorMessage && (
              <ErrorMessage
                className={cn(['error-message', errorClassName])}
                error={fieldProps.meta.touched && fieldProps.meta.error ? getErrorMessage(fieldProps.meta.error) : undefined}
              />
            )}
          </div>
        );
      }}
    </FormikField>
  );
}
