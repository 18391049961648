import { ActionType, Action } from 'typesafe-actions';

import { createDictionaryActions } from '@/common/utils/store';
import { StatusesDictionaryVM } from '@/models/statuses';

export const statusesActions = createDictionaryActions(
  `[MODERATION STATUSES DICTIONARY] FETCH REQUEST`,
  `[MODERATION STATUSES DICTIONARY] FETCH SUCCESS`,
  `[MODERATION STATUSES DICTIONARY] FETCH FAILURE`,
  `[MODERATION STATUSES DICTIONARY] FETCH CANCEL`,
  `[MODERATION STATUSES DICTIONARY] FETCH SILENT_REQUEST`
)<undefined, StatusesDictionaryVM>();

export type statusesActions = Action | ActionType<typeof statusesActions>;
