import { createReducer } from 'typesafe-actions';

import { ActivitiesDictionaryVM } from '@/models/activities';
import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { FeatureStateType } from '../helpers';
import { activitiesActions } from './actions';

type activitiesState = DictionaryState<ActivitiesDictionaryVM>;

const INITIAL_STATE: activitiesState = getDictionaryInitialState<ActivitiesDictionaryVM>();

const reducer = createReducer<activitiesState, activitiesActions>(INITIAL_STATE)
  .handleAction(activitiesActions.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(activitiesActions.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(activitiesActions.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(activitiesActions.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(activitiesActions.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const activitiesReducer = { activities: reducer };
export type activitiesReducer = FeatureStateType<typeof activitiesReducer>;
