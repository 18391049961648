import { ActionType, Hooks, IdType } from 'react-table';

export const useCustomRowSelect =
  (maxSelectedRows?: number) =>
  <T extends {}>(hooks: Hooks<T>) => {
    if (!maxSelectedRows) return;

    hooks.stateReducers.push((state, action, prevState) => {
      const { type, value } = action as ActionType & { value: boolean };

      if (type !== 'toggleAllRowsSelected') return state;

      if (!value)
        return {
          ...state,
          selectedRowIds: {} as Record<IdType<T>, boolean>,
        };

      const allIds = Object.keys(state.selectedRowIds);

      if (allIds.length <= maxSelectedRows) {
        return state;
      }

      const nextIds: string[] = Object.keys(prevState?.selectedRowIds || {});

      for (let i = 0; nextIds.length < maxSelectedRows; i += 1) {
        if (!nextIds.find(id => id === allIds[i])) {
          nextIds.push(allIds[i]);
        }
      }
      const selectedRowIds = Object.fromEntries(nextIds.map(id => [id, true])) as Record<IdType<T>, boolean>;

      return {
        ...state,
        selectedRowIds,
      };
    });

    hooks.useInstance.push(instance => {
      const {
        rowsById,
        nonGroupedRowsById = rowsById,
        state: { selectedRowIds },
      } = instance;

      const length = Object.keys(selectedRowIds).length;

      Object.assign(instance, {
        isAllRowsSelected: length === maxSelectedRows || length === Object.keys(nonGroupedRowsById).length,
      });
    });
  };
