import { createReducer } from 'typesafe-actions';

import { UserRoutesListVM } from '@/features/content/models/routes';
import { FeatureStateType } from '../../helpers';
import { contentProvidedByUserActions } from '../actions';

export type routesState = UserRoutesListVM;

const INITIAL_STATE: routesState = {
  list: [],
  status: 'loading',
};

const reducer = createReducer<routesState, contentProvidedByUserActions>(INITIAL_STATE)
  .handleAction(contentProvidedByUserActions.routes.request, () => ({ ...INITIAL_STATE }))
  .handleAction(contentProvidedByUserActions.routes.success, (_, { payload }) => ({ ...INITIAL_STATE, list: payload.list, status: 'success' }))
  .handleAction(contentProvidedByUserActions.routes.failure, () => ({ ...INITIAL_STATE, status: 'failure' }))
  .handleAction(contentProvidedByUserActions.routes.clear, () => ({ ...INITIAL_STATE }));

export const routesReducer = { routes: reducer };
export type routesReducer = FeatureStateType<typeof routesReducer>;
