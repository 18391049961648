import { createReducer } from 'typesafe-actions';

import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { AppDictionaryVM } from '@/features/accounts/models/users';
import { FeatureStateType } from '../../helpers';
import { usersActions } from '../actions';

type appDictionaryState = DictionaryState<AppDictionaryVM>;

const INITIAL_STATE: appDictionaryState = getDictionaryInitialState<AppDictionaryVM>();

const reducer = createReducer<appDictionaryState, usersActions>(INITIAL_STATE)
  .handleAction(usersActions.appDictionary.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(usersActions.appDictionary.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(usersActions.appDictionary.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(usersActions.appDictionary.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(usersActions.appDictionary.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const appDictionaryReducer = { appDictionary: reducer };
export type appDictionaryReducer = FeatureStateType<typeof appDictionaryReducer>;
