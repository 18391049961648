import React from 'react';
import { TFunction } from 'react-i18next';

import { tooltipMessageStyles } from './styles';

export const tooltipMessage = (t: TFunction) => (
  <div css={tooltipMessageStyles}>
    <p>{t('tooltips.coordinates.use')}</p>
    <p>
      {t('tooltips.coordinates.latitude')}
      <span className='correct'>{t('tooltips.coordinates.correct.latitude')}</span>
      <br />
      {t('tooltips.coordinates.longitude')}
      <span className='correct'>{t('tooltips.coordinates.correct.longitude')}</span>
    </p>
    <p>{t('tooltips.coordinates.notUse')}</p>
    <p>
      {t('tooltips.coordinates.latitude')}
      <span className='incorrect'>{t('tooltips.coordinates.incorrect.latitude')}</span>
      <br />
      {t('tooltips.coordinates.longitude')}
      <span className='incorrect'>{t('tooltips.coordinates.incorrect.longitude')}</span>
    </p>
  </div>
);
