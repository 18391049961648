import React, { FC, memo, useContext, useMemo } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { FieldArrayRenderProps } from 'formik';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { useStyles } from '@/styles/hooks';
import { EditorValue } from '../../models';
import { EditorDroppableContext } from '../editor-container';
import { editorContentStyle } from './styles';

export const CONTENT_ID = 'CONTENT';

type Props = {
  fieldArrayRender: FieldArrayRenderProps;
};

export const EditorContent: FC<Props> = memo(({ fieldArrayRender }) => {
  const list = fieldArrayRender.form.values[fieldArrayRender.name] as EditorValue[];
  const [t] = useTranslation();
  const { styles } = useStyles(editorContentStyle);

  const editorDroppableContext = useContext(EditorDroppableContext);
  const anyActiveAccordion = useMemo(() => !!editorDroppableContext.activeAccordions.length, [editorDroppableContext.activeAccordions]);
  const anyActiveList = useMemo(() => !!editorDroppableContext.activeLists.length, [editorDroppableContext.activeLists]);

  return (
    <Droppable key={CONTENT_ID} droppableId={CONTENT_ID} isDropDisabled={anyActiveAccordion || anyActiveList}>
      {(provided, snapshot) => (
        <div css={styles} ref={provided.innerRef} className={cn(['content', snapshot.isDraggingOver && 'content--dragging-over'])}>
          {(list || []).map((item, index) => (
            <Draggable key={item.id} draggableId={item.id} index={index}>
              {(provided, snapshot) => (
                <item.card.content id={item.id} provided={provided} snapshot={snapshot} item={item.card} index={index} fieldArrayRender={fieldArrayRender} />
              )}
            </Draggable>
          ))}
          {!snapshot.isUsingPlaceholder && <div className='notice'>{t('storyContentEditor.notice')}</div>}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
});
