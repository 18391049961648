import { Styles, theme, rem } from '@/styles';

const SMALL_AVATAR_SIZE = 27;
const MEDIUM_AVATAR_SIZE = 60;
const LARGE_AVATAR_SIZE = 133;

const SMALL_AVATAR_FONT_SIZE = 14;
const MEDIUM_AVATAR_FONT_SIZE = 30;
const LARGE_AVATAR_FONT_SIZE = 60;

export const styles: Styles = {
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 600,

  '&.small': {
    minHeight: SMALL_AVATAR_SIZE,
    minWidth: SMALL_AVATAR_SIZE,
    fontSize: rem(SMALL_AVATAR_FONT_SIZE),
  },

  '&.medium': {
    minHeight: MEDIUM_AVATAR_SIZE,
    minWidth: MEDIUM_AVATAR_SIZE,
    fontSize: rem(MEDIUM_AVATAR_FONT_SIZE),
  },

  '&.large': {
    minHeight: LARGE_AVATAR_SIZE,
    minWidth: LARGE_AVATAR_SIZE,
    fontSize: rem(LARGE_AVATAR_FONT_SIZE),
  },

  '&.primary': {
    color: theme.textWhite,
    backgroundColor: theme.primary,
  },

  '&.secondary': {
    color: theme.primary,
    backgroundColor: theme.backgroundPrimary,
  },
};
