import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import omit from 'lodash/omit';
import { stringify } from 'qs';

import { ThingsToDoElementVM, ThingsToDoListResponse, ThingsToDoListVM, ThingsToDoParams } from '@/features/content/models/things-to-do';
import { httpClient } from '@/core/services/http-client';
import { getEnv, CommonError } from '@/common/utils';

const config = getEnv();

const CONTENT_THINGS_TO_DO_LIST_ENDPOINT = `${config.REACT_APP_API_URL}/tag-api/v1/admin/things-to-do`;

export const getThingsToDoData = (data: ThingsToDoParams) => {
  const { name, ...payload } = data;
  const requestPayload = name === '' ? payload : data;

  return httpClient()
    .authorized.post<ThingsToDoListResponse>(`${CONTENT_THINGS_TO_DO_LIST_ENDPOINT}/search`, requestPayload, {
      params: { ...omit(requestPayload, ['text', 'name', 'shortResponse', 'displayedFrom', 'displayedTo', 'placeCategories', 'recurring', 'tagIds']) },
      paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' }),
    })
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new ThingsToDoListVM({ data, VM: ThingsToDoElementVM });
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

export const deleteThingToDo = (id: number) =>
  httpClient()
    .authorized.delete<ThingsToDoListResponse>(`${CONTENT_THINGS_TO_DO_LIST_ENDPOINT}/${id}`)
    .pipe(
      map(({ status }) => {
        if (status === 200) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => {
        return of(new CommonError({ code: '500', message: e }));
      })
    );
