import React, { InputHTMLAttributes, useCallback, KeyboardEvent, forwardRef, useRef, useImperativeHandle, useState } from 'react';
import cn from 'classnames';

import { getTranslation } from '@/common/utils';
import {
  KEY_CODE_ARROW_DOWN,
  KEY_CODE_ARROW_LEFT,
  KEY_CODE_ARROW_RIGHT,
  KEY_CODE_ARROW_UP,
  KEY_CODE_ENTER,
  KEY_CODE_SPACE,
  KEY_CODE_TAB,
} from '@/common/utils/key-codes';
import { useStyles } from '@/styles/hooks';
import { CommonRadioOnChange, CommonRadioOnNext, CommonRadioOnPrevious, RadioOption } from '../../models';
import { radioStyles } from './styles';

export type RadioRef = {
  focus: () => void;
};

export type RadioProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onFocus'> & {
  index?: number;
  option: RadioOption;
  disabled?: boolean;
  name: string;
  onChange: CommonRadioOnChange;
  onNext: CommonRadioOnNext;
  onPrevious: CommonRadioOnPrevious;
};

export const Radio = forwardRef<RadioRef, RadioProps>(({ index = 0, name, option, value, checked, disabled, onChange, onNext, onPrevious }, ref) => {
  const calculatedChecked = checked || value === option.value;
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const { styles } = useStyles(radioStyles);

  const handleOnChange = useCallback(() => onChange(option.value), [option.value, onChange]);
  const handleOnFocus = useCallback(() => setIsFocused(true), [option.value, setIsFocused]);
  const handleOnBlur = useCallback(() => setIsFocused(false), [option.value, setIsFocused]);
  const handleOnKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      switch (e.which || e.keyCode) {
        case KEY_CODE_ARROW_RIGHT:
        case KEY_CODE_ARROW_DOWN: {
          e.preventDefault();
          onNext(index);
          break;
        }

        case KEY_CODE_ARROW_LEFT:
        case KEY_CODE_ARROW_UP: {
          e.preventDefault();
          onPrevious(index);
          break;
        }

        case KEY_CODE_TAB: {
          break;
        }

        case KEY_CODE_ENTER:
        case KEY_CODE_SPACE: {
          handleOnChange();
          break;
        }

        default: {
          e.preventDefault();
          break;
        }
      }
    },
    [index, handleOnChange]
  );

  useImperativeHandle(ref, () => ({
    focus: () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
  }));

  return (
    <div css={styles}>
      <div className='radio-container'>
        <label className={cn('radio', calculatedChecked ? 'checked' : 'unchecked', { disabled }, isFocused ? 'focused' : '')} key={option.value.toString()}>
          <span className='radio-label'>{getTranslation(option.translationId, option.label) as string}</span>
          <input
            ref={inputRef}
            name={name}
            tabIndex={calculatedChecked ? 0 : -1}
            onClick={handleOnChange}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            onKeyDown={handleOnKeyDown}
            type='radio'
            id={option.value.toString()}
            value={option.value.toString()}
            className='input'
            disabled={disabled}
          />
          <span className='checkmark' />
        </label>
      </div>
      {option.additionalInfo && <div className={cn(['additional-info', { 'additional-info--disabled': disabled }])}>{option.additionalInfo}</div>}
    </div>
  );
});
