import { createSelector } from 'reselect';

import { getDataStatus } from '@/common/utils';
import { activitiesReducer } from './reducer';

type MakeGetActivitiesData = activitiesReducer['activities']['data'];

const getActivities = (state: activitiesReducer) => state.activities;

export const makeGetActivitiesData = () =>
  createSelector<activitiesReducer, activitiesReducer['activities'], MakeGetActivitiesData>(getActivities, ({ data }) => data);
export const makeGetActivitiesStatus = () =>
  createSelector<activitiesReducer, activitiesReducer['activities'], ReturnType<typeof getDataStatus>>(getActivities, getDataStatus);
