import React, { FC } from 'react';
import cn from 'classnames';

import { Field, FieldProps } from '../form-controls-deprecated/field';
import { Autocomplete, AutocompleteCustomProps } from './autocomplete';

export type FieldAutocompleteProps = Omit<FieldProps, 'children'> & Omit<AutocompleteCustomProps, 'onChange'>;

export const FieldAutocomplete: FC<FieldAutocompleteProps> = ({ name, label, labelIcon, isFocusOnLabelEnabled, className, link, onBlur, ...props }) => (
  <Field name={name} label={label} labelIcon={labelIcon} isFocusOnLabelEnabled={isFocusOnLabelEnabled} className={className} link={link}>
    {({ field, meta, form }) => {
      const inputClassName = cn([{ error: meta.touched && meta.error }]);

      const handleFieldBlur = () => form.setFieldTouched(name);

      return <Autocomplete {...props} form={form} className={inputClassName} {...field} onBlur={handleFieldBlur} />;
    }}
  </Field>
);
