import { combineReducers } from 'redux';

import { FeatureStateType } from '@/store/helpers';
import { thingsToDoListReducer } from './list';
import { thingsToDoDetailsReducer } from './details';
import { placeCategoriesDictionaryReducer } from './place-categories';

export type thingsToDoReducer = FeatureStateType<typeof thingsToDoReducer>;

export const thingsToDoReducer = {
  thingsToDo: combineReducers({
    ...thingsToDoListReducer,
    ...thingsToDoDetailsReducer,
    ...placeCategoriesDictionaryReducer,
  }),
};
