import { createSelector } from 'reselect';

import { MapAttributionListVM } from '@/models/map-attribution';
import { getDataStatus } from '@/common/utils';
import { mapAttributionReducer } from './reducers';

const getMapAttributionList = (state: mapAttributionReducer) => state.mapAttribution.list;

type MapAttributionList = mapAttributionReducer['mapAttribution']['list'];
type MakeGetMapAttributionListData = MapAttributionListVM['list'];
type MakeGetMapAttributionListPaginationData = MapAttributionListVM['listPaginationData'] | undefined;
type MakeGetMapAttributionListFilters = MapAttributionList['params'];

export const makeGetMapAttributionListData = () =>
  createSelector<mapAttributionReducer, MapAttributionList, MakeGetMapAttributionListData>(getMapAttributionList, ({ data }) => data?.list || []);

export const makeGetMapAttributionListStatus = () =>
  createSelector<mapAttributionReducer, MapAttributionList, ReturnType<typeof getDataStatus>>(getMapAttributionList, getDataStatus);

export const makeGetMapAttributionListPaginationData = () =>
  createSelector<mapAttributionReducer, MapAttributionList, MakeGetMapAttributionListPaginationData>(
    getMapAttributionList,
    ({ data }) => data?.listPaginationData
  );

export const makeGetMapAttributionListFilters = () =>
  createSelector<mapAttributionReducer, MapAttributionList, MakeGetMapAttributionListFilters>(getMapAttributionList, ({ params }) => params);

const getMapAttributionDetails = (state: mapAttributionReducer) => state.mapAttribution.details;

type MapAttributionDetails = mapAttributionReducer['mapAttribution']['details'];
type MakeGetMapAttributionDetailsData = MapAttributionDetails['data'];

export const makeGetMapAttributionDetailsData = () =>
  createSelector<mapAttributionReducer, MapAttributionDetails, MakeGetMapAttributionDetailsData>(getMapAttributionDetails, ({ data }) => data);

export const makeGetMapAttributionDetailsStatus = () =>
  createSelector<mapAttributionReducer, MapAttributionDetails, ReturnType<typeof getDataStatus>>(getMapAttributionDetails, getDataStatus);
