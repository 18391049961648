import { Enum } from '@/common/utils';

export type ContentFragmentType = Enum<typeof ContentFragmentType>;
export const ContentFragmentType = Enum('header', 'paragraph', 'image', 'audio', 'video', 'list', 'accordion');

export type HeaderContentFragment = {
  type: typeof ContentFragmentType['header'];
  value: string;
};

export type ParagraphContentFragment = {
  type: typeof ContentFragmentType['paragraph'];
  value: string;
  attribution?: string;
};

export type ImageContentFragment = {
  type: typeof ContentFragmentType['image'];
  image: {
    id: string;
  };
  attribution?: string;
};

export type AudioContentFragment = {
  type: typeof ContentFragmentType['audio'];
  audio: {
    id: string;
  };
  attribution?: string;
};

export type VideoContentFragment = {
  type: typeof ContentFragmentType['video'];
  video: {
    id: string;
  };
  cover: {
    id: string;
  };
  attribution?: string;
};

export type ListContentFragment = {
  type: typeof ContentFragmentType['list'];
  items: ParagraphContentFragment[];
};

export type AccordionContentFragment = {
  type: typeof ContentFragmentType['accordion'];
  title: string;
  items: ContentFragmentTypes[];
};

export type UploadAudioResponse = {
  accelerometer: null;
  captureTime: null;
  createdAt: string;
  duration: null;
  gyroscope: null;
  id: string;
  likeCounter: number;
  location: null;
  magnetometer: null;
  provider: null;
  providerUrl: null;
  sizeOriginal: number;
  sizeResized: number;
  sourceApp: null;
  type: null;
  urlOrginal: null;
  urlResized: null;
  validationStatus: string;
};

export type UploadImageResponse = UploadAudioResponse;
export type UploadVideoCoverResponse = UploadAudioResponse;
export type UploadVideoResponse = UploadAudioResponse;

export type ContentFragmentTypes =
  | HeaderContentFragment
  | ParagraphContentFragment
  | ImageContentFragment
  | AudioContentFragment
  | VideoContentFragment
  | ListContentFragment
  | AccordionContentFragment;
