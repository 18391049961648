import { createReducer } from 'typesafe-actions';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';

import { ROUTE_LIST_PAGE_SIZE } from '@/features/content/pages/routes-list/constants';
import { DEFAULT_LIST_PAGINATION_DATA, PageableListResponse, PageableListVM } from '@/common/utils/pageable';
import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { RoutesListVM, RoutesParams } from '@/features/content/models/routes';
import { FeatureStateType } from '../../helpers';
import { routesActions } from '../actions';

export type routesListState = FetchState<RoutesListVM, RoutesParams>;

const INITIAL_STATE: routesListState = getFetchInitialState<RoutesListVM, RoutesParams>({
  size: ROUTE_LIST_PAGE_SIZE,
  sort: ['metadata.modifiedAt,DESC'],
  state: 'ACTIVE',
  minRating: 1,
  maxRating: 5,
  includeWithoutRating: true,
  isOrganisational: false,
});

export const routeListSuccessReducer = <T, P extends PageableListResponse<any>, State extends FetchState, Payload extends PageableListVM<T, P>>(
  initialState: State,
  state: State,
  payload: Payload
) => {
  const newState = fetchReducerHelpers.success(initialState, state, payload);
  const { list } = payload;
  const omitKeys: (keyof RoutesParams)[] = ['page'];
  const omittedPrevParams = omit(state.prevParams, omitKeys);
  const omittedParams = omit(state.params, omitKeys);
  const addToPrevList = isEqual(omittedPrevParams, omittedParams) && (state.prevParams?.page || 0) < (state.params.page || 0);

  const newList = [...(addToPrevList ? state.data?.list || [] : []), ...list];

  return {
    ...newState,
    data: newState.data === undefined ? undefined : { ...newState.data, list: newList },
  };
};

const reducer = createReducer<routesListState, routesActions>(INITIAL_STATE)
  .handleAction(routesActions.list.request, (state, { payload }) => fetchReducerHelpers.request(INITIAL_STATE, state, payload))
  .handleAction(routesActions.list.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(routesActions.list.success, (state, { payload }) => routeListSuccessReducer(INITIAL_STATE, state, payload))
  .handleAction(routesActions.clearRoutesList, state => ({
    ...state,
    data: {
      ...state.data,
      list: [],
      listPaginationData: state.data?.listPaginationData || DEFAULT_LIST_PAGINATION_DATA,
      hasNextPage: state.data?.hasNextPage || false,
    },
  }));

export const routesListReducer = { list: reducer };
export type routesListReducer = FeatureStateType<typeof routesListReducer>;

export const routeListInitialState = INITIAL_STATE;
