import React, { forwardRef, HTMLAttributes } from 'react';
import { FieldInputProps, FormikProps } from 'formik';

import { useStyles } from '@/styles/hooks';

import { useSlateMethods } from './hooks';
import { richTextStyles } from './styles';

import { TextEditor } from '../../text-editor';

export type RichTextAttributes = Pick<HTMLAttributes<HTMLDivElement>, 'className'> & {
  maxLength?: number;
  disabled?: boolean;
};

export type RichTextProps = RichTextAttributes &
  FieldInputProps<string> & {
    form?: FormikProps<any>;
  };

export const RichText = forwardRef<HTMLAnchorElement, RichTextProps>(({ className, maxLength, form, name, value, disabled, onBlur, ...props }, ref) => {
  const { onChangeSlate, initialValue, shouldInitializedSlate, t } = useSlateMethods({ form, name, value, ref });

  const { styles } = useStyles(richTextStyles);

  return (
    <div {...props} css={styles} className={className}>
      <TextEditor initialValue={initialValue} onChange={onChangeSlate} shouldBeInitialized={shouldInitializedSlate} />
      {maxLength && <div className='max-length-message'>{t('validationMessage.maxLength', { length: maxLength })}</div>}
    </div>
  );
});
