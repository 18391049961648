import { combineReducers } from 'redux';

import { FeatureStateType } from '@/store/helpers';
import { communityStoriesListReducer } from './list';
import { communityStoryDetailsReducer } from './details';

export type communityStoriesReducer = FeatureStateType<typeof communityStoriesReducer>;

export const communityStoriesReducer = {
  communityStories: combineReducers({
    ...communityStoriesListReducer,
    ...communityStoryDetailsReducer,
  }),
};
