import { constants, Styles, rgba } from '@/styles';
import { JoyrideStyleFunction, StyleFunction } from '@/styles/models';

export const joyrideStyles: JoyrideStyleFunction = ({ theme }) => ({
  options: {
    overlayColor: rgba(theme.backgroundContrast, 0.4),
  },
  spotlight: {
    borderRadius: constants.borderRadius.small,
    boxShadow: constants.dialogShadow,
  },
  overlay: {
    minWidth: constants.minWindowWidth,
  },
});

export const tooltipStyles: StyleFunction = ({ theme }) => ({
  backgroundColor: theme.backgroundPrimary,
  borderRadius: constants.borderRadius.small,
  boxShadow: constants.dialogShadow,
  width: 410,

  '& div': {
    display: 'flex',
  },

  '.content': {
    padding: constants.offset.xlarge,
    justifyContent: 'space-between',
    paddingBottom: 0,
    lineHeight: 1.4,

    '&__left': {
      flexDirection: 'column',

      '& > *': {
        color: theme.textAccent,
        fontWeight: 600,
        margin: 0,
      },

      '.title': {
        fontSize: constants.fontSize.h2,
        marginBottom: constants.offset.large,
      },

      '.paragraph': {
        fontSize: constants.fontSize.medium,
      },
    },

    '&__right': {
      marginLeft: constants.offset.medium,
      fontWeight: 600,
    },
  },

  '.footer': {
    justifyContent: 'space-between',
    padding: constants.offset.xlarge,

    '&__left': {
      '& button': {
        fontWeight: 600,
      },
    },

    '&__right': {
      '& button': {
        marginLeft: constants.offset.large,
      },
    },
  },
});

export const runButtonStyles: Styles = {
  position: 'absolute',
  right: constants.offset.medium,
  top: constants.offset.small,
};
