import { Styles as RJStyles } from 'react-joyride';
import { GroupBase, StylesConfig } from 'react-select';
import { Enum } from '@/common/utils';
import { Interpolation } from '@emotion/core';
import { Theme } from '@material-ui/core';

import { SelectValue } from '@/common/components/form-controls-deprecated';
import { ThemeType } from './theme';

export const THEME = Enum('LIGHT', 'DARK');

export type StyleFunction<R = Interpolation> = (props: { theme: ThemeType; themeName: string }) => R;

export type MuiStyleFunction<R = Theme> = (props: { theme: ThemeType; themeName: string }) => R;

export type JoyrideStyleFunction<R = RJStyles> = (props: { theme: ThemeType; themeName: string }) => R;

export type ReactSelectStyleFunction<R = StylesConfig<SelectValue, false, GroupBase<SelectValue>>> = (props: { theme: ThemeType; themeName: string }) => R;
