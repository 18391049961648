import React, { FC } from 'react';
import { useStyles } from '@/styles/hooks';

import { TOOLBAR_INFO } from '@/common/utils/element-ids';
import { links } from '../../constants';
import { NavigationElementGroup } from './components/navigation-element-group';
import { navigationElementStyles } from './styles';

type Props = {
  elements: typeof links;
};

export const NavigationElementsBar: FC<Props> = ({ elements }) => {
  const { styles } = useStyles(navigationElementStyles);

  return (
    <div css={styles}>
      {elements.map((group, index) => (
        <NavigationElementGroup key={`navigation-element-group-${index}`} data={group} />
      ))}
      <div id={TOOLBAR_INFO} />
    </div>
  );
};
