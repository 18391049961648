import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const fieldStyles: StyleFunction = ({ theme }) => ({
  '.label': {
    fontWeight: 'bold',
    display: 'flex',
    color: theme.primary,
    marginBottom: constants.offset.small,
  },

  '.error-message': {
    marginTop: constants.offset.xsmall,
  },

  '.label-icon': {
    color: theme.primary,
    paddingLeft: constants.offset.xsmall,
    cursor: 'pointer',
    transition: 'opacity .2s',

    '&:hover': {
      opacity: 0.6,
    },

    '&--disabled': {
      color: theme.textDisabled,

      '&:hover': {
        opacity: 1,
        cursor: 'not-allowed',
      },
    },
  },

  '.link-button': {
    height: 17,
    marginLeft: constants.offset.xsmall,

    i: {
      fontSize: constants.fontSize.h6,
    },
  },
});
