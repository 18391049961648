import React, { FC, useMemo } from 'react';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { useStyles } from '@/styles/hooks';
import { NavElement } from '../../../../models';
import { navigationStyles } from './styles';

type Props = {
  data: NavElement;
};

export const NavigationElement: FC<Props> = ({ data }) => {
  const [t] = useTranslation();
  const { pathname } = useLocation();
  const match = useRouteMatch(data.urlMatchPattern || { path: data.url, strict: data.strict });
  const { styles } = useStyles(navigationStyles);

  const selected = useMemo(() => {
    return data.urlExcludedFromMatch && data.urlExcludedFromMatch.includes(pathname) ? false : match;
  }, [data, match, pathname]);

  if (data.url && !data.disabled) {
    return (
      <Link css={styles} to={data.url} className={cn({ selected })} id={data.navLinkId}>
        <span className='inner-text'>{t(data.label)}</span>
      </Link>
    );
  }

  return (
    <div css={styles} className='disabled'>
      {t(data.label)}
    </div>
  );
};
