import React, { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { IconButton } from '@/common/components/icon-button';
import { interfaceActions } from '@/store/interface';
import { THEME } from '@/styles/models';
import { styles } from './styles';

export const ThemeToggle: FC = () => {
  const dispatch = useDispatch();

  const [currentTheme, setCurrentTheme] = useState(localStorage.getItem('currentTheme') === THEME.DARK ? 'DARK' : 'LIGHT');

  const handleToggleTheme = useCallback(
    (theme: string) => () => {
      dispatch(interfaceActions.setTheme(theme));
      localStorage.setItem('currentTheme', theme);
      setCurrentTheme(theme);
    },
    []
  );

  return (
    <div css={styles}>
      <IconButton iconName='mdi-light_mode' className={cn({ active: currentTheme === THEME.LIGHT })} onClick={handleToggleTheme(THEME.LIGHT)} />
      <IconButton iconName='mdi-dark_mode' className={cn({ active: currentTheme === THEME.DARK })} onClick={handleToggleTheme(THEME.DARK)} />
    </div>
  );
};
