import { createReducer } from 'typesafe-actions';

import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { VoucherBatchStatusesDictionaryVM } from '@/models/voucher-batch';
import { FeatureStateType } from '../../helpers';
import { voucherBatchActions } from '../actions';

type statusState = DictionaryState<VoucherBatchStatusesDictionaryVM>;

const INITIAL_STATE: statusState = getDictionaryInitialState<VoucherBatchStatusesDictionaryVM>();

const reducer = createReducer<statusState, voucherBatchActions>(INITIAL_STATE)
  .handleAction(voucherBatchActions.statuses.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(voucherBatchActions.statuses.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(voucherBatchActions.statuses.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(voucherBatchActions.statuses.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(voucherBatchActions.statuses.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const statusDictionaryReducer = { statuses: reducer };
export type statusDictionaryReducer = FeatureStateType<typeof statusDictionaryReducer>;
