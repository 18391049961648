import React, { FC } from 'react';

import { ContentFragmentType } from '@/models/tours';
import { ARRAY_AT_LEAST_ONE } from '@/common/utils';
import { CommonEditorCard, CommonEditorCardInnerComponent } from '../components/common-editor-card';
import { EditorCard, EditorCardProps, EditorValue } from '../../../models';
import { ListCardContentComponent } from './list-card-content';

export const LIST_CARD_ID_PREFIX = 'LIST_CARD';
export const LIST_ALLOWED_CARDS = [ContentFragmentType.paragraph] as ContentFragmentType[];

export type ListCardPayload = EditorValue[];
export type ListCardValue = EditorValue<typeof ContentFragmentType['list'], ListCardPayload>;

const VALIDATION_SCHEMA = ARRAY_AT_LEAST_ONE;

export const handleValidation = () => (value: ListCardPayload) => {
  return VALIDATION_SCHEMA.validate(value, { abortEarly: false })
    .then(() => undefined)
    .catch(err => {
      return err.errors;
    });
};

const ListCardHeaderComponent: CommonEditorCardInnerComponent<ListCardPayload> = () => {
  return null;
};

type Props = EditorCardProps;

const ListCardComponent: FC<Props> = props => {
  return (
    <CommonEditorCard<ListCardPayload>
      {...props}
      headerComponent={ListCardHeaderComponent}
      contentComponent={ListCardContentComponent}
      validate={handleValidation}
    />
  );
};

export const ListCard: EditorCard = {
  type: 'list',
  title: 'storyContentEditor.card.list.title',
  iconName: 'mdi-list',
  content: ListCardComponent,
};
