import { SelectOption } from '@/common/components/form-controls-deprecated';

type ApplicationsResponse = {
  id: string;
  displayName: string;
};

export type ApplicationsListResponse = ApplicationsResponse[];

export interface ApplicationsDictionaryElementVM extends SelectOption {}
export class ApplicationsDictionaryElementVM {
  constructor({ id, displayName }: ApplicationsResponse) {
    this.value = id;
    this.label = displayName;
  }
}

export interface ApplicationsDictionaryVM {
  list: ApplicationsDictionaryElementVM[];
}

export class ApplicationsDictionaryVM {
  constructor(list: ApplicationsListResponse) {
    this.list = (list || []).map(element => new ApplicationsDictionaryElementVM(element));
  }
}
