import { createReducer } from 'typesafe-actions';

import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { ModerationElementVM } from '@/models/moderation';
import { FeatureStateType } from '../../helpers';
import { moderationActions } from '../actions';

export type moderationDetailsState = FetchState<ModerationElementVM>;

const INITIAL_STATE: moderationDetailsState = getFetchInitialState<ModerationElementVM>();

const reducer = createReducer<moderationDetailsState, moderationActions>(INITIAL_STATE)
  .handleAction(moderationActions.details.request, state => fetchReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(moderationActions.details.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(moderationActions.details.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(moderationActions.clearModerationDetails, () => ({ ...INITIAL_STATE }));

export const moderationDetailsReducer = { details: reducer };
export type moderationDetailsReducer = FeatureStateType<typeof moderationDetailsReducer>;
