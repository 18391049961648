import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/en';

import { Enum } from './common';

dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);
dayjs.extend(utc);

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
export const DEFAULT_DATE_FORMAT_WITH_TIME = 'DD/MM/YYYY h:mm A';
export const DATE_YEAR_MONTH_FORMAT = 'YYYY/MM';
export const DATEPICKER_DEFAULT_DATE_FORMAT = 'dd/MM/yyyy';

export const getStartOfMonth = (date: string | Date | Dayjs) => dayjs(date).startOf('month');
export const getEndOfMonth = (date: string | Date | Dayjs) => dayjs(date).endOf('month');

export const DateType = Enum('FROM', 'TO');
export type DateType = Enum<typeof DateType>;

export const formatDateToISOString = (date: Dayjs, type: DateType) => {
  return type === DateType.FROM ? date.format('YYYY-MM-DDT00:00:00.000') + 'Z' : date.format('YYYY-MM-DDT23:59:59.000') + 'Z';
};
