import React, { FC } from 'react';

import { Field, FieldProps } from '../field';
import { Checkbox } from './checkbox';

type Props = Omit<FieldProps, 'children'>;

export const FieldCheckbox: FC<Props> = ({ name, label, labelIcon, isFocusOnLabelEnabled, className, ...props }) => (
  <Field name={name} isFocusOnLabelEnabled={isFocusOnLabelEnabled} className={className}>
    {({ field, meta }) => {
      const isError = meta.touched && meta.error;
      return <Checkbox {...props} label={label} checked={field.value} status={isError ? 'error' : undefined} {...field} />;
    }}
  </Field>
);
