import React, { FC, HTMLAttributes, useState, useCallback, useEffect } from 'react';
import { motion } from 'framer-motion';
import cn from 'classnames';

import { useStyles } from '@/styles/hooks';
import { Icon } from '../icon';
import { collapsibleFilterStyles } from './styles';

type CollapsibleFilterProps = HTMLAttributes<HTMLElement> & {
  label: string;
  activeFiltersCount?: number;
  defaultOpen?: boolean;
  isOpen?: boolean;
};

export const CollapsibleFilter: FC<CollapsibleFilterProps> = ({ children, label: header, activeFiltersCount, defaultOpen = false, isOpen: controlOpen }) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);
  const { styles } = useStyles(collapsibleFilterStyles);

  useEffect(() => {
    if (controlOpen !== undefined) setIsOpen(controlOpen);
  }, [controlOpen]);

  const handleOnHeaderClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <div css={styles}>
      <div className='label-wrapper'>
        <button onClick={handleOnHeaderClick} className='filter-label-wrapper' type='button'>
          <div className='filter-label'>{isOpen || activeFiltersCount === undefined ? header : `${header} (${activeFiltersCount})`}</div>
          <Icon name={'mdi-chevron-down'} className={cn(['expand-icon', isOpen ? 'expand-icon--up' : 'expand-icon--down'])} />
        </button>
      </div>
      <motion.div
        initial='collapsed'
        animate={isOpen ? 'open' : 'collapsed'}
        variants={{
          open: { height: 'auto', visibility: 'visible' },
          collapsed: { height: 0, visibility: 'collapse' },
        }}
        transition={{ duration: 0.2 }}
        className={isOpen ? 'filter-wrapper' : 'filter-wrapper--collapsed'}
      >
        {children}
      </motion.div>
    </div>
  );
};
