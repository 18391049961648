import React, { createRef, FC, InputHTMLAttributes, RefObject, useCallback, useRef } from 'react';
import { FormikProps } from 'formik';
import uniqueId from 'lodash/uniqueId';
import cn from 'classnames';

import { StatusWrapper, StatusWrapperStatus } from '../../status-wrapper';
import { CommonRadioOnChange, RadioOption } from './models';
import { Radio, RadioRef } from './components/radio';
import { styles } from './styles';

export type RadioGroupAlign = 'vertical' | 'horizontal';

export type RadioGroupProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'form'> & {
  data: RadioOption[];
  onChange: CommonRadioOnChange;
  form?: FormikProps<any>;
  align?: RadioGroupAlign;
  status?: StatusWrapperStatus;
  minHeight?: number;
};

export const RadioGroup: FC<RadioGroupProps> = ({
  name = uniqueId(),
  data = [],
  form,
  value,
  onChange,
  disabled,
  align = 'vertical',
  status,
  minHeight = 30,
}) => {
  const entitiesRef = useRef<RefObject<RadioRef>[]>([]);

  const changeValueAndFocus = useCallback((index: number) => {
    let nextIndex = index;

    if (index < 0) {
      nextIndex = entitiesRef.current.length - 1;
    } else if (index >= entitiesRef.current.length) {
      nextIndex = 0;
    }

    entitiesRef.current[nextIndex].current?.focus();
  }, []);

  const handleOnRadioChange = useCallback<CommonRadioOnChange>(
    radioValue => {
      if (form !== undefined && name !== undefined) {
        form.setFieldTouched(name);
        form.setFieldValue(name, radioValue);
      } else {
        onChange(radioValue);
      }
    },
    [data, form?.setFieldTouched, form?.setFieldValue, onChange]
  );

  const onEntityNext = useCallback(index => changeValueAndFocus(index + 1), [changeValueAndFocus]);
  const onEntityPrevious = useCallback(index => changeValueAndFocus(index - 1), [changeValueAndFocus]);

  return (
    <StatusWrapper status={status || 'success'} minHeight={minHeight}>
      <div css={styles}>
        <div className={cn(['radio-wrapper', align === 'vertical' ? 'radio-wrapper--vertical' : 'radio-wrapper--horizontal'])}>
          {data.map(({ isDisabled, ...option }, index) => {
            entitiesRef.current[index] = createRef<RadioRef>();
            return (
              <Radio
                index={index}
                onChange={handleOnRadioChange}
                onNext={onEntityNext}
                onPrevious={onEntityPrevious}
                key={`radio-option-${option.value}-for-${name}`}
                name={name}
                option={option}
                disabled={disabled || isDisabled}
                value={value}
                ref={entitiesRef.current[index]}
                className='radio-button'
              />
            );
          })}
        </div>
      </div>
    </StatusWrapper>
  );
};
