import { createReducer } from 'typesafe-actions';

import { VisibilityDictionaryVM } from '@/models/visibility';
import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { FeatureStateType } from '../helpers';
import { visibilityActions } from './actions';

type visibilityState = DictionaryState<VisibilityDictionaryVM>;

const INITIAL_STATE: visibilityState = getDictionaryInitialState<VisibilityDictionaryVM>();

const reducer = createReducer<visibilityState, visibilityActions>(INITIAL_STATE)
  .handleAction(visibilityActions.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(visibilityActions.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(visibilityActions.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(visibilityActions.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(visibilityActions.failure, (state, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const visibilityReducer = { visibility: reducer };
export type visibilityReducer = FeatureStateType<typeof visibilityReducer>;
