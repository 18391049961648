import { createReducer } from 'typesafe-actions';

import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { StatusesDictionaryVM } from '@/models/statuses';
import { FeatureStateType } from '../helpers';
import { statusesActions } from './actions';

type statusesState = DictionaryState<StatusesDictionaryVM>;

const INITIAL_STATE: statusesState = getDictionaryInitialState<StatusesDictionaryVM>();

const reducer = createReducer<statusesState, statusesActions>(INITIAL_STATE)
  .handleAction(statusesActions.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(statusesActions.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(statusesActions.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(statusesActions.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(statusesActions.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const statusesReducer = { statuses: reducer };
export type statusesReducer = FeatureStateType<typeof statusesReducer>;
