import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const editorToolbarStyle: StyleFunction = ({ theme }) => ({
  '.content': {
    border: `1px solid ${theme.brandPrimary}`,
    marginRight: constants.offset.medium,
    minWidth: 180,
  },

  '.tooltip': {
    color: theme.textThird,
    marginTop: constants.offset.medium,
    width: 180,
    lineHeight: 1.5,
    fontSize: constants.fontSize.h4,
    padding: `0 ${constants.offset.xsmall}px`,

    span: {
      color: theme.textPrimary,
    },

    '&__icon': {
      display: 'inline-box',
      position: 'relative',
      top: 3,
      padding: '0px 2px',
      color: theme.textPrimary,
    },

    '&:not(:first-of-type)': {
      marginTop: constants.offset.small,
    },
  },
});
