import { Column } from 'react-table';
import { TFunction } from 'i18next';
import { AxiosError } from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import isString from 'lodash/isString';

import { i18n } from '@/core/services/i18n';

type UnionFromTuple<T> = T extends (infer U)[] ? U : never;

export const Enum = <T extends string[]>(...args: T) =>
  Object.freeze(
    args.reduce(
      (acc, next) => ({
        ...acc,
        [next]: next,
      }),
      Object.create(null)
    ) as { [P in UnionFromTuple<typeof args>]: P }
  );

export type Enum<T extends object> = T[keyof T];

const checkIsHttpClientCommonErrorType = (data: any): data is HttpClientCommonError => data.config && data.message;

export interface CommonError {
  code?: string;
  message?: any;
  errorId?: string;
  timestamp?: string;
  additionalInfo?: {
    '@errors': string[];
  };
}

export class CommonError {
  constructor(data: HttpClientCommonError | CommonError | undefined = {}) {
    if (checkIsHttpClientCommonErrorType(data)) {
      const { code, errorId, message, timestamp, additionalInfo } = data.response?.data || {};
      this.code = code;
      this.message = message;
      this.errorId = errorId;
      this.timestamp = timestamp || dayjs().format();
      this.additionalInfo = additionalInfo;
    } else {
      const { code, errorId, message, timestamp, additionalInfo } = data;
      this.code = code;
      this.message = message;
      this.errorId = errorId;
      this.timestamp = timestamp || dayjs().format();
      this.additionalInfo = additionalInfo;
    }
  }
}

export const prepareTableColumns = <T extends object = {}>(tableDefinitions: Column<T>[], t: TFunction, disableSort?: boolean) => {
  return tableDefinitions.map(({ Header, ...props }) => {
    return {
      ...props,
      disableSortBy: disableSort || props.disableSortBy,
      Header: isString(Header) ? (t(Header) as string) : '',
    };
  }) as Column<T>[];
};

export type HttpClientCommonError = AxiosError<CommonError>;

export const getErrorMessage = (error?: CommonError, commonMessageKey?: string) => {
  const getErrorKey = (code: string) => `errorCode.${code}`;

  if (error?.code && i18n.exists(getErrorKey(error?.code))) {
    return i18n.t(getErrorKey(error?.code));
  }

  if (error?.message) {
    return error?.message;
  }

  if (commonMessageKey && i18n.exists(commonMessageKey)) {
    return i18n.t(commonMessageKey);
  }

  return i18n.t('error.common');
};

export type AllowNull<T> = {
  [P in keyof T]-?: T[P] | null;
};

export type FormStatus = 'INVALID_TO_SAVE' | 'READY_TO_SAVE';
