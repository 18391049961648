import { combineReducers } from 'redux';

import { FeatureStateType } from '@/store/helpers';
import { smartMapsListReducer } from './list';
import { smartMapsDetailsReducer } from './details';

export type smartMapsReducer = FeatureStateType<typeof smartMapsReducer>;

export const smartMapsReducer = {
  smartMaps: combineReducers({
    ...smartMapsListReducer,
    ...smartMapsDetailsReducer,
  }),
};
