import { SelectOption } from '@/common/components/form-controls-deprecated';

type ContentTypesResponse = {
  id: string;
  displayName: string;
};

export type ContentTypesListResponse = ContentTypesResponse[];

export interface ContentTypesDictionaryElementVM extends SelectOption {}
export class ContentTypesDictionaryElementVM {
  constructor({ id, displayName }: ContentTypesResponse) {
    this.value = id;
    this.label = displayName;
  }
}

export interface ContentTypesDictionaryVM {
  list: ContentTypesDictionaryElementVM[];
}

export class ContentTypesDictionaryVM {
  constructor(list: ContentTypesListResponse) {
    this.list = (list || []).map(element => new ContentTypesDictionaryElementVM(element));
  }
}
