import { useCallback, useState, useRef } from 'react';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { useTranslation } from 'react-i18next';

import { AutocompleteOption, AutocompleteOnInputChangeFunc } from '@/common/components/autocomplete';
import { toast } from '@/core/services/toast';
import { CommonError } from '@/common/utils';
import { getUsersAutocompleteOptionList } from '@/features/accounts/services/users';

type UsersAutocomplete<T = string | number> = {
  initialOptions?: AutocompleteOption<T>[];
};

export const useUsersAutocomplete = <T = string | number>({ initialOptions }: UsersAutocomplete<T>) => {
  const destroy$ = useRef(new Subject());
  const [options, setOptions] = useState<AutocompleteOption<T>[]>(initialOptions || []);
  const [isOptionListLoading, setIsOptionListLoading] = useState<boolean>(false);
  const [t] = useTranslation();

  const handleOnInputChange = useCallback<AutocompleteOnInputChangeFunc>(
    (_, value, reason) => {
      if (reason === 'reset') {
        return;
      }

      if ((value?.length || 0) < 1) {
        setOptions([]);
        return;
      }

      setIsOptionListLoading(true);
      getUsersAutocompleteOptionList(value)
        .pipe(takeUntil(destroy$.current))
        .subscribe(data => {
          if (data instanceof CommonError) {
            toast.show({ type: 'error', text: t('error.usersList') });
          } else {
            setOptions(data.list as any as AutocompleteOption<T>[]);
          }

          setIsOptionListLoading(false);
        });
    },
    [t]
  );

  const clearOptions = useCallback(() => {
    setOptions([]);
  }, []);

  return { options, isOptionListLoading, handleOnInputChange, clearOptions };
};
