import { createReducer } from 'typesafe-actions';

import { OneTimeProductDataVM, EntitlementsParams } from '@/models/entitlements';
import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { FeatureStateType } from '../../helpers';
import { entitlementsActions } from '../actions';

export type oneTimeProductsState = FetchState<OneTimeProductDataVM, EntitlementsParams>;

const INITIAL_STATE: oneTimeProductsState = getFetchInitialState<OneTimeProductDataVM, EntitlementsParams>();
const reducer = createReducer<oneTimeProductsState, entitlementsActions>(INITIAL_STATE)
  .handleAction(entitlementsActions.oneTimeProducts.request, (state, { payload }) => fetchReducerHelpers.request(INITIAL_STATE, state, payload))
  .handleAction(entitlementsActions.oneTimeProducts.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(entitlementsActions.oneTimeProducts.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(entitlementsActions.clearOneTimeProducts, () => ({ ...INITIAL_STATE }))
  .handleAction(entitlementsActions.oneTimeProductsTotalCount.success, (state, action) => ({ ...state, collectionTotalCount: action.payload }))
  .handleAction(entitlementsActions.oneTimeProductsTotalCount.failure, state => ({ ...state, collectionTotalCount: 0 }));

export const oneTimeProductsReducer = { oneTimeProducts: reducer };
export type oneTimeProductsReducer = FeatureStateType<typeof oneTimeProductsReducer>;
