import { constants, rem } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const textAreaStyles: StyleFunction = ({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  '.text-area': {
    borderRadius: constants.borderRadius.small,
    border: `1px solid ${theme.borderThird}`,
    boxSizing: 'border-box',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: constants.offset.medium,
    paddingRight: constants.offset.medium,
    lineHeight: 1.5,
    width: '100%',
    backgroundColor: theme.backgroundNinth,
    color: theme.textPrimary,
    transitionProperty: 'border-color, box-shadow',
    transitionDuration: '.2s',
    resize: 'none',
    minHeight: 125,

    '&:active:enabled, &:focus:enabled': {
      border: `1px solid ${theme.borderPrimaryActive}`,
      boxShadow: `0 0 1px 1px ${theme.borderPrimaryActive} inset`,
      outline: 'none',
    },

    '&:disabled': {
      backgroundColor: theme.backgroundPrimaryDisabled,
      color: theme.textDisabled,
      cursor: 'not-allowed',
      borderColor: theme.borderPrimaryDisabled,
    },
  },

  '.max-length-message': {
    marginTop: constants.offset.xsmall,
    color: theme.textSecondary,
    fontSize: rem(constants.fontSize.p),
  },
});
