import dayjs, { Dayjs } from 'dayjs';
import capitalize from 'lodash/capitalize';
import { DatePickerView } from '@material-ui/pickers';

import '@/common/utils/date';
import { Enum } from '@/common/utils';

/**
 * @type {Date} - value has been changed and display string should be updated
 * @type {null} - value is incorrect so display string shouldn't be updated
 * @type {undefined} - value is empty so display string will be cleared
 */
export type DateValueDeprecated = Dayjs | null | undefined;
export type DateBoundaries<T = DateValueDeprecated> = { start?: T; end?: T };

const getDayjs = (date: string | Dayjs): Dayjs => (typeof date === 'string' ? dayjs(date) : date);

export const isDateInRange = <T extends string | Dayjs>(date: Dayjs, { start, end }: DateBoundaries<T>): boolean =>
  date.isBetween(getDayjs(start || '1900'), getDayjs(end || '2100'));

export const parseDate = <T extends string | Dayjs>(value: string | undefined, formatStrings: string[], range: DateBoundaries<T>): Dayjs | null => {
  if (!value || !formatStrings.length) return null;
  const prepared = value
    .replace(/(,|\.|-|:|\/|\\| )+/g, ' ')
    .trimStart()
    .split(' ')
    .map(part => capitalize(part))
    .join(' ');
  const parsed = dayjs(prepared, formatStrings, 'en', true).utc(true);
  if (parsed.isValid() && isDateInRange(parsed, range)) return parsed;

  return null;
};

export const MONTH_DATE_FORMATS = Object.freeze({
  M_YYYY: 'M YYYY',
  MM_YYYY: 'MM YYYY',
  MMM_YYYY: 'MMM YYYY',
  MMMM_YYYY: 'MMMM YYYY',
  YYYY_M: 'YYYY M',
  YYYY_MM: 'YYYY MM',
  YYYY_MMM: 'YYYY MMM',
  YYYY_MMMMM: 'YYYY MMMMM',
} as const);

export const FULL_DATE_FORMATS = Object.freeze({
  DD_M_YYYY: 'DD M YYYY',
  DD_MM_YYYY: 'DD MM YYYY',
  DD_MMM_YYYY: 'DD MMM YYYY',
  DD_MMMM_YYYY: 'DD MMMM YYYY',
  D_M_YYYY: 'D M YYYY',
  D_MM_YYYY: 'D MM YYYY',
  D_MMM_YYYY: 'D MMM YYYY',
  D_MMMM_YYYY: 'D MMMM YYYY',
} as const);

export const DAY_MONTH_DATE_FORMATS = Object.freeze({
  DD_MM: 'DD MM',
} as const);

export const DATE_FORMATS = Object.freeze({
  ...MONTH_DATE_FORMATS,
  ...FULL_DATE_FORMATS,
  ...DAY_MONTH_DATE_FORMATS,
  default: 'DD/MM/YYYY',
} as const);

export type DateFormat<T = typeof DATE_FORMATS> = T[keyof T];

export const MONTH_DATE_FORMATS_LIST: DateFormat[] = Object.values(MONTH_DATE_FORMATS);
export const FULL_DATE_FORMATS_LIST: DateFormat[] = Object.values(FULL_DATE_FORMATS);

type DatePickerSchemasType = 'FULL' | 'MONTH';
export type DatePickerSchemas = Record<
  DatePickerSchemasType,
  {
    views: DatePickerView[];
    validationFormats: DateFormat[];
    displayFormat: DateFormat;
  }
>;

export const DATE_PICKER_SCHEMAS: DatePickerSchemas = {
  FULL: {
    views: ['year', 'month', 'date'],
    displayFormat: FULL_DATE_FORMATS.D_MMMM_YYYY,
    validationFormats: FULL_DATE_FORMATS_LIST,
  },
  MONTH: {
    views: ['year', 'month'],
    displayFormat: MONTH_DATE_FORMATS.MMMM_YYYY,
    validationFormats: MONTH_DATE_FORMATS_LIST,
  },
};

export const DatePickerOnChangeErrorReason = Enum('minDate', 'maxDate', 'invalidDate', 'noValue', 'none');
export type DatePickerOnChangeErrorReason = Enum<typeof DatePickerOnChangeErrorReason>;

type OnErrorCallback<R = 'minDate' | 'maxDate' | 'shouldDisableDate' | 'invalidDate' | 'disableFuture' | 'disablePast' | null, V = unknown> = (
  reason: R,
  value: V
) => void;

export type DatePickerOnChangeErrorCallback = OnErrorCallback<DatePickerOnChangeErrorReason, DateValueDeprecated>;
export type DatePickerValueWithReason = { value: DateValueDeprecated; reason?: DatePickerOnChangeErrorReason };
export type DatePickerOnChangeHandlerParams = {
  value: Dayjs | null;
  inputValue: string | undefined;
  minDate?: DateValueDeprecated;
  maxDate?: DateValueDeprecated;
};
export type DatePickerOnChangeHandler<R = void> = (params: DatePickerOnChangeHandlerParams) => R;
export type DatePickerOnInputChange = (value: Date | null, inputValue: string | undefined) => void;
export type DatePickerOnChange = (value: DateValueDeprecated) => void;
