import { Styles, constants } from '@/styles';

export const styles: Styles = {
  '.radio-wrapper': {
    display: 'flex',

    '&--vertical': {
      flexDirection: 'column',
    },

    '&--horizontal': {
      flexDirection: 'row',
      height: 40,
      alignItems: 'center',

      '.checkmark': {
        top: 'calc(50% - 8px)',
      },

      '& > *': {
        '&:not(:last-child)': {
          marginRight: constants.offset.large,
        },
      },
    },
  },
};
