import { ActionType, Action } from 'typesafe-actions';

import { createDictionaryActions } from '@/common/utils/store';
import { ReasonsDictionaryVM } from '@/models/reasons';

export const reasonsActions = createDictionaryActions(
  `FETCH_REASONS_DICTIONARY_REQUEST`,
  `FETCH_REASONS_DICTIONARY_SUCCESS`,
  `FETCH_REASONS_DICTIONARY_FAILURE`,
  `FETCH_REASONS_DICTIONARY_CANCEL`,
  `FETCH_REASONS_DICTIONARY_SILENT_REQUEST`
)<undefined, ReasonsDictionaryVM>();

export type reasonsActions = Action | ActionType<typeof reasonsActions>;
