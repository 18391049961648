import { createReducer } from 'typesafe-actions';

import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { DEFAULT_LIST_PAGINATION_DATA } from '@/common/utils/pageable';
import { ToursListVM, ToursParams } from '@/features/content/models/tours';
import { FeatureStateType } from '../../helpers';
import { toursActions } from '../actions';

export type toursListState = FetchState<ToursListVM, ToursParams>;

const INITIAL_STATE: toursListState = getFetchInitialState<ToursListVM, ToursParams>({ statuses: ['PUBLISHED'] });

const reducer = createReducer<toursListState, toursActions>(INITIAL_STATE)
  .handleAction(toursActions.list.request, (state, { payload }) => fetchReducerHelpers.request(INITIAL_STATE, state, payload))
  .handleAction(toursActions.list.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(toursActions.list.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(toursActions.clearToursList, state => ({
    ...state,
    data: { ...state.data, list: [], listPaginationData: state.data?.listPaginationData || DEFAULT_LIST_PAGINATION_DATA },
  }));

export const toursListReducer = { list: reducer };
export type toursListReducer = FeatureStateType<typeof toursListReducer>;
