import { createSelector } from 'reselect';

import { UserRoutesListVM } from '@/features/content/models/routes';
import { UserCommunityStoriesListVM } from '@/features/content/models/community-stories';
import { StatusWrapperStatus } from '@/common/components/status-wrapper';
import { getDataStatus } from '@/common/utils';
import { contentProvidedByUserReducer } from './reducers';

type MakeGetStatus = StatusWrapperStatus;
type MakeGetListLength = number;
type MakeGetTotalElements = number;
type MakeGetSort = string[] | undefined;

type Routes = contentProvidedByUserReducer['contentProvidedByUser']['routes'];
type MakeGetRoutesData = UserRoutesListVM['list'];

const getRoutes = (state: contentProvidedByUserReducer) => state.contentProvidedByUser.routes;

export const makeGetUserRoutesList = () => createSelector<contentProvidedByUserReducer, Routes, MakeGetRoutesData>(getRoutes, ({ list }) => list);

export const makeGetUserRoutesListLength = () => createSelector<contentProvidedByUserReducer, Routes, MakeGetListLength>(getRoutes, ({ list }) => list.length);

export const makeGetUserRoutesListStatus = () => createSelector<contentProvidedByUserReducer, Routes, MakeGetStatus>(getRoutes, ({ status }) => status);

type CommunityStories = contentProvidedByUserReducer['contentProvidedByUser']['communityStories'];
type MakeGetCommunityStoriesData = UserCommunityStoriesListVM['list'];
type MakeGetCommunityStoriesPaginationData = UserCommunityStoriesListVM['listPaginationData'] | undefined;

const getCommunityStories = (state: contentProvidedByUserReducer) => state.contentProvidedByUser.communityStories;

export const makeGetUserCommunityStoriesList = () =>
  createSelector<contentProvidedByUserReducer, CommunityStories, MakeGetCommunityStoriesData>(getCommunityStories, ({ data }) => data?.list || []);

export const makeGetUserCommunityStoriesPaginationData = () =>
  createSelector<contentProvidedByUserReducer, CommunityStories, MakeGetCommunityStoriesPaginationData>(
    getCommunityStories,
    ({ data }) => data?.listPaginationData
  );

export const makeGetUserCommunityStoriesStatus = () =>
  createSelector<contentProvidedByUserReducer, CommunityStories, MakeGetStatus>(getCommunityStories, getDataStatus);

export const makeGetUserCommunityStoriesListLength = () =>
  createSelector<contentProvidedByUserReducer, CommunityStories, MakeGetTotalElements>(
    getCommunityStories,
    ({ data }) => data?.listPaginationData.totalElements || 0
  );

export const makeGetUserCommunityStoriesSort = () =>
  createSelector<contentProvidedByUserReducer, CommunityStories, MakeGetSort>(getCommunityStories, ({ params }) => params.sort);
