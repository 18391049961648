import { combineReducers } from 'redux';

import { FeatureStateType } from '@/store/helpers';
import { voucherBatchListReducer } from './list';
import { voucherBatchDetailsReducer } from './details';
import { statusDictionaryReducer } from './statuses';
import { addVoucherBatchReducer } from './add-voucher-batch';

export type voucherBatchReducer = FeatureStateType<typeof voucherBatchReducer>;

export const voucherBatchReducer = {
  voucherBatch: combineReducers({
    ...voucherBatchListReducer,
    ...voucherBatchDetailsReducer,
    ...statusDictionaryReducer,
    ...addVoucherBatchReducer,
  }),
};
