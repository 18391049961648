import React, { FC, TextareaHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/styles/hooks';
import { textAreaStyles } from './styles';

const DEFAULT_ROWS_COUNT = 3;

export type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  maxLength?: number;
};

export const Textarea: FC<TextareaProps> = ({ maxLength, ...props }) => {
  const [t] = useTranslation();
  const { styles } = useStyles(textAreaStyles);

  return (
    <div css={styles}>
      <textarea rows={props.rows || DEFAULT_ROWS_COUNT} {...props} className='text-area' />
      {maxLength && <div className='max-length-message'>{t('validationMessage.maxLength', { length: maxLength })}</div>}
    </div>
  );
};
