import React, { FC, HTMLAttributes, ReactNode } from 'react';

import { StepWithoutTarget, useOnboardingState, useTourStepRef } from '../hooks';
import { CurrentStep } from '../contextStore';

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  step: StepWithoutTarget;
  children?: ReactNode | ((currentStep: Partial<CurrentStep>) => ReactNode);
}

export const OnboardingTourStep: FC<Props> = ({ step, children, ...props }) => {
  const [ref] = useTourStepRef<HTMLDivElement>(step);
  const currentStep = useOnboardingState();

  return (
    <div ref={ref} {...props}>
      {children instanceof Function ? children(currentStep || {}) : children}
    </div>
  );
};
