import { ActionType, Action } from 'typesafe-actions';

import { createDictionaryActions } from '@/common/utils/store';
import { ContentTypesDictionaryVM } from '@/models/content-types';

export const contentTypesActions = createDictionaryActions(
  `FETCH_CONTENT_TYPES_DICTIONARY_REQUEST`,
  `FETCH_CONTENT_TYPES_DICTIONARY_SUCCESS`,
  `FETCH_CONTENT_TYPES_DICTIONARY_FAILURE`,
  `FETCH_CONTENT_TYPES_DICTIONARY_CANCEL`,
  `FETCH_CONTENT_TYPES_DICTIONARY_SILENT_REQUEST`
)<undefined, ContentTypesDictionaryVM>();

export type contentTypesActions = Action | ActionType<typeof contentTypesActions>;
