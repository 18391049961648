import { createReducer } from 'typesafe-actions';

import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { DEFAULT_LIST_PAGINATION_DATA } from '@/common/utils/pageable';
import { OrganisationListVM, OrganisationsListParams } from '@/models/organisations';
import { FeatureStateType } from '../../helpers';
import { organisationsActions } from '../actions';

export type organisationsListState = FetchState<OrganisationListVM, OrganisationsListParams>;

const INITIAL_STATE: organisationsListState = getFetchInitialState<OrganisationListVM>();

const reducer = createReducer<organisationsListState, organisationsActions>(INITIAL_STATE)
  .handleAction(organisationsActions.list.request, (state, { payload }) => fetchReducerHelpers.request(INITIAL_STATE, state, payload))
  .handleAction(organisationsActions.list.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(organisationsActions.list.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(organisationsActions.clearOrganisationsList, state => ({
    ...state,
    data: { ...state.data, list: [], listPaginationData: state.data?.listPaginationData || DEFAULT_LIST_PAGINATION_DATA },
  }));

export const organisationsListReducer = { list: reducer };
export type organisationsListReducer = FeatureStateType<typeof organisationsListReducer>;
