import { Store } from 'redux';
import type { StringDict } from '@azure/msal-common';

import { Enum } from '@/common/utils';
import { LogoutReasons } from '../../../navigation';

export type AccountHubPolicy = Enum<typeof AccountHubPolicy>;
export const AccountHubPolicy = Enum('B2C_1A_signup_signin', 'B2C_1A_PasswordReset', 'B2C_1A_signup_admin_panel_invitation');

export type Status = Enum<typeof Status>;
export const Status = Enum('none', 'sso', 'handleRedirect', 'initial');

export type RequestParams = {
  policy?: AccountHubPolicy;
  state?: string;
  extraQueryParameters?: StringDict;
};

export type LoginParams = {
  initialUrl?: string;
};

export type LogoutParams = {
  reason?: LogoutReasons;
};

export type UserProfileParams = {
  store: Store;
};

export type HandleRedirectPromiseParams = {
  store: Store;
};
