import { Styles, constants } from '@/styles';

export const styles: Styles = {
  '.description': {
    marginTop: 0,
    marginBottom: constants.offset.xlarge,
  },
};

export const defaultSettingsButtonStyle: Styles = {
  marginRight: 45,
};
