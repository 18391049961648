import React, { ChangeEvent, useEffect, useCallback, useContext, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';

import { Input } from '@/common/components/form-controls-deprecated';
import { useStyles } from '@/styles/hooks';
import { CommonEditorCardInnerComponent } from '../../components/common-editor-card';
import { EditorDroppableContext } from '../../../editor-container';
import { AccordionCardPayload } from '../accordion-card';
import { accordionCardStyles } from './styles';

export const AccordionCardHeaderComponent: CommonEditorCardInnerComponent<AccordionCardPayload> = ({ isEditable, formField, id }) => {
  const [field, meta, helpers] = formField;
  const [innerValue, setInnerValue] = useState<string>(field.value?.title || '');
  const editorDroppableContext = useContext(EditorDroppableContext);
  const [t] = useTranslation();
  const { styles } = useStyles(accordionCardStyles);

  const handleOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setInnerValue(e.target.value), []);

  const handleOnBlur = useCallback(() => {
    helpers.setTouched(true);
    helpers.setValue(
      {
        ...field.value,
        title: innerValue,
      },
      true
    );
  }, [innerValue, helpers.setValue, helpers.setTouched]);

  useEffect(() => {
    isEditable ? editorDroppableContext.addActiveAccordion(id) : editorDroppableContext.removeActiveAccordion(id);
  }, [isEditable]);

  useEffect(
    () => () => {
      editorDroppableContext.removeActiveAccordion(id);
    },
    [id]
  );

  useEffect(() => {
    if (!isEqual(innerValue, field.value)) {
      setInnerValue(field.value?.title);
    }
  }, [field.value]);

  return (
    <div css={styles}>
      {isEditable ? (
        <Input
          {...field}
          value={innerValue || ''}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          placeholder={t('storyContentEditor.card.accordion.titlePlaceholder')}
        />
      ) : meta.value?.title ? (
        <div className='value'>{meta.value?.title}</div>
      ) : (
        <div className='placeholder'>{t('storyContentEditor.card.accordion.titlePlaceholder')}</div>
      )}
    </div>
  );
};
