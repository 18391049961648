import { createReducer } from 'typesafe-actions';

import { themeDark, themeLight, ThemeType } from '@/styles/theme';
import { FeatureStateType } from '../helpers';
import { interfaceActions } from './actions';
import { resetStoreAction } from '../middleware';

const isDarkMode = () => {
  const storedThemeMode = localStorage.getItem('currentTheme');
  return storedThemeMode ? storedThemeMode === 'DARK' : window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
};

type CurrentTheme = {
  themeName: string;
  theme: ThemeType;
};

type interfaceState = {
  numberOfActiveActions: number;
  loadingSectionActive: boolean;
  initialUrl: string | undefined;
  currentTheme: CurrentTheme;
};

const INITIAL_STATE: interfaceState = {
  numberOfActiveActions: 0,
  loadingSectionActive: false,
  initialUrl: undefined,
  currentTheme: {
    themeName: isDarkMode() ? 'DARK' : 'LIGHT',
    theme: isDarkMode() ? themeDark : themeLight,
  },
};

const reducer = createReducer<interfaceState, interfaceActions>(INITIAL_STATE)
  .handleAction(interfaceActions.increaseNumberOfActiveActions, state => ({ ...state, numberOfActiveActions: state.numberOfActiveActions + 1 }))
  .handleAction(interfaceActions.reduceNumberOfActiveActions, state => ({ ...state, numberOfActiveActions: state.numberOfActiveActions - 1 }))
  .handleAction(interfaceActions.toggleLoadingSection, (state, action) => ({ ...state, loadingSectionActive: action.payload }))
  .handleAction(interfaceActions.setInitialUrl, (state, action) => ({ ...state, initialUrl: action.payload }))
  .handleAction(interfaceActions.setTheme, (state, action) => {
    switch (action.payload) {
      case 'LIGHT':
        return {
          ...state,
          currentTheme: {
            themeName: 'LIGHT',
            theme: themeLight,
          },
        };
      case 'DARK':
        return {
          ...state,
          currentTheme: {
            themeName: 'DARK',
            theme: themeDark,
          },
        };
      default:
        return {
          ...state,
        };
    }
  })
  .handleAction(resetStoreAction, state => {
    return {
      ...state,
      currentTheme: {
        themeName: isDarkMode() ? 'DARK' : 'LIGHT',
        theme: isDarkMode() ? themeDark : themeLight,
      },
    };
  })
  .handleAction(interfaceActions.clearInitialUrl, state => ({ ...state, initialUrl: undefined }));

export const interfaceReducer = { interface: reducer };
export type interfaceReducer = FeatureStateType<typeof interfaceReducer>;
