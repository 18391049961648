import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { ColumnInstance, IdType } from 'react-table';
import { useTranslation } from 'react-i18next';

import { Dialog } from '@/common/components/dialog';
import { Checkbox } from '@/common/components/form-controls-deprecated';
import { Button, ButtonTheme } from '@/common/components/button';
import { useForceUpdate } from '@/common/utils';
import { setTableLocalStorage, getTableLocalStorage, TableStorageEntityState } from './local-storage';
import { styles, defaultSettingsButtonStyle } from './styles';

export type HidingColumnProps = {
  alwaysVisible?: boolean;
  defaultVisibility?: boolean;
  visible?: boolean;
};

export type HidableColumnInstance<T extends {} = {}> = ColumnInstance<T> & HidingColumnProps;

export const useColumnHiding = <T extends {} = {}>(
  allColumns: HidableColumnInstance<T>[],
  setHiddenColumns: (param: IdType<T>[]) => void,
  columnsHiding?: boolean,
  isInRowsSelectMode?: boolean,
  name?: string
) => {
  const [t] = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [tempState, setTempState] = useState<(boolean | undefined)[]>([]);
  const forceUpdate = useForceUpdate();

  const savedState = useMemo(() => (name ? getTableLocalStorage(name) : undefined), [name, isInRowsSelectMode]);

  const columns = useMemo<HidableColumnInstance<T>[]>(() => {
    const columns = allColumns.map(column => ({
      ...column,
      visible: column.visible ?? savedState?.[column.id]?.visible ?? column.defaultVisibility ?? true,
    }));
    columns.pop();
    if (isInRowsSelectMode) columns.shift();
    return columns;
  }, [allColumns, isInRowsSelectMode]);

  useEffect(() => {
    if (columnsHiding) {
      const defaultColumns = {};

      columns.forEach(({ id, defaultVisibility }) => Object.assign(defaultColumns, { [id]: { id, visible: defaultVisibility ?? true } }));

      if (savedState) {
        Object.values(savedState).forEach(({ id, visible }) => Object.assign(defaultColumns, { [id]: { id, visible } }));
      }

      setHiddenColumns(
        Object.values<TableStorageEntityState<T>>(defaultColumns)
          .filter(column => !column.visible)
          .map(({ id }) => id)
      );
    }
  }, [name, columnsHiding, isInRowsSelectMode]);

  useEffect(() => {
    const storage = {};
    const toTempState: (boolean | undefined)[] = [];

    columns.forEach(column => {
      Object.assign(storage, {
        [column.id]: {
          id: column.id,
          visible: column.visible,
        },
      });
      toTempState.push(column.visible);
    });

    if (name) setTableLocalStorage(name, storage);
    if (isDialogOpen) setTempState(toTempState);
  }, [name, isDialogOpen]);

  const handleOnChange = useCallback(
    (column: HidableColumnInstance<T>) => () => {
      column.visible = !column.visible;
      forceUpdate();
    },
    []
  );

  const handleRestoreDefault = useCallback(() => {
    columns.forEach(column => {
      column.visible = column.defaultVisibility ?? true;
      forceUpdate();
    });
  }, [columns]);

  const handleOnClose = useCallback(() => {
    columns.forEach((column, index) => {
      column.visible = tempState[index];
      column.getToggleHiddenProps().onChange({ target: { checked: tempState[index] } });
    });
    setIsDialogOpen(false);
  }, [columns, tempState]);

  const handleOnConfirm = useCallback(() => {
    columns.forEach(column => {
      column.getToggleHiddenProps().onChange({ target: { checked: column.visible } });
    });
    setIsDialogOpen(false);
  }, [columns]);

  return {
    onHideColumnDialogOpen: columnsHiding ? () => setIsDialogOpen(true) : undefined,
    columnHidingDialog: columnsHiding ? (
      <Dialog
        open={isDialogOpen}
        onClose={handleOnClose}
        onConfirm={handleOnConfirm}
        title={t('dialog.table.title')}
        confirmLabel={t('button.save')}
        additionalButtons={[
          <Button css={defaultSettingsButtonStyle} key='0' theme={ButtonTheme.primary} onClick={handleRestoreDefault}>
            {t('button.defaultSettings')}
          </Button>,
        ]}
      >
        <div css={styles}>
          <p className={'description'}>{t('dialog.table.text')}</p>
          {columns.map((column, index) => (
            <Checkbox key={index} label={column.render('Header')} checked={column.visible} disabled={column.alwaysVisible} onChange={handleOnChange(column)} />
          ))}
        </div>
      </Dialog>
    ) : undefined,
  };
};
