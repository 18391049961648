import { createSelector } from 'reselect';

import { getDataStatus } from '@/common/utils';
import { difficultiesReducer } from './reducer';

type MakeGetDifficultiesData = difficultiesReducer['difficulties']['data'];

const getDifficulties = (state: difficultiesReducer) => state.difficulties;

export const makeGetDifficultiesData = () =>
  createSelector<difficultiesReducer, difficultiesReducer['difficulties'], MakeGetDifficultiesData>(getDifficulties, ({ data }) => data);

export const makeGetDifficultiesStatus = () =>
  createSelector<difficultiesReducer, difficultiesReducer['difficulties'], ReturnType<typeof getDataStatus>>(getDifficulties, getDataStatus);
