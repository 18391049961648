import React, { FC } from 'react';
import cn from 'classnames';

import { Field, FieldProps } from '../field';
import { FileInput, FileInputProps } from './file-input';

type FieldFileInputProps = Omit<FieldProps<FileInputProps>, 'children'> & {
  filePreviewLink?: string;
  isFilePreviewDisabled?: boolean;
  tooltipMessage?: string;
  className?: string;
};

export const FieldFileInput: FC<FieldFileInputProps> = ({
  name,
  label,
  labelIcon,
  className,
  filePreviewLink,
  isFilePreviewDisabled,
  tooltipMessage,
  ...props
}) => (
  <Field
    name={name}
    label={label}
    labelIcon={labelIcon}
    isFocusOnLabelEnabled={false}
    className={className}
    filePreviewLink={filePreviewLink}
    isFilePreviewDisabled={isFilePreviewDisabled}
    tooltipMessage={tooltipMessage}
  >
    {({ field, meta, form }) => {
      const inputClassName = cn([{ error: meta.touched && meta.error }]);
      return <FileInput {...props} form={form} {...field} inputProps={{ className: inputClassName }} />;
    }}
  </Field>
);
