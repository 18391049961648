import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { getEnv, CommonError } from '@/common/utils';
import { httpClient } from '@/core/services/http-client';
import { toast } from '@/core/services/toast';
import { RoleListResponse, RoleDictionaryVM } from '@/models/roles';
import { OrganisationListResponse, OrganisationDictionaryVM, OrganisationStatusDictionaryVM, OrganisationStatusListResponse } from '@/models/organisations';
import {
  AppDictionaryVM,
  AppListResponse,
  PlatformDictionaryVM,
  PlatformListResponse,
  UserListSearchByOptionListResponse,
  UserListSearchByOptionListVM,
} from '../models/users';

const config = getEnv();

const ROLES_ENDPOINT = `${config.REACT_APP_API_URL}/user-account-api/v1/admin/roles`;
const ORGANISATIONS_ENDPOINT = `${config.REACT_APP_API_URL}/user-account-api/v1/organisations`;
const ORGANISATION_STATUS_DICTIONARY_ENDPOINT = `${config.REACT_APP_API_URL}/user-account-api/v1/dictionaries/status`;
const USER_LIST_SEARCH_BY_OPTIONS_ENDPOINT = `${config.REACT_APP_API_URL}/admin-panel-api/v1/users/search/options`;
const APP_DICTIONARY_ENDPOINT = `${config.REACT_APP_API_URL}/user-account-api/v1/dictionaries/apps`;
const PLATFORM_DICTIONARY_ENDPOINT = `${config.REACT_APP_API_URL}/user-account-api/v1/dictionaries/platforms`;

export const getRolesDictionaryData = () =>
  httpClient()
    .authorized.get<RoleListResponse>(ROLES_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new RoleDictionaryVM(data);
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getOrganisationsDictionaryData = () =>
  httpClient()
    .authorized.get<OrganisationListResponse>(ORGANISATIONS_ENDPOINT, { params: { size: 1000 } })
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new OrganisationDictionaryVM(data);
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getUserListSearchByOptionsData = () =>
  httpClient()
    .authorized.get<UserListSearchByOptionListResponse>(USER_LIST_SEARCH_BY_OPTIONS_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new UserListSearchByOptionListVM(data);
        }
        throw undefined;
      }),
      catchError(e => {
        toast.show({ type: 'error', text: 'error.searchByOptions' });
        return of(new CommonError({ code: '500', message: e }));
      })
    );

export const getAppDictionaryData = () =>
  httpClient()
    .authorized.get<AppListResponse>(APP_DICTIONARY_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new AppDictionaryVM(data);
        }
        throw undefined;
      }),
      catchError(e => {
        return of(new CommonError({ code: '500', message: e }));
      })
    );

export const getPlatformDictionaryData = () =>
  httpClient()
    .authorized.get<PlatformListResponse>(PLATFORM_DICTIONARY_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new PlatformDictionaryVM(data);
        }
        throw undefined;
      }),
      catchError(e => {
        return of(new CommonError({ code: '500', message: e }));
      })
    );

export const getOrganisationStatusDictionaryData = () =>
  httpClient()
    .authorized.get<OrganisationStatusListResponse>(ORGANISATION_STATUS_DICTIONARY_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new OrganisationStatusDictionaryVM(data);
        }
        throw undefined;
      }),
      catchError(e => {
        return of(new CommonError({ code: '500', message: e }));
      })
    );
