import React, { ChangeEventHandler, FC, KeyboardEventHandler, MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';

import { useStyles } from '@/styles/hooks';
import { constants, getClassName } from '@/styles';
import { makeGetCurrentTheme } from '@/store/interface';
import { IconButton } from '../icon-button';
import { Input } from '../form-controls-deprecated';
import { Variants } from './constants';
import { ChipValue } from './types';
import { chipStyles } from './styles';

const getCurrentTheme = makeGetCurrentTheme();

type Props = ChipValue<unknown, Error> & {
  disabled?: boolean;
  onRemove: () => void;
  onChange: (next: string) => void;
  isEditable?: boolean;
};

export const Chip: FC<Props> = ({ label, duplicates, error, onRemove, onChange, isEditable, disabled }) => {
  const { styles } = useStyles(chipStyles);
  const { theme } = useSelector(getCurrentTheme);

  const [notifyDuplication, setNotifyDuplication] = useState(!!duplicates);
  const [changeState, setChangeState] = useState<string>(label);
  const [isInEditMode, setIsInEditMode] = useState<boolean>(false);

  useEffect(() => {
    setNotifyDuplication(!!duplicates);
  }, [duplicates]);

  const animate = useMemo(() => {
    if (disabled) return Variants.disabled;
    if (error) return notifyDuplication ? Variants.errorDuplicated : Variants.error;
    return notifyDuplication ? Variants.duplicated : Variants.initial;
  }, [error, notifyDuplication, disabled]);

  const variants = useMemo(
    () => ({
      [Variants.initial]: {
        backgroundColor: theme.primary,
      },
      [Variants.duplicated]: {
        backgroundColor: theme.primaryFocus,
      },
      [Variants.error]: {
        backgroundColor: theme.error,
      },
      [Variants.errorDuplicated]: {
        backgroundColor: theme.errorFocus,
      },
      [Variants.disabled]: {
        backgroundColor: theme.backgroundSecondaryDisabled,
      },
    }),
    []
  );

  const handleAnimationComplete = useCallback(() => setNotifyDuplication(false), []);

  const handleToggleEditMode = useCallback(
    (isEditMode: boolean): MouseEventHandler<HTMLButtonElement> =>
      event => {
        event.preventDefault();
        setIsInEditMode(isEditMode);
      },
    []
  );

  const handleEditConfirm = useCallback<MouseEventHandler<HTMLButtonElement>>(
    event => {
      event.preventDefault();
      onChange(changeState);
      setIsInEditMode(false);
    },
    [changeState, onChange]
  );

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(({ target: { value } }) => setChangeState(value), []);

  const handleKeyPress = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    ({ key, currentTarget: { value } }) => {
      if (key === 'Enter') {
        onChange(value);
        setIsInEditMode(false);
      }
    },
    [onChange, changeState]
  );

  const handleBlur = useCallback(() => {
    setChangeState(label);
    setIsInEditMode(false);
  }, [label]);

  const handleCancel = useCallback<MouseEventHandler<HTMLButtonElement>>(
    event => {
      event.preventDefault();
      handleBlur();
    },
    [handleBlur]
  );

  const handleRemove = useCallback<MouseEventHandler<HTMLButtonElement>>(
    event => {
      event.preventDefault();
      onRemove();
    },
    [onRemove]
  );

  const editableLabel = useMemo(
    () =>
      isEditable && isInEditMode ? (
        <Input
          type='text'
          className={getClassName('chip-edit', { __correct: !error, __error: !!error })}
          value={changeState}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
        />
      ) : (
        <span className='chip'>{label}</span>
      ),
    [isEditable, label, error, isInEditMode, changeState, handleChange]
  );

  return (
    <motion.div
      css={styles}
      initial={error ? variants.error : variants.initial}
      variants={variants}
      animate={animate}
      onAnimationComplete={handleAnimationComplete}
      style={{ paddingLeft: isInEditMode ? constants.offset.medium : undefined }}
    >
      {editableLabel}

      {isEditable && (
        <IconButton
          type='button'
          iconName={isInEditMode ? 'mdi-check-circle' : 'mdi-pencil'}
          className={getClassName('chip-icon', '__edit')}
          theme='secondary'
          disabled={disabled}
          onClick={isInEditMode ? handleEditConfirm : handleToggleEditMode(true)}
        />
      )}

      <IconButton
        type='button'
        iconName='mdi-close'
        className={getClassName('chip-icon', { __remove: isEditable })}
        theme='secondary'
        disabled={disabled}
        onClick={isInEditMode ? handleCancel : handleRemove}
      />
    </motion.div>
  );
};
