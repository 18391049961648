import { Enum } from '@/common/utils';
import { RoutePaths } from '../../navigation.models';
import { rootRoutePaths } from '../root';

export type authenticatedRoutes = Enum<typeof authenticatedRoutes>;
export const authenticatedRoutes = Enum('DASHBOARD', 'ACCOUNTS', 'CONTENT', 'MANAGEMENT', 'REPORTING');

export const authenticatedRoutePaths: RoutePaths<authenticatedRoutes> = {
  [authenticatedRoutes.DASHBOARD]: `${rootRoutePaths.AUTHENTICATED}/dashboard`,
  [authenticatedRoutes.ACCOUNTS]: `${rootRoutePaths.AUTHENTICATED}/accounts`,
  [authenticatedRoutes.CONTENT]: `${rootRoutePaths.AUTHENTICATED}/content`,
  [authenticatedRoutes.MANAGEMENT]: `${rootRoutePaths.AUTHENTICATED}/management`,
  [authenticatedRoutes.REPORTING]: `${rootRoutePaths.AUTHENTICATED}/reporting`,
};
