import { createReducer } from 'typesafe-actions';

import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { SubscriptionsProductsDictionaryVM } from '@/models/entitlements';
import { FeatureStateType } from '../../helpers';
import { entitlementsActions } from '../actions';

type subscriptionsProductsState = DictionaryState<SubscriptionsProductsDictionaryVM>;

const INITIAL_STATE: subscriptionsProductsState = getDictionaryInitialState<SubscriptionsProductsDictionaryVM>();

const reducer = createReducer<subscriptionsProductsState, entitlementsActions>(INITIAL_STATE)
  .handleAction(entitlementsActions.subscriptionsProducts.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(entitlementsActions.subscriptionsProducts.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(entitlementsActions.subscriptionsProducts.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(entitlementsActions.subscriptionsProducts.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(entitlementsActions.subscriptionsProducts.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const subscriptionsProductsDictionaryReducer = { subscriptionsProducts: reducer };
export type subscriptionsProductsDictionaryReducer = FeatureStateType<typeof subscriptionsProductsDictionaryReducer>;
