import { SelectOption } from '@/common/components/form-controls-deprecated';

type ActivitiesResponse = {
  id: string;
  displayName: string;
};

export type ActivitiesListResponse = ActivitiesResponse[];

export interface ActivitiesDictionaryElementVM extends SelectOption {}
export class ActivitiesDictionaryElementVM {
  constructor({ id, displayName }: ActivitiesResponse) {
    this.value = id;
    this.label = displayName;
  }
}

export interface ActivitiesDictionaryVM {
  list: ActivitiesDictionaryElementVM[];
}

export class ActivitiesDictionaryVM {
  constructor(list: ActivitiesListResponse) {
    this.list = (list || []).map(element => new ActivitiesDictionaryElementVM(element));
  }
}
