import { constants, rem } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const collapsibleFilterStyles: StyleFunction = ({ theme }) => ({
  marginTop: constants.offset.small,

  '.label-wrapper': {
    borderBottom: `1px solid ${theme.borderAccent}`,

    button: {
      backgroundColor: theme.backgroundEight,
    },
  },

  '.filter-label-wrapper': {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: constants.offset.small,
    cursor: 'pointer',
    borderRadius: constants.borderRadius.small,
    padding: 0,

    '&:hover': {
      '& > *': {
        color: theme.primaryHover,
      },
    },

    '&:active': {
      '& > *': {
        color: theme.primaryActive,
      },
    },

    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${theme.primaryFocus}`,
    },
  },

  '.filter-label': {
    color: theme.primary,
    fontSize: rem(constants.fontSize.h6),
    fontWeight: 600,
    transition: 'color .2s',
  },

  '.expand-icon': {
    color: theme.primary,
    fontSize: constants.fontSize.icon,
    transition: 'color .2s, transform 0.2s linear',

    '&--up': {
      transform: 'scaleY(-1)',
    },

    '&--down': {
      transform: 'scaleY(1)',
    },
  },

  '.filter-wrapper': {
    backgroundColor: theme.backgroundSecondary,
    overflow: 'hidden',
    padding: `${constants.offset.medium}px ${constants.offset.large}px`,

    '&--collapsed': {
      padding: 0,
    },

    '.MuiAutocomplete-clearIndicatorDirty': {
      display: 'none',
    },
  },
});
