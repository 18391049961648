import React, { FocusEventHandler, FormEventHandler, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import { MuiTextFieldProps } from '@material-ui/pickers/_shared/PureDateInput';

import { DateValueDeprecated, DatePickerOnChangeErrorReason as ErrorReason, DateFormat, DatePickerOnInputChange as OnInputChange } from '../constants';
import { useStyles } from '../styles';

export type DateInputProps = {
  disabled?: boolean;
  value: DateValueDeprecated;
  onChange: OnInputChange;
  placeholder?: string;
  lastReason: ErrorReason;
  displayFormat: DateFormat;
  onBlur?: FocusEventHandler<HTMLInputElement>;
};

export const DateInputDeprecated = ({ value, onChange, lastReason, disabled, placeholder, displayFormat }: DateInputProps) => {
  const TextInput = (props: MuiTextFieldProps) => {
    const classes = useStyles();
    const [t] = useTranslation();

    const getValue = (date: DateValueDeprecated) => (prev: string) => date ? date.format(displayFormat) : date === null ? prev : '';
    const [innerValue, setInnerValue] = useState(() => getValue(value)(''));

    useEffect(() => setInnerValue(getValue(value)), [value]);
    useEffect(() => {
      if (lastReason === ErrorReason.minDate || lastReason === ErrorReason.maxDate) setInnerValue(getValue(value));
    }, [lastReason]);

    const handleChange = useCallback<FormEventHandler<HTMLInputElement>>(({ currentTarget }) => {
      setInnerValue(currentTarget.value);
      onChange(currentTarget.valueAsDate, currentTarget.value);
    }, []);

    return (
      <TextField
        {...props}
        className={disabled ? classes.disabled : void 0}
        disabled={disabled}
        inputProps={{
          ...props.inputProps,
          placeholder: placeholder || t('placeholder.datePicker'),
          value: innerValue,
          onChange: handleChange,
        }}
        variant='outlined'
        size='small'
        InputLabelProps={{ shrink: false }}
        helperText=''
      />
    );
  };
  return TextInput;
};
