import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import isArray from 'lodash/isArray';

import { Scope } from '@/core/models/user-roles';
import { makeGetUserStatus } from '@/store/authentication';
import { checkUserPermissions, CheckUserPermissionScopes } from '../utils';

export type WithPermissionCallback<T = void> = () => T;
export type WithPermissionMode = 'some' | 'every';

const getUserStatus = makeGetUserStatus();

export const useWithPermission = <T extends keyof Scope = keyof Scope>(
  permissions: CheckUserPermissionScopes<T> | CheckUserPermissionScopes<T>[],
  mode: 'some' | 'every' = 'some'
): [boolean, <A = void, F = undefined>(onAllowed: WithPermissionCallback<A>, onForbidden?: WithPermissionCallback<F>) => A | F | undefined] => {
  const { roles } = useSelector(getUserStatus);

  const isAllowed = useMemo(
    () => (isArray(permissions) ? permissions : [permissions]).map(permission => checkUserPermissions(permission, roles))[mode](entity => entity),
    [permissions, roles]
  );

  const withPermission = useCallback(
    <A = void, F = undefined>(onAllowed: WithPermissionCallback<A>, onForbidden?: WithPermissionCallback<F>) => (isAllowed ? onAllowed() : onForbidden?.()),
    [isAllowed]
  );

  return [isAllowed, withPermission];
};
