import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { stringify } from 'qs';
import omit from 'lodash/omit';

import { toast } from '@/core/services/toast';
import { httpClient } from '@/core/services/http-client';
import { getEnv, CommonError, formatDateToISOString, DateType } from '@/common/utils';
import {
  RatingContentTypeDictionaryVM,
  RatingContentTypeListResponse,
  ReviewApplicationsDictionaryVM,
  ReviewApplicationsListResponse,
  ReviewListElementVM,
  ReviewListRequest,
  ReviewListResponse,
  ReviewListSearchByOptionListResponse,
  ReviewListSearchByOptionListVM,
  ReviewListVM,
  ReviewStatusDictionaryVM,
  ReviewStatusListResponse,
  ReviewTargetTypesDictionaryVM,
  ReviewTargetTypesListResponse,
} from '@/models/reviews';

const config = getEnv();

const REVIEW_LIST_ENDPOINT = '/rating-and-review-api/v1/admin/ratings/search';
const REVIEW_STATUS_ENDPOINT = `${config.REACT_APP_API_URL}/rating-and-review-api/v1/admin/ratings/validation-statuses`;
const RATING_CONTENT_TYPE_ENDPOINT = `${config.REACT_APP_API_URL}/rating-and-review-api/v1/admin/ratings/rating-content-types`;
const REVIEW_TARGET_TYPES_ENDPOINT = `${config.REACT_APP_API_URL}/rating-and-review-api/v1/admin/ratings/target-types`;
const REVIEW_APPLICATIONS_ENDPOINT = `${config.REACT_APP_API_URL}/rating-and-review-api/v1/admin/ratings/applications`;
const REVIEW_LIST_SEARCH_BY_OPTIONS_ENDPOINT = `${config.REACT_APP_API_URL}/rating-and-review-api/v1/admin/ratings/target-types`;

export const getReviewsListData = (payload: ReviewListRequest) => {
  if (payload.currentRegionUrl === undefined) {
    return of(new CommonError());
  }

  const param = {
    ...payload,
    dateFrom: payload.dateFrom ? formatDateToISOString(payload.dateFrom, DateType.FROM) : undefined,
    dateTo: payload.dateTo ? formatDateToISOString(payload.dateTo, DateType.TO) : undefined,
    authorId: payload.authorId?.value,
  };

  return httpClient()
    .authorized.post<ReviewListResponse>(`${param.currentRegionUrl}${REVIEW_LIST_ENDPOINT}`, param, {
      params: {
        ...omit(param, [
          'text',
          'authorId',
          'validationStatus',
          'targetType',
          'application',
          'rating',
          'activeModerationRequestIds',
          'reported',
          'ratingContentTypes',
        ]),
      },
      paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' }),
    })
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new ReviewListVM({ data, VM: ReviewListElementVM });
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

export const getReviewStatusData = () =>
  httpClient()
    .authorized.get<ReviewStatusListResponse>(REVIEW_STATUS_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) return new ReviewStatusDictionaryVM(data);
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getRatingContentTypeData = () =>
  httpClient()
    .authorized.get<RatingContentTypeListResponse>(RATING_CONTENT_TYPE_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) return new RatingContentTypeDictionaryVM(data);
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getReviewTargetTypesData = () =>
  httpClient()
    .authorized.get<ReviewTargetTypesListResponse>(REVIEW_TARGET_TYPES_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) return new ReviewTargetTypesDictionaryVM(data);
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getReviewApplicationsData = () =>
  httpClient()
    .authorized.get<ReviewApplicationsListResponse>(REVIEW_APPLICATIONS_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) return new ReviewApplicationsDictionaryVM(data);
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getReviewListSearchByOptionsData = () =>
  httpClient()
    .authorized.get<ReviewListSearchByOptionListResponse>(REVIEW_LIST_SEARCH_BY_OPTIONS_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new ReviewListSearchByOptionListVM(data);
        }
        throw undefined;
      }),
      catchError(e => {
        toast.show({ type: 'error', text: 'error.searchByOptions' });
        return of(new CommonError({ code: '500', message: e }));
      })
    );
