import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';

const USER_INFO_WIDTH = 250;

export const userInfoStyles: StyleFunction = ({ theme }) => ({
  width: USER_INFO_WIDTH,
  margin: `${constants.offset.small}px ${constants.offset.xsmall}px`,

  '.info-top': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: constants.offset.medium,

    '& > *': {
      maxWidth: '50%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      alignItems: 'start',
    },
  },

  '.organisation, .email': {
    color: theme.textPrimary,
  },

  '.info-bottom': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '.avatar': {
      marginRight: constants.offset.medium,
    },

    '.user-name': {
      fontWeight: 600,
      color: theme.primary,
    },

    '.user-info': {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',

      '& > *': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        alignItems: 'start',
      },

      '.loader': {
        alignItems: 'start',
        minHeight: 21,
      },
    },
  },
});
