import { createReducer } from 'typesafe-actions';

import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { OrganisationDetailsVM } from '@/models/organisations';
import { FeatureStateType } from '../../helpers';
import { organisationsActions } from '../actions';

export type organisationDetailsState = FetchState<OrganisationDetailsVM>;

const INITIAL_STATE: organisationDetailsState = getFetchInitialState<OrganisationDetailsVM>();

const reducer = createReducer<organisationDetailsState, organisationsActions>(INITIAL_STATE)
  .handleAction(organisationsActions.details.request, (state, { payload }) => fetchReducerHelpers.request(INITIAL_STATE, state, payload))
  .handleAction(organisationsActions.details.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(organisationsActions.details.failure, (_, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, _, payload))
  .handleAction(organisationsActions.clearOrganisationForm, () => ({ ...INITIAL_STATE }));

export const organisationDetailsReducer = { details: reducer };
export type organisationDetailsReducer = FeatureStateType<typeof organisationDetailsReducer>;
