import { createSelector } from 'reselect';

import { ToursListVM } from '@/features/content/models/tours';
import { getDataStatus } from '@/common/utils';
import { toursReducer } from './reducers';

type ToursList = toursReducer['tours']['list'];
type MakeGetToursListData = ToursListVM['list'];
type MakeGetToursListPaginationData = ToursListVM['listPaginationData'] | undefined;
type MakeGetToursListLength = { length: number };
type MakeGetToursListFilters = ToursList['params'];

const getToursList = (state: toursReducer) => state.tours.list;

export const makeGetToursList = () =>
  createSelector<toursReducer, ToursList, MakeGetToursListData>(getToursList, ({ data }) => (data === undefined ? [] : data.list));

export const makeGetToursListLength = () =>
  createSelector<toursReducer, ToursList, MakeGetToursListLength>(getToursList, ({ data }) => ({ length: (data?.list || []).length }));

export const makeGetToursListStatus = () => createSelector<toursReducer, ToursList, ReturnType<typeof getDataStatus>>(getToursList, getDataStatus);

export const makeGetToursListPaginationData = () =>
  createSelector<toursReducer, ToursList, MakeGetToursListPaginationData>(getToursList, ({ data }) => data?.listPaginationData);

export const makeGetToursListFilters = () => createSelector<toursReducer, ToursList, MakeGetToursListFilters>(getToursList, ({ params }) => params);

const getTourDetails = (state: toursReducer) => state.tours.details;

type TourDetails = toursReducer['tours']['details'];
type MakeGetTourDetailsData = TourDetails['data'];

export const makeGetTourDetailsData = () => createSelector<toursReducer, TourDetails, MakeGetTourDetailsData>(getTourDetails, ({ data }) => data);
export const makeGetTourDetailsStatus = () => createSelector<toursReducer, TourDetails, ReturnType<typeof getDataStatus>>(getTourDetails, getDataStatus);

const getStoryDetails = (state: toursReducer) => state.tours.story;

type StoryDetails = toursReducer['tours']['story'];
type MakeGetStoryDetailsData = StoryDetails['data'];

export const makeGetStoryDetailsStatus = () => createSelector<toursReducer, StoryDetails, ReturnType<typeof getDataStatus>>(getStoryDetails, getDataStatus);
export const makeGetStoryDetailsData = () => createSelector<toursReducer, StoryDetails, MakeGetStoryDetailsData>(getStoryDetails, ({ data }) => data);

type PricesDictionary = toursReducer['tours']['prices'];
type MakeGePricesDictionaryData = toursReducer['tours']['prices']['data'];

const getPricesDictionary = (state: toursReducer) => state.tours.prices;

export const makeGetPricesDictionaryData = () =>
  createSelector<toursReducer, PricesDictionary, MakeGePricesDictionaryData>(getPricesDictionary, ({ data }) => data);

export const makeGetPricesDictionaryStatus = () =>
  createSelector<toursReducer, PricesDictionary, ReturnType<typeof getDataStatus>>(getPricesDictionary, getDataStatus);

const getTourThumbnail = (state: toursReducer) => state.tours.thumbnail;

type TourThumbnail = toursReducer['tours']['thumbnail'];
type MakeGetTourThumbnailData = TourThumbnail['data'];

export const makeGetTourThumbnailData = () => createSelector<toursReducer, TourThumbnail, MakeGetTourThumbnailData>(getTourThumbnail, ({ data }) => data);
export const makeGetTourThumbnailStatus = () => createSelector<toursReducer, TourThumbnail, ReturnType<typeof getDataStatus>>(getTourThumbnail, getDataStatus);

type TourStatusesDictionary = toursReducer['tours']['statuses'];
type MakeGetTourStatusesDictionaryData = toursReducer['tours']['statuses']['data'];

const getTourStatusesDictionary = (state: toursReducer) => state.tours.statuses;

export const makeGetTourStatusesDictionaryData = () =>
  createSelector<toursReducer, TourStatusesDictionary, MakeGetTourStatusesDictionaryData>(getTourStatusesDictionary, ({ data }) => data);

export const makeGetTourStatusesDictionaryStatus = () =>
  createSelector<toursReducer, TourStatusesDictionary, ReturnType<typeof getDataStatus>>(getTourStatusesDictionary, getDataStatus);

type TourTypesDictionary = toursReducer['tours']['types'];
type MakeGetTourTypesDictionaryData = toursReducer['tours']['types']['data'];

const getTourTypesDictionary = (state: toursReducer) => state.tours.types;

export const makeGetTourTypesDictionaryData = () =>
  createSelector<toursReducer, TourTypesDictionary, MakeGetTourTypesDictionaryData>(getTourTypesDictionary, ({ data }) => data);

export const makeGetTourTypesDictionaryStatus = () =>
  createSelector<toursReducer, TourTypesDictionary, ReturnType<typeof getDataStatus>>(getTourTypesDictionary, getDataStatus);
