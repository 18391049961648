import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import omit from 'lodash/omit';
import { stringify } from 'qs';

import { httpClient } from '@/core/services/http-client';
import { CommonError } from '@/common/utils';
import { ModerationElementVM, ModerationListResponse, ModerationListVM, ModerationParams } from '@/models/moderation';

const CONTENT_MODERATION_LIST_ENDPOINT = '/rating-and-review-api/v1/admin/moderation-requests/search';

export const getModerationData = (data: ModerationParams) => {
  const { name, currentRegionUrl, ...payload } = data;

  if (currentRegionUrl === undefined) {
    return of(new CommonError());
  }

  const payloadWithSort = {
    ...payload,
    sort: payload.sort && payload.sort[0],
  };

  return httpClient()
    .authorized.post<ModerationListResponse>(`${currentRegionUrl}${CONTENT_MODERATION_LIST_ENDPOINT}`, payloadWithSort, {
      params: { ...omit(payloadWithSort, ['reasons', 'statuses', 'contentTypes', 'contextTypes', 'name']) },
      paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' }),
    })
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new ModerationListVM({ data, VM: ModerationElementVM });
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};
