import { Styles, theme, constants } from '@/styles';

const CONTROL_HEIGHT = 27;

export const style: Styles = {
  '.drag-area': {
    maxHeight: CONTROL_HEIGHT,

    '&__text': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  },

  '.upload-button-wrapper': {
    paddingLeft: constants.offset.medium,
  },

  '.upload-button': {
    minHeight: CONTROL_HEIGHT,
    padding: 0,
  },

  '.upload-icon': {
    fontSize: constants.fontSize.h6,
    marginRight: constants.offset.small,
    marginLeft: constants.offset.small,
    paddingBottom: 0,
  },
};
