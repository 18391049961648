import { constants, rem } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const buttonStyles: StyleFunction = ({ theme }) => ({
  padding: `${constants.offset.small}px ${constants.offset.large}px`,
  borderRadius: constants.borderRadius.small,
  cursor: 'pointer',
  transition: 'box-shadow .15s ease-in, background-color .15s ease-in',
  fontWeight: 600,
  minWidth: 120,
  minHeight: 40,

  ':disabled': {
    cursor: 'not-allowed',
  },

  '&.primary': {
    color: theme.textWhite,
    border: `1px solid ${theme.primary}`,
    backgroundColor: theme.primary,

    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${theme.primaryFocus}`,
    },

    '&:hover:not(:disabled)': {
      backgroundColor: theme.primaryHover,
      border: `1px solid ${theme.primaryHover}`,
    },

    '&:disabled': {
      backgroundColor: theme.backgroundSecondaryDisabled,
      border: `1px solid ${theme.backgroundSecondaryDisabled}`,
    },

    '&:active:not(:disabled)': {
      backgroundColor: `${theme.primaryActive} !important`,
      border: `1px solid ${theme.primaryActive} !important`,
    },
  },

  '&.secondary': {
    color: theme.primary,
    backgroundColor: theme.backgroundPrimary,
    border: `1px solid ${theme.primary}`,

    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${theme.primaryFocus}`,
    },

    '&:hover:not(:disabled)': {
      backgroundColor: theme.backgroundPrimaryHover,
    },

    '&:disabled': {
      border: `1px solid ${theme.backgroundSecondaryDisabled}`,
      color: theme.textDisabled,
    },

    '&:active:not(:disabled)': {
      backgroundColor: `${theme.backgroundPrimaryActive} !important`,
    },
  },

  '&.error': {
    color: theme.textWhite,
    backgroundColor: theme.error,
    border: `1px solid ${theme.error}`,

    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${theme.errorFocus}`,
    },

    '&:hover:not(:disabled)': {
      backgroundColor: theme.errorHover,
      border: `1px solid ${theme.errorHover}`,
    },

    '&:disabled': {
      backgroundColor: theme.backgroundSecondaryDisabled,
      border: `1px solid ${theme.backgroundSecondaryDisabled}`,
    },

    '&:active:not(:disabled)': {
      backgroundColor: `${theme.errorActive} !important`,
      border: `1px solid ${theme.errorActive} !important`,
    },
  },

  '&.ghost_error': {
    color: theme.error,
    backgroundColor: 'transparent',
    padding: '0px 2px',
    fontSize: rem(constants.fontSize.h6),
    fontWeight: 400,
    minWidth: 0,
    minHeight: 0,

    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${theme.errorFocus}`,
    },

    '&:hover:not(:disabled)': {
      color: theme.errorHover,
    },

    '&:disabled': {
      color: theme.textDisabled,
    },

    '&:active:not(:disabled)': {
      color: `${theme.errorActive} !important`,
    },
  },

  '&.ghost_primary': {
    color: theme.primary,
    backgroundColor: 'transparent',
    padding: '0px 2px',
    fontSize: rem(constants.fontSize.h6),
    fontWeight: 400,
    minWidth: 0,
    minHeight: 0,

    '& > *': {
      color: theme.primary,
    },

    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${theme.primaryFocus}`,
    },

    '&:hover:not(:disabled)': {
      color: theme.primaryHover,

      '& > *': {
        color: theme.primaryHover,
      },
    },

    '&:disabled': {
      color: theme.textDisabled,

      '& > *': {
        color: theme.textDisabled,
      },
    },

    '&:active:not(:disabled)': {
      color: `${theme.primaryActive} !important`,

      '& > *': {
        color: `${theme.primaryActive} !important`,
      },
    },
  },

  '&.button-link': {
    display: 'inline-block',
    textDecoration: 'none',
    textAlign: 'center',
    lineHeight: '38px',
    padding: 0,
    minHeight: 'auto',

    ':focus': {
      outline: 'none',
      boxShadow: `0px 0px 0px 3px ${theme.primaryFocus}`,
    },

    '&--primary-disabled': {
      backgroundColor: theme.backgroundSecondaryDisabled,
      border: `1px solid ${theme.backgroundSecondaryDisabled}`,
      cursor: 'not-allowed',

      ':hover': {
        backgroundColor: `${theme.backgroundSecondaryDisabled} !important`,
        border: `1px solid ${theme.backgroundSecondaryDisabled} !important`,
      },
    },

    '&--secondary-disabled': {
      border: `1px solid ${theme.backgroundSecondaryDisabled}`,
      color: theme.textDisabled,
      cursor: 'not-allowed',

      ':hover': {
        backgroundColor: `${theme.backgroundPrimary} !important`,
      },
    },
  },
});
