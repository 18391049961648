import { switchMap, filter, map } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { getThingsToDoData } from '@/features/content/services/things-to-do/things-to-do-list';
import { getThingsToDoDetailsData } from '@/features/content/services/things-to-do/things-to-do-details';
import { getPlaceCategoriesData } from '@/features/content/services/dictionaries';
import { untilLocationChange, CommonError, dictionaryEffectHelper } from '@/common/utils';
import { thingsToDoReducer } from './reducers';
import { thingsToDoActions } from './actions';

const getThingsToDoList: Epic<thingsToDoActions, thingsToDoActions, thingsToDoReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(thingsToDoActions.list.request)),
    switchMap(() =>
      getThingsToDoData(state$.value.thingsToDo.list.params).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return thingsToDoActions.list.failure(response);
          }

          return thingsToDoActions.list.success(response);
        })
      )
    )
  );

const getThingsToDoDetails: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(thingsToDoActions.details.request)),
    switchMap(action =>
      getThingsToDoDetailsData(action.payload.params)
        .pipe(untilLocationChange(action$))
        .pipe(
          map(response => {
            if (response instanceof CommonError) {
              return thingsToDoActions.details.failure(response);
            }

            return thingsToDoActions.details.success(response);
          })
        )
    )
  );

const getPlaceCategoriesDictionary: Epic<thingsToDoActions, thingsToDoActions, thingsToDoReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(thingsToDoActions.placeCategories.request)),
    switchMap(() => {
      const state = state$.value.thingsToDo.placeCategories;
      return dictionaryEffectHelper(() => getPlaceCategoriesData(), state, thingsToDoActions.placeCategories);
    })
  ) as any;

export const thingsToDoEpics = [getThingsToDoList, getThingsToDoDetails, getPlaceCategoriesDictionary];
