import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/common/components/button';
import { Icon } from '@/common/components/icon';
import { useStyles } from '@/styles/hooks';
import { errorStyles } from './styles';

type ErrorProps = {
  title: string;
  description: string;
};

export const Error: FC<ErrorProps> = ({ title, description }) => {
  const [t] = useTranslation();
  const { styles } = useStyles(errorStyles);

  const reload = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <div css={styles}>
      <div className='error-wrapper'>
        <Icon name='mdi-alert-circle' className='icon-element' />

        <div className='header'>{title}</div>
        <div className='description'>{description}</div>

        <Button className='button' theme='error' onClick={reload}>
          {t('button.reload')}
        </Button>
      </div>
    </div>
  );
};
