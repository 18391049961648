import { Store } from 'redux';

import { authenticationStorageEvents } from '../authentication/storage';
import { i18nStorageEvents } from '../i18n/storage';

const EVENT_NAME = 'storage';

export const createStorageManagement = (store: Store) => {
  const listener = (event: StorageEvent) => {
    authenticationStorageEvents({ event, store });
    i18nStorageEvents({ event, store });
  };

  const init = () => window.addEventListener(EVENT_NAME, listener);
  const clear = () => window.removeEventListener(EVENT_NAME, listener);

  return {
    init,
    clear,
  };
};
