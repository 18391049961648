import React, { FC, TextareaHTMLAttributes } from 'react';
import cn from 'classnames';

import { useStyles } from '@/styles/hooks';
import { Field, FieldProps } from '../field';
import { textAreaStyles } from './styles';
import { Textarea } from './textarea';

type FieldTextareaProps = Omit<FieldProps<TextareaHTMLAttributes<HTMLTextAreaElement>>, 'children'>;

export const FieldTextarea: FC<FieldTextareaProps> = ({ name, label, labelIcon, isFocusOnLabelEnabled, className, ...props }) => {
  const { styles } = useStyles(textAreaStyles);
  return (
    <Field name={name} label={label} labelIcon={labelIcon} isFocusOnLabelEnabled={isFocusOnLabelEnabled} className={className}>
      {({ field, meta }) => {
        const inputClassName = cn([{ error: meta.touched && meta.error }]);
        return <Textarea css={styles} {...props} className={inputClassName} {...field} />;
      }}
    </Field>
  );
};
