import { Styles, constants } from '@/styles';

export const styles: Styles = {
  display: 'flex',
  flexDirection: 'row',

  '.unit-input': {
    borderRadius: `${constants.borderRadius.small}px 0 0 ${constants.borderRadius.small}px`,
    MozAppearance: 'textfield',
    borderRight: 'none',

    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
};
