import { Enum } from '@/common/utils';
import { RoutePaths } from '../../navigation.models';
import { authenticatedRoutePaths } from './authenticated';

export const ReportingRoutes = Enum('TOUR_ANALYTICS', 'ROUTE_ANALYTICS');
export type ReportingRoutes = Enum<typeof ReportingRoutes>;

export const reportingPaths: RoutePaths<ReportingRoutes> = {
  [ReportingRoutes.TOUR_ANALYTICS]: `${authenticatedRoutePaths.REPORTING}/tour-analytics`,
  [ReportingRoutes.ROUTE_ANALYTICS]: `${authenticatedRoutePaths.REPORTING}/route-analytics`,
};
