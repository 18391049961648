import { Epic } from 'redux-observable';
import { switchMap, filter, map } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { CommonError } from '@/common/utils';
import { getTourAnalyticsListData } from '@/features/reporting/services';
import { tourAnalyticsActions } from './actions';
import { tourAnalyticsReducer } from './reducers';

const getTourAnalyticsList: Epic<tourAnalyticsActions, tourAnalyticsActions, tourAnalyticsReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(tourAnalyticsActions.list.request)),
    switchMap(() =>
      getTourAnalyticsListData(state$.value.tourAnalytics.list.params).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return tourAnalyticsActions.list.failure(response);
          }
          return tourAnalyticsActions.list.success(response);
        })
      )
    )
  );

export const tourAnalyticsEpic = [getTourAnalyticsList];
