import { combineReducers } from 'redux';

import { FeatureStateType } from '@/store/helpers';
import { tourStatusesDictionaryReducer } from './statuses';
import { toursListReducer } from './list';
import { storyDetailsReducer } from './story';
import { tourDetailsReducer } from './details';
import { tipsDictionaryReducer } from './tips';
import { pricesDictionaryReducer } from './prices';
import { tourThumbnailReducer } from './thumbnail';
import { tourTypeDictionaryReducer } from './types';

export type toursReducer = FeatureStateType<typeof toursReducer>;

export const toursReducer = {
  tours: combineReducers({
    ...toursListReducer,
    ...storyDetailsReducer,
    ...tourDetailsReducer,
    ...tipsDictionaryReducer,
    ...pricesDictionaryReducer,
    ...tourThumbnailReducer,
    ...tourStatusesDictionaryReducer,
    ...tourTypeDictionaryReducer,
  }),
};
