import { Action, ActionType, createAction } from 'typesafe-actions';

import { createFetchActions } from '@/common/utils/store';
import { RouteAnalyticsListVM, RouteAnalyticsParams } from '@/features/reporting/models/route-analytics';

export const routeAnalyticsActions = {
  list: createFetchActions('FETCH_ROUTE_ANALYTICS_REQUEST', 'FETCH_ROUTE_ANALYTICS_SUCCESS', 'FETCH_ROUTE_ANALYTICS_FAILURE')<
    RouteAnalyticsParams,
    RouteAnalyticsListVM
  >(),
  clearRouteAnalyticsList: createAction('CLEAR_ROUTE_ANALYTICS_LIST')(),
};

export type routeAnalyticsActions = Action | ActionType<typeof routeAnalyticsActions>;
