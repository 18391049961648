import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const toolbarStyles: StyleFunction = ({ theme }) => ({
  display: 'flex',
  minHeight: 35,
  borderTopLeftRadius: constants.borderRadius.small,
  borderTopRightRadius: constants.borderRadius.small,
  backgroundColor: theme.borderPrimary,
  border: `1px solid ${theme.borderThird}`,
  borderBottomWidth: 0,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,

  '.action-button': {
    height: 'auto',
    padding: `0 ${constants.offset.xsmall}px`,
  },

  '.icon': {
    transform: 'rotate(125deg)',
    fontSize: 20,
  },
});
