import React, { FC, HTMLAttributes } from 'react';

import { Icon } from '@/common/components/icon';
import { Tooltip, TooltipProps } from '@/common/components/tooltip';
import { useStyles } from '@/styles/hooks';
import { filterWrapperStyle } from './styles';

type FilterWrapperProps = HTMLAttributes<HTMLElement> & {
  label: string;
  tooltip?: TooltipProps;
};
export const FilterWrapper: FC<FilterWrapperProps> = ({ label, tooltip, children }) => {
  const { styles } = useStyles(filterWrapperStyle);
  return (
    <div css={styles}>
      <div className='filter-label'>
        {label}
        {tooltip && (
          <Tooltip placement={'right-end'} {...tooltip}>
            <Icon name='mdi-information' className='label-icon' />
          </Tooltip>
        )}
      </div>
      <div className='filter-wrapper'>{children}</div>
    </div>
  );
};
