import { createReducer } from 'typesafe-actions';

import { OrganisationStatusDictionaryVM } from '@/models/organisations';
import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { FeatureStateType } from '../../helpers';
import { organisationsActions } from '../actions';

type statusState = DictionaryState<OrganisationStatusDictionaryVM>;

const INITIAL_STATE: statusState = getDictionaryInitialState<OrganisationStatusDictionaryVM>();

const reducer = createReducer<statusState, organisationsActions>(INITIAL_STATE)
  .handleAction(organisationsActions.statusesDictionary.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(organisationsActions.statusesDictionary.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(organisationsActions.statusesDictionary.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(organisationsActions.statusesDictionary.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(organisationsActions.statusesDictionary.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const statusDictionaryReducer = { statuses: reducer };
export type statusDictionaryReducer = FeatureStateType<typeof statusDictionaryReducer>;
