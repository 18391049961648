import { createReducer } from 'typesafe-actions';

import { TourThumbnailVM } from '@/features/content/models/tours';
import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { FeatureStateType } from '../../helpers';
import { toursActions } from '../actions';

export type tourThumbnailState = FetchState<TourThumbnailVM>;

const INITIAL_STATE: tourThumbnailState = getFetchInitialState<TourThumbnailVM>();

const reducer = createReducer<tourThumbnailState, toursActions>(INITIAL_STATE)
  .handleAction(toursActions.thumbnail.request, state => fetchReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(toursActions.thumbnail.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(toursActions.thumbnail.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(toursActions.clearTourDetails, () => ({ ...INITIAL_STATE }));

export const tourThumbnailReducer = { thumbnail: reducer };
export type tourThumbnailReducer = FeatureStateType<typeof tourThumbnailReducer>;
