import { ContentFragmentTypes } from './story-content-fragments';

type StoryEntityResponse = {
  address: {
    locality: string;
    streetName: string;
  };
  contentFragments: ContentFragmentTypes[];
  coverImage: {
    id: string;
    sizeOriginal: number;
    sizeResized: number;
    type: string;
    urlOriginal: string;
    urlResized: string;
  };
  descriptionLong: string;
  descriptionShort: string;
  id: string;
  tourId: string;
  location: {
    coordinates: number[];
    type: string;
  };
  name: string;
  storyNumber: number;
  whatNext: string;
  type: string;
};

export interface StoryDetailsVM extends StoryEntityResponse {}

export class StoryDetailsVM {
  constructor(props: StoryEntityResponse) {
    Object.assign(this, props);
  }
}

export type AddStoryResponse = {
  id?: string;
};
