import { switchMap, filter } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { dictionaryEffectHelper } from '@/common/utils';
import { getRegionsList } from '@/core/services/data-center-switcher';
import { dataCenterSwitcherActions } from './actions';

const getRegions: Epic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(dataCenterSwitcherActions.regions.request)),
    switchMap(() => {
      const state = state$.value;
      return dictionaryEffectHelper(() => getRegionsList(), state, dataCenterSwitcherActions.regions);
    })
  );

export const dataCenterSwitcherEpics = [getRegions];
