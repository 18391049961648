import React, { FC, useCallback, useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import isEqual from 'lodash/isEqual';

import { useInstanceValue } from '@/common/utils/functions';
import { KEY_CODE_ENTER } from '@/common/utils/key-codes';
import { postponeEmission } from '@/common/utils/rxjs';
import {
  ClearableInput,
  ClearableInputValue,
  OnClearableInputChangeFunc,
  OnClearableInputKeyDownFunc,
} from '@/common/components/form-controls-deprecated/clearable-input';
import { Boundaries, OnRangeFilterChangeFunc, Range, RangeValue } from './types';
import { style } from './styles';

const DEBOUNCE_TIME = 200;

export type SanitizeInputValueFunc = (value: string | undefined, prev: RangeValue, key: keyof RangeValue) => string;

export type RangeFilterProps = {
  value: RangeValue;
  onChange: OnRangeFilterChangeFunc;
  disabled: boolean;
  placeholders: Range<string>;
  boundaries?: Partial<Range<Partial<Boundaries>>>;
  sanitizeInputValue?: SanitizeInputValueFunc;
};

export const RangeFilter: FC<RangeFilterProps> = ({ value, onChange, sanitizeInputValue, placeholders, disabled, boundaries }) => {
  const [innerValue, setInnerValue] = useState<RangeValue>(value);

  const onChange$ = useInstanceValue(() => new Subject<RangeValue>());
  const preventSubmit$ = useInstanceValue(() => new Subject<boolean>());

  const handleOnKeyDown = useCallback<OnClearableInputKeyDownFunc>(
    event => {
      switch (event.which || event.keyCode) {
        case KEY_CODE_ENTER:
          return handleOnBlur();

        default:
          break;
      }
    },
    [innerValue]
  );
  const handleOnFocus = useCallback(() => preventSubmit$.current.next(true), []);
  const handleOnBlur = useCallback(() => onChange$.current.next(innerValue), [innerValue]);

  const handleChange = useCallback(
    (key: keyof RangeValue, value: ClearableInputValue) => {
      const newValue = sanitizeInputValue ? sanitizeInputValue(value, innerValue, key) : value;

      setInnerValue(oldInnerState => ({ ...oldInnerState, [key]: newValue !== '' ? Number(newValue) : undefined }));
    },
    [sanitizeInputValue, innerValue]
  );

  const handleStartChange = useCallback<OnClearableInputChangeFunc>(
    (value, changeType) => changeType === 'CHANGE' && handleChange('start', value),
    [handleChange]
  );

  const handleEndChange = useCallback<OnClearableInputChangeFunc>((value, changeType) => changeType === 'CHANGE' && handleChange('end', value), [handleChange]);

  const handleStartClear = useCallback(() => {
    preventSubmit$.current.next(true);

    let previous;
    setInnerValue(oldInnerState => {
      previous = oldInnerState.start;
      return { ...oldInnerState, start: undefined };
    });

    if (previous !== undefined || innerValue.end !== undefined) {
      onChange$.current.next({ end: innerValue.end, start: undefined });
    }
  }, [innerValue]);

  const handleEndClear = useCallback(() => {
    preventSubmit$.current.next(true);

    let previous;
    setInnerValue(oldInnerState => {
      previous = oldInnerState.end;
      return { ...oldInnerState, end: undefined };
    });

    if (previous !== undefined || innerValue.start !== undefined) {
      onChange$.current.next({ start: innerValue.start, end: undefined });
    }
  }, [innerValue]);

  useEffect(() => {
    if (!isEqual(innerValue, value)) {
      setInnerValue(value);
    }
  }, [value]);

  useEffect(() => {
    const subscription = onChange$.current.pipe(postponeEmission(DEBOUNCE_TIME, preventSubmit$.current)).subscribe(value => onChange(value));

    return () => subscription.unsubscribe();
  }, [onChange]);

  return (
    <div css={style}>
      <ClearableInput
        disabled={disabled}
        value={innerValue.start ?? ''}
        placeholder={placeholders.start}
        onChange={handleStartChange}
        onClear={handleStartClear}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        onKeyDown={handleOnKeyDown}
        type='number'
        {...boundaries?.start}
      />
      <ClearableInput
        disabled={disabled}
        value={innerValue.end ?? ''}
        placeholder={placeholders.end}
        onChange={handleEndChange}
        onClear={handleEndClear}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        onKeyDown={handleOnKeyDown}
        type='number'
        {...boundaries?.end}
      />
    </div>
  );
};
