import React, { FC, useCallback } from 'react';
import { Field, FieldProps } from 'formik';

import { OnSelectChangeFunc, Tabs, TabsProps } from '../../tabs';

type Props = TabsProps & {
  name: string;
};

export const FieldTabs: FC<Props> = ({ name, children, onSelectChange, ...tabProps }) => {
  const TabsComponent = ({ form }: FieldProps) => {
    const handleSelectChange = useCallback<OnSelectChangeFunc>(
      (index: number, last: number, event: Event) => {
        form.setFieldValue(name, index);
        onSelectChange?.(index, last, event);
      },
      [form, onSelectChange]
    );

    return (
      <Tabs {...tabProps} onSelectChange={handleSelectChange}>
        {children}
      </Tabs>
    );
  };

  return <Field name={name}>{TabsComponent}</Field>;
};
