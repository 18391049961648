import React, { FC, useContext } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { Icon } from '@/common/components/icon';
import { useStyles } from '@/styles/hooks';
import { CardList } from '../editor-cards';
import { DisabledStoryContentEditorContext } from '../story-content-field/disabled-story-content-editor-context';
import { EditorToolbarCard } from './components/editor-toolbar-card';
import { editorToolbarStyle } from './styles';

export const TOOLBAR_ID = 'TOOLBAR';

type Props = {};

export const EditorToolbar: FC<Props> = () => {
  const [t] = useTranslation();
  const { styles } = useStyles(editorToolbarStyle);

  const isDisabled = useContext(DisabledStoryContentEditorContext);

  return (
    <div css={styles}>
      <Droppable droppableId={TOOLBAR_ID} isDropDisabled={true}>
        {provided => (
          <div ref={provided.innerRef} className='content'>
            {CardList.map((card, index) => {
              return (
                <Draggable key={card.type} draggableId={card.type} index={index} isDragDisabled={false}>
                  {(provided, snapshot) => <EditorToolbarCard card={card} provided={provided} snapshot={snapshot} disabled={isDisabled} />}
                </Draggable>
              );
            })}
          </div>
        )}
      </Droppable>

      <div className='tooltip'>
        {`${t('storyContentEditor.tooltip.firstText')} '`}
        <span>{t('storyContentEditor.tooltip.secondText')}</span>
        {"'"}
        <Icon name='mdi-arrow-expand-up' className='tooltip__icon' /> {`${t('storyContentEditor.tooltip.thirdText')} '`}
        <span>{t('storyContentEditor.tooltip.forthText')}</span>
        {`' ${t('storyContentEditor.tooltip.fifthText')}`}
        <Icon name='mdi-arrow-collapse-right' className='tooltip__icon' /> {t('storyContentEditor.tooltip.sixthText')}
      </div>

      <div className='tooltip'>
        {`${t('storyContentEditor.tooltip.seventhText')} '`}
        <span>{t('storyContentEditor.tooltip.eightText')}</span>
        {`' ${t('storyContentEditor.tooltip.ninthText')}`}
        <Icon name='mdi-view-headline' className='tooltip__icon' /> {t('storyContentEditor.tooltip.tenthText')}
      </div>
    </div>
  );
};
