import { defer, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import omit from 'lodash/omit';
import isNil from 'lodash/isNil';

import { httpClient } from '@/core/services/http-client';
import { getEnv, CommonError, Enum } from '@/common/utils';
import { StoryImageUploadResponse } from '@/features/content/models/tours';
import { CustomFile } from '@/common/components/form-controls-deprecated/file-input';
import { AddStoryResponse, StoryDetailsVM } from '@/models/tours';
import { ControlListDefs } from '../../pages/story-form/components/form';
import { parseFromEditorData } from '../../pages/story-form/components/story-content-editor';

const config = getEnv();

const STORY_COVER_UPLOAD_ENDPOINT = `${config.REACT_APP_API_URL}/media-access-api/v2/images/stories`;
const ADD_STORY_ENDPOINT = `${config.REACT_APP_API_URL}/story-api/v1/admin/stories`;
const EDIT_STORY_ENDPOINT = `${config.REACT_APP_API_URL}/story-api/v1/admin/stories`;
const STORY_DATA_ENDPOINT = `${config.REACT_APP_API_URL}/story-api/v1/admin/stories`;
const DELETE_STORY_ENDPOINT = `${config.REACT_APP_API_URL}/story-api/v1/admin/stories`;

type UploadStoryCoverParams = { files: File[] | CustomFile[] | undefined };

const uploadStoryCover = ({ files }: UploadStoryCoverParams) => {
  if (files !== undefined) {
    const formData = new FormData();
    formData.append('file', files[0] as Blob);
    formData.append('metadata', new Blob(['{}'], { type: 'application/json' }));
    return httpClient()
      .authorized.post<StoryImageUploadResponse>(STORY_COVER_UPLOAD_ENDPOINT, formData)
      .pipe(
        map(({ data, status }) => {
          if (status === 200 || status === 201 || (status === 202 && data !== undefined)) {
            return data;
          }
          throw undefined;
        }),
        catchError(e => of(new CommonError({ code: '500', message: e })))
      );
  }
};

export type CreateStoryParams = { tourId: string; data: ControlListDefs; fileData?: StoryImageUploadResponse };

const addStory = ({ tourId, data, fileData }: CreateStoryParams) => {
  const detailsData = {
    ...prepareStoryData(data),
    coverImageId: fileData ? fileData.id : data.coverImageId,
  };

  return httpClient()
    .authorized.post<undefined>(ADD_STORY_ENDPOINT, { ...detailsData, tourId })
    .pipe(
      map(({ data, status }) => {
        if (status === 200 || status === 201 || status === 202) {
          return data;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

const prepareStoryData = (data: ControlListDefs) => {
  return {
    ...omit(data, ['streetName', 'latitude', 'longitude', 'storyDetails']),
    address: {
      streetName: data.streetName,
    },
    location: {
      type: 'Point',
      coordinates: [
        typeof data.longitude === 'number' ? data.longitude.toFixed(7) : parseFloat(data.longitude).toFixed(7),
        typeof data.latitude === 'number' ? data.latitude.toFixed(7) : parseFloat(data.latitude).toFixed(7),
      ],
    },
    contentFragments: parseFromEditorData(data.storyDetails),
  };
};

export type AddStoryEntityParams = CreateStoryParams & UploadStoryCoverParams;
export const CreateStoryResponseType = Enum('successful', 'uploadCoverError', 'uploadStoryError', 'streetAddressError');
export type CreateStoryResponseType = { msg: Enum<typeof CreateStoryResponseType>; response?: AddStoryResponse };

export const addStoryEntity = ({ data, files, tourId }: AddStoryEntityParams): Observable<CreateStoryResponseType> =>
  defer(() => {
    if (!isNil(files) && files[0] instanceof File) {
      return uploadStoryCover({ files });
    }

    return of(undefined);
  }).pipe(
    switchMap(response => {
      if (response instanceof CommonError) {
        return of({ msg: CreateStoryResponseType.uploadCoverError });
      }

      return addStory({ tourId, data, fileData: response }).pipe(
        map(response => {
          if (response instanceof CommonError) {
            if (response.code === 'OSCP-002') {
              return { msg: CreateStoryResponseType.streetAddressError };
            }
            return { msg: CreateStoryResponseType.uploadStoryError };
          }

          return { response, msg: CreateStoryResponseType.successful };
        })
      );
    })
  );

export type EditStoryParams = {
  storyId: string;
  data: ControlListDefs;
  fileData?: StoryImageUploadResponse | CustomFile;
};

const editStory = ({ storyId, data, fileData }: EditStoryParams) => {
  const customFile = data.coverImageId ? (data.coverImageId[0] as CustomFile) : undefined;

  const detailsData = {
    ...prepareStoryData(data),
    coverImageId: fileData ? fileData.id : customFile ? customFile.id : undefined,
  };

  return httpClient()
    .authorized.put<undefined>(`${EDIT_STORY_ENDPOINT}/${storyId}`, { ...detailsData })
    .pipe(
      map(response => {
        if (response.status === 200 || response.status === 201 || response.status === 202) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

export type EditStoryEntityParams = EditStoryParams & UploadStoryCoverParams;

export const editStoryEntity = ({ data, storyId, files }: EditStoryEntityParams): Observable<CreateStoryResponseType> =>
  defer(() => {
    if (!isNil(files) && files[0] instanceof File) {
      return uploadStoryCover({ files });
    }

    return of(undefined);
  }).pipe(
    switchMap(response => {
      if (response instanceof CommonError) {
        return of({ msg: CreateStoryResponseType.uploadCoverError });
      }

      return editStory({ data, storyId, fileData: response }).pipe(
        map(response => {
          if (response instanceof CommonError) {
            if (response.code === 'OSCP-002') {
              return { msg: CreateStoryResponseType.streetAddressError };
            }
            return { msg: CreateStoryResponseType.uploadStoryError };
          }

          return { msg: CreateStoryResponseType.successful };
        })
      );
    })
  );

export const getStoryDetailsData = (storyId: string) =>
  httpClient()
    .authorized.get<any>(`${STORY_DATA_ENDPOINT}/${storyId}`)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new StoryDetailsVM(data);
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: e.code, message: e.errorMessage })))
    );

export const deleteStory = (storyId: string) =>
  httpClient()
    .authorized.delete<any>(`${DELETE_STORY_ENDPOINT}/${storyId}`)
    .pipe(
      map(({ status }) => {
        if (status === 200) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => {
        return of(new CommonError({ code: '500', message: e }));
      })
    );
