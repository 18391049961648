import React, { createContext, FC, useCallback, useContext, useState } from 'react';
import { FieldArrayRenderProps } from 'formik';
import { DragDropContext, DragDropContextProps } from 'react-beautiful-dnd';

import { ContentFragmentType } from '@/models/tours';
import { ACCORDION_ALLOWED_CARDS, ACCORDION_CARD_ID_PREFIX } from '../editor-cards/accordion-card';
import { LIST_ALLOWED_CARDS, LIST_CARD_ID_PREFIX } from '../editor-cards/list-card';
import { EditorToolbar, TOOLBAR_ID } from '../editor-toolbar';
import { EditorValue } from '../../models';
import { createCardId } from '../../services';
import { CardList } from '../editor-cards';
import { EditorContent } from '../editor-content';
import { style } from './styles';

type Props = FieldArrayRenderProps;

const cardDropAllowed = (droppableId: string, draggableId: ContentFragmentType): boolean => {
  if (droppableId.includes(ACCORDION_CARD_ID_PREFIX)) {
    return ACCORDION_ALLOWED_CARDS.includes(draggableId);
  }

  if (droppableId.includes(LIST_CARD_ID_PREFIX)) {
    return LIST_ALLOWED_CARDS.includes(draggableId);
  }

  return false;
};

export const EditorContainer: FC<Props> = fieldArrayRender => {
  const { move, insert } = fieldArrayRender;

  const arrayContext = useContext(EditorInnerArrayContext);
  const draggableContext = useContext(EditorDraggableContext);

  const onBeforeDragStart = useCallback(
    result => {
      draggableContext.setDraggedItem(result.draggableId);
    },
    [draggableContext]
  );

  const handleOnDragEnd = useCallback<DragDropContextProps['onDragEnd']>(
    result => {
      const { source, destination } = result;

      draggableContext.setDraggedItem(undefined);

      if (!destination) {
        return;
      }

      switch (source.droppableId) {
        case destination.droppableId:
          if (destination.droppableId.includes(ACCORDION_CARD_ID_PREFIX) || destination.droppableId.includes(LIST_CARD_ID_PREFIX)) {
            if (arrayContext.data[destination.droppableId]) {
              arrayContext.data[destination.droppableId].move(source.index, destination.index);
            }
          } else {
            move(source.index, destination.index);
          }
          break;

        case TOOLBAR_ID:
          const sourceClone = Array.from(CardList);
          const item = sourceClone[source.index];
          const newValue: EditorValue = { id: createCardId(item.type), card: item, payload: undefined };

          if (destination.droppableId.includes(ACCORDION_CARD_ID_PREFIX) || destination.droppableId.includes(LIST_CARD_ID_PREFIX)) {
            if (arrayContext.data[destination.droppableId] && cardDropAllowed(destination.droppableId, newValue.card.type)) {
              arrayContext.data[destination.droppableId].insert(destination.index, newValue);
            }
          } else {
            insert(destination.index, newValue);
          }
          break;

        default:
          break;
      }
    },
    [move, insert, arrayContext, draggableContext]
  );

  return (
    <div css={style}>
      <DragDropContext onDragEnd={handleOnDragEnd} onBeforeDragStart={onBeforeDragStart}>
        <EditorToolbar />

        <EditorContent fieldArrayRender={fieldArrayRender} />
      </DragDropContext>
    </div>
  );
};

export const EditorDroppableContext = createContext<{
  activeAccordions: string[];
  activeLists: string[];
  addActiveAccordion: (id: string) => void;
  removeActiveAccordion: (id: string) => void;
  addActiveList: (id: string) => void;
  removeActiveList: (id: string) => void;
}>({
  activeAccordions: [],
  activeLists: [],
  addActiveAccordion: (id: string) => {},
  removeActiveAccordion: (id: string) => {},
  addActiveList: (id: string) => {},
  removeActiveList: (id: string) => {},
});

export const EditorDraggableContext = createContext<{
  draggedItem: ContentFragmentType | undefined;
  setDraggedItem: (id: ContentFragmentType | undefined) => void;
}>({
  draggedItem: undefined,
  setDraggedItem: (id: ContentFragmentType | undefined) => {},
});

export const EditorInnerArrayContext = createContext<{
  data: {
    [key: string]: {
      insert: (index: number, value: any) => void;
      move: (index: number, value: any) => void;
    };
  };
  setData: (newData: any) => void;
}>({
  data: {},
  setData: (x: any) => {},
});

export const EditorDroppableContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [activeAccordions, setActiveAccordions] = useState<string[]>([]);
  const [activeLists, setActiveLists] = useState<string[]>([]);
  const [draggedItem, setDraggedItem] = useState<ContentFragmentType | undefined>(undefined);
  const [data, setData] = useState({});

  const addActiveAccordion = useCallback((id: string) => {
    setActiveAccordions(arr => Array.from(new Set([...arr, id])));
  }, []);

  const removeActiveAccordion = useCallback((id: string) => {
    setActiveAccordions(arr => arr.filter(activeId => activeId !== id));
  }, []);

  const addActiveList = useCallback((id: string) => {
    setActiveLists(arr => Array.from(new Set([...arr, id])));
  }, []);

  const removeActiveList = useCallback((id: string) => {
    setActiveLists(arr => arr.filter(activeId => activeId !== id));
  }, []);

  return (
    <EditorDroppableContext.Provider
      value={{
        activeAccordions,
        addActiveAccordion,
        removeActiveAccordion,
        activeLists,
        addActiveList,
        removeActiveList,
      }}
    >
      <EditorInnerArrayContext.Provider
        value={{
          data,
          setData,
        }}
      >
        <EditorDraggableContext.Provider
          value={{
            draggedItem,
            setDraggedItem,
          }}
        >
          {children}
        </EditorDraggableContext.Provider>
      </EditorInnerArrayContext.Provider>
    </EditorDroppableContext.Provider>
  );
};
