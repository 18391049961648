export const REQ_AUTHORIZATION_HEADER = 'Authorization';
export const TOKEN_ENDPOINT_SEGMENT = '/oauth2/v2.0/token';

export const COOKIE_ACCESS_TOKEN = 'authenticationAccessTokenKey';
export const COOKIE_REFRESH_TOKEN = 'authenticationRefreshTokenKey';
export const COOKIE_TOKEN_TYPE = 'authenticationTokenTypeKey';
export const COOKIE_EXPIRES_ON = 'authenticationExpiresOn';
export const COOKIE_EXPIRES_IN = 'authenticationExpiresIn';

export const APPLICATION_LOGOUT_STORAGE_KEY = 'applicationLogout';
export const APPLICATION_LOGOUT_STORAGE_VALUE = 'I7A3J}i"8fa123fv23v202vf323"Cjl+KS?DUu;A-wUr]';
export const APPLICATION_LOGIN_STORAGE_KEY = 'applicationLogin';
export const APPLICATION_LOGIN_STORAGE_VALUE = '4Z-Cv!`67jj}A67jCfZjPD>9<{G25h23X#)>q23fpC8';
