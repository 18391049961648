import { constants, Styles, theme } from '@/styles';

export const styles: Styles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  '.icon-wrapper': {
    width: 50,
    height: 50,
    backgroundColor: theme.backgroundPrimary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: constants.borderRadius.xlarge,

    '& > i': {
      fontSize: constants.fontSize.iconMedium,
      color: theme.textThird,
    },
  },

  '.text': {
    color: theme.textThird,
    marginTop: constants.offset.small,
  },
};

export const editorCardModeStyle: Styles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',

  '.icon-wrapper': {
    width: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& > i': {
      fontSize: constants.fontSize.icon,
      color: theme.textThird,
    },
  },

  '.text': {
    color: theme.textThird,
    marginLeft: constants.offset.small,
  },
};
