import { createReducer } from 'typesafe-actions';

import { OrganisationDictionaryVM } from '@/models/organisations';
import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { FeatureStateType } from '../../helpers';
import { organisationsActions } from '../actions';

type organisationsState = DictionaryState<OrganisationDictionaryVM>;

const INITIAL_STATE: organisationsState = getDictionaryInitialState<OrganisationDictionaryVM>();

const reducer = createReducer<organisationsState, organisationsActions>(INITIAL_STATE)
  .handleAction(organisationsActions.dictionary.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(organisationsActions.dictionary.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(organisationsActions.dictionary.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(organisationsActions.dictionary.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(organisationsActions.dictionary.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const organisationsDictionaryReducer = { dictionary: reducer };
export type organisationsDictionaryReducer = FeatureStateType<typeof organisationsDictionaryReducer>;
