import React, { FC } from 'react';
import cn from 'classnames';

import { useStyles } from '@/styles/hooks';
import { Icon } from '../icon';
import { Anchor, AnchorProps } from './anchor';
import { linkStyles } from './styles';

type Props = Partial<AnchorProps> & {
  iconName?: string;
  noDecoration?: boolean;
};

export const Link: FC<Props> = ({ children, className, iconName, href, to, noDecoration, ...props }) => {
  const { styles } = useStyles(linkStyles);

  return (
    <Anchor
      css={styles}
      to={to ?? href ?? ''}
      href={href}
      {...props}
      className={cn(['link', className, { 'no-decoration': noDecoration, 'with-icon': !!iconName }])}
    >
      {iconName && <Icon name={iconName} className='link-icon' />}
      {children && <span className='link-text'>{children}</span>}
    </Anchor>
  );
};
