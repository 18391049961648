import { ReactChild, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CheckUserPermissionScopes, checkUserPermissions } from '@/common/utils';
import { Scope } from '@/core/models/user-roles';
import { makeGetUserStatus } from '@/store/authentication';

export type TabType<K extends string> = {
  key: K;
  label: string;
  content: ReactChild;
};

export type TabWithPermissionType<K extends string, S extends keyof Scope = keyof Scope> = TabType<K> & {
  permission?: CheckUserPermissionScopes<S>;
};

export type MapTabNamesType<K extends string = string> = (tab: TabType<K>, index: number) => string;

const getUserStatus = makeGetUserStatus();

export const useTabs = <K extends string, S extends keyof Scope = keyof Scope>(tabs: TabWithPermissionType<K, S>[], mapNames?: MapTabNamesType<K>) => {
  const { roles } = useSelector(getUserStatus);
  const [t] = useTranslation();

  const map = useMemo<MapTabNamesType<K>>(() => mapNames || (tab => t(tab.label)), [mapNames]);

  const filtered = useMemo(() => tabs.filter(({ permission }) => (permission ? checkUserPermissions(permission, roles) : true)), [tabs, roles]);
  const tabNames = useMemo(() => filtered.map(map), [filtered, map]);
  const tabContents = useMemo(() => filtered.map(({ content }) => content), [filtered]);
  const isAnyTabPermitted = useMemo(() => !!filtered.length, [filtered]);

  return { tabNames, tabContents, isAnyTabPermitted };
};
