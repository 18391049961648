import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { httpClient } from '@/core/services/http-client';
import { getEnv, CommonError } from '@/common/utils';
import { RegionListResponse, RegionDictionaryVM, RegionElementVM, RegionEntityResponse } from '@/core/models/data-center-regions';

const config = getEnv();

const REGIONS_ENDPOINT = `${config.REACT_APP_API_URL}/user-account-api/v1/regions`;

export const getRegionsList = () =>
  httpClient()
    .authorized.get<RegionListResponse>(REGIONS_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new RegionDictionaryVM(data);
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getRegionDetails = (countryCode: string) =>
  httpClient()
    .authorized.get<RegionEntityResponse>(`${REGIONS_ENDPOINT}/${countryCode}`)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new RegionElementVM(data);
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
