import React, { ReactElement, FC } from 'react';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';

import { Icon } from '@/common/components/icon';
import { Spinner } from '@/common/components/spinner';
import { Enum } from '@/common/utils';
import { statusStyle } from './styles';

export type CommonEditorCardStatus = Enum<typeof CommonEditorCardStatus>;
export const CommonEditorCardStatus = Enum('SUCCESS', 'LOADING', 'ERROR', 'VALIDATION_ERROR');

export type CommonEditorCardStatusElement = {
  status: CommonEditorCardStatus;
  text: string;
};

const flattenErrorPayload = (error: unknown): string | undefined => {
  if (isString(error)) {
    return error;
  }

  if (isArray(error)) {
    return error
      .map(innerError => flattenErrorPayload(innerError))
      .filter(e => !!e)
      .join(', ');
  }

  return undefined;
};

export const CommonEditorCardStatusElement: FC<CommonEditorCardStatusElement> = ({ status, text }) => {
  let iconElement: ReactElement;
  switch (status) {
    case CommonEditorCardStatus.SUCCESS: {
      iconElement = <Icon className='status__icon' name='mdi-check-circle' />;
      break;
    }

    case CommonEditorCardStatus.LOADING: {
      iconElement = <Spinner />;
      break;
    }

    default: {
      iconElement = <Icon className='status__icon' name='mdi-alert-circle' />;
      break;
    }
  }
  return (
    <div css={statusStyle} className={`status status--${status}`}>
      {iconElement}
      <span className='status__text'>{flattenErrorPayload(text)}</span>
    </div>
  );
};
