import { ApiPagination, PageableListResponse, PageableListVM } from '@/common/utils/pageable';

type PlaceCategory = {
  code: string;
  name: string;
};

export type ThingsToDoTagsParams = ApiPagination<{
  displayName?: string;
  placeCategories?: string[];
}>;

type ThingsToDoTagsEntityResponse = {
  displayName: string;
  id: string;
  placeCategories: PlaceCategory[];
};

export type ThingsToDoTagsListResponse = PageableListResponse<ThingsToDoTagsEntityResponse>;

export interface ThingsToDoTagsElementVM extends ThingsToDoTagsEntityResponse {}
export class ThingsToDoTagsElementVM {
  constructor(props: ThingsToDoTagsEntityResponse) {
    Object.assign(this, props);
  }
}

export class ThingsToDoTagsListVM extends PageableListVM<ThingsToDoTagsElementVM, ThingsToDoTagsListResponse> {}

type PlaceCategoriesType = {
  code: string;
  name: string;
};

export type ThingsToDoTagDetailsResponse = {
  displayName: string;
  id: string;
  placeCategories: PlaceCategoriesType[];
};

export interface ThingsToDoTagDetailsVM extends ThingsToDoTagDetailsResponse {}

export class ThingsToDoTagDetailsVM {
  constructor({ placeCategories, ...props }: ThingsToDoTagDetailsResponse) {
    Object.assign(this, {
      ...props,
      placeCategories: placeCategories ? placeCategories : [],
    });
  }
}

export type UpdateThingsToDoTagDetailsParams = {
  displayName: string;
  placeCategories: string[];
};

export type CreateThingsToDoTagParams = UpdateThingsToDoTagDetailsParams;
