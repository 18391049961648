import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const iconButtonStyles: StyleFunction = ({ theme }) => ({
  backgroundColor: 'transparent',
  cursor: 'pointer',
  padding: 0,
  borderRadius: constants.borderRadius.small,
  display: 'flex',
  alignItems: 'center',
  height: 26,

  '&:focus': {
    '&.primary': {
      boxShadow: `0px 0px 0px 3px ${theme.primaryFocus}`,
    },

    '&.secondary': {
      boxShadow: `0px 0px 0px 3px ${theme.backgroundPrimaryFocus}`,
    },

    '&.error': {
      boxShadow: `0px 0px 0px 3px ${theme.errorFocus}`,
    },
  },

  '&:disabled .icon': {
    cursor: 'not-allowed',
    color: theme.textDisabled,

    '&:hover': {
      color: theme.textDisabled,
    },
  },

  '.icon': {
    fontSize: constants.fontSize.icon,
    transition: 'box-shadow .15s ease-in, color .15s ease-in',

    '&--primary': {
      color: theme.primary,

      '&:hover': {
        color: theme.primaryHover,
      },

      '&:active': {
        color: theme.primaryActive,
      },
    },

    '&--secondary': {
      color: theme.backgroundPrimary,

      '&:hover': {
        color: theme.backgroundPrimaryHover,
      },

      '&:active': {
        color: theme.backgroundPrimaryActive,
      },
    },

    '&--error': {
      color: theme.error,

      '&:hover': {
        color: theme.errorHover,
      },

      '&:active': {
        color: theme.errorActive,
      },
    },
  },

  '&.icon-button-link': {
    textDecoration: 'none',
    textAlign: 'center',
    padding: 0,

    '&--disabled': {
      color: `${theme.textDisabled} !important`,
      cursor: 'not-allowed',
    },

    ':focus': {
      outline: 'none',
      boxShadow: `0px 0px 0px 3px ${theme.primaryFocus}`,
    },
  },

  '.icon--disabled': {
    color: `${theme.textDisabled} !important`,
    cursor: 'not-allowed',
  },
});
