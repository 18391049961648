import { createReducer } from 'typesafe-actions';

import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { InvitationTypeDictionaryVM } from '@/models/invitations';
import { FeatureStateType } from '../../helpers';
import { invitationsActions } from '../actions';

type typesState = DictionaryState<InvitationTypeDictionaryVM>;

const INITIAL_STATE: typesState = getDictionaryInitialState<InvitationTypeDictionaryVM>();

const reducer = createReducer<typesState, invitationsActions>(INITIAL_STATE)
  .handleAction(invitationsActions.types.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(invitationsActions.types.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(invitationsActions.types.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(invitationsActions.types.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(invitationsActions.types.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const typesDictionaryReducer = { types: reducer };
export type typesDictionaryReducer = FeatureStateType<typeof typesDictionaryReducer>;
