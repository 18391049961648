export type ConsentDocumentEntityResponse = {
  documentId: string;
  documentType: string;
  endDate: string;
  mediaId: string;
  mediaUrl: {
    authority: string;
    content: {};
    defaultPort: number;
    file: string;
    host: string;
    path: string;
    port: 0;
    protocol: string;
    query: string;
    ref: string;
    userInfo: string;
  };
  startDate: string;
  version: string;
};

export type PerformConsent = {
  documentId: string;
  startDate: string;
  endDate: string;
};

export type ConsentDocumentListResponse = ConsentDocumentEntityResponse[];

export interface ConsentDocumentVM extends ConsentDocumentEntityResponse {}

export class ConsentDocumentVM {
  constructor({ ...props }: ConsentDocumentEntityResponse) {
    Object.assign(this, { ...props });
  }
}

export interface ConsentDocumentListVM {
  list: ConsentDocumentVM[];
}

export class ConsentDocumentListVM {
  constructor(list: ConsentDocumentListResponse) {
    this.list = (list || []).map(element => new ConsentDocumentVM(element));
  }
}
