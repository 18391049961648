import { Enum } from '@/common/utils';
import { RoutePaths } from '../../navigation.models';
import { authenticatedRoutePaths } from './authenticated';

export type dashboardRoutes = Enum<typeof dashboardRoutes>;
export const dashboardRoutes = Enum('SHORTCUTS');

export const dashboardRoutePaths: RoutePaths<dashboardRoutes> = {
  [dashboardRoutes.SHORTCUTS]: `${authenticatedRoutePaths.DASHBOARD}/shortcuts`,
};
