import { createContext, Dispatch } from 'react';

import { OnboardingActions, INITIAL_STATE, OnboardingState } from '../contextStore';

export const OnboardingContext = createContext<{ state: OnboardingState; dispatch: Dispatch<OnboardingActions> }>({
  state: INITIAL_STATE,
  dispatch: () => null,
});

export const OnboardingProvider = OnboardingContext.Provider;
