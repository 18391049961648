import { Enum } from '@/common/utils';
import { RoutePaths } from '../../navigation.models';
import { rootRoutePaths } from '../root';

export type unauthenticatedRoutes = Enum<typeof unauthenticatedRoutes>;
export const unauthenticatedRoutes = Enum('AUTHENTICATION');

export const unauthenticatedRoutePaths: RoutePaths<unauthenticatedRoutes> = {
  [unauthenticatedRoutes.AUTHENTICATION]: `${rootRoutePaths.UNAUTHENTICATED}`,
};
