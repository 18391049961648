import { i18n, Callback } from 'i18next';

import { APPLICATION_LNG_STORAGE_KEY } from './constants';

const createOverriddenChangeLanguage = (baseChangeLanguage: i18n['changeLanguage']) =>
  function overriddenChangeLanguage(this: i18n, lng: string, callback?: Callback, preventEmission?: boolean): ReturnType<i18n['changeLanguage']> {
    return baseChangeLanguage(lng, (error, t) => {
      if (preventEmission !== true) {
        window.localStorage.setItem(APPLICATION_LNG_STORAGE_KEY, lng);
      }

      callback && callback(error, t);
    });
  };

export type ChangeLanguage = ReturnType<typeof createOverriddenChangeLanguage>;

export const overrideChangeLanguage = (instance: i18n) => {
  const baseChangeLanguage = instance.changeLanguage.bind(instance);
  instance.changeLanguage = createOverriddenChangeLanguage(baseChangeLanguage);
  return instance as i18n & { changeLanguage: ChangeLanguage };
};
