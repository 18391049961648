import { createAction, EmptyActionCreator, PayloadActionCreator } from 'typesafe-actions';
import { of, Observable, concat, MonoTypeOperatorFunction } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { ApiPagination, DEFAULT_API_PAGINATION } from './pageable';
import { CommonError } from './common';

const DICTIONARY_FETCH_TRASHHOLD = 15 * 1000;
const DICTIONARY_SILENT_FETCH_TRASHHOLD = 5 * 60 * 1000;

const LOCATION_CHANGE_ACTION = '@@router/LOCATION_CHANGE';

export type FetchState<T = any, P extends ApiPagination = ApiPagination> = {
  isLoading: boolean;
  error: CommonError | undefined;
  data: T | undefined;
  params: P;
  prevParams: P | undefined;
  timestamp: number;
  collectionTotalCount: number;
};

export type FetchRequestParams<P = any> = { params: P; options?: { mergeWithPrevProps: boolean } };

export const getFetchInitialState = <T, P extends ApiPagination = ApiPagination>(initialParams?: P): FetchState<T, P> => ({
  params: initialParams !== undefined ? ({ ...DEFAULT_API_PAGINATION, ...initialParams } as P) : (DEFAULT_API_PAGINATION as P),
  isLoading: false,
  error: undefined,
  data: undefined,
  prevParams: undefined,
  timestamp: Date.now(),
  collectionTotalCount: 0,
});

export const createFetchActions =
  <T1 extends string, T2 extends string, T3 extends string>(request: T1, success: T2, failure: T3) =>
  <P = undefined, T = any>() => ({
    request: createAction(request)<FetchRequestParams<P>>(),
    success: createAction(success)<T>(),
    failure: createAction(failure)<CommonError>(),
  });

export const fetchReducerHelpers = {
  request: <P extends FetchRequestParams = FetchRequestParams, T extends FetchState = FetchState>(
    INITIAL_STATE: T,
    state: typeof INITIAL_STATE,
    payload?: P
  ): T => ({
    ...state,
    prevParams: state.params,
    params: {
      ...(payload?.options?.mergeWithPrevProps === true ? state.params : {}),
      ...(payload?.params || state.params),
    },
    error: INITIAL_STATE.error,
    isLoading: true,
  }),
  success: <P = any, T extends FetchState = FetchState>(INITIAL_STATE: T, state: typeof INITIAL_STATE, payload?: P): T => ({
    ...state,
    isLoading: false,
    error: undefined,
    data: payload,
    timestamp: Date.now(),
  }),
  failure: <T extends FetchState = FetchState>(INITIAL_STATE: T, state: typeof INITIAL_STATE, error: CommonError): T => ({
    ...state,
    error,
    isLoading: INITIAL_STATE.isLoading,
    data: INITIAL_STATE.data,
    timestamp: Date.now(),
  }),
};

export type DictionaryState<T = any> = FetchState<T> & {
  isSilentLoading: boolean;
};

export const getDictionaryInitialState = <T>(): DictionaryState<T> => ({
  ...getFetchInitialState<T>(DEFAULT_API_PAGINATION),
  isSilentLoading: false,
});

export const createDictionaryActions =
  <T1 extends string, T2 extends string, T3 extends string, T4 extends string, T5 extends string>(
    request: T1,
    success: T2,
    failure: T3,
    cancel: T4,
    silentRequest: T5
  ) =>
  <P = undefined, T = any>() => ({
    request: createAction(request)<P>(),
    success: createAction(success)<T>(),
    failure: createAction(failure)<CommonError>(),
    cancel: createAction(cancel)(),
    silentRequest: createAction(silentRequest)(),
  });

export const dictionaryReducerHelpers = {
  request: <T extends DictionaryState = DictionaryState>(INITIAL_STATE: T, state: typeof INITIAL_STATE): T => ({
    ...state,
    error: INITIAL_STATE.error,
    isLoading: true,
  }),
  silentRequest: <T extends DictionaryState = DictionaryState>(INITIAL_STATE: T, state: typeof INITIAL_STATE): T => ({
    ...state,
    error: INITIAL_STATE.error,
    isLoading: INITIAL_STATE.isLoading,
    isSilentLoading: true,
  }),
  rejected: <T extends DictionaryState = DictionaryState>(INITIAL_STATE: T, state: typeof INITIAL_STATE): T => ({
    ...state,
    error: INITIAL_STATE.error,
    isLoading: INITIAL_STATE.isLoading,
    isSilentLoading: INITIAL_STATE.isSilentLoading,
  }),
  success: <T extends DictionaryState = DictionaryState>(INITIAL_STATE: T, payload: any): T => ({
    ...INITIAL_STATE,
    data: payload,
    timestamp: Date.now(),
  }),
  failure: <T extends DictionaryState = DictionaryState>(INITIAL_STATE: T, error: CommonError): T => ({ ...INITIAL_STATE, error, timestamp: Date.now() }),
};

export const dictionaryEffectHelper = <T = any>(
  request: () => Observable<T | CommonError>,
  state: DictionaryState<T>,
  action: {
    success: PayloadActionCreator<string, any>;
    failure: PayloadActionCreator<string, any>;
    cancel: EmptyActionCreator<string>;
    silentRequest: EmptyActionCreator<string>;
  }
) => {
  const concatTable = [] as any;
  if (state.data !== undefined) {
    if (Date.now() - state.timestamp < DICTIONARY_FETCH_TRASHHOLD) {
      return of(action.cancel());
    }

    if (Date.now() - state.timestamp < DICTIONARY_SILENT_FETCH_TRASHHOLD) {
      concatTable.push(of(action.silentRequest()));
    }
  }

  concatTable.push(
    request().pipe(
      map(response => {
        if (response instanceof CommonError) {
          return action.failure(response);
        }

        return action.success(response);
      })
    )
  );

  return concat(...concatTable);
};

export const untilLocationChange = <T>(action$: Observable<any>): MonoTypeOperatorFunction<T> => {
  return takeUntil(action$.pipe(ofType(LOCATION_CHANGE_ACTION)));
};
