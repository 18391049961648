import { Styles, constants, theme } from '@/styles';

export const style: Styles = {
  minHeight: 50,
  width: '100%',
  backgroundColor: theme.warning,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  '.message-wrapper': {
    color: theme.textWhite,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '.warning-icon': {
      color: theme.textWhite,
      marginRight: constants.offset.small,
      fontSize: constants.fontSize.icon,
    },
  },

  '.close-button': {
    marginRight: constants.offset.medium,

    i: {
      fontSize: constants.fontSize.icon,
    },
  },
};
