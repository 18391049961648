import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getEnv } from '@/common/utils';
import { Icon } from '../../icon';
import { IconButtonLink } from '../../icon-button';
import { Tooltip } from '../../tooltip';
import { FieldGroup } from '../field-group';
import { FieldInput } from '../input';
import { tooltipMessage } from './tooltip-message';
import { COORDINATES, DEFAULT_ZOOM } from './constants';
import { style } from './styles';

export type Coordinates = {
  longitude: string | number | null;
  latitude: string | number | null;
};

type Props = {
  disabled: boolean;
  coordinates: Coordinates;
  zoom?: number;
};

export const getOSMapsURL = (latitude: string | number, longitude: string | number, zoom: number = DEFAULT_ZOOM) =>
  `${getEnv().REACT_APP_OS_MAPS_URL}/pin?lat=${latitude}&lon=${longitude}&zoom=${zoom}`;

export const CoordinateFiled: FC<Props> = ({ disabled, coordinates, zoom }) => {
  const [t] = useTranslation();
  const OSMapsURL = useMemo(() => {
    if (coordinates.latitude === null || coordinates.latitude > 90 || coordinates.latitude < -90) return null;
    if (coordinates.longitude === null || coordinates.longitude > 180 || coordinates.longitude < -180) return null;
    return getOSMapsURL(coordinates.latitude, coordinates.longitude, zoom);
  }, [coordinates]);

  const sideActions = useMemo(
    () => (
      <div css={style}>
        <Tooltip messageAsJsx={tooltipMessage(t)} placement='right-start' withIcon={false}>
          <Icon name='mdi-help' className='tooltip-icon' />
        </Tooltip>
        <div className='map-button-wrapper'>
          <IconButtonLink external target='_blank' iconName='mdi-map-marker' link={OSMapsURL || ''} disabled={OSMapsURL === null} />
        </div>
      </div>
    ),
    [tooltipMessage, t, OSMapsURL]
  );

  return (
    <FieldGroup sideActions={sideActions}>
      <FieldInput
        disabled={disabled}
        label={t('label.latitudeCoordinates')}
        name={COORDINATES.latitude}
        type={'number'}
        placeholder={t('page.storyForm.placeholder.latitudeCoordinates')}
      />
      <FieldInput
        disabled={disabled}
        label={t('label.longitudeCoordinates')}
        name={COORDINATES.longitude}
        type={'number'}
        placeholder={t('page.storyForm.placeholder.longitudeCoordinates')}
      />
    </FieldGroup>
  );
};
