import { createReducer } from 'typesafe-actions';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';

import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { UserListVM, UserListParams } from '@/features/accounts/models/users';
import { FeatureStateType } from '@/store/helpers';
import { usersActions } from '../actions';

type usersState = FetchState<UserListVM, UserListParams>;

const INITIAL_STATE: usersState = getFetchInitialState<UserListVM, UserListParams>({ userState: ['ACTIVE'] });

const reducer = createReducer<usersState, usersActions>(INITIAL_STATE)
  .handleAction(usersActions.list.request, (state, { payload }) => fetchReducerHelpers.request(INITIAL_STATE, state, payload))
  .handleAction(usersActions.list.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(usersActions.list.success, (state, { payload }) => {
    const newState = fetchReducerHelpers.success(INITIAL_STATE, state, payload);
    const { nextBatchLink, users: newUsers } = payload;
    const users = [
      ...(state.params.nextBatchLink !== undefined &&
      state.params.nextBatchLink !== state.prevParams?.nextBatchLink &&
      isEqual(omit(state.prevParams, 'nextBatchLink'), omit(state.params, 'nextBatchLink'))
        ? state.data?.users || []
        : []),
      ...newUsers,
    ];

    return {
      ...newState,
      data: { nextBatchLink, users },
    };
  })
  .handleAction(usersActions.clearUsersList, state => ({ ...state, data: { ...state.data, users: [] } }));

export const userListReducer = { list: reducer };
export type userListReducer = FeatureStateType<typeof userListReducer>;
