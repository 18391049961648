import { throwError, timer, Observable, Observer, Subject, concat, forkJoin } from 'rxjs';
import { retryWhen, mergeMap, debounceTime, takeUntil, repeat } from 'rxjs/operators';
import chunk from 'lodash/chunk';

import { store as rootStore, rootState } from '@/store';

export type GenericRetryConfig = {
  maxRetryAttempts?: number;
  scalingDuration?: number;
  excludedStatusCodes?: number[];
};

export const genericAxiosRetry = <T>({ maxRetryAttempts = 3, scalingDuration = 1000, excludedStatusCodes = [] }: GenericRetryConfig = {}) =>
  retryWhen<T>(attempts =>
    attempts.pipe(
      mergeMap((error, i) => {
        const retryAttempt = i + 1;
        if (retryAttempt > maxRetryAttempts || excludedStatusCodes.find(e => e === error?.response?.status)) {
          return throwError(error);
        }

        return timer(retryAttempt * scalingDuration);
      })
    )
  );

export const rxStore: (store?: typeof rootStore) => Observable<rootState> = (store = rootStore) =>
  Observable.create((observer: Observer<rootState>) => {
    observer.next(store.getState());
    const sub = store.subscribe(() => {
      observer.next(store.getState());
    });

    return sub;
  });

export const postponeEmission =
  <N>(until: number, notifier$: Subject<N>) =>
  <T>(source: Observable<T>): Observable<T> =>
    source.pipe(debounceTime(until), takeUntil(notifier$), repeat());

type ChunkRequestCallback<T, R> = (value: T, index: number, array: T[]) => Observable<R>;
type ChunkSubscriber<L> = (responses: L) => void;
type SendChunkRequestOptions<R, D> = {
  size?: number;
  takeUntil$?: Subject<D>;
  next?: ChunkSubscriber<R[]>;
  error?: () => void;
  complete?: () => void;
};

export const DEFAULT_CHUNK_SIZE = 50;

export const sendChunkedRequestsFunc = <T, R, D>(
  list: T[],
  request: ChunkRequestCallback<T, R>,
  { next, error, complete, takeUntil$, size = DEFAULT_CHUNK_SIZE }: SendChunkRequestOptions<R, D>
) => {
  const chunks = chunk(list, size);
  let result = concat(...chunks.map(chunk => forkJoin(chunk.map(request))));

  if (takeUntil$) {
    result = result.pipe(takeUntil(takeUntil$));
  }

  return result.subscribe(next, error, complete);
};

export const sendChunkedRequests = <T, R, D>(list: T[], chunkSize: number = DEFAULT_CHUNK_SIZE) => ({
  send: (observableRequest: ChunkRequestCallback<T, R>) => {
    const subscriberFunction = (takeUntil$?: Subject<D>) => (complete?: () => void, next?: ChunkSubscriber<R[]>, error?: () => void) =>
      sendChunkedRequestsFunc(list, observableRequest, { complete, next, error, takeUntil$, size: chunkSize });
    return {
      subscribe: subscriberFunction(),
      takeUntil: (takeUntil$?: Subject<D>) => ({
        subscribe: subscriberFunction(takeUntil$),
      }),
    };
  },
});
