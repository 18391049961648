import React, { ChangeEvent, useCallback, useLayoutEffect, useRef } from 'react';
import cn from 'classnames';
import MuiSlider, { SliderTypeMap } from '@material-ui/core/Slider';
import { styled } from '@material-ui/core';

import { Range } from '../filter';
import { styles, sliderStyles } from './styles';

const StyledSlider = styled(MuiSlider)(sliderStyles);
type MuiSliderOnChange = (event: React.ChangeEvent<{}>, value: number | number[]) => void;

export type OnSliderChangeFunc<T = number | Range<number>> = (value: T, event: ChangeEvent<{}>) => void;

export type SliderProps<T = number | Range<number>> = Omit<SliderTypeMap['props'], 'value' | 'defaultValue' | 'onChange'> & {
  value: T;
  defaultValue?: T;
  onChange?: OnSliderChangeFunc<T>;
};

export const Slider = <T extends number | Range<number> = number | Range<number>>({
  value,
  defaultValue,
  onChange,
  step = 0.1,
  disabled,
  ...props
}: SliderProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);

  const getMuiValue = (propValue?: T) => (typeof propValue === 'number' ? propValue : propValue ? [propValue.start, propValue.end] : undefined);

  const handleChange = useCallback<MuiSliderOnChange>(
    (event, next) => onChange && onChange((typeof next === 'number' ? next : { start: next[0], end: next[1] }) as T, event),
    [onChange]
  );

  useLayoutEffect(() => {
    const track = ref.current?.getElementsByClassName('MuiSlider-track')[0]?.classList;
    disabled ? track?.add('disabled') : track?.remove('disabled');
  }, [disabled]);

  return (
    <div css={styles}>
      <div className={cn(['wrapper', { 'wrapper--disabled': disabled }])}>
        <StyledSlider
          ref={ref}
          value={getMuiValue(value)}
          defaultValue={getMuiValue(defaultValue)}
          onChange={handleChange}
          step={step}
          disabled={disabled}
          {...props}
        />
      </div>
    </div>
  );
};
