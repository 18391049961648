import React, { FC, useMemo, PropsWithChildren, useState, useEffect, useRef, useCallback } from 'react';
import { Subject, timer } from 'rxjs';
import { debounce, startWith, pairwise } from 'rxjs/operators';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/styles/hooks';
import { LoadingSection } from '../loading-section';
import { Icon } from '../icon';
import { statusWrapperStyles } from './styles';

export type StatusWrapperStatus = 'no-results' | 'failure' | 'loading' | 'success' | 'not-searched' | 'access-denied' | 'not-found';

export type StatusProps = PropsWithChildren<{
  status: StatusWrapperStatus;
  errorMessage?: string;
  minHeight?: number;
  containerClassName?: string;
  hideContentWhileLoading?: boolean;
}>;

const DEBOUNCE_TIME = 200;

export const StatusWrapper: FC<StatusProps> = ({ status: type, children, containerClassName, minHeight, hideContentWhileLoading, errorMessage }) => {
  const status$ = useRef(new Subject<StatusWrapperStatus>());
  const [status, setStatus] = useState<StatusWrapperStatus>(type);
  const { styles } = useStyles(statusWrapperStyles);

  useEffect(() => {
    const subscription = status$.current
      .pipe(
        startWith(status),
        pairwise(),
        debounce(([previousValue]) => timer(previousValue === 'loading' ? DEBOUNCE_TIME : 0))
      )
      .subscribe(([, currentValue]) => setStatus(currentValue));

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    status$.current.next(type);
  }, [type]);

  const [t] = useTranslation();

  const getErrorData = useCallback(
    (type: StatusWrapperStatus) => {
      switch (type) {
        case 'no-results':
          return { icon: 'mdi-search-off', message: t('error.noResults') };
        case 'not-searched':
          return { icon: 'mdi-search-off', message: t('error.noSearchYet') };
        case 'access-denied':
          return { icon: 'mdi-information', message: errorMessage ? errorMessage : t('error.accessDenied') };
        case 'not-found':
          return { icon: 'mdi-information', message: errorMessage ? errorMessage : t('error.notFound') };
        case 'failure':
          return { icon: 'mdi-information', message: errorMessage ? errorMessage : t('error.common') };
        default:
          return { icon: 'mdi-information', message: errorMessage ? errorMessage : t('error.common') };
      }
    },
    [t, errorMessage]
  );

  const content = useMemo(() => {
    if (status === 'loading' && hideContentWhileLoading) {
      return null;
    }

    if (status === 'failure' || status === 'no-results' || status === 'not-searched' || status === 'access-denied' || status === 'not-found') {
      const { icon, message } = getErrorData(status);
      return (
        <div className='error'>
          <div className='error__message'>{t(message)}</div>
          <Icon name={icon} className='error__icon' />
        </div>
      );
    }

    return children;
  }, [status, children, hideContentWhileLoading]);

  return (
    <div css={[styles, { minHeight }]} className={containerClassName}>
      {content}
      {status === 'loading' && (
        <div className='loading'>
          <LoadingSection />
        </div>
      )}
    </div>
  );
};
