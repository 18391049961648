import { createReducer } from 'typesafe-actions';

import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { ContentTypesDictionaryVM } from '@/models/content-types';
import { FeatureStateType } from '../helpers';
import { contentTypesActions } from './actions';

type contentTypesState = DictionaryState<ContentTypesDictionaryVM>;

const INITIAL_STATE: contentTypesState = getDictionaryInitialState<ContentTypesDictionaryVM>();

const reducer = createReducer<contentTypesState, contentTypesActions>(INITIAL_STATE)
  .handleAction(contentTypesActions.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(contentTypesActions.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(contentTypesActions.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(contentTypesActions.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(contentTypesActions.failure, (_, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const contentTypesReducer = { contentTypes: reducer };
export type contentTypesReducer = FeatureStateType<typeof contentTypesReducer>;
