import { constants, rem } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const richTextStyles: StyleFunction = ({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  '.ck': {
    '.ck-editor__top': {
      '.ck-sticky-panel': {
        '&__content_sticky': {
          position: 'absolute',
        },

        '.ck-toolbar': {
          borderTopLeftRadius: constants.borderRadius.small,
          borderTopRightRadius: constants.borderRadius.small,
          backgroundColor: theme.borderPrimary,
          border: `1px solid ${theme.borderThird}`,
          borderBottomWidth: 0,

          '.ck-button': {
            transition: 'background-color .2s',
            fontSize: rem(constants.fontSize.p),
            cursor: 'pointer',

            '&.ck-off': {
              color: theme.textPrimary,

              '&:hover': {
                backgroundColor: theme.borderPrimary,
              },

              '&:focus': {
                boxShadow: 'none',
                border: 'none',
              },

              '&:active': {
                boxShadow: 'none',
                border: 'none',
              },
            },

            '&.ck-on': {
              color: theme.textPrimary,
              backgroundColor: theme.backgroundFourthFocus,
            },

            '&:hover': {
              backgroundColor: theme.backgroundFourthHover,
            },

            '&.ck-disabled': {
              cursor: 'not-allowed',
            },
          },
        },
      },
    },

    '.ck-editor__main': {
      ' > .ck-editor__editable': {
        boxSizing: 'border-box',
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: constants.offset.medium,
        paddingRight: constants.offset.medium,
        lineHeight: 1.5,
        width: '100%',
        backgroundColor: theme.backgroundNinth,
        color: theme.textFourth,
        transitionProperty: 'border-color, box-shadow',
        transitionDuration: '.2s',
        resize: 'none',
        minHeight: 125,

        '&.ck-rounded-corners': {
          borderRadius: constants.borderRadius.small,
        },

        '&.ck-focused': {
          border: `1px solid ${theme.borderPrimaryActive}`,
          boxShadow: `0 0 1px 1px ${theme.borderPrimaryActive} inset`,
          outline: 'none',
        },

        '&.ck-read-only': {
          cursor: 'not-allowed !important',
          backgroundColor: `${theme.backgroundPrimaryDisabled} !important`,
          border: `1px solid ${theme.borderPrimaryDisabled} !important`,

          '&> p': {
            color: `${theme.textDisabled} !important`,
          },
        },

        '&:not(.ck-focused)': {
          border: `1px solid ${theme.borderThird}`,
        },

        '& > p': {
          color: theme.textPrimary,
        },
      },
    },

    '.ck-editor__top + .ck-editor__main': {
      '> .ck-editor__editable.ck-rounded-corners': {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
    },
  },

  '.max-length-message': {
    marginTop: constants.offset.xsmall,
    color: theme.textSecondary,
    fontSize: rem(constants.fontSize.p),
  },
});
