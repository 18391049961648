import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import omit from 'lodash/omit';
import { stringify } from 'qs';

import { httpClient } from '@/core/services/http-client';
import { getEnv, CommonError } from '@/common/utils';
import { ToursListResponse, ToursListVM, ToursElementVM, ToursParams } from '@/features/content/models/tours';

const config = getEnv();

const CONTENT_TOURS_ENDPOINT = `${config.REACT_APP_API_URL}/story-api/v1/admin/tours`;

export const getToursData = (data: ToursParams) =>
  httpClient()
    .authorized.post<ToursListResponse>(
      `${CONTENT_TOURS_ENDPOINT}/search`,
      {
        text: data.text,
        categoryIds: data.categoryIds,
        organisationId: data.organisationId?.value,
        ageRecommendations: data.ageRecommendations,
        createdBy: data.createdBy?.value,
        tagIds: data.tagIds,
        statuses: data.statuses,
        types: data.types,
      },
      {
        params: {
          ...omit(data, ['text', 'name', 'shortResponse', 'categoryIds', 'organisationId', 'ageRecommendations', 'createdBy', 'tagIds', 'statuses', 'types']),
        },
        paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' }),
      }
    )
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new ToursListVM({ data, VM: ToursElementVM });
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const deleteTour = (id: string) => {
  return httpClient()
    .authorized.delete(`${CONTENT_TOURS_ENDPOINT}/${id}`)
    .pipe(
      map(({ status }) => {
        if (status === 200) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => {
        return of(new CommonError({ code: '500', message: e }));
      })
    );
};

export const archiveTour = (id: string) => {
  return httpClient()
    .authorized.put(`${CONTENT_TOURS_ENDPOINT}/${id}/archive`)
    .pipe(
      map(({ status }) => {
        if (status === 200) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => {
        return of(new CommonError({ code: '500', message: e }));
      })
    );
};

export const reactivateTour = (id: string) => {
  return httpClient()
    .authorized.put(`${CONTENT_TOURS_ENDPOINT}/${id}/restore`)
    .pipe(
      map(({ status }) => {
        if (status === 200) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => {
        return of(new CommonError({ code: e.data.code, message: e }));
      })
    );
};
