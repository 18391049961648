export type RegionEntityResponse = {
  regionCode: string;
  url: string;
};

export type RegionListResponse = RegionEntityResponse[];

export interface RegionElementVM extends RegionEntityResponse {}

export class RegionElementVM {
  constructor({ ...props }: RegionEntityResponse) {
    Object.assign(this, { ...props });
  }
}

export interface RegionDictionaryVM {
  list: RegionElementVM[];
}

export class RegionDictionaryVM {
  constructor(list: RegionListResponse) {
    this.list = (list || []).map(element => new RegionElementVM(element)).sort((a, b) => (a.regionCode > b.regionCode ? 1 : -1));
  }
}
