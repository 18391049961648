import React, { InputHTMLAttributes } from 'react';
import Switch, { SwitchProps as MaterialSwitchProps, SwitchClassKey } from '@material-ui/core/Switch';
import { withStyles, createStyles } from '@material-ui/core/styles';
import cn from 'classnames';

import { toggleStyle, styles } from './styles';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

type Props = InputHTMLAttributes<HTMLInputElement> &
  MaterialSwitchProps & {
    classes: Styles;
  };

export const Toggle = withStyles(() => createStyles(toggleStyle))(({ classes, disabled, ...props }: Props) => {
  const ToggleClasses = {
    root: classes.root,
    switchBase: classes.switchBase,
    thumb: classes.thumb,
    track: classes.track,
    checked: classes.checked,
  };

  return (
    <Switch
      {...props}
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={ToggleClasses}
      disabled={disabled}
      css={styles}
      className={cn(['toggle', { 'toggle toggle--disabled': disabled }])}
    />
  );
});
