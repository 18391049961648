import { createSelector } from 'reselect';

import { interfaceReducer } from './reducer';

type MakeGetNumberOfActiveActions = interfaceReducer['interface']['numberOfActiveActions'];

const getInterface = (state: interfaceReducer) => state.interface;

export const makeGetNumberOfActiveActions = () =>
  createSelector<interfaceReducer, interfaceReducer['interface'], MakeGetNumberOfActiveActions>(
    getInterface,
    ({ numberOfActiveActions }) => numberOfActiveActions || 0
  );

type MakeGetLoadingSectionActive = interfaceReducer['interface']['loadingSectionActive'];
type MakeGetInitialUrl = interfaceReducer['interface']['initialUrl'];
type MakeGetCurrentTheme = interfaceReducer['interface']['currentTheme'];

export const makeGetLoadingSectionActive = () =>
  createSelector<interfaceReducer, interfaceReducer['interface'], MakeGetLoadingSectionActive>(
    getInterface,
    ({ loadingSectionActive }) => loadingSectionActive
  );

export const makeGetInitialUrl = () =>
  createSelector<interfaceReducer, interfaceReducer['interface'], MakeGetInitialUrl>(getInterface, ({ initialUrl }) => initialUrl);

export const makeGetCurrentTheme = () =>
  createSelector<interfaceReducer, interfaceReducer['interface'], MakeGetCurrentTheme>(getInterface, ({ currentTheme }) => currentTheme);
