import React, { forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';

export type AnchorProps = LinkProps & {
  external?: boolean;
};

export const Anchor = forwardRef<HTMLAnchorElement, AnchorProps>(({ external, to, href, target, ...props }, ref) =>
  external || href ? (
    <a ref={ref} href={href || (to as string)} target={target ?? '_blank'} {...props} />
  ) : (
    <Link ref={ref} to={to} target={target} {...props} />
  )
);
