import { createReducer } from 'typesafe-actions';

import { TourDetailsVM } from '@/features/content/models/tours';
import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { FeatureStateType } from '../../helpers';
import { toursActions } from '../actions';

export type tourDetailsState = FetchState<TourDetailsVM>;

const INITIAL_STATE: tourDetailsState = getFetchInitialState<TourDetailsVM>();

const reducer = createReducer<tourDetailsState, toursActions>(INITIAL_STATE)
  .handleAction(toursActions.details.request, state => fetchReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(toursActions.details.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(toursActions.details.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(toursActions.clearTourDetails, () => ({ ...INITIAL_STATE }));

export const tourDetailsReducer = { details: reducer };
export type tourDetailsReducer = FeatureStateType<typeof tourDetailsReducer>;
