import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const inputStyles: StyleFunction = ({ theme }) => ({
  borderRadius: constants.borderRadius.small,
  border: `1px solid ${theme.borderThird}`,
  paddingLeft: constants.offset.medium,
  paddingRight: constants.offset.medium,
  textOverflow: 'ellipsis',
  lineHeight: '36px',
  width: '100%',
  backgroundColor: theme.backgroundNinth,
  color: theme.textPrimary,
  transitionProperty: 'border-color, box-shadow',
  transitionDuration: '.2s',

  '&:active:enabled, &:focus:enabled': {
    border: `1px solid ${theme.borderPrimaryActive}`,
    boxShadow: `0 0 1px 1px ${theme.borderPrimaryActive} inset`,
    outline: 'none',
  },

  '&:disabled': {
    backgroundColor: theme.backgroundPrimaryDisabled,
    borderColor: theme.borderPrimaryDisabled,
    color: theme.borderPrimaryDisabled,
    cursor: 'not-allowed',
  },

  '&::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },

  '&::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },

  '&[type=number]': {
    MozAppearance: 'textfield',
  },
});
