import { createAction, ActionType, Action } from 'typesafe-actions';

export const interfaceActions = {
  increaseNumberOfActiveActions: createAction('[INTERFACE] increase the number of active actions')(),
  reduceNumberOfActiveActions: createAction('[INTERFACE] reduce the number of active actions')(),
  toggleLoadingSection: createAction('[INTERFACE] toggle loading section')<boolean>(),
  setInitialUrl: createAction('[INTERFACE] set redirect url')<string>(),
  setTheme: createAction('[THEME] set theme')<string>(),
  clearInitialUrl: createAction('[INTERFACE] clear redirect url')(),
};

export type interfaceActions = Action | ActionType<typeof interfaceActions>;
