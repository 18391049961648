import { Enum, LATITUDE_VALIDATOR, LONGITUDE_VALIDATOR } from '@/common/utils';

export const COORDINATES = Enum('longitude', 'latitude');

export type CoordinateDefs = {
  [COORDINATES.latitude]: string | number;
  [COORDINATES.longitude]: string | number;
};

export const COORDINATES_VALIDATORS = {
  [COORDINATES.latitude]: LATITUDE_VALIDATOR,
  [COORDINATES.longitude]: LONGITUDE_VALIDATOR,
};

export const DEFAULT_ZOOM = 15;
