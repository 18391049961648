import React, { FC } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import { history } from '@/store';
import { Layout } from '../components/layout';
import { rootRoutePaths, rootRoutes } from './routes/root';
import { RoutesDef } from './navigation.models';
import { generateRoutes } from './navigation.helpers';
import { AuthenticatedNavigation } from './authenticated.navigation';
import { UnauthenticatedNavigation } from './unauthenticated.navigation';

const rootRoutesDef: RoutesDef<rootRoutes> = {
  [rootRoutes.AUTHENTICATED]: {
    path: rootRoutePaths[rootRoutes.AUTHENTICATED],
    component: AuthenticatedNavigation,
  },
  [rootRoutes.UNAUTHENTICATED]: {
    path: rootRoutePaths[rootRoutes.UNAUTHENTICATED],
    component: UnauthenticatedNavigation,
  },
};

const rootRoutesElement = generateRoutes(rootRoutesDef, 'root', rootRoutePaths[rootRoutes.UNAUTHENTICATED]);

export const RootNavigation: FC = () => (
  <Router>
    <ConnectedRouter history={history}>
      <Layout>
        <Switch>{rootRoutesElement}</Switch>
      </Layout>
    </ConnectedRouter>
  </Router>
);
