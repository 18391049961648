import { createReducer } from 'typesafe-actions';

import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { DEFAULT_LIST_PAGINATION_DATA } from '@/common/utils/pageable';
import { SmartMapsListRequest, SmartMapsListVM } from '@/models/smartmaps';
import { FeatureStateType } from '../../helpers';
import { smartMapsActions } from '../actions';

export type smartMapsListState = FetchState<SmartMapsListVM, SmartMapsListRequest>;

const INITIAL_STATE: smartMapsListState = getFetchInitialState<SmartMapsListVM, SmartMapsListRequest>({ sort: ['publishedDate,DESC'] });

const reducer = createReducer<smartMapsListState, smartMapsActions>(INITIAL_STATE)
  .handleAction(smartMapsActions.list.request, (state, { payload }) => fetchReducerHelpers.request(INITIAL_STATE, state, payload))
  .handleAction(smartMapsActions.list.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(smartMapsActions.list.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(smartMapsActions.clearSmartMapsList, state => ({
    ...state,
    data: { ...state.data, list: [], listPaginationData: state.data?.listPaginationData || DEFAULT_LIST_PAGINATION_DATA },
  }));

export const smartMapsListReducer = { list: reducer };
export type smartMapsListReducer = FeatureStateType<typeof smartMapsListReducer>;
