import { Action, ActionType, createAction } from 'typesafe-actions';

import { createFetchActions } from '@/common/utils/store';
import { TourAnalyticsListVM, TourAnalyticsParams } from '@/features/reporting/models/tour-analytics';

export const tourAnalyticsActions = {
  list: createFetchActions('FETCH_TOUR_ANALYTICS_REQUEST', 'FETCH_TOUR_ANALYTICS_SUCCESS', 'FETCH_TOUR_ANALYTICS_FAILURE')<
    TourAnalyticsParams,
    TourAnalyticsListVM
  >(),
  clearTourAnalyticsList: createAction('CLEAR_TOUR_ANALYTICS_LIST')(),
};

export type tourAnalyticsActions = Action | ActionType<typeof tourAnalyticsActions>;
