import { switchMap, filter } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { getTourCategoriesData, getTourTagsData } from '@/features/content/services/dictionaries';
import { dictionaryEffectHelper } from '@/common/utils';
import { commonDictionariesReducer } from './reducers';
import { commonDictionariesActions } from './actions';

const getCategoriesDictionary: Epic<commonDictionariesActions, commonDictionariesActions, commonDictionariesReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(commonDictionariesActions.categories.request)),
    switchMap(() => {
      const state = state$.value.commonDictionaries.categories;
      return dictionaryEffectHelper(() => getTourCategoriesData(), state, commonDictionariesActions.categories);
    })
  ) as any;

const getTagsDictionary: Epic<commonDictionariesActions, commonDictionariesActions, commonDictionariesReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(commonDictionariesActions.tags.request)),
    switchMap(() => {
      const state = state$.value.commonDictionaries.tags;
      return dictionaryEffectHelper(() => getTourTagsData(), state, commonDictionariesActions.tags);
    })
  ) as any;

export const commonDictionariesEpics = [getCategoriesDictionary, getTagsDictionary];
