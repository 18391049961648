import React, { FC } from 'react';
import { RouteComponentProps, Switch } from 'react-router-dom';

import { PrivateRoute } from '../components/private-route';
import { authenticatedGuard } from './guards/authenticated.guard';
import { authenticatedRoutePaths, authenticatedRoutes } from './routes';
import { RoutesDef } from './navigation.models';
import { generateRoutes, lazy } from './navigation.helpers';

const authenticatedRoutesDef: RoutesDef<authenticatedRoutes> = {
  [authenticatedRoutes.DASHBOARD]: {
    path: authenticatedRoutePaths[authenticatedRoutes.DASHBOARD],
    component: lazy(import(/* webpackChunkName: "dashboard" */ '@/features/dashboard')),
  },
  [authenticatedRoutes.ACCOUNTS]: {
    path: authenticatedRoutePaths[authenticatedRoutes.ACCOUNTS],
    component: lazy(import(/* webpackChunkName: "accounts" */ '@/features/accounts')),
  },
  [authenticatedRoutes.CONTENT]: {
    path: authenticatedRoutePaths[authenticatedRoutes.CONTENT],
    component: lazy(import(/* webpackChunkName: "content" */ '@/features/content')),
  },
  [authenticatedRoutes.MANAGEMENT]: {
    path: authenticatedRoutePaths[authenticatedRoutes.MANAGEMENT],
    component: lazy(import(/* webpackChunkName: "management" */ '@/features/management')),
  },
  [authenticatedRoutes.REPORTING]: {
    path: authenticatedRoutePaths[authenticatedRoutes.REPORTING],
    component: lazy(import(/* webpackChunkName: "reporting" */ '@/features/reporting')),
  },
};

const authenticatedRoutesElement = generateRoutes(authenticatedRoutesDef, 'authenticated', authenticatedRoutePaths[authenticatedRoutes.DASHBOARD]);
const AuthenticatedRoutes: FC = () => <Switch>{authenticatedRoutesElement}</Switch>;

export const AuthenticatedNavigation: FC<RouteComponentProps> = props => <PrivateRoute guard={authenticatedGuard} component={AuthenticatedRoutes} {...props} />;
