import { createReducer } from 'typesafe-actions';

import { UserCommunityStoriesListVM } from '@/features/content/models/community-stories';
import { fetchReducerHelpers, FetchState, getFetchInitialState } from '@/common/utils/store';
import { FeatureStateType } from '../../helpers';
import { contentProvidedByUserActions } from '../actions';

export type CommunityStories = FetchState<UserCommunityStoriesListVM>;

const INITIAL_STATE: CommunityStories = getFetchInitialState<UserCommunityStoriesListVM>({ size: 10, sort: ['createdAt,DESC'], page: 0 });

const reducer = createReducer<CommunityStories, contentProvidedByUserActions>(INITIAL_STATE)
  .handleAction(contentProvidedByUserActions.communityStories.request, (state, { payload }) => fetchReducerHelpers.request(INITIAL_STATE, state, payload))
  .handleAction(contentProvidedByUserActions.communityStories.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(contentProvidedByUserActions.communityStories.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(contentProvidedByUserActions.communityStories.clear, () => ({ ...INITIAL_STATE }));

export const communityStoriesReducer = { communityStories: reducer };
export type communityStoriesReducer = FeatureStateType<typeof communityStoriesReducer>;
