import { createReducer } from 'typesafe-actions';

import { fetchReducerHelpers, FetchState, getFetchInitialState } from '@/common/utils/store';
import { DEFAULT_LIST_PAGINATION_DATA } from '@/common/utils/pageable';
import { ROUTE_LIST_PAGE_SIZE } from '@/features/content/pages/routes-list/constants';
import { RouteAnalyticsListVM, RouteAnalyticsParams } from '@/features/reporting/models/route-analytics';
import { FeatureStateType } from '@/store/helpers';
import { routeListSuccessReducer } from '@/store/routes/reducers/list';
import { routeAnalyticsActions } from '../actions';

export type RouteAnalyticsListState = FetchState<RouteAnalyticsListVM, RouteAnalyticsParams>;

const INITIAL_STATE: RouteAnalyticsListState = getFetchInitialState<RouteAnalyticsListVM, RouteAnalyticsParams>({
  size: ROUTE_LIST_PAGE_SIZE,
  includeWithoutRating: true,
  isOrganisational: false,
});

const reducer = createReducer<RouteAnalyticsListState, routeAnalyticsActions>(INITIAL_STATE)
  .handleAction(routeAnalyticsActions.list.request, (state, { payload }) => fetchReducerHelpers.request(INITIAL_STATE, state, payload))
  .handleAction(routeAnalyticsActions.list.success, (state, { payload }) => routeListSuccessReducer(INITIAL_STATE, state, payload))
  .handleAction(routeAnalyticsActions.list.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(routeAnalyticsActions.clearRouteAnalyticsList, state => ({
    ...state,
    data: { ...state.data, list: [], listPaginationData: state.data?.listPaginationData || DEFAULT_LIST_PAGINATION_DATA, hasNextPage: false },
  }));

export const routeAnalyticsListReducer = { list: reducer };
export type routeAnalyticsListReducer = FeatureStateType<typeof routeAnalyticsListReducer>;
