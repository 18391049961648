import { switchMap, filter } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { getReasonsData } from '@/features/management/services/moderation/dictionaries';
import { dictionaryEffectHelper } from '@/common/utils';
import { reasonsActions } from './actions';

const getReasonsList: Epic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(reasonsActions.request)),
    switchMap(() => {
      const state = state$.value.reasons;
      return dictionaryEffectHelper(() => getReasonsData(), state, reasonsActions);
    })
  );

export const reasonsEpics = [getReasonsList];
