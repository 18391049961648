import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const wysiwygStyle: StyleFunction = ({ theme }) => ({
  width: '100%',
  minHeight: 86,

  '& > div:first-of-type': {
    background: theme.backgroundSecondary,
    border: 'none',
    boxSizing: 'border-box',
    fontFamily: '"Helvetica Neue", "Helvetica", "Arial", "sans-serif"',
    padding: '8px',

    '&:after': {
      clear: 'both',
      content: '""',
      display: 'table',
    },
  },

  '.ql-container.ql-snow': {
    background: theme.backgroundPrimary,
    border: 'none',
    borderRadius: constants.borderRadius.small,
  },

  '.ql-toolbar.ql-snow': {
    borderLeft: 'none',
    borderRight: 'none',

    button: {
      transition: 'color .2s, opacity .2s',
      borderRadius: constants.borderRadius.small,

      '&.ql-active': {
        color: theme.primaryHover,

        '.ql-stroke': {
          stroke: theme.primaryHover,
        },
      },

      '&:focus': {
        borderColor: theme.primaryFocus,
        boxShadow: `0px 0px 0px 3px ${theme.primaryFocus}`,

        '.ql-stroke': {
          stroke: theme.primary,
        },
      },

      '&:hover': {
        color: theme.primaryHover,

        '.ql-stroke': {
          stroke: theme.primaryHover,
        },
      },
    },

    '.ql-stroke': {
      stroke: theme.primary,
    },
  },

  '&.disabled .ql-toolbar button': {
    opacity: 0.3,
    cursor: 'default',
    pointerEvents: 'none',

    '&:hover, &:focus, &.ql-active': {
      color: theme.textPrimary,
      border: 'none',
      boxShadow: 'none',

      '.ql-stroke': {
        stroke: theme.textPrimary,
      },
    },
  },

  '.ql-editor': {
    fontSize: constants.fontSize.h4,

    p: {
      color: theme.textFourth,
    },

    i: {
      fontWeight: 'inherit',
      color: 'inherit',
    },

    b: {
      color: 'inherit',
    },

    '&.ql-blank': {
      '&::before': {
        color: theme.textPrimary,
      },
    },
  },
});
