import React, { useContext, FC, useMemo, useEffect } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { useStyles } from '@/styles/hooks';
import { CommonEditorCardInnerComponent } from '../../components/common-editor-card';
import { EditorDraggableContext, EditorDroppableContext, EditorInnerArrayContext } from '../../../editor-container';
import { EditorValue } from '../../../../models';
import { AccordionCardPayload, ACCORDION_CARD_ID_PREFIX, ACCORDION_ALLOWED_CARDS } from '../accordion-card';
import { accordionCardContentStyles } from './styles';

type AccordionBodyProps = FieldArrayRenderProps & { id: string };

const AccordionBodyComponent: FC<AccordionBodyProps> = props => {
  const [t] = useTranslation();
  const arrayContext = useContext(EditorInnerArrayContext);
  const droppableContext = useContext(EditorDroppableContext);
  const draggableContext = useContext(EditorDraggableContext);

  const accordionId = useMemo(() => `${ACCORDION_CARD_ID_PREFIX}_${props.id}`, [props.id]);

  const accordionEnabled = useMemo(
    () => droppableContext.activeAccordions && droppableContext.activeAccordions.includes(props.id),
    [droppableContext.activeAccordions]
  );

  const cardAllowed = useMemo(
    () => (draggableContext.draggedItem ? ACCORDION_ALLOWED_CARDS.includes(draggableContext.draggedItem) : false),
    [draggableContext.draggedItem, ACCORDION_ALLOWED_CARDS]
  );

  const { move, insert } = props;
  const list = props.form.getFieldMeta(props.name).value as EditorValue[];

  useEffect(() => {
    if (!arrayContext.data[accordionId]) {
      arrayContext.setData({
        ...arrayContext.data,
        [accordionId]: {
          insert,
          move,
        },
      });
    }
  }, [accordionId, arrayContext.data]);

  return (
    <Droppable key={accordionId} droppableId={accordionId} isDropDisabled={!accordionEnabled}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} className={cn(['content', snapshot.isDraggingOver && cardAllowed && 'content--dragging-over'])}>
          {(list === undefined || list.length === 0) && <div className='notice'>{t('storyContentEditor.card.accordion.bodyPlaceholder')}</div>}
          {(list || []).map((item, index) => (
            <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={!accordionEnabled}>
              {(provided, snapshot) => (
                <item.card.content id={item.id} provided={provided} snapshot={snapshot} item={item.card} index={index} fieldArrayRender={props} />
              )}
            </Draggable>
          ))}
        </div>
      )}
    </Droppable>
  );
};

export const AccordionCardContentComponent: CommonEditorCardInnerComponent<AccordionCardPayload> = ({ isEditable, formField, id }) => {
  const [field] = formField;
  const { styles } = useStyles(accordionCardContentStyles);

  const accordionBodyName = `${field.name}.body`;

  return (
    <div css={styles} className={cn([!isEditable && 'disabled'])}>
      <FieldArray name={accordionBodyName} render={props => <AccordionBodyComponent {...props} id={id} />} />
    </div>
  );
};
