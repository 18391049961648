import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { httpClient } from '@/core/services/http-client';
import { getEnv, CommonError } from '@/common/utils';
import { SmartMapsDetailsResponse, SmartMapsDetailsVM } from '@/models/smartmaps';

const config = getEnv();

const SMART_MAPS_LIST_ENDPOINT = `${config.REACT_APP_API_URL}/entitlement-api/v1/adminPanel/products/smartMaps`;

export const getSmartMapsDetailsData = (productId: string) =>
  httpClient()
    .authorized.get<SmartMapsDetailsResponse>(`${SMART_MAPS_LIST_ENDPOINT}/${productId}`)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new SmartMapsDetailsVM(data);
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: e.code, message: e.errorMessage })))
    );
