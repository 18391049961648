import { createSelector } from 'reselect';

import { getDataStatus } from '@/common/utils';
import { InvitationListVM } from '@/models/invitations';
import { invitationsReducer } from './reducers';

type InvitationList = invitationsReducer['invitations']['list'];
type MakeGetInvitationListData = InvitationListVM['list'];
type MakeGetInvitationListFilters = InvitationList['params'];
type MakeGetInvitationListPaginationData = InvitationListVM['listPaginationData'] | undefined;

const getInvitationList = (state: invitationsReducer) => state.invitations.list;

export const makeGetInvitationListData = () =>
  createSelector<invitationsReducer, InvitationList, MakeGetInvitationListData>(getInvitationList, ({ data }) => (data === undefined ? [] : data.list));

export const makeGetInvitationListStatus = () =>
  createSelector<invitationsReducer, InvitationList, ReturnType<typeof getDataStatus>>(getInvitationList, getDataStatus);

export const makeGetInvitationListFilters = () =>
  createSelector<invitationsReducer, InvitationList, MakeGetInvitationListFilters>(getInvitationList, ({ params }) => params);

export const makeGetInvitationListPaginationData = () =>
  createSelector<invitationsReducer, InvitationList, MakeGetInvitationListPaginationData>(getInvitationList, ({ data }) => data?.listPaginationData);

type InvitationTypesDictionary = invitationsReducer['invitations']['types'];
type MakeGetInvitationTypesDictionaryData = invitationsReducer['invitations']['types']['data'];

const getInvitationTypesDictionary = (state: invitationsReducer) => state.invitations.types;

export const makeGetInvitationTypesDictionaryData = () =>
  createSelector<invitationsReducer, InvitationTypesDictionary, MakeGetInvitationTypesDictionaryData>(getInvitationTypesDictionary, ({ data }) => data);

export const makeGetInvitationTypesDictionaryStatus = () =>
  createSelector<invitationsReducer, InvitationTypesDictionary, ReturnType<typeof getDataStatus>>(getInvitationTypesDictionary, getDataStatus);

type InvitationStatusesDictionary = invitationsReducer['invitations']['statuses'];
type MakeGetInvitationStatusesDictionaryData = invitationsReducer['invitations']['statuses']['data'];

const getInvitationStatusesDictionary = (state: invitationsReducer) => state.invitations.statuses;

export const makeGetInvitationStatusesDictionaryData = () =>
  createSelector<invitationsReducer, InvitationStatusesDictionary, MakeGetInvitationStatusesDictionaryData>(
    getInvitationStatusesDictionary,
    ({ data }) => data
  );

export const makeGetInvitationStatusesDictionaryStatus = () =>
  createSelector<invitationsReducer, InvitationStatusesDictionary, ReturnType<typeof getDataStatus>>(getInvitationStatusesDictionary, getDataStatus);
