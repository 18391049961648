import { switchMap, filter, map } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { getStoryDetailsData, getTourThumbnailData, getToursData, getToursDetailsData } from '@/features/content/services/tours';
import { getPricesData, getTipsData, getTourStatusesData, getTourTypesData } from '@/features/content/services/dictionaries';
import { untilLocationChange, CommonError, dictionaryEffectHelper } from '@/common/utils';
import { toursReducer } from './reducers';
import { toursActions } from './actions';

const getToursList: Epic<toursActions, toursActions, toursReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(toursActions.list.request)),
    switchMap(() =>
      getToursData(state$.value.tours.list.params).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return toursActions.list.failure(response);
          }

          return toursActions.list.success(response);
        })
      )
    )
  );

const getStoryDetails: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(toursActions.story.request)),
    switchMap(action =>
      getStoryDetailsData(action.payload.params)
        .pipe(untilLocationChange(action$))
        .pipe(
          map(response => {
            if (response instanceof CommonError) {
              return toursActions.story.failure(response);
            }

            return toursActions.story.success(response);
          })
        )
    )
  );

const getTourDetails: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(toursActions.details.request)),
    switchMap(action =>
      getToursDetailsData(action.payload.params)
        .pipe(untilLocationChange(action$))
        .pipe(
          map(response => {
            if (response instanceof CommonError) {
              return toursActions.details.failure(response);
            }

            return toursActions.details.success(response);
          })
        )
    )
  );

const getTourThumbnail: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(toursActions.thumbnail.request)),
    switchMap(action =>
      getTourThumbnailData(action.payload.params)
        .pipe(untilLocationChange(action$))
        .pipe(
          map(response => {
            if (response instanceof CommonError) {
              return toursActions.thumbnail.failure(response);
            }

            return toursActions.thumbnail.success(response);
          })
        )
    )
  );

const getTipsDictionary: Epic<toursActions, toursActions, toursReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(toursActions.tips.request)),
    switchMap(() => {
      const state = state$.value.tours.tips;
      return dictionaryEffectHelper(() => getTipsData(), state, toursActions.tips);
    })
  ) as any;

const getTourStatusesDictionary: Epic<toursActions, toursActions, toursReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(toursActions.statuses.request)),
    switchMap(() => {
      const state = state$.value.tours.statuses;
      return dictionaryEffectHelper(() => getTourStatusesData(), state, toursActions.statuses);
    })
  ) as any;

const getPricesDictionary: Epic<toursActions, toursActions, toursReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(toursActions.prices.request)),
    switchMap(() => {
      const state = state$.value.tours.prices;
      return dictionaryEffectHelper(() => getPricesData(), state, toursActions.prices);
    })
  ) as any;

const getTypesDictionary: Epic<toursActions, toursActions, toursReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(toursActions.types.request)),
    switchMap(() => {
      const state = state$.value.tours.types;
      return dictionaryEffectHelper(() => getTourTypesData(), state, toursActions.types);
    })
  ) as any;

export const toursEpics = [
  getToursList,
  getTourDetails,
  getStoryDetails,
  getTipsDictionary,
  getTourStatusesDictionary,
  getPricesDictionary,
  getTourThumbnail,
  getTypesDictionary,
];
