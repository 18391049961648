import { createAction, ActionType, Action } from 'typesafe-actions';

import { LoggedUserDataVM, Role, TokenStatus } from '@/core/models/authentication';
import { RegionElementVM } from '@/core/models/data-center-regions';
import { ConsentDocumentListVM } from '@/models/consents';

export const authenticationActions = {
  getUserData: createAction('[AUTHENTICATION] get user data')(),
  setUserData: createAction('[AUTHENTICATION] set user data')<LoggedUserDataVM | undefined>(),
  getUserRole: createAction('[AUTHENTICATION] get user role')<string>(),
  setUserRole: createAction('[AUTHENTICATION] set user role')<Role | undefined>(),
  getUserOrganisationName: createAction('[AUTHENTICATION] get user organisation name')<string>(),
  setUserOrganisationName: createAction('[AUTHENTICATION] set user organisation name')<string | undefined>(),
  refreshUserConsents: createAction('[AUTHENTICATION] refresh user consents')(),
  setUserConsents: createAction('[AUTHENTICATION] set user consents')<ConsentDocumentListVM | undefined>(),
  clearUserData: createAction('[AUTHENTICATION] clear user data')(),
  setTokenStatus: createAction('[AUTHENTICATION] set token status')<TokenStatus>(),
  setUserCurrentRegion: createAction('[AUTHENTICATION] set user current region')<RegionElementVM | undefined>(),
  setUserDefaultRegion: createAction('[AUTHENTICATION] set user default region')<{ regionData: RegionElementVM | undefined; canUserChangeRegion: boolean }>(),
  restoreDefaultRegion: createAction('[AUTHENTICATION] restore user default region')(),
};

export type authenticationActions = Action | ActionType<typeof authenticationActions>;
