import { constants, Styles } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const attributionStyle: StyleFunction = ({ theme }) => ({
  borderTop: `1px solid ${theme.borderSecondary}`,
  height: 40,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  '.copyright-icon': {
    fontSize: constants.fontSize.icon,
    marginLeft: 55,
    color: theme.textFourth,
  },

  '.input-wrapper': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: `0 ${constants.offset.small}px 0 ${constants.offset.large}px`,
    width: 346,
  },

  '.value, .placeholder': {
    fontSize: constants.fontSize.h4,
    color: theme.textThird,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  '.value': {
    color: theme.textPrimary,
    maxWidth: 350,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  '.placeholder': {
    fontStyle: 'italic',
  },

  input: {
    width: '100%',
    lineHeight: '24px',
  },
});

export const wyswigStyle: Styles = {
  '.ql-tooltip': {
    zIndex: 1,
  },
};
