import { Styles, constants, theme } from '@/styles';

export const style: Styles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',

  '& > *': {
    marginLeft: constants.offset.medium,
  },

  '.action-button': {
    height: 26,

    '.icon': {
      backgroundColor: 'transparent',
    },

    '&--link': {
      height: 26,

      span: {
        textDecoration: 'none',

        '.icon': {
          color: theme.primary,
          fontSize: constants.fontSize.icon,

          ':hover': {
            color: theme.primaryHover,
          },

          ':active': {
            color: theme.primaryActive,
          },
        },
      },
    },
  },
};
